import * as React from 'react';
import { Localized } from '../../../../../common/hooks/LanguageProvider';
import {
    Box,
    Fade,
    Stack,
    Step,
    StepIcon,
    StepLabel,
    Stepper,
    Typography,
} from '@mui/material';
import { RequestStatus } from '../../../../../lib/hooks/ServerStateHooks';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { ParkingaboButton } from '../../../components/layout/ParkingaboButton';

export interface WizardStepInfo<Step extends number> {
    step: Step;
    label: React.ReactNode;
}

export function WizardStepper<Step extends number>({
    steps,
    stepsWithClearance,
    activeStep,
    offset = 0,
    onStepClick,
}: {
    steps: WizardStepInfo<Step>[];
    stepsWithClearance: Step[];
    activeStep: Step;
    offset?: number;
    onStepClick: (step: Step) => void;
}) {
    return (
        <Stepper
            activeStep={activeStep}
            alternativeLabel
            sx={theme => ({
                backgroundColor: theme.palette.primary.light,
                paddingTop: theme.spacing(3),
                paddingBottom: theme.spacing(2),
            })}
            nonLinear
        >
            {steps.map((stepInfo, index) => {
                const disabled = !stepsWithClearance.includes(stepInfo.step);
                return (
                    <Step
                        key={stepInfo.step}
                        sx={{
                            cursor: disabled ? 'default' : 'pointer',
                        }}
                        onClick={() => !disabled && onStepClick(stepInfo.step)}
                    >
                        <StepLabel
                            icon={
                                <StepIcon
                                    active={stepInfo.step === activeStep}
                                    icon={index + 1 + offset}
                                />
                            }
                        >
                            <Typography variant="overline">
                                {stepInfo.label}
                            </Typography>
                        </StepLabel>
                    </Step>
                );
            })}
        </Stepper>
    );
}

export function WizardBottomBar({
    onNextClick,
    nextLabel = <Localized de="Weiter" fr="Continuer" it="Avanti" en="Next" />,
    nextAsSubmitRequestState,
    onPreviousClick,
    previousLabel = (
        <Localized de="Zurück" fr="Retour" it="Indietro" en="Back" />
    ),
    hidePrevious = false,
}: {
    onNextClick?: () => void;
    nextLabel?: React.ReactNode;
    nextAsSubmitRequestState?: RequestStatus;
    onPreviousClick?: () => void;
    previousLabel?: React.ReactNode;
    hidePrevious?: boolean;
}) {
    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="stretch"
            spacing={0}
            sx={theme => ({
                backgroundColor: theme.palette.primary.light,
                padding: theme.spacing(1),
            })}
        >
            <ParkingaboButton
                onClick={onPreviousClick}
                disabled={!onPreviousClick}
                sx={{
                    visibility: hidePrevious ? 'hidden' : undefined,
                }}
            >
                <KeyboardArrowLeft /> {previousLabel}
            </ParkingaboButton>

            {nextAsSubmitRequestState ? (
                <ParkingaboButton
                    loading={nextAsSubmitRequestState === RequestStatus.PENDING}
                    variant="contained"
                    type="submit"
                    disabled={!onNextClick}
                    color="success"
                    sx={{ width: '50%' }}
                    key="success"
                    onClick={onNextClick}
                >
                    {nextLabel}
                </ParkingaboButton>
            ) : (
                <ParkingaboButton onClick={onNextClick} disabled={!onNextClick}>
                    {nextLabel} <KeyboardArrowRight />
                </ParkingaboButton>
            )}
        </Stack>
    );
}

export function WizardProductHeader({
    children,
}: {
    children: React.ReactNode;
}) {
    return (
        <Typography
            variant="h3"
            sx={theme => ({
                padding: theme.spacing(2),
                backgroundColor: theme.palette.grey[500],
                color: theme.palette.primary.contrastText,
                textAlign: 'center',
            })}
        >
            {children}
        </Typography>
    );
}

export function WizardFadeInEffect({
    children,
}: {
    children: React.ReactNode;
}): JSX.Element {
    return (
        <Fade in={true}>
            <div>{children}</div>
        </Fade>
    );
}

export function WizardBody({ children }: { children: React.ReactNode }) {
    return (
        <Box
            sx={{
                paddingX: theme => theme.spacing(3),
                paddingY: theme => theme.spacing(2),
                flexGrow: 1,
                overflowY: 'scroll',
                scrollbarWidth: 'none' /* Firefox */,
                msOverflowStyle: 'none' /* Internet Explorer, Edge */,
                '&::-webkit-scrollbar': {
                    display: 'none',
                } /* Chrome */,
                borderTop: '1px solid',
                borderBottom: '1px solid',
                borderColor: theme => theme.palette.background.default,
            }}
        >
            {children}
        </Box>
    );
}
