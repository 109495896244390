import { css } from '@emotion/css';
import * as React from 'react';
import { Clickable } from '../../../ui/Clickable';
import { Translation, txt } from '../Texts';
import { Lang } from '../Translate';
import { green, lightBlue, lineGrey, red, white } from './Colors';
import { Modal } from './Modal';
import { useState } from 'react';
import { DisplayPriority } from './DisplayPriority';

interface AlertBoxedProps extends AlertBoxProps {
    trigger: React.ReactElement<any>;
}

export function AlertBoxed({
    trigger,
    onConfirm,
    onCancel,
    confirmLabel,
    cancelLabel,
    children,
    confirmColor,
}: AlertBoxedProps) {
    const [showAlertBox, setShowAlertBox] = useState(false);

    const triggerElement = React.cloneElement(trigger, {
        onClick: onConfirm
            ? (e: React.SyntheticEvent<any>) => {
                  e.stopPropagation();
                  setShowAlertBox(true);
              }
            : undefined,
    });

    return (
        <div>
            {triggerElement}
            {showAlertBox && (
                <AlertBox
                    onConfirm={e => {
                        setShowAlertBox(false);
                        onConfirm && onConfirm(e);
                    }}
                    onCancel={() => {
                        setShowAlertBox(false);
                        onCancel && onCancel();
                    }}
                    confirmLabel={confirmLabel}
                    confirmColor={confirmColor}
                    cancelLabel={cancelLabel}
                    children={children}
                />
            )}
        </div>
    );
}

export function NotificationBoxed({
    trigger,
    children,
}: {
    children?: React.ReactChild | React.ReactChild[];
    trigger: React.ReactElement;
}) {
    const [showNotification, setShowNotification] = useState(false);
    const triggerElement = React.cloneElement(trigger, {
        onClick: (e: React.SyntheticEvent<any>) => {
            e.stopPropagation();
            setShowNotification(true);
        },
    });

    return (
        <div>
            {triggerElement}
            {showNotification && (
                <AlertBox
                    onCancel={() => setShowNotification(false)}
                    cancelLabel={txt.general.close}
                >
                    {children}
                </AlertBox>
            )}
        </div>
    );
}

interface AlertBoxProps {
    onConfirm?: (e: React.MouseEvent<any>) => void;
    onCancel?: () => void;
    confirmLabel?: Translation;
    cancelLabel?: Translation;
    children?: React.ReactChild | React.ReactChild[];
    confirmColor?: AlertBoxColor;
}

const buttonStyles: {
    height: string;
    lineHeight: string;
    textAlign: 'center';
    flex: string;
    padding: string;
    borderTop: string;
    whiteSpace: 'nowrap';
} = {
    height: '50px',
    lineHeight: '50px',
    textAlign: 'center',
    flex: '1 0 50%',
    padding: '0 24px',
    borderTop: `1px solid ${lineGrey(false)}`,
    whiteSpace: 'nowrap',
};

export enum AlertBoxColor {
    RED = 'red',
    GREEN = 'green',
    BLUE = 'blue',
}

export const AlertBox = (p: AlertBoxProps): JSX.Element => {
    return (
        <Modal>
            <div
                className={css({
                    position: 'absolute',
                    fontSize: '16px',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    background: 'rgba(4, 4 , 15, 0.4)',
                    zIndex: DisplayPriority.modal,
                })}
            >
                <div
                    className={css({
                        background: white,
                        margin: '0 32px',
                        position: 'relative',
                        overflow: 'hidden', // to prevent visibility of borderLeft of second button
                        borderRadius: '3px',
                    })}
                >
                    <div
                        className={css({
                            padding: '24px 16px',
                            textAlign: 'center',
                            maxHeight: '300px',
                            overflowY: 'auto',
                            WebkitOverflowScrolling: 'touch',
                        })}
                    >
                        {p.children}
                    </div>
                    <div className={css({ display: 'flex', flexWrap: 'wrap' })}>
                        {p.onCancel && (
                            <Clickable
                                element="div"
                                className={css(buttonStyles)}
                                onClick={p.onCancel}
                            >
                                <Lang
                                    {...(p.cancelLabel || txt.general.cancel)}
                                />
                            </Clickable>
                        )}
                        {p.onConfirm && (
                            <Clickable
                                className={css(buttonStyles, {
                                    borderLeft: `1px solid ${lineGrey(false)}`,
                                    marginLeft: '-1px',
                                    color: setAlertboxColor(p.confirmColor),
                                    fontWeight: 500,
                                })}
                                element="div"
                                onClick={p.onConfirm}
                            >
                                <Lang
                                    {...(p.confirmLabel || {
                                        de: 'OK',
                                        fr: 'OK',
                                        it: 'OK',
                                        en: 'OK',
                                    })}
                                />
                            </Clickable>
                        )}
                    </div>
                </div>
            </div>
        </Modal>
    );
};

function setAlertboxColor(color: AlertBoxColor | undefined) {
    switch (color) {
        case AlertBoxColor.RED:
            return red;
        case AlertBoxColor.GREEN:
            return green;
        default:
            return lightBlue;
    }
}
