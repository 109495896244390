import * as React from 'react';
import { ReactHookFormSelectOption } from '../../../common/components/material-ui/react-hook-form-fields/ReactHookFormSelect';
import { Localized } from '../../../common/hooks/LanguageProvider';
import { Language } from '../../../lib/Localized';
import { SavedCreditCardAliasResponse } from '../../../model/PaymentAlias';

export const MAX_CONTAINER_WIDTH = 444;

export enum Sex {
    Male = 'm',
    Female = 'f',
}

export enum AliasRequiredReason {
    ONBOARDING_REQUIRED = 'ONBOARDING_REQUIRED',
    DELETED = 'DELETED',
    INVALID = 'INVALID',
}

export interface ParkingaboUser {
    customerNr: string;
    email: string;
    requestedEmail: string | null;
    firstName: string | null;
    lastName: string | null;
    gender: Sex | null;
    language: Language;
    pendingTwintPaymentAttemptId?: number;
    alias: SavedCreditCardAliasResponse;
    aliasRequiredReason?: AliasRequiredReason;
    tenantId: number;
}

export const sexTranslations: { [key in Sex]: React.ReactNode } = {
    [Sex.Male]: <Localized de="Herr" fr="Mr." it="Signor" en="Mr." />,
    [Sex.Female]: <Localized de="Frau" fr="Mrs." it="Signora" en="Mrs." />,
};

export const sexOptions: ReactHookFormSelectOption<Sex>[] = [
    {
        value: Sex.Male,
        display: sexTranslations[Sex.Male],
    },
    {
        value: Sex.Female,
        display: sexTranslations[Sex.Female],
    },
];
