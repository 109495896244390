import * as React from 'react';
import { ReactHookFormSelectOption } from '../../../../../common/components/material-ui/react-hook-form-fields/ReactHookFormSelect';
import {
    Localized,
    useLanguage,
} from '../../../../../common/hooks/LanguageProvider';
import { LicensePlateCountry } from '../../../../../dto/LicensePlateCountry';
import { makeLocalizedText } from '../../../../../lib/Localized';
import {
    useParkingaboServerFetch,
    useParkingaboServerWrite,
} from '../../../api/ParkingaboApi';
import {
    VehicleForm,
    VehicleOnboardingPayload,
} from '../../../components/forms/VehicleForm';
import { ParkingaboAsyncLoadedSection } from '../../../components/layout/ParkingaboAsyncLoadedSection';
import { AuthedRouteCompProps } from '../../RouteUtils';
import {
    useNavigateOnSuccess,
    useServerSuccessEffect,
} from '../../../../../lib/hooks/ServerStateHooks';
import { ParkingaboLayoutWithHeader } from '../../../components/layout/ParkingaboLayoutWithHeader';
import { ParkingaboRoutedModal } from '../../../components/layout/ParkingaboRoutedModal';

export function AddVehicleRoute({ vehicles }: AuthedRouteCompProps) {
    const { language } = useLanguage();
    const translate = makeLocalizedText(language);

    const [countriesState] = useParkingaboServerFetch<
        LicensePlateCountry[],
        {}
    >(
        () => ({
            url: `/ui-api/meta/license-plate-countries`,
        }),
        {},
    );

    const [state, submit] = useParkingaboServerWrite<
        VehicleOnboardingPayload,
        string
    >(() => ({
        url: '/ui-api/parkingabo/user/self/vehicles',
    }));

    useServerSuccessEffect(state, vehicles.refetch);
    useNavigateOnSuccess(state, '..');

    return (
        <ParkingaboRoutedModal
            open={true}
            backUrl={'..'}
            render={() => (
                <ParkingaboLayoutWithHeader
                    backTo={'..'}
                    title={
                        <Localized
                            de="Fahrzeug hinzufügen"
                            fr="Ajouter un véhicule"
                            it="Aggiungi veicolo"
                            en="Add vehicle"
                        />
                    }
                >
                    <ParkingaboAsyncLoadedSection
                        state={countriesState}
                        render={data => {
                            const countryOptions = data.map<
                                ReactHookFormSelectOption<string>
                            >(c => ({
                                value: c.id,
                                display: translate(c.name),
                            }));

                            return (
                                <VehicleForm
                                    countriesOptions={countryOptions}
                                    requestState={state}
                                    submit={submit}
                                />
                            );
                        }}
                    />
                </ParkingaboLayoutWithHeader>
            )}
        />
    );
}
