/**
 * encodes given object to a url string
 */
export function encodeData(data: any): string {
    const props: string[] = [];
    for (const key in data) {
        if (
            data.hasOwnProperty(key) &&
            data[key] !== undefined &&
            data[key] !== null
        ) {
            props.push([key, data[key]].map(encodeURIComponent).join('='));
        }
    }
    return props.join('&');
}

export function getQueryParameters<T>(url: string): T {
    const parameters = {};

    if (url === '') {
        return <T>{};
    }

    url.split('&').forEach((param: string) => {
        let key: string;
        let value: string;
        [key, value] = param.split('=');
        if (typeof value === 'undefined') {
            value = key;
        }
        (<{ [i: string]: string }>parameters)[
            decodeURIComponent(key)
        ] = decodeURIComponent(value);
    });

    return <T>parameters;
}

export function getParameterByName(url: string, name: string): string | null {
    // courtesy of
    // http://stackoverflow.com/questions/901115/how-can-i-get-query-string-values-in-javascript
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
    const results = regex.exec(url);

    if (!results) {
        return null;
    }

    if (!results[2]) {
        return null;
    }

    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
