import { Box, Typography, useTheme } from '@mui/material';
import Alert from '@mui/material/Alert';
import * as React from 'react';
import { licensePlateOptions } from '../../../../common/components/material-ui/react-hook-form-fields/CommonOptions';
import { GenericFormSubmitError } from '../../../../common/components/material-ui/react-hook-form-fields/GenericFormSubmitError';
import {
    ReactHookFormDropdownSelect,
    ReactHookFormRadioSelect,
    ReactHookFormSelectOption,
} from '../../../../common/components/material-ui/react-hook-form-fields/ReactHookFormSelect';
import { ReactHookFormTextField } from '../../../../common/components/material-ui/react-hook-form-fields/ReactHookFormTextField';
import {
    Localized,
    useLanguage,
} from '../../../../common/hooks/LanguageProvider';
import { VehicleLicensePlateType } from '../../../../common/models/Vehicle';
import { useEasyForm } from '../../../../common/utils/FormHooksUtils';
import {
    RequestStatus,
    ServerRequestState,
} from '../../../../lib/hooks/ServerStateHooks';
import { CancelSave } from '../layout/CancelSave';
import { useNavigate } from 'react-router-dom';
import { ParkingaboButton } from '../layout/ParkingaboButton';
import { BackendRequestErrorMessage } from '../../../../common/components/material-ui/BackendRequestErrorMessage';
import { FeedbackPopup } from '../FeedbackPopup';
import { useState } from 'react';
import { ValidationData } from '../../../../common/utils/FormValidationHelpers';

enum Fields {
    Description = 'description',
    LicensePlate = 'licensePlateNr',
    Country = 'country',
    Type = 'type',
}

export type VehicleOnboardingPayload = {
    [Fields.Description]: string;
    [Fields.LicensePlate]?: string;
    [Fields.Country]?: string;
    [Fields.Type]?: VehicleLicensePlateType;
};

interface VehicleFormProps {
    requestState: ServerRequestState<string, ValidationData>;
    submit: (formData: VehicleOnboardingPayload) => void;
    deleteVehicle?: () => void;
    vehicleDeleteState?: ServerRequestState<string, ValidationData>;
    countriesOptions: ReactHookFormSelectOption<string>[];
    loading?: boolean;
    showOnboardingInfo?: boolean;
    style?: React.CSSProperties;
    readonly?: boolean;
    initialValues?: VehicleOnboardingPayload;
}

export function VehicleForm({
    requestState,
    submit,
    deleteVehicle,
    vehicleDeleteState,
    countriesOptions,
    loading,
    showOnboardingInfo,
    style,
    readonly,
    initialValues,
}: VehicleFormProps) {
    const theme = useTheme();
    const { language } = useLanguage();
    const navigate = useNavigate();
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);

    const {
        formInfo: { control, handleSubmit, reset, watch },
        formState: { isDirty },
        genericSubmitError,
    } = useEasyForm(undefined, requestState, language, {
        defaultValues: {
            [Fields.Description]: initialValues?.description ?? '',
            [Fields.LicensePlate]: initialValues?.licensePlateNr ?? '',
            [Fields.Country]: initialValues?.country ?? 'CH',
            [Fields.Type]: initialValues?.type ?? VehicleLicensePlateType.CAR,
        },
    });

    const description = watch(Fields.Description);
    const lp = watch(Fields.LicensePlate);

    React.useEffect(() => {
        if (initialValues) {
            reset();
        }
    }, [initialValues]);

    function handleCancel() {
        reset();
        navigate(-1);
    }

    return (
        <>
            <FeedbackPopup
                open={deleteConfirmOpen}
                onAbort={() => setDeleteConfirmOpen(false)}
                onConfirm={() => {
                    setDeleteConfirmOpen(false);
                    deleteVehicle && deleteVehicle();
                }}
                abortLabel={
                    <Localized
                        de="Abbrechen"
                        fr="Annuler"
                        it="Annulla"
                        en="Cancel"
                    />
                }
                confirmLabel={
                    <Localized
                        de="Löschen"
                        fr="Effacer"
                        it="Elimina"
                        en="Delete"
                    />
                }
                title={
                    <Localized
                        de="Fahrzeug löschen"
                        fr="Supprimer véhicule"
                        it="Eliminare veicolo"
                        en="Delete vehicle"
                    />
                }
            >
                <Localized
                    de={`Wollen Sie wirklich das Fahrzeug «${description}» löschen?`}
                    fr={`Voulez-vous vraiment effacer le véhicule «${description}»?`}
                    it={`Vuole veramente eliminare il veicolo «${description}»?`}
                    en={`Do you really want to delete the vehicle «${description}»?`}
                />
            </FeedbackPopup>
            <form
                style={{
                    flexGrow: 1,
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'column',
                    ...style,
                }}
                onSubmit={handleSubmit(submit)}
            >
                <Box>
                    <ReactHookFormRadioSelect
                        style={{
                            marginBottom: theme.spacing(3),
                        }}
                        control={control}
                        name={Fields.Type}
                        label={
                            <Localized
                                de="Fahrzeugtyp"
                                fr="Type de véhicule"
                                it="Tipo veicolo"
                                en="Vehicle type"
                            />
                        }
                        options={licensePlateOptions(language)}
                        readonly={readonly}
                    />
                    <ReactHookFormTextField
                        style={{
                            marginBottom: theme.spacing(3),
                        }}
                        control={control}
                        name={Fields.Description}
                        required={true}
                        label={
                            <Localized
                                de="Beschreibung"
                                fr="Description"
                                it="Descrizione"
                                en="Description"
                            />
                        }
                        readonly={readonly}
                    />
                    <ReactHookFormTextField
                        style={{
                            marginBottom: theme.spacing(3),
                        }}
                        control={control}
                        name={Fields.LicensePlate}
                        required={true}
                        label={
                            <Localized
                                de="Kennzeichen"
                                fr="Immatriculation"
                                it="Targa"
                                en="License plate"
                            />
                        }
                        readonly={readonly}
                    />
                    <ReactHookFormDropdownSelect
                        style={{
                            marginBottom: theme.spacing(3),
                        }}
                        control={control}
                        name={Fields.Country}
                        label={
                            <Localized
                                de="Land"
                                fr="Pays"
                                it="Nazione"
                                en="Country"
                            />
                        }
                        options={countriesOptions}
                        readonly={readonly}
                    />
                    <GenericFormSubmitError
                        submitState={requestState}
                        error={genericSubmitError}
                    />
                    {vehicleDeleteState && (
                        <BackendRequestErrorMessage
                            requestState={vehicleDeleteState}
                        />
                    )}
                    {showOnboardingInfo && (
                        <Alert
                            color="info"
                            style={{ marginBottom: theme.spacing(2) }}
                        >
                            <Typography>
                                <Localized
                                    de="Nach der Registrierung können weitere Fahrzeuge direkt im Konto erfasst und verwaltet werden."
                                    fr="Après l'enregistrement, d'autres véhicules peuvent être saisis et gérés directement dans le compte."
                                    it="Dopo la registrazione, è possibile aggiungere e gestire altri veicoli direttamente nel conto."
                                    en="After registration, additional vehicles can be added and managed directly in the account."
                                />
                            </Typography>
                        </Alert>
                    )}
                </Box>
                {!readonly && (isDirty || !deleteVehicle) && (
                    <CancelSave
                        onCancel={
                            !showOnboardingInfo ? handleCancel : undefined
                        }
                        saveDisabled={
                            !isDirty ||
                            description.length === 0 ||
                            lp.length === 0
                        }
                        requestState={requestState}
                        loading={loading}
                    />
                )}
                {!readonly && !isDirty && deleteVehicle && (
                    <ParkingaboButton
                        loading={
                            vehicleDeleteState?.status === RequestStatus.PENDING
                        }
                        variant={'outlined'}
                        onClick={() => setDeleteConfirmOpen(true)}
                    >
                        <Localized
                            de="Löschen"
                            fr="Supprimer"
                            it="Elimina"
                            en="Delete"
                        />
                    </ParkingaboButton>
                )}
            </form>
        </>
    );
}
