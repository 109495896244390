import * as React from 'react';
import { useMemo, useState } from 'react';
import {
    Localized,
    useTranslation,
} from '../../../../../common/hooks/LanguageProvider';
import { Box, InputBase, Stack, Typography } from '@mui/material';
import { ParkingaboProductTemplate } from './ParkingaboProductTemplateModels';
import {
    WizardBody,
    WizardBottomBar,
    WizardFadeInEffect,
    WizardStepInfo,
    WizardStepper,
} from './WizardComponents';
import { ErrorAlert } from '../../../../../common/components/material-ui/ErrorAlert';
import { SelectableList } from '../../../components/SelectableList';
import { useNavigate } from 'react-router-dom';
import { useProductAddContext } from './ProductsAddOutlet';
import {
    ParkingaboCityInfo,
    ParkingaboZoneInfo,
} from '../../../shared/ParkingaboProductModels';
import { normalizeString } from '../../../../../lib/utils/SearchNormalize';
import { Clear, Search } from '@mui/icons-material';

const SEARCH_ACTIVE_LIST_LENGTH = 10;

enum WizardStep {
    CITY,
    ZONE,
    PRODUCT,
}

export interface WizardSelectionState {
    city: ParkingaboCityInfo | null;
    zone: ParkingaboZoneInfo | null;
    product: ParkingaboProductTemplate | null;
}

export function ProductsSelectionOutlet() {
    const {
        productTemplates,
        zones,
        cities,
        productSelectionState,
        setProductSelectionState,
        productNotFound,
    } = useProductAddContext();

    const navigate = useNavigate();

    const [activeStep, setActiveStep] = React.useState<WizardStep>(
        productSelectionState.zone ? WizardStep.PRODUCT : WizardStep.CITY,
    );

    const steps: WizardStepInfo<WizardStep>[] = [
        {
            step: WizardStep.CITY,
            label: <Localized de="Ort" fr="Lieu" it="Località" en="City" />,
        },
        {
            step: WizardStep.ZONE,
            label: (
                <Localized
                    de="Zone / Parking"
                    fr="Zone / Parking"
                    it="Zona / Parcheggio"
                    en="Zone / Parking"
                />
            ),
        },
        {
            step: WizardStep.PRODUCT,
            label: (
                <Localized
                    de="Produkt"
                    fr="Produit"
                    it="Prodotto"
                    en="Product"
                />
            ),
        },
    ];

    const stepsWithClearance = useMemo(
        () => determineStepsWithClearance(productSelectionState),
        [productSelectionState],
    );

    function handleNext() {
        switch (activeStep) {
            case WizardStep.CITY:
                setActiveStep(activeStep + 1);
                break;
            case WizardStep.ZONE:
                setActiveStep(activeStep + 1);
                break;
            case WizardStep.PRODUCT:
                if (productSelectionState.product) {
                    navigate(
                        productSelectionState.product.contractTemplateId.toString(),
                    );
                }
                break;
        }
    }

    return (
        <>
            {productNotFound && (
                <ErrorAlert>
                    <Localized
                        de="Produkt nicht gefunden. Wählen Sie manuell ein Produkt aus."
                        fr="Produit pas trouvé. Sélectionnez manuellement un produit."
                        it="Prodotto non trovato. Selezioni un prodotto manualmente."
                        en="Product not found. Select a product manually."
                    />
                </ErrorAlert>
            )}
            <WizardStepper
                steps={steps}
                stepsWithClearance={stepsWithClearance}
                activeStep={activeStep}
                onStepClick={setActiveStep}
            />
            <WizardBody>
                {activeStep === WizardStep.CITY && (
                    <CitySelection
                        cities={cities}
                        selectedCity={productSelectionState.city?.city}
                        onCityChange={city => {
                            setProductSelectionState({
                                city: city,
                                zone: null,
                                product: null,
                            });
                            handleNext();
                        }}
                    />
                )}
                {activeStep === WizardStep.ZONE && productSelectionState.city && (
                    <ZoneSelection
                        zones={zones}
                        selectedZoneId={productSelectionState.zone?.zoneId}
                        selectedCity={productSelectionState.city.city}
                        onZoneChange={zone => {
                            setProductSelectionState({
                                ...productSelectionState,
                                zone: zone,
                                product: null,
                            });
                            handleNext();
                        }}
                    />
                )}
                {activeStep === WizardStep.PRODUCT &&
                    productSelectionState.zone && (
                        <ProductSelection
                            products={productTemplates}
                            selectedContractTemplateId={
                                productSelectionState.product
                                    ?.contractTemplateId
                            }
                            selectedZoneId={productSelectionState.zone.zoneId}
                            onProductChange={product => {
                                setProductSelectionState({
                                    ...productSelectionState,
                                    product,
                                });
                                if (product) {
                                    navigate(
                                        product.contractTemplateId.toString(),
                                    );
                                }
                            }}
                        />
                    )}
            </WizardBody>
            <WizardBottomBar
                onNextClick={
                    stepsWithClearance.includes(activeStep + 1)
                        ? handleNext
                        : undefined
                }
                onPreviousClick={
                    activeStep === WizardStep.PRODUCT ||
                    activeStep === WizardStep.ZONE
                        ? () => setActiveStep(activeStep - 1)
                        : undefined
                }
                hidePrevious={activeStep === WizardStep.CITY}
            />
        </>
    );
}

function determineStepsWithClearance(selection: WizardSelectionState) {
    const steps = Object.values(WizardStep).filter(
        (step): step is WizardStep =>
            typeof step !== 'string' && typeof step !== 'undefined',
    );
    const stepsIncludingLastNextStep = [...steps, steps[steps.length - 1] + 1];
    return stepsIncludingLastNextStep.filter(step => {
        switch (step) {
            case WizardStep.CITY:
                return true;
            case WizardStep.ZONE:
                return Boolean(selection.city);
            case WizardStep.PRODUCT:
                return Boolean(selection.zone);
            default:
                return (
                    Boolean(selection.zone) &&
                    Boolean(selection.product) &&
                    Boolean(selection.city)
                );
        }
    });
}

function CitySelection({
    cities,
    selectedCity,
    onCityChange,
}: {
    cities: ParkingaboCityInfo[];
    selectedCity: string | undefined;
    onCityChange: (city: ParkingaboCityInfo | null) => void;
}) {
    const [searchString, setSearchString] = useState('');
    const selectionItems = useMemo(
        () =>
            cities
                .filter(city => {
                    if (searchString === '') {
                        return true;
                    }
                    const normalizedSearchString = normalizeString(
                        searchString.toLocaleLowerCase(),
                    );
                    const cityMatch = normalizeString(
                        city.city.toLocaleLowerCase(),
                    ).includes(normalizedSearchString);
                    if (cityMatch) {
                        return true;
                    }
                    const zipCodeMatch = normalizeString(
                        city.zipCode.toLocaleLowerCase(),
                    ).includes(normalizedSearchString);
                    return zipCodeMatch;
                })
                .map(city => ({
                    id: city.city,
                    title: city.city,
                    content: <Typography>{city.zipCode}</Typography>,
                })),
        [cities, searchString],
    );

    const placeholderText = useTranslation({
        de: 'Suchen',
        fr: 'Recherche',
        it: 'Cerca',
        en: 'Search',
    });

    function handleSelectionChange(selectedCities: string[]) {
        const selectedZone =
            cities.find(city => city.city === selectedCities[0]) ?? null;
        onCityChange(selectedZone);
    }

    const initialSelection = selectedCity ? [selectedCity] : undefined;

    return (
        <WizardFadeInEffect>
            {cities.length > SEARCH_ACTIVE_LIST_LENGTH && (
                <SearchField
                    placeholder={placeholderText}
                    onChange={setSearchString}
                    onClear={() => setSearchString('')}
                    value={searchString}
                />
            )}
            <SelectableList
                selectionItems={selectionItems}
                onChange={handleSelectionChange}
                initialSelection={initialSelection}
                preventDeselect
            />
        </WizardFadeInEffect>
    );
}

function SearchField({
    placeholder,
    onChange,
    onClear,
    value,
}: {
    placeholder?: string;
    onChange: (inputString: string) => void;
    onClear: () => void;
    value: string;
}) {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                padding: 1,
                marginBottom: 2,
                border: '1px solid',
                borderRadius: theme => theme.shape.borderRadius + 'px',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexShrink: 0,
                    marginRight: 1,
                }}
            >
                <Search />
            </Box>
            <InputBase
                sx={{ flex: 1 }}
                placeholder={placeholder}
                onChange={e => onChange(e.currentTarget.value)}
                value={value}
            />
            {Boolean(value) && (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexShrink: 0,
                        marginLeft: 1,
                        cursor: 'pointer',
                    }}
                    onClick={onClear}
                >
                    <Clear />
                </Box>
            )}
        </Box>
    );
}

function ZoneSelection({
    zones,
    selectedZoneId,
    selectedCity,
    onZoneChange,
}: {
    zones: ParkingaboZoneInfo[];
    selectedZoneId: number | undefined;
    selectedCity: string;
    onZoneChange: (zone: ParkingaboZoneInfo | null) => void;
}) {
    const selectionItems = useMemo(() => {
        const availableZonesOfSelectedCity = zones.filter(
            zone => zone.city === selectedCity,
        );
        return availableZonesOfSelectedCity.map(zone => ({
            id: zone.zoneId,
            title: zone.name,
            content: <Typography>{zone.city}</Typography>,
        }));
    }, [zones, selectedCity]);

    const initialSelection = selectedZoneId ? [selectedZoneId] : undefined;

    return (
        <WizardFadeInEffect>
            <SelectableList
                selectionItems={selectionItems}
                onChange={(selectedIds: number[]) => {
                    const selectedZone =
                        zones.find(zone => zone.zoneId === selectedIds[0]) ??
                        null;
                    onZoneChange(selectedZone);
                }}
                initialSelection={initialSelection}
                preventDeselect
            />
        </WizardFadeInEffect>
    );
}

function ProductSelection({
    products,
    selectedContractTemplateId,
    selectedZoneId,
    onProductChange,
}: {
    products: ParkingaboProductTemplate[];
    selectedContractTemplateId?: number;
    selectedZoneId: number;
    onProductChange: (zone: ParkingaboProductTemplate | null) => void;
}) {
    const selectionItems = useMemo(() => {
        const availableProductsOfSelectedZone = products.filter(product =>
            product.zoneIds.includes(selectedZoneId),
        );
        return availableProductsOfSelectedZone.map(product => ({
            id: product.contractTemplateId,
            content: (
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Typography display="inline" fontWeight="bold">
                        <Localized {...product.name} />
                    </Typography>
                </Stack>
            ),
        }));
    }, [products, selectedZoneId]);

    function handleSelectionChange(selectedIds: number[]) {
        const selectedProduct =
            products.find(
                product => product.contractTemplateId === selectedIds[0],
            ) ?? null;
        onProductChange(selectedProduct);
    }

    const initialSelection = selectedContractTemplateId
        ? [selectedContractTemplateId]
        : undefined;

    return (
        <WizardFadeInEffect>
            <SelectableList
                selectionItems={selectionItems}
                onChange={handleSelectionChange}
                initialSelection={initialSelection}
                preventDeselect
            />
        </WizardFadeInEffect>
    );
}
