import {
    RequestStatus,
    ServerRequestState,
} from '../../../lib/hooks/ServerStateHooks';
import * as React from 'react';
import {
    ValidationData,
    isFormGlobalViolationEntry,
} from '../../utils/FormValidationHelpers';
import { Localized } from '../../hooks/LanguageProvider';
import { ErrorAlert } from './ErrorAlert';

export function BackendRequestErrorMessage<D, ED extends ValidationData>({
    requestState,
}: {
    requestState: ServerRequestState<D, ED>;
}) {
    const isError = requestState.status === RequestStatus.ERROR;

    if (isError && !!requestState.data && !!requestState.data.violations) {
        const globalValidationError = requestState.data.violations.filter(
            isFormGlobalViolationEntry,
        );
        return (
            <>
                {globalValidationError.map((e, i) => (
                    <ErrorAlert key={i}>
                        <Localized
                            {...e.issue.humanReadableMessage.byLanguage}
                        />
                    </ErrorAlert>
                ))}
            </>
        );
    }

    if (isError) {
        return (
            <ErrorAlert>
                <Localized
                    de={`Fehler bei der Anfrage (Code ${requestState.httpStatusCode})`}
                    fr={`Erreur dans la requête (code ${requestState.httpStatusCode})`}
                    it={`Errore nella richiesta (codice ${requestState.httpStatusCode})`}
                    en={`Request error (code ${requestState.httpStatusCode})`}
                />
            </ErrorAlert>
        );
    }

    return null;
}
