import { css } from '@emotion/css';
import * as React from 'react';
import { Clickable } from '../../ui/Clickable';
import { LicensePlateState } from './state/SharedState';

export type Translation = {
    de: React.ReactChild;
    fr: React.ReactChild;
    it: React.ReactChild;
    en: React.ReactChild;
};

export const txt: Texts = {
    lp: {
        type: {
            de: 'Fahrzeugtyp',
            fr: 'Type de véhicule',
            it: 'Tipo di veicolo',
            en: 'Vehicle type',
        },
        typeCar: {
            de: 'Motorwagen',
            fr: 'Voiture',
            it: 'Automobile',
            en: 'Car',
        },
        typeMotorcycle: {
            de: 'Motorrad',
            fr: 'Moto',
            it: 'Motocicletta',
            en: 'Motorcycle',
        },
        licensePlate: {
            de: 'Kennzeichen',
            fr: 'Plaque',
            it: 'Numero di targa',
            en: 'License plate',
        },
        licensePlateHint: {
            de: 'z.B. ZH123456',
            fr: 'p.ex. VD123456',
            it: 'p.es. TI123456',
            en: 'e.g. ZH123456',
        },
        mostUsedCountries: {
            de: 'Nachbarländer',
            fr: 'Pays voisins',
            it: 'Nazioni limitrofi',
            en: 'Neighboring countries',
        },
        allCountries: {
            de: 'Andere Länder',
            fr: 'Autres pays',
            it: 'Altre nazioni',
            en: 'Other countries',
        },
        delete: { de: 'Löschen', fr: 'Effacer', it: 'Elimina', en: 'Delete' },
        deleteDescription: {
            de: 'Möchten Sie das Fahrzeug unwiderrufbar löschen?',
            fr: 'Voulez-vous supprimer définitivement ce véhicule?',
            it: 'Vuole eliminare definitivamente questo veicolo?',
            en: 'Would you like to delete this vehicle permanently?',
        },
        addLicensePlate: {
            de: 'Weiteres Fahrzeug hinzufügen',
            fr: 'Ajouter un autre véhicule',
            it: 'Aggiungi un altro veicolo',
            en: 'Add another vehicle',
        },
        cars: {
            de: 'Fahrzeuge',
            fr: 'Véhicules',
            it: 'Veicoli',
            en: 'Vehicles',
        },
        lprNotificationTitle: {
            de: 'KENNZEICHENERKENNUNG',
            fr: 'RECONNAISSANCE DE PLAQUE',
            it: 'RICONOSCIMENTO TARGA',
            en: 'LICENSE PLATE RECOGNITION',
        },
        lprNotificationText: onClick => {
            return {
                de: (
                    <>
                        Bei{' '}
                        <Clickable
                            element={'span'}
                            className={css({ textDecoration: 'underline' })}
                            onClick={onClick}
                        >
                            Parkings mit dieser Funktion
                        </Clickable>{' '}
                        <br />
                        einfach ohne Ticket ein- und ausfahren.
                    </>
                ),
                fr: (
                    <>
                        Entrer et sortir sans ticket
                        <br />
                        dans les{' '}
                        <Clickable
                            element={'span'}
                            className={css({ textDecoration: 'underline' })}
                            onClick={onClick}
                        >
                            parkings dotés de cette fonction
                        </Clickable>
                        .
                    </>
                ),
                it: (
                    <>
                        Entrare e uscire senza prendere il ticket,
                        <br />
                        nei{' '}
                        <Clickable
                            element={'span'}
                            className={css({ textDecoration: 'underline' })}
                            onClick={onClick}
                        >
                            parcheggi provvisti di questa funzione
                        </Clickable>
                        .
                    </>
                ),
                en: (
                    <>
                        Enter and exit without a ticket,
                        <br />
                        in{' '}
                        <Clickable
                            element={'span'}
                            className={css({ textDecoration: 'underline' })}
                            onClick={onClick}
                        >
                            parkings equipped with this feature
                        </Clickable>{' '}
                        .
                    </>
                ),
            };
        },
        lprDeleteLpTitle: {
            de: 'Fahrzeug deaktivieren',
            fr: 'Désactiver le véhicule',
            it: 'Disattiva veicolo',
            en: 'Disable vehicle',
        },
        lprDeleteLpDesc: lp => {
            return {
                de: (
                    <>
                        {`Wollen Sie die Kennzeichenerkennung bei Parkings mit Schranken für das Kennzeichen `}
                        <b>{lp}</b>
                        {` deaktivieren?`}
                    </>
                ),
                fr: (
                    <>
                        {`Vous souhaitez désactiver la reconnaissance de l'immatriculation `}
                        <b>{lp}</b>
                        {` dans les parkings à barrières?`}
                    </>
                ),
                it: (
                    <>
                        {`Vuole disattivare il riconoscimento della targa `}
                        <b>{lp}</b>
                        {` nei parcheggi con barriere?`}
                    </>
                ),
                en: (
                    <>
                        {`Do you want to disable the recognition of the license plate `}
                        <b>{lp}</b>
                        {` in parkings with barriers?`}
                    </>
                ),
            };
        },
        lprActivateLpTitle: {
            de: 'Fahrzeug aktivieren',
            fr: 'Activer le véhicule',
            it: 'Attiva veicolo',
            en: 'Enable vehicle',
        },
        lprActivateLpDesc: lp => {
            return {
                de: (
                    <>
                        {`Wollen Sie die Kennzeichenerkennung bei Parkings mit Schranken für das Kennzeichen `}
                        <b>{lp}</b>
                        {` aktivieren?`}
                    </>
                ),
                fr: (
                    <>
                        {`Vous souhaitez activer la reconnaissance de l'immatriculation `}
                        <b>{lp}</b>
                        {` dans les parkings à barrières?`}
                    </>
                ),
                it: (
                    <>
                        {`Vuole attivare il riconoscimento della targa `}
                        <b>{lp}</b>
                        {` nei parcheggi con barriere?`}
                    </>
                ),
                en: (
                    <>
                        {`Do you want to enable the recognition of the license plate `}
                        <b>{lp}</b>
                        {` in parkings with barriers?`}
                    </>
                ),
            };
        },
        lprActivateLpFees: {
            de: 'Zuschlag: CHF 0.25 pro Transaktion',
            fr: 'Supplément: CHF 0.25 par transaction',
            it: 'Supplemento: CHF 0.25 per transazione',
            en: 'Surcharge: CHF 0.25 per transaction',
        },
        lprErrorTitle: {
            de: 'Fahrzeug nicht aktivierbar',
            fr: 'Véhicule non activable',
            it: 'Veicolo non attivabile',
            en: 'Vehicle not activatable',
        },
        lprErrorReasonOccupied: lp => {
            return {
                de: (
                    <>
                        {`Für das Fahrzeug `}
                        <b>{lp}</b>
                        {` wurde die Kennzeichenerkennung schon in einer anderen TWINT App aktiviert.`}
                    </>
                ),
                fr: (
                    <>
                        {`Pour le véhicule `}
                        <b>{lp}</b>
                        {`, la reconnaissance des plaques d'immatriculation a déjà été activée dans une autre appli TWINT.`}
                    </>
                ),
                it: (
                    <>
                        {`Per il veicolo `}
                        <b>{lp}</b>
                        {`, il riconoscimento della targa è già stato attivato in un'altra app TWINT.`}
                    </>
                ),
                en: (
                    <>
                        {`For vehicle `}
                        <b>{lp}</b>
                        {` the license plate recognition has already been activated in another TWINT app.`}
                    </>
                ),
            };
        },
        lprErrorReasonNotAllowedMotorcycle: lp => {
            return {
                de: (
                    <>
                        {`Das Fahrzeug `}
                        <b>{lp}</b>
                        {` kann nicht aktiviert werden, da es sich um ein Motorrad handelt.`}
                    </>
                ),
                fr: (
                    <>
                        {`Le véhicule `}
                        <b>{lp}</b>
                        {` ne peut pas être activé, puisque c'est une moto.`}
                    </>
                ),
                it: (
                    <>
                        {`Il veicolo `}
                        <b>{lp}</b>
                        {` non può essere attivato, dal momento che è una moto.`}
                    </>
                ),
                en: (
                    <>
                        {`The vehicle `}
                        <b>{lp}</b>
                        {` can not be activated, as it is a motorcycle.`}
                    </>
                ),
            };
        },
        lprErrorReasonNotAllowedDiplomatic: lp => {
            return {
                de: (
                    <>
                        {`Das Fahrzeug `}
                        <b>{lp}</b>
                        {` kann nicht aktiviert werden, da es sich um ein Diplomatenkennzeichen handelt.`}
                    </>
                ),
                fr: (
                    <>
                        {`Le véhicule `}
                        <b>{lp}</b>
                        {` ne peut pas être activé, puisque c'est une plaque diplomatique.`}
                    </>
                ),
                it: (
                    <>
                        {`Il veicolo `}
                        <b>{lp}</b>
                        {` non può essere attivato, dal momento che è una targa diplomatica.`}
                    </>
                ),
                en: (
                    <>
                        {`The vehicle `}
                        <b>{lp}</b>
                        {` can not be activated, as it is a diplomatic plate.`}
                    </>
                ),
            };
        },
    },
    lpValidation: {
        allLettersUppercase: {
            de: 'Nur Grossbuchstaben erlaubt.',
            fr: 'Seulement lettres capitales autorisées.',
            it: 'Sono consentite solo lettere maiuscole.',
            en: 'Only uppercase letters are allowed.',
        },
        countryMustBeFL: {
            de: 'Das Kennzeichen muss  mit FL beginnen',
            fr: "L'immatriculation doit commencer par FL",
            it: 'Il numero di targa deve cominciare con FL',
            en: 'The license plate must start with FL',
        },
        hasMoreThanFiveNumbers: {
            de: 'Maximal 5 Zahlen erlaubt',
            fr: 'Maximum 5 chiffres autorisés',
            it: 'Sono ammessi al max. 5 numeri',
            en: 'Max 5 numbers permitted',
        },
        hasMoreThanSixNumbers: {
            de: 'Maximal 6 Zahlen erlaubt',
            fr: 'Maximum 6 chiffres autorisés',
            it: 'Sono ammessi al max. 6 numeri',
            en: 'Max 6 numbers permitted',
        },
        lettersAfterCanton: {
            de: 'Nur Zahlen nach Kantonskürzel erlaubt',
            fr: 'Seulement des chiffres autorisés après le code canton',
            it: 'Sono consentite solo cifre dopo il codice del cantone',
            en: 'Only numbers after canton abbreviation',
        },
        lettersAfterCountryFL: {
            de: 'Nur Zahlen nach FL-Kürzel erlaubt',
            fr: 'Seule les chiffres sont autorisés après le code FL',
            it: 'Sono consentite solo cifre dopo il codice FL',
            en: 'Only numbers after FL abbreviation',
        },
        minThreeLetters: {
            de: 'Mindestens 3 Zeichen eingeben',
            fr: 'Saisir au moins 3 caractères',
            it: 'Inserire almeno 3 caratteri',
            en: 'Enter at least 3 characters',
        },
        noValidCanton: {
            de: 'Keine gültige Kantonsbezeichnung',
            fr: "Le code du canton n'est pas valable",
            it: 'Il codice del cantone non è valido',
            en: 'No valid canton abbreviation',
        },
        noWhiteSpace: {
            de: 'Keine Leerzeichen erlaubt',
            fr: 'Espaces non autorisés',
            it: 'Non sono consentiti spazi',
            en: 'No spaces allowed',
        },
        mandatory: {
            de: 'Kennzeichen zwingend',
            fr: "L'immatriculation est obligatoire",
            it: 'Numero di targa obbligatorio',
            en: 'License plate mandatory',
        },
    },
    parkingsList: {
        parkingsListTitle: {
            de: 'Parkings mit Kennzeichenerkennung',
            fr: 'Parkings avec reconnaissance de plaque',
            it: 'Parcheggi con riconoscimento targa',
            en: 'Parkings with license plate recognition',
        },
    },
    lprSettings: {
        title: {
            de: 'Kennzeichenerkennung',
            fr: 'Reconnaissance de plaque',
            it: 'Riconoscimento targa',
            en: 'License plate recognition',
        },
        lprFunctionDescription: {
            de:
                'Mit dieser Funktion können Sie bei entsprechend ausgestatteten Parkings direkt ein- und ausfahren, ohne ein Ticket zu ziehen oder die Kasse zu bedienen.',
            fr:
                'Cette fonction vous permet de directement entrer et sortir des parkings convenablement équipés sans avoir à prendre un ticket ou passer à la caisse.',
            it:
                'Questa funzione vi permette di entrare e uscire direttamente dai parcheggi opportunamente attrezzati senza dover prendere un ticket o passare dalla cassa.',
            en:
                'With this function, you can drive directly in and out of appropriately equipped parkings without having to take a ticket or pay at the ticket machines.',
        },
        showParkingslist: {
            de: 'Parkingliste anzeigen',
            fr: 'Afficher liste des parkings',
            it: 'Mostra lista parcheggi',
            en: 'Show parkings list',
        },
        lprFeeDescription: {
            de: (
                <>
                    Zusätzlich zu den im jeweiligen Parking geltenden
                    Parkgebühren wird ein{' '}
                    <b>Zuschlag von CHF 0.25 pro Transaktion</b> erhoben.
                </>
            ),
            fr: (
                <>
                    En plus des frais de stationnement applicables dans le
                    parking concerné,{' '}
                    <b>un supplément de CHF 0.25 par transaction</b> sera
                    prélevé.
                </>
            ),
            it: (
                <>
                    In aggiunta alle tariffe di parcheggio in vigore nel
                    rispettivo parcheggio, viene riscosso{' '}
                    <b>un supplemento di 0.25 CHF per transazione</b>.
                </>
            ),
            en: (
                <>
                    In addition to the parking fees applicable in the given
                    parking, <b>a surcharge of CHF 0.25 per transaction</b> will
                    be charged.
                </>
            ),
        },
        toggleLPR: {
            de: 'Kennzeichenerkennung aktiv',
            fr: "Reconnaissance de l'immatriculation active",
            it: 'Riconoscimento della targa attivo',
            en: 'License plate recognition active',
        },
        deactivateLPRTitle: {
            de: 'KENNZEICHENERKENNUNG DEAKTIVIEREN',
            fr: 'DÉSACTIVER LA RECONNAISSANCE DE PLAQUE',
            it: 'DISATTIVARE RICONOSCIMENTO TARGA',
            en: 'DEACTIVATE LICENSE PLATE RECOGNITION',
        },
        deactivateLPRQuestion: {
            de: 'Möchten Sie die Kennzeichenerkennung wirklich deaktivieren?',
            fr:
                "Souhaitez-vous vraiment désactiver la reconnaissance de l'immatriculation?",
            it: 'Vuoi veramente disattivare il riconoscimento della targa?',
            en:
                'Do you really want to deactivate the license plate recognition?',
        },
    },
    lprActivation: {
        consentTitle: {
            de: 'KENNZEICHENERKENNUNG',
            fr: 'RECONNAISSANCE DE PLAQUE',
            it: 'RICONOSCIMENTO TARGA',
            en: 'LICENSE PLATE RECOGNITION',
        },
        consentTwintPermission: {
            de:
                'Diese Funktion erfordert die Berechtigung, Ihre TWINT App ohne weitere Bestätigung zu belasten.',
            fr:
                'Cette fonction nécessite une autorisation pour débiter votre appli TWINT sans autre confirmation.',
            it:
                'Questa funzione richiede il permesso di addebitare la sua app TWINT senza ulteriori conferme.',
            en:
                'This function requires authorization to debit your TWINT app without further confirmation.',
        },
        consentTwintAcceptNote: {
            de: 'Bitte akzeptieren Sie im nächsten Schritt diese Änderung.',
            fr: 'Veuillez accepter ce changement lors de la prochaine étape.',
            it:
                'Si prega di accettare questo cambiamento nel prossimo passaggio.',
            en: 'Please accept this change in the next step.',
        },
        pairingOngoingTitle: {
            de: 'AUTOMATISCHE ZAHLUNG',
            fr: 'PAIEMENT AUTOMATIQUE',
            it: 'PAGAMENTO AUTOMATICO',
            en: 'AUTOMATIC PAYMENT',
        },
        pairingOngoingDesc: {
            de: 'Die Aktivierung wird bearbeitet...',
            fr: "L'activation sera traité...",
            it: "L'attivazione viene elaborata...",
            en: 'The activation will be processed...',
        },
        pairingOngoingReopenIntent: {
            de: 'Erneut versuchen',
            fr: 'Essayer de nouveau',
            it: 'Riprova',
            en: 'Try again',
        },
        pairingSuccessTitle: {
            de: 'AKTIVIERUNGSBESTÄTIGUNG',
            fr: "CONFIRMATION DE L'ACTIVATION",
            it: "CONFERMA DELL'ATTIVAZIONE",
            en: 'ACTIVATION CONFIRMATION',
        },
        pairingSuccessDesc: {
            de:
                'Die Kennzeichenerkennung wurde erfolgreich aktiviert und kann ab sofort benutzt werden.',
            fr:
                "La reconnaissance des plaques d'immatriculation a été activée avec succès et peut être immédiatement utilisée.",
            it:
                'Il riconoscimento della targa è stato attivato con successo e può essere utilizzato immediatamente.',
            en:
                'License plate recognition has been successfully activated and can be used from now on.',
        },
        pairingSuccessDescShort: {
            de: 'Die Kennzeichenerkennung wurde erfolgreich aktiviert.',
            fr:
                "La reconnaissance des plaques d'immatriculation a été activée avec succès.",
            it: 'Il riconoscimento della targa è stato attivato con successo.',
            en: 'License plate recognition has been successfully activated.',
        },
        pairingSingleLpOccupied: (
            lp: LicensePlateState.LicensePlate,
        ): Translation => {
            return {
                de: `Das Fahrzeug ${lp.licensePlateNr} konnte nicht aktiviert werden, da es bereits in einer anderen TWINT App aktiv ist.`,
                fr: `Le véhicule ${lp.licensePlateNr} n'a pas pu être activé, car il est déjà actif dans une autre appli TWINT.`,
                it: `Il veicolo ${lp.licensePlateNr} non ha potuto essere attivato, in quanto è già attivo in un'altra app TWINT.`,
                en: `The vehicle ${lp.licensePlateNr} could not be activated, as it is already active in another TWINT app.`,
            };
        },
        pairingMultipleLpsOccupied: (
            lps: LicensePlateState.LicensePlate[],
        ): Translation => {
            return {
                de: `Die Fahrzeuge ${lps
                    .map(lp => lp.licensePlateNr)
                    .join(
                        ', ',
                    )} konnten nicht aktiviert werden, da sie bereits in einer anderen TWINT App aktiv sind.`,
                fr: `Les véhicules ${lps
                    .map(lp => lp.licensePlateNr)
                    .join(
                        ', ',
                    )} n'ont pas pu être activés, car ils sont déjà actifs dans une autre application TWINT.`,
                it: `I veicoli ${lps
                    .map(lp => lp.licensePlateNr)
                    .join(
                        ', ',
                    )} non hanno potuto essere attivati, in quanto sono già attivi in un'altra app TWINT.`,
                en: `The vehicles ${lps
                    .map(lp => lp.licensePlateNr)
                    .join(
                        ', ',
                    )} could not be activated, as they are already active in another TWINT app.`,
            };
        },
        pairingSingleLpNotAllowed: (
            lp: LicensePlateState.LicensePlate,
        ): Translation => {
            return {
                de: `Das Fahrzeug ${lp.licensePlateNr} konnte nicht aktiviert werden, da es sich um ein Motorrad handelt.`,
                fr: `Le véhicule ${lp.licensePlateNr} n'a pas pu être activé, puisque c'est une moto.`,
                it: `Il veicolo ${lp.licensePlateNr} non ha potuto essere attivato, dal momento che è una moto.`,
                en: `The vehicle ${lp.licensePlateNr} could not be activated, as it is a motorcycle`,
            };
        },
        pairingMultipleLpsNotAllowed: (
            lps: LicensePlateState.LicensePlate[],
        ): Translation => {
            return {
                de: `Die Fahrzeuge ${lps
                    .map(lp => lp.licensePlateNr)
                    .join(
                        ', ',
                    )} konnten nicht aktiviert werden, da es sich um Motorräder handelt.`,
                fr: `Les véhicules ${lps
                    .map(lp => lp.licensePlateNr)
                    .join(
                        ', ',
                    )} n'ont pas pu être activés, puisque ce sont des motos.`,
                it: `I veicoli ${lps
                    .map(lp => lp.licensePlateNr)
                    .join(
                        ', ',
                    )} non hanno potuto essere attivati, dal momento che sono delle moto`,
                en: `The vehicles ${lps
                    .map(lp => lp.licensePlateNr)
                    .join(
                        ', ',
                    )} could not be activated, as they are motorcycles.`,
            };
        },
        pairingErrorTitle: {
            de: 'FEHLER',
            fr: 'ERREUR',
            it: 'ERRORE',
            en: 'ERROR',
        },
        pairingErrorDesc: {
            de: 'Die Kennzeichenerkennung konnte nicht aktiviert werden.',
            fr:
                "La reconnaissance des plaques d'immatriculation n'a pas pu être activée.",
            it: 'Il riconoscimento della targa non ha potuto essere attivato.',
            en: 'License plate recognition could not be activated.',
        },
        pairingErrorReasonAbort: {
            de:
                'Sie haben die Aktivierung der automatischen Zahlung abgebrochen.',
            fr: "Vous avez annulé l'activation du paiement automatique.",
            it: "Avete annullato l'attivazione del pagamento automatico.",
            en: 'You have cancelled the activation of the automatic payment.',
        },
        pairingErrorReasonIntent: {
            de:
                'Bitte gehen Sie zurück zur TWINT App und wiederholen Sie den Vorgang, indem Sie den Bereich Parking erneut auswählen.',
            fr:
                "Veuillez retourner à l'app TWINT et répétez le processus en sélectionnant à nouveau la section Parking.",
            it:
                "Voglia tornare nell'app TWINT e ripetere il processo selezionando nuovamente la sezione Parking.",
            en:
                'Please return to the TWINT app and repeat the process by selecting the Parking section again.',
        },
        pairingErrorReasonNoCar: {
            de:
                'Zur Aktivierung von TWINT LPR ist mindestens ein Motorwagen erforderlich. Motorräder können nicht aktiviert werden.',
            fr:
                'Au moins une voiture est nécessaire pour activer TWINT LPR. Les motos ne peuvent pas être activées.',
            it:
                "Per attivare TWINT LPR è necessaria almeno un'automobile. Le moto non possono essere attivate.",
            en:
                'At least one car is required to activate TWINT LPR. Motorbikes cannot be activated.',
        },
        pairingAbortTitle: {
            de: 'ABBRUCH',
            fr: 'ANNULATION',
            it: 'ANNULLAMENTO',
            en: 'CANCELLATION',
        },
        pairingAbortDesc: {
            de: 'Die Aktivierung wird abgebrochen...',
            fr: "L'activation sera annulée...",
            it: "L'attivazione verrà interrotta...",
            en: 'The activation will be cancelled...',
        },
    },
    onboarding: {
        lpForEnforcement: {
            de:
                'Ihr Kennzeichen dient zur Kontrolle. Die korrekte Eingabe ist deshalb wichtig.',
            fr:
                'Votre numéro d’immatriculation sert à des fins de contrôle. Il est donc essentiel que la saisie soit correcte.',
            it:
                'La sua targa ha funzioni di controllo. Per questo è importante inserirla correttamente.',
            en:
                'Your registration number is used for control purposes. It is therefore important that you enter the correct number.',
        },
        atLeastOneCar: {
            de: 'Sie müssen mindestens ein Kennzeichen hinzufügen.',
            fr: 'Vous devez ajouter au moins un numéro d’immatriculation.',
            it: 'Deve inserire almeno una targa.',
            en: 'You must enter at least one registration number.',
        },
    },
    checkin: {
        parkDuration: {
            de: 'Gültig bis',
            fr: "Valable jusqu'à",
            it: 'Validità fino a',
            en: 'Valid until',
        },
        until: { de: 'bis', fr: "jusqu'à", it: 'fino a', en: 'until' },
        price: { de: 'Preis', fr: 'Prix', it: 'Prezzo', en: 'Price' },
        payNow: {
            de: 'Parkvorgang starten',
            fr: 'Démarrer le stationnement',
            it: 'Avvia sosta',
            en: 'Start transaction',
        },
        buyParkTicket: {
            de: 'Parkticket kaufen',
            fr: 'Acheter un ticket de stationnement',
            it: 'Acquista ticket di parcheggio',
            en: 'Buy car park ticket',
        },
        enterTime: {
            de: 'Parkdauer einstellen',
            fr: 'Sélectionner la durée',
            it: 'Selezionare la durata',
            en: 'Select parking time',
        },
        operatorName: {
            de: 'Betreiber',
            fr: 'Exploitant',
            it: 'Gestore',
            en: 'Operator',
        },
        cityName: { de: 'Ort', fr: 'Lieu', it: 'Località', en: 'Location' },
        maxParkTime: {
            de: 'Maximale Parkdauer',
            fr: 'Durée maximale',
            it: 'Durata massima',
            en: 'Maximum parking time',
        },
        confirmAmount: {
            de:
                'Bestätigen Sie bitte den zu bezahlenden Betrag für den Parkvorgang in der TWINT-App.',
            fr:
                "Confirmer dans l'appli TWINT le montant à payer pour le stationnement.",
            it: "Confermare nell'app TWINT l'importo da pagare per la sosta.",
            en: 'Confirm the fee for the transaction in the TWINT app.',
        },
        zoneName: { de: 'Zone', fr: 'Zone', it: 'Zona', en: 'Zone' },
        showReceipt: {
            de: 'Quittung anzeigen',
            fr: 'Afficher le reçu',
            it: 'Mostra ricevuta',
            en: 'Show receipt',
        },
        urlUsed: {
            de:
                'Diese Seite darf nur einmal aufgerufen werden. Bitte scannen Sie erneut den QR-Code.',
            fr:
                "Cette page ne peut être appelée qu'une seule fois. Scannez à nouveau le code QR.",
            it:
                'Questa pagina può essere richiamata solo una volta. Scansionare nuovamente il codice QR.',
            en:
                'This page may only be called once. Please scan the QR code again.',
        },
        expired: {
            de:
                'Die Seite war zu lange inaktiv. Bitte gehen Sie zurück zur TWINT-App und wiederholen Sie den Vorgang.',
            fr:
                "Cette page est restée inactive trop longtemps. Veuillez retourner à l'app TWINT et répéter le processus.",
            it:
                "La pagina è rimasta inattiva troppo a lungo. Voglia tornare nell'app TWINT e ripetere il processo.",
            en:
                'The page has been inactive for too long. Please return to the TWINT app and repeat the process.',
        },
        transactionRunning: {
            de:
                'Mit diesem Kennzeichen läuft schon ein Parkvorgang in einem anderen Konto.',
            fr:
                'Avec cette immatriculation un stationnement est déjà actif dans un autre compte.',
            it:
                "Con questo numero di targa è già attiva una sosta in un'altro conto.",
            en:
                'A park transaction is already running with this license plate in another account.',
        },
        unpairedQrCodeError: {
            de:
                'Dieser QR-Code ist leider nicht korrekt eingerichtet. Um das zu beheben, benötigen wir den Standort dieses QR-Codes. Dazu brauchen wir Ihre Hilfe: erteilen Sie eine (einmalige) Freigabe Ihres aktuellen Standorts.',
            fr:
                "Malheureusement, ce code QR n'a pas été configuré correctement. Pour résoudre ce problème, nous avons besoin de la position exacte de ce code QR. À cette fin, nous vous invitons à partager (une seule fois) votre position actuelle.",
            it:
                'Purtroppo questo codice QR non è stato configurato correttamente. Per risolvere il problema, abbiamo bisogno della posizione esatta del codice QR. A questo scopo la invitiamo a condividere (solo una volta) la sua posizione attuale.',
            en:
                'This QR-Code is not correctly configured. To be able to fix this, we need to know the exact location of this QR-Code. For this, we need your help: please share your location (only once).',
        },
        unpairedQrCodeShareLocation: {
            de: 'Standort teilen',
            fr: 'Partager la position',
            it: 'Condividi posizione',
            en: 'Share location',
        },
        unpairedQrCodeGeoSent: {
            de:
                'Standort erfolgreich übermittelt. Vielen Dank für Ihre Hilfe. In den nächsten Tagen wird der Fehler behoben, so dass der QR-Code das nächste Mal funktioniert.',
            fr:
                "Position transmise correctement. Merci pour votre aide. Dans les prochains jours, l'erreur sera corrigée afin que le code QR fonctionne correctement.",
            it:
                "Posizione trasmessa correttamente. Grazie per il suo aiuto. Nei prossimi giorni l'errore verrà corretto, in modo che la prossima volta il codice QR funzioni correttamente.",
            en:
                'Location sent. Thank you for your help. In the next days the error will be corrected, so that the next time the QR code works properly.',
        },
        unpairedQrCodeGeoNotSent: {
            de: 'Standort konnte nicht übermittelt werden.',
            fr: "La position n'a pas pu être transmise.",
            it: 'La posizione non ha potuto essere trasmessa.',
            en: 'Location could not be transmitted.',
        },
        close: { de: 'Weiter', fr: 'Continuer', it: 'Avanti', en: 'Continue' },
        immediateParkForThisLicensePlateAndZone: {
            de:
                'In dieser Zone und mit diesem Kennzeichen haben Sie soeben einen Parkvorgang beendet.',
            fr:
                'Vous venez de terminer une procédure de stationnement dans cette zone avec l’immatriculation sélectionnée.',
            it:
                'Con questo numero di targa avete appena terminato una sosta in questa zona.',
            en:
                'With this license plate you just finished a parking transaction in this zone.',
        },
        subsequentPaymentForbiddenAnyway: {
            de:
                'Nachzahlungsverbot gilt in jedem Fall auch bei der Nutzung von Parkingpay und bei den Gratis-Zeiten.',
            fr:
                "Linterdiction de payer à nouveau s'applique dans tous les cas, même avec l'utilisation de Parkingpay et dans les temps gratuites.",
            it:
                'Il divieto di rinnovare il pagamento vale anche per Parkingpay e i tempi gratuiti.',
            en:
                'The prohibition to renew the payment also applies in case of using the Parkingpay and during the free times.',
        },
        title: {
            de: 'Nachzahlen verboten',
            fr: 'Interdit de payer à nouveau',
            it: 'Proibito ripagare',
            en: 'Payment renewal prohibited',
        },
        whatYouShouldDo: {
            de:
                'Nach dem Ablauf eines Parkvorgangs muss das Fahrzeug zuerst wieder in den Verkehr eingefügt werden; ist dies nicht der Fall und wird ein neuer Parkvorgang gestartet, muss trotz laufendem Parkvorgang mit einer Parkbusse gerechnet werden.',
            fr:
                'Au terme de chaque procédure de stationnement, le véhicule doit être remis en circulation. Si tel n’est pas le cas et qu’une nouvelle procédure de stationnement est démarrée, une amende d’ordre peut être émise par l’autorité de contrôle.',
            it:
                'Dopo la fine di ogni sosta, il veicolo dev’essere rimesso in circolazione; se questo non avviene e una nuova sosta viene avviata, si può andare in contro a una contravvenzione, nonostante la sosta sia attiva.',
            en:
                'After the end of each parking transaction, the vehicle must be re-inserted in the traffic; if this is not the case and a new parking transaction is started, a parking fine must be expected despite active parking transaction.',
        },
        parkingProhibitedTitle: {
            de: 'Parkverbot',
            fr: 'Interdiction de stationnement',
            it: 'Divieto di parcheggio',
            en: 'Parking prohibited',
        },
        parkingProhibitedDescription: {
            de:
                'Zurzeit darf in dieser Zone mit diesem Fahrzeug nicht parkiert werden.',
            fr:
                'Actuellement, il est interdit de se garer dans cette zone avec ce véhicule.',
            it:
                'Attualmente non è permesso parcheggiare questo veicolo in questa zona.',
            en:
                "Currently it's not allowed to park in this zone with this vehicle.",
        },
        closeWindow: {
            de: 'Schliessen',
            fr: 'Fermer',
            it: 'Chiudi',
            en: 'Close',
        },
        hour: {
            de: 'Stunde',
            fr: 'heure',
            it: 'ora',
            en: 'hour',
        },
        hours: {
            de: 'Stunden',
            fr: 'heures',
            it: 'ore',
            en: 'hours',
        },
        day: {
            de: 'Tag',
            fr: 'jour',
            it: 'giorno',
            en: 'day',
        },
        days: {
            de: 'Tage',
            fr: 'jours',
            it: 'giorni',
            en: 'days',
        },
        week: {
            de: 'Woche',
            fr: 'semaine',
            it: 'settimana',
            en: 'week',
        },
        weeks: {
            de: 'Wochen',
            fr: 'semaines',
            it: 'settimane',
            en: 'weeks',
        },
        month: {
            de: 'Monat',
            fr: 'mois',
            it: 'mese',
            en: 'month',
        },
        months: {
            de: 'Monate',
            fr: 'mois',
            it: 'mesi',
            en: 'months',
        },
        purchasePermit: {
            de: 'Bewilligung kaufen',
            fr: 'Acheter autorisation',
            it: 'Acquista autorizzazione',
            en: 'Purchase permit',
        },
        additionalInfos: {
            de: 'Zusätzliche Informationen',
            fr: 'Informations complémentaires',
            it: 'Informazioni supplementari',
            en: 'Additional information',
        },
        zones: {
            de: 'Zonen',
            fr: 'Zones',
            it: 'Zone',
            en: 'Zones',
        },
    },
    receipt: {
        terminate: {
            de: 'Parkvorgang beenden',
            fr: 'Terminer le stationnement',
            it: 'Termina la sosta',
            en: 'End parking time',
        },
        parkingTime: {
            de: 'Parkzeit',
            fr: 'Temps de stationnement',
            it: 'Tempo di parcheggio',
            en: 'Parking time',
        },
        from: { de: 'von', fr: 'du', it: 'dal', en: 'from' },
        to: { de: 'bis', fr: 'au', it: 'al', en: 'to' },
        terminationAlertInfo: {
            de:
                'Möchten Sie wirklich den Parkvorgang im Voraus beenden? Die Preisdifferenz wird Ihnen zurückerstattet.',
            fr:
                "Voulez-vous vraiment arrêter le stationnement à l'avance? La différence de prix vous sera remboursée.",
            it:
                'Vuole veramente terminare la sosta? La differenza di prezzo le verrà rimborsata.',
            en:
                'Do you really want to stop the park transaction in advance? The price difference will be refunded.',
        },
        downloadReceipt: {
            de: 'Beleg anzeigen',
            fr: 'Afficher le reçu',
            it: 'Mostra la ricevuta',
            en: 'Display receipt',
        },
        time: {
            de: 'Uhr',
            fr: '',
            it: '',
            en: '',
        },
        terminated: (amount: number) => {
            return {
                de: `Die Parkzeit ist beendet. ${
                    amount > 0 ? 'Der Betrag wurde erfolgreich abgebucht.' : ''
                }`,
                fr: `Le stationnement est terminé. ${
                    amount > 0 ? 'Le montant dû a été débité.' : ''
                }`,
                it: `La sosta è terminata. ${
                    amount > 0 ? "L'importo dovuto è stato addebitato." : ''
                }`,
                en: `Park transaction terminated. ${
                    amount > 0 ? 'The amount due has been debited.' : ''
                }`,
            };
        },
    },
    outstandingAmount: {
        outstandingAmountTitle: {
            de: 'OFFENER BETRAG',
            fr: 'MONTANT NON ACQUITTÉ',
            it: 'IMPORTO SCOPERTO',
            en: 'OUTSTANDING AMOUNT',
        },
        outstandingAmountText: (outstandingAmount: number) => ({
            de: `Ihre letzten Parkgebühren konnten nicht belastet werden. Der ausstehende Betrag von CHF ${outstandingAmount.toFixed(
                2,
            )} muss gedeckt sein, bevor neue Transaktionen durchgeführt werden können.`,
            fr: `Vos derniers frais de stationnement n'ont pas pu être débités. Le montant impayé de ${outstandingAmount.toFixed(
                2,
            )} CHF doit être payé avant que de nouvelles transactions puissent être effectuées.`,
            it: `Le sue ultime tasse di parcheggio non hanno potuto essere addebitate. Prima di poter effettuare nuove transazioni, l'importo dovuto pari a CHF ${outstandingAmount.toFixed(
                2,
            )} deve venir coperto.`,
            en: `Your last parking fees could not be charged. The outstanding amount of CHF ${outstandingAmount.toFixed(
                2,
            )} must be covered before new transactions can be made.`,
        }),
        retryPayment: {
            de: 'Zahlung erneut versuchen',
            fr: 'Retenter le paiement',
            it: 'Riprova pagamento',
            en: 'Try payment again',
        },
        renewAlias: {
            de: 'Automatische Zahlung erneuern',
            fr: 'Renouveler le paiement automatique',
            it: 'Rinnova pagamento automatico',
            en: 'Renew automatic payment',
        },
        failedAttemptTitle: {
            de: 'VERSUCH GESCHEITERT',
            fr: 'TENTATIVE ÉCHOUÉE',
            it: 'TENTATIVO FALLITO',
            en: 'FAILED ATTEMPT',
        },
        failedAttemptText: {
            de:
                'Der Versuch, den ausstehenden Betrag zu belasten, blieb leider erfolglos.',
            fr:
                "Malheureusement, la tentative de débiter le montant restant n'a pas abouti.",
            it:
                "Purtroppo il tentativo di addebitare l'importo scoperto non è andato a buon fine.",
            en:
                'Unfortunately, the attempt to debit the outstanding amount was unsuccessful.',
        },
        restartSession: {
            de:
                'Bitte gehen Sie zurück zur TWINT App und wiederholen Sie den Vorgang, indem Sie den Bereich Parking erneut auswählen.',
            fr:
                "Veuillez retourner à l'app TWINT et répétez le processus en sélectionnant à nouveau la section Parking.",
            it:
                "Voglia tornare nell'app TWINT e ripetere il processo selezionando nuovamente la sezione Parking.",
            en:
                'Please return to the TWINT app and repeat the process by selecting the Parking section again.',
        },
        contactIssuer: {
            de:
                'Wenn Sie TWINT Prepaid nutzen, prüfen Sie bitte, ob Sie genügend Guthaben haben. In den anderen Fällen wenden Sie sich bitte an Ihr Finanzinstitut, um den Grund für die Ablehnung der Belastung zu klären.',
            fr:
                'Si vous utilisez le service prépayé TWINT, veuillez vérifier que votre solde disponible est suffisant. Dans les autres cas, veuillez contacter votre institution financière pour clarifier la raison du refus de débit.',
            it:
                "Se utilizza TWINT Prepaid verifichi che il saldo disponibile sia sufficiente. Negli altri casi voglia contattare il suo Istituto finanziario per chiarire il motivo del rifiuto dell'addebito.",
            en:
                'If you are using TWINT Prepaid, please verify that you have sufficient available balance. In other cases, please contact your financial institution to clarify the reason for the debit refusal.',
        },
        askToRenewAlias: {
            de:
                'Um dieses Problem zu lösen, bitten wir Sie, die automatische Zahlung zu erneuern, indem Sie auf die Schaltfläche hier unten drücken.',
            fr:
                'Pour résoudre ce problème, nous vous invitons à renouveler le paiement automatique en appuyant sur le bouton ci-dessous.',
            it:
                'Per risolvere il problema, la invitiamo a rinnovare il pagamento automatico, premendo il tasto qui sotto.',
            en:
                'To solve this problem, we invite you to renew your automatic payment by pressing the button below.',
        },
        reasonInsufficientFunds: {
            de:
                'Der Versuch, den ausstehenden Betrag zu belasten, war leider nicht erfolgreich, da die notwendigen Mittel nicht vorhanden sind.',
            fr:
                "Malheureusement, la tentative de débiter le montant restant n'a pas abouti, car les fonds nécessaires ne sont pas disponibles.",
            it:
                "Purtroppo il tentativo di addebitare l'importo scoperto non è andato a buon fine, in quanto i fondi necessari non sono disponibili.",
            en:
                'Unfortunately, the attempt to debit the outstanding amount was unsuccessful, as the necessary funds are not available.',
        },
        topUpInstructions: {
            de:
                'Wenn Sie TWINT Prepaid nutzen, müssen Sie Ihr Guthaben zunächst in der TWINT-App aufladen. In den anderen Fällen prüfen Sie das Guthaben des mit der TWINT-App Ihres Finanzinstituts verbundenen Kontos (oder der Karte).',
            fr:
                "Si vous utilisez TWINT Prepaid, vous devez d'abord recharger votre solde dans l'application TWINT. Dans les autres cas, vérifiez la disponibilité du compte (ou de la carte) lié à l'application TWINT de votre institution financière.",
            it:
                "Se utilizza TWINT Prepaid deve prima ricaricare il saldo nell'app TWINT. Negli altri casi verifichi la disponibilità del conto (risp. della carta) collegato all'app TWINT del suo istituto finanziario.",
            en:
                'If you use TWINT Prepaid, you must first recharge your balance in the TWINT app. In other cases, check the balance of the account (or card) linked to the TWINT app of your financial institution.',
        },
        paymentSuccessfulTitle: {
            de: 'ZAHLUNG ERFOLGREICH',
            fr: 'PAIEMENT EFFECTUÉ',
            it: 'PAGAMENTO ESEGUITO',
            en: 'PAYMENT COMPLETED',
        },
        paymentSuccessfullText: {
            de: 'Der fällige Betrag wurde erfolgreich bezahlt.',
            fr: 'Le montant dû a été payé avec succès.',
            it: "L'importo dovuto è stato pagato con successo. ",
            en: 'The amount due has been successfully paid.',
        },
        collectAmount: {
            de: 'Einziehung des ausstehenden Betrags',
            fr: 'Recouvrement du montant en souffrance',
            it: "Riscossione dell'importo dovuto",
            en: 'Collecting outstanding amount',
        },
    },
    general: {
        dayShort: { de: 'Tag', fr: 'j', it: 'g', en: 'day' },
        daysShort: { de: 'Tage', fr: 'j', it: 'g', en: 'days' },
        hoursShort: { de: 'Std.', fr: 'h', it: 'h', en: 'h' },
        minutesShort: { de: 'Min.', fr: 'min.', it: 'min.', en: 'm' },
        secondsShort: { de: 'S.', fr: 's', it: 's', en: 's' },
        description: {
            de: 'Bezeichnung',
            fr: 'Désignation',
            it: 'Descrizione',
            en: 'Description',
        },
        details: {
            de: 'Details',
            fr: 'Détails',
            it: 'Dettagli',
            en: 'Details',
        },
        activate: {
            de: 'Aktivieren',
            fr: 'Activer',
            it: 'Attiva',
            en: 'Activate',
        },
        permitValidity: {
            de: 'Gültigkeit',
            fr: 'Validité',
            it: 'Validità',
            en: 'Validity',
        },
        back: { de: 'Zurück', fr: 'Retour', it: 'Indietro', en: 'Back' },
        close: { de: 'Schliessen', fr: 'Fermer', it: 'Chiudi', en: 'Close' },
        ok: { de: 'OK', fr: 'OK', it: 'OK', en: 'OK' },
        confirm: {
            de: 'Bestätigen',
            fr: 'Confirmer',
            it: 'Conferma',
            en: 'Confirm',
        },
        confirmAmount: {
            de: 'Bestätigen Sie den zu bezahlenden Betrag in der TWINT-App.',
            fr: "Confirmer dans l'appli TWINT le montant à payer.",
            it: "Confermare nell'app TWINT l'importo da pagare.",
            en: 'Confirm the fee for the transaction in the TWINT app.',
        },
        continue: {
            de: 'Weiter',
            fr: 'Continuer',
            it: 'Continua',
            en: 'Continue',
        },
        cancel: { de: 'Abbrechen', fr: 'Annuler', it: 'Annulla', en: 'Cancel' },
        deactivate: {
            de: 'Deaktivieren',
            fr: 'Désactiver',
            it: 'Disattiva',
            en: 'Deactivate',
        },
        hide: {
            de: 'Ausblenden',
            fr: 'Cacher',
            it: 'Nascondi',
            en: 'Hide',
        },
        tryAgain: {
            de: 'Erneut versuchen',
            fr: 'Essayer de nouveau',
            it: 'Riprova',
            en: 'Try again',
        },
        useMeter: {
            de: 'Leider müssen Sie zur Zeit an der Parkuhr / Kasse bezahlen.',
            fr: 'Malheureusement, vous devez payer au parcmètre en ce moment.',
            it: 'Purtroppo al momento deve pagare al parchimetro.',
            en:
                'Unfortunately, at the moment you have to pay at the parking meter.',
        },
        error: {
            de: 'Es ist ein Problem aufgetreten.',
            fr: 'Il y avait un problème.',
            it: 'Si è verificato un problema.',
            en: 'A problem occured.',
        },
        errorNonActivePermit: {
            de: 'Es ist nicht mehr möglich, diese Bewilligung zu erwerben.',
            fr: "Il n'est plus possible d'acheter cette autorisation.",
            it: "Non è più possibile acquistare quest'autorizzazione.",
            en: 'It is no longer possible to purchase this permit.',
        },
        errorNonActiveZone: {
            de:
                'Es ist nicht möglich, in dieser Zone einen Parkvorgang zu starten',
            fr:
                "Il n'est pas possible de lancer une procédure de stationnement dans cette zone",
            it: 'Non è possibile avviare una sosta in questa zona',
            en: 'It is not possible to start a procedure in this zone',
        },
        contactOperatorCallToAction: {
            de:
                'Bitte kontaktieren Sie den Betreiber für weitere Informationen:',
            fr: "Veuillez contacter l'exploitant pour plus d'informations:",
            it: 'Per ulteriori informazioni si rivolga al gestore:',
            en: 'Please contact the operator for more information:',
        },
        wereSorry: {
            de: 'Wir bitten Sie um Ihr Verständnis.',
            fr: 'Nous nous excusons pour la gêne occasionnée.',
            it: "Ci scusiamo per l'inconveniente.",
            en: 'We apologize for any inconvenience.',
        },
        backToPortraitMode: {
            de: 'Bitte wechseln Sie zurück zum Hochformatmodus.',
            fr: 'Veuillez revenir en mode portrait.',
            it: 'Voglia tornare alla modalità verticale.',
            en: 'Please rotate back to portrait mode.',
        },
        lprAdvertisement: {
            de: 'Mit TWINT ticketlos ins Parkhaus ein-/ausfahren?',
            fr: 'Entrer/sortir du parking sans ticket avec TWINT?',
            it: "Entrare/uscire senza ticket dall'autosilo con TWINT?",
            en: 'Enter/exit the car park ticket-free with TWINT?',
        },
        lprAdvertisementWithParking: {
            de: 'mit TWINT ticketlos ein-/ausfahren?',
            fr: 'entrer/sortir sans ticket avec TWINT?',
            it: 'entrare/uscire senza ticket con TWINT?',
            en: 'enter/exit ticket-free with TWINT?',
        },
        transactionsTitle: {
            de: 'Abgeschlossene Parkvorgänge',
            fr: 'Stationnements terminés',
            it: 'Soste concluse',
            en: 'Completed transactions',
        },
        twintLimitationTitle: {
            de: 'Einschränkung',
            fr: 'Limitation',
            it: 'Limitazione',
            en: 'Limitation',
        },
        twintLimitationText1: {
            de:
                'Aus technischen Gründen können TWINT-Transaktionen maximal 6 Tage und 16 Stunden lang offen bleiben.',
            fr:
                'Pour des raisons techniques, les transactions TWINT peuvent rester ouvertes pendant un maximum de 6 jours et 16 heures.',
            it:
                'Per motivi tecnici le transazioni TWINT possono rimanere aperte al massimo 6 giorni e 16 ore.',
            en:
                'For technical reasons, TWINT transactions can remain open for a maximum of 6 days and 16 hours.',
        },
        twintLimitationText2: {
            de:
                'Die Höchstparkzeit, die für diese Zone zur Verfügung steht, wurde daher entsprechend angepasst.',
            fr:
                'La durée maximale disponible pour cette zone a donc été ajustée en conséquence.',
            it:
                'La durata massima disponibile per questa zona à stata quindi adattata di conseguenza.',
            en:
                'The maximum duration available for this zone has therefore been adjusted accordingly.',
        },
        noTransactions: {
            de:
                'Sie haben noch keinen Parkvorgang mit dieser TWINT App bezahlt.',
            fr:
                "Vous n'avez encore payé aucun stationnement avec cette appli TWINT.",
            it: 'Non hai ancora pagato alcuna sosta con questa app TWINT.',
            en:
                'You have not yet paid for a parking transaction with this TWINT app.',
        },
    },
    quota: {
        quotaExhausted: {
            de: 'Kontingent erschöpft',
            fr: 'Contingent épuisé',
            it: 'Contingente esaurito',
            en: 'Quota exhausted',
        },
        quotaExhaustedExplanation: {
            de:
                'Das Kontingent für diese Bewilligung ist für mindestends einen Tag im ausgewählten Zeitraum erschöpft.',
            fr:
                'Le contingent pour cette autorisation est épuisé pour au moins un jour dans la période sélectionnée.',
            it:
                'Il contingente per questa autorizzazione è esaurito per almeno un giorno nel periodo selezionato.',
            en:
                'The quota for this permit is exhausted for at least one day in the selected period.',
        },
    },
};

interface FaqQuestion {
    question: Translation;
    answer: Translation;
}

const HELPDESK = (
    <div>
        <div className={css({ marginBottom: '8px' })}>Helpdesk</div>
        <a
            className={css({ marginBottom: '4px' })}
            href="mailto:info@parkingpay.ch"
        >
            info@parkingpay.ch
        </a>
        <div className={css({ marginBottom: '4px' })}>0848 330 555</div>
    </div>
);

export const FAQTexts: FaqQuestion[] = [
    {
        question: {
            de:
                'Ich habe eine ungerechtfertigte Parkbusse erhalten, was kann ich tun?',
            fr:
                'J’ai reçu une amende de parking injustifiée. Que puis-je faire?',
            it: 'Ho ricevuto una multa ingiusta, cosa posso fare?',
            en: 'I have received an unwarranted parking fine. What can I do?',
        },
        answer: {
            de:
                'In der TWINT App findest du im Menü «Bewegungen» die Zahlungsbestätigung und Quittung. Lade die Quittung herunter und melde dich bitte direkt an die auf dem Bussenbeleg aufgeführte Stelle. Diese wird den bezahlten Parkvorgang überprüfen und über eine allfällige Aufhebung der Busse entscheiden. TWINT oder Parkingpay können keine Busse aufheben.',
            fr:
                "Dans l'app TWINT, vous trouverez la confirmation de paiement et votre reçu dans le menu «Transactions». Téléchargez le reçu et adressez-vous directement à l’instance figurant sur l’amende. Cet office vérifiera le stationnement payé et décidera d’une éventuelle annulation de l’amende. TWINT ou Parkingpay ne sont pas en mesure d’annuler des amendes.",
            it:
                "Nell’app TWINT al menu «Movimenti» sono disponibili ricevute e conferme di pagamento. Scarica la ricevuta e contatta direttamente l'ufficio indicato sulla multa. Sarà compito di quest'ultimo verificare l’avvenuto pagamento del parcheggio e decidere l’eventuale annullamento della multa. Né TWINT né Parkingpay hanno la facoltà di annullare una multa.",
            en:
                'In the TWINT app, you will find the payment confirmation and your receipt in the menu «Statement entries». Download the receipt and contact the office listed on the fine. This office will check the paid parking transaction and, where applicable, decide whether to rescind the fine. TWINT and Parkingpay are unable to cancel fines.',
        },
    },
    {
        question: {
            de: 'Woher weiss der Parkplatzkontrolleur, dass ich bezahlt habe?',
            fr:
                'Comment la personne en charge du contrôle peut-elle savoir si j’ai payé mon stationnement?',
            it: 'Il controllore come fa a sapere che ho pagato il parcheggio?',
            en: 'How does the enforcement agent know whether I have paid?',
        },
        answer: {
            de:
                'Der Kontrolleur verfügt über ein Kontrollgerät, welches online mit unserem System verbunden ist. Mit diesem Gerät wird das Kennzeichen des Fahrzeuges gescannt. So wird festgestellt, ob die Parkgebühr bezahlt wurde.',
            fr:
                'La personne en charge du contrôle dispose d’un appareil connecté en ligne avec notre système. La plaque d’immatriculation du véhicule est scannée à l’aide de cet appareil. Ceci permet de vérifier si le stationnement a été payée.',
            it:
                'Il controllore è dotato di un dispositivo di controllo connesso online con il nostro sistema. Questo dispositivo permette di scannerizzare le targhe dei veicoli e quindi di verificare l’avvenuto pagamento della tassa di parcheggio.',
            en:
                'The enforcement agent has a control device, that is linked online to our system. This device is used to scan your vehicle’s license plate number, meaning the enforcement agent can establish whether the parking fee has been paid.',
        },
    },
    {
        question: {
            de:
                'Darf ich nach dem Ende eines Parkvorganges sofort einen neuen starten?',
            fr:
                'Est-ce que je peux démarrer une nouvelle procédure de stationnement immédiatement après la fin de la précédente?',
            it:
                'Posso avviare una nuova sosta, subito dopo la fine di quella precedente?',
            en:
                'Following the end of a parking transaction, can I immediately start a new one?',
        },
        answer: {
            de:
                'Nein. Nach dem Ende eines Parkvorganges muss das Fahrzeug wieder in Verkehr gesetzt werden.',
            fr:
                'Non. Après la fin de la procédure de stationnement, le véhicule doit être engagé de nouveau dans la circulation.',
            it:
                'No. Dopo la fine della sosta, il veicolo deve essere rimesso in circolazione.',
            en:
                'No. Following the end of a parking transaction, the vehicle must be driven away.',
        },
    },
    {
        question: {
            de: 'Wie kann ich einen laufenden Parkvorgang frühzeitig beenden?',
            fr: 'Comment puis-je abréger la durée de stationnement en cours?',
            it:
                'Come faccio a interrompere anticipatamente una sosta in corso?',
            en: 'How can I prematurely end a current parking transaction?',
        },
        answer: {
            de:
                'Öffne in der TWINT App das Menü «Bewegungen» und tippe auf die entsprechende Zahlung oder scanne erneut den QR-Code. Tippe dann auf «Parkvorgang beenden». Solltest du den Parkvorgang nicht frühzeitig beenden können, weil z.B. dein Smartphone-Akku leer war, kann dir die restliche Parkzeit nicht zurückerstattet werden.',
            fr:
                'Dans l’app TWINT, ouvrez le menu «Transactions» et cliquez sur le paiement concerné ou scannez à nouveau le code QR. Sélectionnez ensuite «Terminer stationnement». Si vous n’êtes pas en mesure d’abréger la durée de stationnement, par exemple parce que la batterie de votre smartphone est vide, le temps de stationnement restant ne pourra pas vous être remboursé.',
            it:
                "Apri il menu «Movimenti» nell'app TWINT e clicca sul pagamento corrispondente oppure scansiona di nuovo il codice QR. Clicca quindi su «Termina sosta». Se non è stato possibile interrompere la sosta, ad esempio perché la batteria dello smartphone è scarica, non sarà possibile ottenere il rimborso del tempo rimanente.",
            en:
                'In the TWINT app, open the «Statement entries» menu and tap the relevant payment or scan the QR code again. Now, tap «End parking time». If you are unable to end the parking transaction early, for example because your smartphone battery was empty, it will not be possible to refund the remaining parking time.',
        },
    },
    {
        question: {
            de: 'Wie lange darf ich parkieren?',
            fr: 'Combien de temps suis-je autorisé à stationner?',
            it: 'Qual è la durata massima di un parcheggio?',
            en: 'For how am I allowed to park?',
        },
        answer: {
            de:
                'Je nach Parkplatz ist die maximale Parkdauer unterschiedlich und wird sowohl auf der Parkuhr wie auch in der App ausgewiesen. Es ist nicht gestattet, einen laufenden Parkvorgang zu verlängern. Tipp: Du hast die Möglichkeit, die maximale Parkzeit zu wählen und bei frühzeitiger Beendigung des Parkvorgangs den Restbetrag zurück zu erhalten, indem du den laufenden Parkvorgang frühzeitig beendest.',
            fr:
                'La durée maximale varie selon la place de stationnement et est indiquée sur le parcomètre et dans l’app. Il n’est pas autorisé de prolonger la durée de stationnement en cours. Conseil: vous avez la possibilité de sélectionner la durée maximale de stationnement; la différence vous sera restituée si vous abrégez la durée de stationnement.',
            it:
                'Può variare da parcheggio a parcheggio, ed è indicata sia sul parchimetro sia nell’app. Non è possibile prolungare una sosta già in corso. Suggerimento: puoi selezionare la durata massima e farti rimborsare l’importo residuo in caso di interruzione anticipata della sosta. Ti basterà interrompere la sosta in corso.',
            en:
                'Depending on the car park, the maximum parking duration can vary and is displayed both on the parking meter and in the app. It is not permitted to extend an ongoing parking transaction. Tip: you can choose the maximum parking time and then receive the remaining amount back if you end a parking transaction early.',
        },
    },
    {
        question: {
            de:
                'Entstehen zusätzliche Kosten, wenn ich die Parkgebühr mit TWINT bezahle?',
            fr:
                'Payer les frais de stationnement avec TWINT entraîne-t-il des coûts supplémentaires?',
            it:
                'Pagare le tasse di parcheggio con TWINT comporta dei costi aggiuntivi?',
            en:
                'Are there any additional costs if I pay for the parking fee with TWINT?',
        },
        answer: {
            de:
                'In der Regel gelten dieselben Tarife wie bei der Parkuhr oder an der Kasse. Bei entsprechendem und ausdrücklichem Hinweis erheben einzelne Betreiber für die Benutzung der Parkingpay-Funktionen Zuschläge. Beachte, dass für die Funktion «Ticketloses Parkieren» (Kennzeichenerkennung bei Parkanlagen mit Schranken) pro konstenpflichtigem Parkvorgang zusätzliche Gebühren von CHF 0.25 anfallen.',
            fr:
                "En général, les tarifs sont identiques à ceux perçus au parcomètre ou à la caisse. Certains exploitants prélèvent un supplément pour l’utilisation des fonctions Parkingpay. Dans ce cas, ceci est explicitement indiqué. Veuillez noter qu’avec la fonction «Stationner sans ticket» (reconnaissance de la plaque d'immatriculation dans les parkings à barrières), des taxes supplémentaires de CHF 0.25 sont perçues par procédure de stationnement payant.",
            it:
                'Di regola valgono le stesse tariffe del parchimetro o della cassa. Può capitare che, previa esplicita avvertenza in tal senso, singoli gestori richiedano un supplemento per l’uso delle funzioni Parkingpay. Ricorda che la funzione «Parcheggiare senza ticket» (riconoscimento della targa nei parcheggi con barriere) comporta una commissione di CHF 0.25 per ogni sosta a pagamento.',
            en:
                'Generally speaking, the same fees apply as those shown on the parking meter or on the cash desk. Where corresponding and express reference is made, individual operators may levy surcharges for the use of the Parkingpay functions. Please note that the «Ticketless parking» function (number plate recognition in parkings with barriers) incurs a fee of CHF 0.25 per each paid parking transaction.',
        },
    },
    {
        question: {
            de: 'Wie kann ich mein hinterlegtes Fahrzeugkennzeichen anpassen?',
            fr:
                'Comment puis-je modifier la plaque d’immatriculation enregistré?',
            it:
                'Come faccio a modificare la mia targa del veicolo, che avevo registrato in precedenza?',
            en: 'How do I change my saved vehicle license plate number?',
        },
        answer: {
            de:
                'Tippe oben rechts auf das Fahrzeug-Symbol und im nächsten Screen auf «Weiteres Fahrzeug hinzufügen». Gib das vollständige Kennzeichen an und tippe auf «Bestätigen». Du siehst nun eine Übersicht all deiner hinterlegten Kennzeichen.',
            fr:
                'Cliquez sur le symbole véhicule en haut à droite, puis sur «Ajouter véhicule» sur le prochain écran. Saisissez l’intégralité du numéro d’immatriculation et cliquez sur «Confirmer». Vous verrez alors un aperçu de tous les plaques d’immatriculation enregistrés.',
            it:
                "Clicca in alto a destra sul simbolo del veicolo e nella schermata successiva su «Aggiungi veicolo». Inserisci la targa completa e clicca su «Confermare». Verrà quindi visualizzato l'elenco di tutte le targhe da te registrate.",
            en:
                'Tap the vehicle symbol in the top-right corner and then «Add additional vehicle» on the next screen. Enter the complete license plate number and tap «Confirm». You will now see an overview of all your saved license plate numbers.',
        },
    },
    {
        question: {
            de:
                'Wo finde ich die Quittung einer mit TWINT bezahlten Parkgebühr?',
            fr:
                "Où puis-je trouver le reçu d'une taxe de stationnement payée avec TWINT?",
            it:
                'Dove posso trovare la ricevuta di una tassa di parcheggio pagata con TWINT?',
            en:
                'Where can I find the receipt for a parking fee paid with TWINT?',
        },
        answer: {
            de:
                'Öffne die Funktion Parkieren. Tippe auf das Symbol mit den drei Strichen oben rechts auf der Karte. Du siehst nun eine Übersicht all deiner abgeschlossenen Parkvorgänge. Tippe auf den Betrag neben dem Parkvorgang, für welchen du die Quittung herunterladen möchtest. Die Quittung wird dir nun als PDF-Dokument angezeigt und du kannst diese auf deinem Smartphone speichern.',
            fr:
                'Ouvrez la fonction Parking. Cliquez sur les trois tirets en haut à droite. Vous verrez alors un aperçu de toutes tes procédures de stationnement terminées. Cliquez sur le montant à côté de la procédure pour laquel vous souhaitez télécharger le reçu. Le reçu s’affiche alors au format PDF, et vous pouvez le sauvegarder sur votre smartphone.',
            it:
                'Apri la funzione Parking. Clicca sul simbolo con le tre linee in alto a destra nella mappa. Verrà quindi visualizzato l’elenco di tutte le soste già concluse. Clicca sull’importo accanto alla sosta per cui desideri scaricare la ricevuta. La ricevuta verrà quindi visualizzata in formato PDF e potrai scaricarla sullo tuo smartphone.',
            en:
                'Open the parking function. Tap the symbol with the three lines in the top-right corner of the screen. You will now see an overview of all your past parking transactions. Tap the amount next to the parking transaction for which you would like to download the receipt. The receipt will now be displayed to you as a PDF document that you can save on your smartphone.',
        },
    },
    {
        question: {
            de:
                'Welche zusätzlichen Möglichkeiten bietet Parkieren direkt aus der TWINT App heraus?',
            fr:
                "Quelles sont les possibilités supplémentaires offertes par Parking directement depuis l'app TWINT?",
            it:
                "Quali ulteriori opzioni offre Parking direttamente dall'app TWINT?",
            en:
                'What additional options does parking offer directly from the TWINT app?',
        },
        answer: {
            de:
                'Mit der Funktion Parkieren kannst du direkt aus der TWINT App deine Parkzeit bezahlen, ohne den QR-Code an der Parkuhr scannen zu müssen. Wähle dazu auf der Karte einfach die gewünschte Parkzone in deiner Nähe aus. Zudem kannst du in bestimmten Parkhäusern auch ticketlos Parkieren.',
            fr:
                "Avec la fonction Parking, vous pouvez payer votre temps de stationnement directement depuis l'app TWINT, sans devoir scanner le code QR apposé sur le parcomètre. Pour ce faire, sélectionnez simplement la zone de stationnement souhaitée sur la carte. Vous pouvez en plus stationner sans ticket dans certains parkings.",
            it:
                "La funzione Parking permette di pagare la sosta direttamente dall'app TWINT, senza scannerizzare il codice QR al parchimetro. Per fare questo puoi semplicemente selezionare sulla mappa la zona di parcheggio desiderata nelle tue vicinanze. Inoltre, certi parcheggi offrono la possibilità di parcheggiare anche senza ticket.",
            en:
                'With the parking function, you can pay for your parking time directly from the TWINT app, without having to scan the QR code on the parking meter. On the map, simply select the parking zone near you in which you would like to park. You can also park in certain car parks without having to buy a ticket.',
        },
    },
    {
        question: {
            de: 'Was ist «Ticketloses Parkieren» und wie funktioniert es?',
            fr:
                'Qu’est-ce que le «Stationner sans ticket» et comment fonctionne-t-il?',
            it: 'Che cos’è «Parcheggiare senza ticket» e come funziona?',
            en: 'What is “Ticketless parking” and how does it work?',
        },
        answer: {
            de:
                'Bei der neuen Funktion «Ticketloses Parkieren» bieten wir unseren Nutzern neu die Möglichkeit, bei Parkanlagen mit Schranken (z.B. Parkhäusern) und Kennzeichenerkennung, von einer automatisierten Abrechnung der Parkgebühr zu profitieren. Für dich fallen dabei für diese Dienstleistung pro kostenpflichtigem Parkvorgang zusätzliche Gebühren von CHF 0.25 an. Weitere Informationen dazu findest du im FAQ-Bereich auf www.twint.ch.',
            fr:
                'Avec la nouvelle fonction «Stationner sans ticket», nous offrons à nos clients la possibilité de profiter d’une facturation automatisée dans les parkings dotés de systèmes de barrières (p. ex. parkings souterrains) et d’un système de reconnaissance des plaques d’immatriculation. Pour cette prestation, des frais supplémentaires de CHF 0.25 sont facturés au client pour chaque procédure de stationnement payant. De plus amples informations à ce propos figurent dans la rubrique correspondante de la FAQ sous www.twint.ch.',
            it:
                '«Parcheggiare senza ticket» è una nuova funzione che offre ai nostri utenti la possibilità di saldare automaticamente le tasse di parcheggio negli impianti dotati di barriere (ad es. autosili) e del riconoscimento targa. Questo servizio prevede una commissione supplementare di CHF 0.25 per ogni sosta a pagamento. Ulteriori informazioni sono disponibili nelle FAQ su www.twint.ch.',
            en:
                'With the new «Ticketless parking» function, we are now offering our users the opportunity to benefit from an automated system for paying parking fees in parking facilities with barrier systems (e.g. multi-storey car parks) and a licence plate recognition system. This service will incur an additional fee for you of CHF 0.25 per paid parking transaction. Further information on this topic is available in the FAQ section at www.twint.ch.',
        },
    },
    {
        question: {
            de:
                'Bei welchen Parkings ist das ticketlose Ein- und Ausfahren bereits verfügbar?',
            fr: 'Quels parkings proposent le stationnement sans ticket?',
            it:
                'Quali sono i parcheggi che consentono di entrare e uscire senza ticket?',
            en:
                'In which car parks is it already possible to enter and exit without a ticket?',
        },
        answer: {
            de:
                'Öffne die Funktion Parkieren. Tippe auf das Fahrzeug-Symbol oben rechts auf der Karte. Tippe nun auf «Kennzeichenerkennung» und im nächsten Screen dann auf «Parkingliste anzeigen». Jetzt werden dir alle Parkings angezeigt, bei denen das ticketlose Ein- und Ausfahren möglich ist.',
            fr:
                'Ouvrez la fonction Parking. Cliquez sur le symbole du véhicule en haut à droite de la carte. Cliquez ensuite sur «Reconnaissance des plaques d’immatriculation» puis sur «Afficher la liste des parkings» sur le prochain écran. Tous les parkings proposant le stationnement sans ticket s’afficheront alors.',
            it:
                'Apri la funzione Parking. Clicca sul simbolo del veicolo in alto a destra nella mappa. Clicca quindi su «Riconoscimento targa», e nella schermata successiva su «Visualizza elenco parcheggi». Verrà quindi visualizzata la lista di tutti i parcheggi dov’è possibile entrare e uscire senza ticket.',
            en:
                'Open the parking function. Tap the vehicle symbol in the top-right corner of the map. Now, tap «Licence plate recognition» and then «Show parking list» on the next screen. You will now see all of the car parks where you can enter and exit without a ticket.',
        },
    },
    {
        question: {
            de:
                'Wie kann ich die Funktion «Ticketloses Parkieren» wieder deaktivieren?',
            fr:
                'Comment puis-je désactiver la fonction «Stationner sans ticket»?',
            it:
                'Come faccio a disattivare la funzione «Parcheggiare senza ticket»?',
            en: 'How do I deactivate the service «Ticketless parking»?',
        },
        answer: {
            de:
                'Um ein bestimmtes Fahrzeugkennzeichen abzumelden, öffne die Funktion Parkieren und tippe auf das Fahrzeug-Symbol oben rechts auf der Karte. Tippe nun rechts neben dem Fahrzeugkennzeichen, welches du deaktivieren möchtest, auf das Kamera-Symbol und bestätige die Deaktivierung. Um dich vollständig abzumelden, tippe auf «Kennzeichenerkennung», im nächsten Screen dann auf «Kennzeichenerkennung aktiv» und bestätige die Deaktivierung.',
            fr:
                "Pour révoquer l'enregistrement d'une certaine plaque d'immatriculation, ouvrez la fonction Parking et cliquez sur le symbole du véhicule en haut à droite de la carte. À droite du numéro d’immatriculation que vous souhaitez désactiver, cliquez sur le symbole de caméra et confirmez la désactivation. Pour te désinscrire complètement, appuie sur «Reconnaissance des plaques», puis sur «Reconnaissance des plaques active» sur le prochain écran et confirmer la désactivation.",
            it:
                'Per revocare la registrazione di una determinata targa apri la funzione Parking e clicca sul simbolo del veicolo in alto a destra nella mappa. Clicca quindi sul simbolo della telecamera accanto alla targa che vorresti disattivare e conferma la disattivazione. Per revocare completamente la registrazione, clicca su «Riconoscimento targa», quindi nella schermata successiva su «Riconoscimento targa attivo» e conferma la disattivazione.',
            en:
                'To cancel the registration for a specific licence plate number, open the parking function and tap the vehicle symbol in the top-right corner of the screen. Now tap the camera symbol to the right of the licence plate number that you would like to deactivate and then confirm the deactivation. To deregister completely, tap «Licence plate recognition», then on the next screen «License plate recognition active» and confirm the deactivation.',
        },
    },
];

export const HelpdeskTexts = {
    question: {
        de: 'Weitere Fragen?',
        fr: 'Plus de questions?',
        it: 'Altre domande?',
        en: 'Further questions?',
    },
    answer: {
        de: HELPDESK,
        fr: HELPDESK,
        it: HELPDESK,
        en: HELPDESK,
    },
};

export const FAQDataProtectionTexts = {
    delete: {
        question: {
            de: 'Daten löschen',
            fr: 'Supprimer les données',
            it: 'Elimina dati',
            en: 'Delete data',
        },
        answer: (ref: string) => ({
            de: `Die Parkingpay-Daten werden zwar von der TWINT-App gelöscht, aber bleiben für den Betreiber für max. 24 Monate abrufbar. Wenn Sie Ihre Daten wirklich löschen möchten, wenden Sie sich bitte an unseren Helpdesk unter 0848 330 555 und teilen Sie uns die Referenz ${ref} mit.`,
            fr: `Les données Parkingpay sont supprimées de l'application TWINT, mais restent disponibles pour l'exploitant pour max. 24 mois. Si vous souhaitez vraiment supprimer vos données, veuillez contacter notre Helpdesk au 0848 330 555 et communiquer la référence ${ref}.`,
            it: `I dati Parkingpay vengono cancellati dall'app TWINT, ma rimangono disponibili per il gestore al max. 24 mesi. Se volete veramente cancellare i vostri dati contattate il nostro Helpdesk al numero 0848 330 555 e comunicate il riferimento ${ref}.`,
            en: `The Parkingpay data is deleted from the TWINT app, but remain available for the operator for max. 24 months. If you really want to delete your data please contact our Helpdesk on 0848 330 555 and communicate the reference ${ref}.`,
        }),
    },
    export: {
        question: {
            de: 'Daten exportieren',
            fr: 'Exporter les données',
            it: 'Esporta dati',
            en: 'Export data',
        },
        answer: (link: string) => ({
            de: (
                <a href={link} target="_blank" rel="noreferrer">
                    Download
                </a>
            ),
            fr: (
                <a href={link} target="_blank" rel="noreferrer">
                    Download
                </a>
            ),
            it: (
                <a href={link} target="_blank" rel="noreferrer">
                    Download
                </a>
            ),
            en: (
                <a href={link} target="_blank" rel="noreferrer">
                    Download
                </a>
            ),
        }),
    },
};

interface Texts {
    lp: LicensePlate;
    lpValidation: LpValidation;
    parkingsList: ParkingsList;
    lprActivation: LprActivation;
    lprSettings: LprSettings;
    checkin: Checkin;
    onboarding: Onboarding;
    receipt: Receipt;
    outstandingAmount: OutstandingAmount;
    general: General;
    quota: Quota;
}

interface LicensePlate {
    type: Translation;
    typeCar: Translation;
    typeMotorcycle: Translation;
    licensePlate: Translation;
    licensePlateHint: Translation;
    mostUsedCountries: Translation;
    allCountries: Translation;
    delete: Translation;
    deleteDescription: Translation;
    addLicensePlate: Translation;
    cars: Translation;
    lprNotificationTitle: Translation;
    lprNotificationText: (onClick: () => void) => Translation;
    lprDeleteLpTitle: Translation;
    lprDeleteLpDesc: (lp: string) => Translation;
    lprActivateLpTitle: Translation;
    lprActivateLpDesc: (lp: string) => Translation;
    lprActivateLpFees: Translation;
    lprErrorTitle: Translation;
    lprErrorReasonOccupied: (lp: string) => Translation;
    lprErrorReasonNotAllowedMotorcycle: (lp: string) => Translation;
    lprErrorReasonNotAllowedDiplomatic: (lp: string) => Translation;
}

interface General {
    activate: Translation;
    back: Translation;
    close: Translation;
    confirm: Translation;
    confirmAmount: Translation;
    continue: Translation;
    cancel: Translation;
    ok: Translation;
    dayShort: Translation;
    daysShort: Translation;
    deactivate: Translation;
    hide: Translation;
    hoursShort: Translation;
    secondsShort: Translation;
    minutesShort: Translation;
    description: Translation;
    details: Translation;
    permitValidity: Translation;
    tryAgain: Translation;
    useMeter: Translation;
    error: Translation;
    errorNonActivePermit: Translation;
    errorNonActiveZone: Translation;
    contactOperatorCallToAction: Translation;
    wereSorry: Translation;
    backToPortraitMode: Translation;
    lprAdvertisement: Translation;
    lprAdvertisementWithParking: Translation;
    transactionsTitle: Translation;
    twintLimitationTitle: Translation;
    twintLimitationText1: Translation;
    twintLimitationText2: Translation;
    noTransactions: Translation;
}

interface Checkin {
    parkDuration: Translation;
    until: Translation;
    price: Translation;
    payNow: Translation;
    buyParkTicket: Translation;
    enterTime: Translation;
    confirmAmount: Translation;
    operatorName: Translation;
    zoneName: Translation;
    cityName: Translation;
    maxParkTime: Translation;
    urlUsed: Translation;
    expired: Translation;
    showReceipt: Translation;
    transactionRunning: Translation;
    unpairedQrCodeShareLocation: Translation;
    unpairedQrCodeError: Translation;
    unpairedQrCodeGeoSent: Translation;
    unpairedQrCodeGeoNotSent: Translation;
    close: Translation;
    immediateParkForThisLicensePlateAndZone: Translation;
    subsequentPaymentForbiddenAnyway: Translation;
    title: Translation;
    whatYouShouldDo: Translation;
    parkingProhibitedTitle: Translation;
    parkingProhibitedDescription: Translation;
    closeWindow: Translation;
    hour: Translation;
    hours: Translation;
    day: Translation;
    days: Translation;
    week: Translation;
    weeks: Translation;
    month: Translation;
    months: Translation;
    purchasePermit: Translation;
    additionalInfos: Translation;
    zones: Translation;
}

interface Onboarding {
    lpForEnforcement: Translation;
    atLeastOneCar: Translation;
}

interface Receipt {
    terminate: Translation;
    parkingTime: Translation;
    from: Translation;
    to: Translation;
    time: Translation;
    terminationAlertInfo: Translation;
    terminated: (amount: number) => Translation;
    downloadReceipt: Translation;
}

interface LpValidation {
    allLettersUppercase: Translation;
    countryMustBeFL: Translation;
    hasMoreThanFiveNumbers: Translation;
    hasMoreThanSixNumbers: Translation;
    lettersAfterCanton: Translation;
    lettersAfterCountryFL: Translation;
    minThreeLetters: Translation;
    noValidCanton: Translation;
    noWhiteSpace: Translation;
    mandatory: Translation;
}

interface ParkingsList {
    parkingsListTitle: Translation;
}

interface LprSettings {
    title: Translation;
    lprFunctionDescription: Translation;
    showParkingslist: Translation;
    lprFeeDescription: Translation;
    toggleLPR: Translation;
    deactivateLPRTitle: Translation;
    deactivateLPRQuestion: Translation;
}

interface LprActivation {
    consentTitle: Translation;
    consentTwintPermission: Translation;
    consentTwintAcceptNote: Translation;
    pairingOngoingTitle: Translation;
    pairingOngoingDesc: Translation;
    pairingOngoingReopenIntent: Translation;
    pairingSuccessTitle: Translation;
    pairingSuccessDesc: Translation;
    pairingSuccessDescShort: Translation;
    pairingSingleLpOccupied: (
        lp: LicensePlateState.LicensePlate,
    ) => Translation;
    pairingMultipleLpsOccupied: (
        lps: LicensePlateState.LicensePlate[],
    ) => Translation;
    pairingSingleLpNotAllowed: (
        lp: LicensePlateState.LicensePlate,
    ) => Translation;
    pairingMultipleLpsNotAllowed: (
        lps: LicensePlateState.LicensePlate[],
    ) => Translation;
    pairingErrorTitle: Translation;
    pairingErrorDesc: Translation;
    pairingErrorReasonAbort: Translation;
    pairingErrorReasonIntent: Translation;
    pairingErrorReasonNoCar: Translation;
    pairingAbortTitle: Translation;
    pairingAbortDesc: Translation;
}

interface OutstandingAmount {
    outstandingAmountTitle: Translation;
    outstandingAmountText: (outstandingAmount: number) => Translation;
    retryPayment: Translation;
    renewAlias: Translation;
    failedAttemptTitle: Translation;
    failedAttemptText: Translation;
    restartSession: Translation;
    contactIssuer: Translation;
    askToRenewAlias: Translation;
    reasonInsufficientFunds: Translation;
    topUpInstructions: Translation;
    paymentSuccessfulTitle: Translation;
    paymentSuccessfullText: Translation;
    collectAmount: Translation;
}

interface Quota {
    quotaExhausted: Translation;
    quotaExhaustedExplanation: Translation;
}
