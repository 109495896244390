import * as React from 'react';
import { Localized } from '../../../../../common/hooks/LanguageProvider';
import { FeedbackPopup } from '../../../components/FeedbackPopup';
import { useNavigate } from 'react-router-dom';

export function PaymentUpdateInitialRoute() {
    const navigate = useNavigate();

    return (
        <FeedbackPopup
            open={true}
            color="warning"
            onAbort={() => {
                navigate('..');
            }}
            abortLabel={
                <Localized
                    de="Weiter"
                    fr="Continuer"
                    it="Avanti"
                    en="Proceed"
                />
            }
            title={
                <Localized
                    de="Zahlungsmittel fehlt"
                    fr="Moyen de paiement manquant"
                    it="Mezzo di pagamento mancante"
                    en="Missing payment mean"
                />
            }
        >
            <Localized
                de="Der letzte Zahlungsversuch ist fehlgeschlagen. Bitte überprüfen und aktualisieren Sie das Zahlungsmittel."
                fr="La dernière tentative de paiement a échoué. Veuillez vérifier et mettre à jour votre moyen de paiement."
                it="L'ultimo tentativo di pagamento non è riuscito. Voglia verificare ed aggiornare il mezzo di pagamento."
                en="The last payment attempt failed. Please check and update the payment means."
            />
        </FeedbackPopup>
    );
}
