import { Language } from '../lib/Localized';

export type SavedCreditCardAliasResponse =
    | CreditCardAliasFound
    | CreditCardAliasNotFound;

type CreditCardAliasNotFound = {
    tag: SavedCreditCardAliasResponseTag.NOT_FOUND;
};

export interface CreditCardAliasFound {
    creditCardAlias: CreditCardAlias;
    tag: SavedCreditCardAliasResponseTag.FOUND;
}

export const enum SavedCreditCardAliasResponseTag {
    FOUND = 'FOUND',
    NOT_FOUND = 'NOT_FOUND',
}

export interface CreditCardAlias {
    maskedCreditCardNumber: string | null;
    expiration: CreditCardExpiration | null;
    paymentMethod: PaymentCardType;
    lastDirectFailed: boolean;
}

export interface CreditCardExpiration {
    month: number;
    year: number;
    upperBoundDateTime: string;
    isExpired?: boolean; // todo: remove undefined after there are no more old parkingabo versions in circulation
}

export enum PaymentCardType {
    VIS = 'VIS',
    AMX = 'AMX',
    ECA = 'ECA',
    PEF = 'PEF',
    PFC = 'PFC',
    TWI = 'TWI',
}

export const paymentMethodTranslations: {
    [key in PaymentCardType]: { [key in Language]: string };
} = {
    [PaymentCardType.VIS]: {
        [Language.DE]: 'Visa',
        [Language.FR]: 'Visa',
        [Language.IT]: 'Visa',
        [Language.EN]: 'Visa',
    },
    [PaymentCardType.AMX]: {
        [Language.DE]: 'American Express',
        [Language.FR]: 'American Express',
        [Language.IT]: 'American Express',
        [Language.EN]: 'American Express',
    },
    [PaymentCardType.ECA]: {
        [Language.DE]: 'Mastercard',
        [Language.FR]: 'Mastercard',
        [Language.IT]: 'Mastercard',
        [Language.EN]: 'Mastercard',
    },
    [PaymentCardType.PEF]: {
        [Language.DE]: 'Postfinance E-Finance',
        [Language.FR]: 'Postfinance E-Finance',
        [Language.IT]: 'Postfinance E-Finance',
        [Language.EN]: 'Postfinance E-Finance',
    },
    [PaymentCardType.PFC]: {
        [Language.DE]: 'Postfinance Card',
        [Language.FR]: 'Postfinance Card',
        [Language.IT]: 'Postfinance Card',
        [Language.EN]: 'Postfinance Card',
    },
    [PaymentCardType.TWI]: {
        [Language.DE]: 'TWINT',
        [Language.FR]: 'TWINT',
        [Language.IT]: 'TWINT',
        [Language.EN]: 'TWINT',
    },
};
