import { Box } from '@mui/material';
import * as React from 'react';
import { Localized } from '../../../../../common/hooks/LanguageProvider';
import { ParkingaboLayoutWithHeader } from '../../../components/layout/ParkingaboLayoutWithHeader';
import { ParkingaboFooter } from '../../../components/layout/ParkingaboFooter';
import { ParkingaboMenuListItem } from '../../../components/ParkingaboMenuListItem';
import {
    AuthedRouteCompProps,
    useParkingaboAuthedPathGeneration,
} from '../../RouteUtils';
import { Logout } from '@mui/icons-material';

export function SettingsRoute({ user, logout }: AuthedRouteCompProps) {
    const generateAuthedParkingaboPath = useParkingaboAuthedPathGeneration();
    return (
        <ParkingaboLayoutWithHeader
            noGutter
            backTo={generateAuthedParkingaboPath('')}
            title={
                <Localized
                    de="Einstellungen"
                    fr="Paramètres"
                    it="Impostazioni"
                    en="Settings"
                />
            }
        >
            <Box>
                <ParkingaboMenuListItem
                    to={generateAuthedParkingaboPath('settings/user')}
                    text={
                        <Localized
                            de="Benutzer"
                            fr="Utilisateur"
                            it="Utente"
                            en="User"
                        />
                    }
                />
                <ParkingaboMenuListItem
                    to={generateAuthedParkingaboPath('settings/payment')}
                    text={
                        <Localized
                            de="Zahlungsmittel"
                            fr="Moyen de paiement"
                            it="Mezzo di pagamento"
                            en="Payment mean"
                        />
                    }
                />
                {/*
                    <ParkingaboMenuListItem
                        to=""
                        text={
                            <Localized
                                de="Kontoauszug"
                                fr="Relevé de compte"
                                it="Estratto conto"
                                en="Account statement"
                            />
                        }
                    />
                */}
                <ParkingaboMenuListItem
                    to=""
                    onClick={logout}
                    text={
                        <Localized
                            de="Abmelden"
                            fr="Déconnecter"
                            it="Esci"
                            en="Logout"
                        />
                    }
                    icons={[<Logout key={0} />]}
                    hideChevron
                />
            </Box>
            <ParkingaboFooter internal user={user} />
        </ParkingaboLayoutWithHeader>
    );
}
