import * as React from 'react';
import { Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

export function ParkingaboLink(
    props: React.ComponentProps<typeof RouterLink> &
        React.ComponentProps<typeof Link>,
) {
    return <Link component={RouterLink} {...props} />;
}
