import * as React from 'react';
import { Localized } from '../../../../../common/hooks/LanguageProvider';
import { FeedbackPopup } from '../../../components/FeedbackPopup';
import { useNavigate } from 'react-router-dom';

export function AddPaymentSuccessRoute() {
    const navigate = useNavigate();

    return (
        <FeedbackPopup
            open={true}
            color="success"
            onAbort={() => {
                navigate('..');
            }}
            abortLabel={'OK'}
            title={
                <Localized
                    de="Bestätigung"
                    fr="Confirmation"
                    it="Conferma"
                    en="Confirmation"
                />
            }
        >
            <Localized
                de="Sie haben Ihr Zahlungsmittel erfolgreich hinzugefügt."
                fr="Vous avez ajouté votre moyen de paiement avec succès."
                it="Ha aggiunto con successo il suo mezzo di pagamento."
                en="You have successfully added your payment mean."
            />
        </FeedbackPopup>
    );
}
