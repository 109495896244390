import * as React from 'react';
import { Localized } from '../../../../common/hooks/LanguageProvider';
import { FeedbackPopup } from '../../components/FeedbackPopup';
import { useNavigate } from 'react-router-dom';

export function PasswordRequestSentSuccessRoute() {
    const navigate = useNavigate();
    return (
        <FeedbackPopup
            open={true}
            color="success"
            onAbort={() => {
                navigate('/login');
            }}
            abortLabel={
                <Localized de="Schliessen" fr="Fermer" it="Chiudi" en="Close" />
            }
            title={
                <Localized
                    de="E-Mail erfolgreich gesandt"
                    fr="E-mail envoyé avec succès"
                    it="E-mail inviato con successo"
                    en="Email sent successfully"
                />
            }
        >
            <Localized
                de="Wir haben Ihnen eine E-Mail, mit einem Link zum Ändern des Passworts gesendet."
                fr="Nous vous avons envoyé un e-mail avec un lien pour changer votre mot de passe."
                it="Le abbiamo inviato una e-mail con un link per modificare la sua password."
                en="We have sent you an e-mail with a link to change your password."
            />
        </FeedbackPopup>
    );
}
