import * as React from 'react';
import { Localized } from '../../../../../common/hooks/LanguageProvider';
import {
    AuthedRouteCompProps,
    useParkingaboAuthedPathGeneration,
} from '../../RouteUtils';
import { useParkingaboServerWrite } from '../../../api/ParkingaboApi';
import {
    RequestMethod,
    RequestStatus,
    useServerSuccessEffect,
} from '../../../../../lib/hooks/ServerStateHooks';
import {
    LoadingSpinnerPresets,
    PresetLoadingSpinner,
} from '../../../../../common/components/material-ui/PresetLoadingSpinner';
import { FeedbackPopup } from '../../../components/FeedbackPopup';
import { useNavigate } from 'react-router-dom';
import { AliasRequiredReason } from '../../../shared/ParkingaboModels';

export function OnboardingSuccessRoute({
    user,
    refetchUser,
}: AuthedRouteCompProps) {
    const [
        onboardingCompleteState,
        setOnboardingComplete,
    ] = useParkingaboServerWrite<{}, { success: boolean }>(() => ({
        url: '/ui-api/parkingabo/user/self/set-onboarding-complete',
        method: RequestMethod.POST,
    }));
    const navigate = useNavigate();
    const generateAuthedParkingaboPath = useParkingaboAuthedPathGeneration();

    useServerSuccessEffect(onboardingCompleteState, () => {
        refetchUser();
    });

    if (onboardingCompleteState.status === RequestStatus.NEVER_EXECUTED) {
        setOnboardingComplete();
    } else if (
        onboardingCompleteState.status === RequestStatus.PENDING ||
        user.aliasRequiredReason === AliasRequiredReason.ONBOARDING_REQUIRED
    ) {
        return (
            <PresetLoadingSpinner
                preset={LoadingSpinnerPresets.FillAllSpaceAndCenter}
            />
        );
    }

    return (
        <FeedbackPopup
            open={true}
            color="success"
            onAbort={() => {
                navigate(generateAuthedParkingaboPath(''));
            }}
            abortLabel={
                <Localized
                    de="Weiter"
                    fr="Continuer"
                    it="Avanti"
                    en="Proceed"
                />
            }
            title={
                <Localized
                    de="Konfiguration erfolgreich abgeschlossen"
                    fr="Configuration complété avec succès"
                    it="Configurazione concluso con successo"
                    en="Setup successfully completed"
                />
            }
        >
            <Localized
                de="Die Konfiguration kann jederzeit in den Kontoeinstellungen geändert werden."
                fr="La configuration peut être modifiée à tout moment dans les paramètres du compte."
                it="La configurazione può essere modificata in qualsiasi momento nelle impostazioni del conto."
                en="The configuration can be changed at any time in the account settings."
            />
        </FeedbackPopup>
    );
}
