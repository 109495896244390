import { ErrorOption } from 'react-hook-form';
import { Language } from '../../lib/Localized';

export const enum FormFieldViolationScope {
    FIELD = 'FIELD',
    GLOBAL = 'GLOBAL',
}

interface FormFieldViolationEntry {
    fieldName: string;
    type: 'LENGTH';
    scope: FormFieldViolationScope.FIELD;
    humanReadableMessage: {
        byLanguage: { [key in Language]: string };
    };
    max?: number;
    min?: number;
    message: string;
}

export interface FormGlobalViolationEntry {
    issue: {
        humanReadableMessage: {
            byLanguage: { [key in Language]: string };
        };
        message: string;
    };
    scope: FormFieldViolationScope.GLOBAL;
}

export function isFormGlobalViolationEntry(
    err: FormViolationEntry | undefined,
): err is FormGlobalViolationEntry {
    return err?.scope === FormFieldViolationScope.GLOBAL;
}

export interface ValidationData {
    violations: FormViolationEntry[];
}

export type FormViolationEntry =
    | FormGlobalViolationEntry
    | FormFieldViolationEntry;

export function addValidationErrorsToForm(
    errorData: ValidationData,
    setError: (
        name: string,
        error: ErrorOption,
        options?:
            | {
                  shouldFocus: boolean;
              }
            | undefined,
    ) => void,
    language: Language,
): FormGlobalViolationEntry[] {
    const globalErrors: FormGlobalViolationEntry[] = [];

    errorData.violations.forEach(report => {
        if (report.scope === FormFieldViolationScope.GLOBAL) {
            globalErrors.push(report);
        } else {
            setError(report.fieldName, {
                message: report.humanReadableMessage.byLanguage[language],
                type: report.type ?? 'validation',
            });
        }
    });

    return globalErrors;
}
