import { CssBaseline } from '@mui/material';
import * as React from 'react';
import { ParkingaboApiMiddlewereContextProvider } from './api/ParkingaboApi';
import { AppRoutes, useLogin } from './routes/AppRoutes';
import { MainLayout } from './MainLayout';
import { LanguageProvider } from '../../common/hooks/LanguageProvider';
import { ParkingaboThemeProvider } from './shared/ParkingaboThemeProvider';
import { ParkingaboSubdomain } from './config/TenantConfig';
import { useMemo } from 'react';

export function App() {
    return (
        <LanguageProvider>
            <ParkingaboApiMiddlewereContextProvider>
                <ActualApp />
            </ParkingaboApiMiddlewereContextProvider>
        </LanguageProvider>
    );
}

function ActualApp() {
    const login = useLogin();
    const subdomain = useMemo(() => {
        if (login.subdomain == null) {
            return ParkingaboSubdomain.Digitalparking;
        }

        return (Object.values(ParkingaboSubdomain) as string[]).includes(
            login.subdomain,
        )
            ? (login.subdomain as ParkingaboSubdomain)
            : ParkingaboSubdomain.Digitalparking;
    }, [login.subdomain]);

    return (
        <ParkingaboThemeProvider subdomain={subdomain}>
            <MainLayout>
                <CssBaseline />
                <AppRoutes login={login} />
            </MainLayout>
        </ParkingaboThemeProvider>
    );
}
