import * as React from 'react';
import {
    RoutedModal,
    RoutedModalProps,
} from '../../../../common/components/material-ui/routed-modal/RoutedModal';
import { useLanguage } from '../../../../common/hooks/LanguageProvider';
import { isMobile } from '../../../../common/utils/BrowserOrigin';
import {
    languageFromString,
    makeLocalizedText,
} from '../../../../lib/Localized';
import { MAX_HEIGHT_DESKTOP, MAX_WIDTH_DESKTOP } from '../../MainLayout';

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export function ParkingaboRoutedModal(
    props: PartialBy<RoutedModalProps, 'backNavigationConfirmationMessage'>,
) {
    const { language } = useLanguage();

    const defaultConfirmationMessage =
        props.backNavigationConfirmationMessage ??
        makeLocalizedText(languageFromString(language))({
            de:
                'Die Änderungen werden verloren gehen. Wollen Sie trotzdem die Seite verlassen?',
            fr: 'Les modifications seront perdues. Quitter quand même la page?',
            it: 'Le modifiche andranno perse. Lasciare comunque la pagina?',
            en: 'The changes will be lost. Leave the page anyway?',
        });

    return (
        <RoutedModal
            {...props}
            backNavigationConfirmationMessage={defaultConfirmationMessage}
            style={{
                top: !isMobile() ? 0 : '50%',
                transform: !isMobile()
                    ? 'translate(-50%, 0)'
                    : 'translate(-50%, -50%)',
                width: '100%',
                height: '100%',
                maxWidth: !isMobile() ? MAX_WIDTH_DESKTOP : undefined,
                position: 'relative',
                maxHeight: !isMobile() ? MAX_HEIGHT_DESKTOP : undefined,
            }}
            hideBackdrop
            hideShadows
        />
    );
}
