import { CircularProgress, useTheme } from '@mui/material';
import * as React from 'react';
import { useEffect } from 'react';
import {
    createBrowserRouter,
    RouterProvider,
    useNavigate,
} from 'react-router-dom';
import { isPWA } from '../../../lib/utils/PwaUtils';
import {
    useParkingaboServerFetch,
    useParkingaboServerWrite,
    useUpdateParkingaboCsrfToken,
} from '../api/ParkingaboApi';
import {
    REFRESH_TOKEN_LOCALSTORAGE_KEY,
    SessionValidity,
    useCheckAndRefreshSession,
    useSession,
} from '../api/ParkingaboLoginApi';
import {
    requestDone,
    RequestStatus,
    useServerErrorEffect,
    useServerSuccessEffect,
} from '../../../lib/hooks/ServerStateHooks';
import { OnboardingProvider } from './authorized/onboarding/OnboardingConfig';
import { ParkingaboUser } from '../shared/ParkingaboModels';
import { ParkingaboVehicleWithLicensePlate } from '../../../common/models/Vehicle';
import { ParkingaboAsyncLoadedSection } from '../components/layout/ParkingaboAsyncLoadedSection';
import { VehiclesRoute } from './authorized/vehicles/VehiclesRoute';
import { VehiclesDetailRoute } from './authorized/vehicles/VehiclesDetailRoute';
import { AddVehicleRoute } from './authorized/vehicles/AddVehicleRoute';
import { ProductsAddOutlet } from './authorized/products/ProductsAddOutlet';
import { ProductsConfigurationOutlet } from './authorized/products/ProductsConfigurationOutlet';
import { ProductsSelectionOutlet } from './authorized/products/ProductsSelectionOutlet';
import { ProductsRoute } from './authorized/products/ProductsRoute';
import { ProductsDetailOutlet } from './authorized/products/ProductsDetailOutlet';
import { ParkingaboProductListItem } from '../shared/ParkingaboProductModels';
import { AddPaymentMethodRoute } from './authorized/payment/AddPaymentMethodRoute';
import { PaymentRegisterInitialRoute } from './authorized/payment/PaymentRegisterInitialRoute';
import { PaymentMethodType } from '../components/forms/PaymentMethodForm';
import { PaymentUpdateInitialRoute } from './authorized/payment/PaymentUpdateInitialRoute';
import { PaymentAbortedRoute } from './authorized/payment/PaymentAbortedRoute';
import { TwintPairingRoute } from './authorized/payment/TwintPairingRoute';
import { PaymentMethodCheckRoute } from './authorized/payment/PaymentMethodCheckRoute';
import { OnboardingPaymentMethodRoute } from './authorized/onboarding/OnboardingPaymentMethodRoute';
import { OnboardingUserDataRoute } from './authorized/onboarding/OnboardingUserDataRoute';
import { OnboardingVehicleRoute } from './authorized/onboarding/OnboardingVehicleRoute';
import { OnboardingSuccessRoute } from './authorized/onboarding/OnboardingSuccessRoute';
import { HomeRoute } from './authorized/HomeRoute';
import { TenantSelectionRoute } from './authorized/TenantSelectionRoute';
import { TenantChecker } from '../components/TenantChecker';
import { PaymentChecker } from '../components/PaymentChecker';
import { RedirectRequiredChecker } from '../components/RedirectRequiredChecker';
import { SettingsRoute } from './authorized/settings/SettingsRoute';
import { PaymentSettingsRoute } from './authorized/settings/PaymentSettingsRoute';
import { EmailResentConfirmationRoute } from './authorized/settings/EmailResentConfirmationRoute';
import { AddPaymentSuccessRoute } from './authorized/settings/AddPaymentSuccessRoute';
import { EditLanguageRoute } from './authorized/settings/EditLanguageRoute';
import { EditNameRoute } from './authorized/settings/EditNameRoute';
import { EditEmailRoute } from './authorized/settings/EditEmailRoute';
import { EditPasswordRoute } from './authorized/settings/EditPasswordRoute';
import { EditProfileRoute } from './authorized/settings/EditProfileRoute';
import { LoginRoute } from './public/LoginRoute';
import { ParkingaboRegistrationRoute } from './public/ParkingaboRegistrationRoute';
import { RegistrationConfirmationRoute } from './public/RegistrationConfirmationRoute';
import { RegistrationLinkExpiredRoute } from './public/RegistrationLinkExpiredRoute';
import { RequestPasswordResetRoute } from './public/RequestPasswordResetRoute';
import { PasswordRequestSentSuccessRoute } from './public/PasswordRequestSentSuccessRoute';
import { ResetPasswordRoute } from './public/ResetPasswordRoute';
import { ResetPasswordSuccessRoute } from './public/ResetPasswordSuccessRoute';
import { ResetPasswordTokenErrorRoute } from './public/ResetPasswordTokenErrorRoute';
import { EmailVerificationRoute } from './public/EmailVerificationRoute';
import {
    AccountStatementItemDetail,
    AccountStatementOutlet,
} from './authorized/account-statement/AccountStatementOutlet';

export interface ParkingaboLogin {
    loggedIn: boolean;
    logout: () => void;
    setLoggedIn: (value: boolean) => void;
    subdomain: string | null;
    allowsSignup: boolean | null;
    appNotReadyToBeShown: boolean;
}

export function useLogin(): ParkingaboLogin {
    const setGlobalCsrfToken = useUpdateParkingaboCsrfToken();
    const [loggedIn, setLoggedIn] = React.useState(false);
    const [appReadyToBeShown, setAppReadyToBeShown] = React.useState(false);
    const [
        hasAttemptedLoginWithRefreshToken,
        setHasAttemptedLoginWithRefreshToken,
    ] = React.useState(false);
    const [session, setSession] = React.useState<SessionValidity>();
    const [checkSessionState] = useCheckAndRefreshSession();
    useServerSuccessEffect(checkSessionState, setSession);
    const [sessionState, getSession] = useSession();
    useServerSuccessEffect(sessionState, setSession);

    const refreshToken = localStorage.getItem(REFRESH_TOKEN_LOCALSTORAGE_KEY);

    function loginWithRefreshToken(nonNullableRefreshToken: string) {
        setHasAttemptedLoginWithRefreshToken(true);
        getSession({ refreshToken: nonNullableRefreshToken });
    }

    const [logoutState, logout] = useParkingaboServerWrite(() => ({
        url: '/ui-api/parkingabo/session/logout',
    }));

    useServerSuccessEffect(logoutState, () => setLoggedIn(false));

    React.useEffect(() => {
        if (!loggedIn && appReadyToBeShown) {
            getSession();
        }
    }, [loggedIn]);

    React.useEffect(() => {
        if (!session || (appReadyToBeShown && loggedIn)) {
            return;
        }
        if (session.loginId && session.refreshToken) {
            localStorage.setItem(
                REFRESH_TOKEN_LOCALSTORAGE_KEY,
                session.refreshToken,
            );
        } else if (hasAttemptedLoginWithRefreshToken && !session.loginId) {
            // login didn't work => token is invalid
            localStorage.removeItem(REFRESH_TOKEN_LOCALSTORAGE_KEY);
        }

        setLoggedIn(!!session.loginId);
        setGlobalCsrfToken(session.csrfToken);
        setAppReadyToBeShown(true);
    }, [session]);

    useServerSuccessEffect(checkSessionState, data => {
        if (!data.loginId) {
            if (refreshToken && isPWA()) {
                // could autologin
                loginWithRefreshToken(refreshToken);
            } else {
                // cannot auto-relogin
                setLoggedIn(false);
                setGlobalCsrfToken(data.csrfToken);
                setAppReadyToBeShown(true);
            }
        }
    });

    useServerErrorEffect(checkSessionState, statusCode => {
        if (statusCode === 401) {
            if (isPWA() && refreshToken) {
                loginWithRefreshToken(refreshToken);
            } else {
                getSession();
            }
        }
    });

    const anyPending = [checkSessionState.status, sessionState.status].some(
        status => status === RequestStatus.PENDING,
    );

    return {
        loggedIn,
        logout: () => logout(), // make sure nothing weird is passed in
        setLoggedIn,
        subdomain: session?.subdomain || null,
        allowsSignup: session?.allowsSignup || null,
        appNotReadyToBeShown:
            !requestDone(checkSessionState.status) ||
            anyPending ||
            !appReadyToBeShown,
    };
}

export function AppRoutes({ login }: { login: ParkingaboLogin }) {
    const theme = useTheme();

    if (login.appNotReadyToBeShown) {
        return (
            <div
                style={{
                    backgroundColor: theme.palette.grey[400],
                    height: '100vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <CircularProgress />
            </div>
        );
    }

    if (!login.loggedIn) {
        return <RouterProvider router={publicRouter(login)} />;
    }

    return <AuthedRoutes login={login} logout={login.logout} />;
}

export function NoMatch({ loggedIn }: { loggedIn: boolean }) {
    const navigate = useNavigate();
    useEffect(() => {
        if (!loggedIn) {
            navigate(`/login`, { replace: true });
        } else {
            navigate(`/`, { replace: true });
        }
    }, [loggedIn]);

    return null;
}

function publicRouter(login: ParkingaboLogin) {
    const registrationRoutes = [];
    if (login.allowsSignup) {
        registrationRoutes.push({
            path: '/register/*',
            element: <ParkingaboRegistrationRoute />,
            children: [
                {
                    path: 'email-sent',
                    element: <RegistrationConfirmationRoute />,
                },
                {
                    path: 'link-expired/:token',
                    element: <RegistrationLinkExpiredRoute />,
                },
            ],
        });
    }
    return createBrowserRouter(
        [
            {
                path: '/login',
                element: <LoginRoute login={login} />,
            },
            ...registrationRoutes,
            {
                path: '/request-password-reset/*',
                element: <RequestPasswordResetRoute />,
                children: [
                    {
                        path: 'success',
                        element: <PasswordRequestSentSuccessRoute />,
                    },
                ],
            },
            {
                path: '/reset-password/:token/*',
                element: <ResetPasswordRoute />,
                children: [
                    {
                        path: 'error',
                        element: <ResetPasswordTokenErrorRoute />,
                    },
                    {
                        path: 'success',
                        element: <ResetPasswordSuccessRoute />,
                    },
                ],
            },
            {
                path: '/verify/:token',
                element: <EmailVerificationRoute />,
            },
            { path: '/*', element: <NoMatch loggedIn={login.loggedIn} /> },
        ],
        { future: { v7_normalizeFormMethod: true } },
    );
}

export function AuthedRoutes({
    login,
    logout,
}: {
    login: ParkingaboLogin;
    logout: () => void;
}) {
    const [userState, refetchUser] = useParkingaboServerFetch<
        ParkingaboUser,
        object,
        null
    >(
        () => ({
            url: `/ui-api/parkingabo/user/self`,
        }),
        {},
    );
    const [productState, refetchProducts] = useParkingaboServerFetch<
        ParkingaboProductListItem[],
        object,
        null
    >(
        () => ({
            url: `/ui-api/parkingabo/user/self/product`,
        }),
        {},
    );
    const [vehiclesState, refetchVehicles] = useParkingaboServerFetch<
        ParkingaboVehicleWithLicensePlate[],
        object,
        null
    >(
        () => ({
            url: `/ui-api/parkingabo/user/self/vehicles`,
        }),
        {},
    );

    return (
        <ParkingaboAsyncLoadedSection
            state={userState}
            render={user => (
                <ParkingaboAsyncLoadedSection
                    state={productState}
                    render={products => (
                        <ParkingaboAsyncLoadedSection
                            state={vehiclesState}
                            render={vehiclesData => {
                                const vehiclesStatus = vehiclesState.status;
                                const vehicles = {
                                    data: vehiclesData,
                                    status: vehiclesStatus,
                                    refetch: refetchVehicles,
                                };
                                const baseProps = {
                                    logout,
                                    user,
                                    refetchUser,
                                    products,
                                    refetchProducts,
                                    vehicles,
                                };

                                return (
                                    <OnboardingProvider
                                        user={user}
                                        vehicles={vehiclesData}
                                    >
                                        <RouterProvider
                                            router={createBrowserRouter([
                                                {
                                                    path: '',
                                                    element: (
                                                        <TenantSelectionRoute
                                                            {...baseProps}
                                                        />
                                                    ),
                                                },
                                                {
                                                    element: (
                                                        <TenantChecker
                                                            {...baseProps}
                                                        />
                                                    ),
                                                    children: [
                                                        {
                                                            element: (
                                                                <RedirectRequiredChecker
                                                                    {...baseProps}
                                                                />
                                                            ),
                                                            children: [
                                                                {
                                                                    path:
                                                                        ':tenantId/',
                                                                    element: (
                                                                        <HomeRoute
                                                                            {...baseProps}
                                                                        />
                                                                    ),
                                                                    children: [
                                                                        {
                                                                            path:
                                                                                'onboarding/success',
                                                                            element: (
                                                                                <OnboardingSuccessRoute
                                                                                    {...baseProps}
                                                                                />
                                                                            ),
                                                                        },
                                                                    ],
                                                                },
                                                            ],
                                                        },
                                                        {
                                                            path: ':tenantId/*',
                                                            children: [
                                                                {
                                                                    path:
                                                                        'onboarding/*',
                                                                    children: [
                                                                        {
                                                                            path:
                                                                                'vehicle',
                                                                            element: (
                                                                                <OnboardingVehicleRoute
                                                                                    {...baseProps}
                                                                                />
                                                                            ),
                                                                        },
                                                                        {
                                                                            path:
                                                                                'user-data',
                                                                            element: (
                                                                                <OnboardingUserDataRoute
                                                                                    {...baseProps}
                                                                                />
                                                                            ),
                                                                        },
                                                                        {
                                                                            element: (
                                                                                <PaymentChecker
                                                                                    paymentMethodType={
                                                                                        PaymentMethodType.ONBOARDING
                                                                                    }
                                                                                    {...baseProps}
                                                                                />
                                                                            ),
                                                                            children: [
                                                                                {
                                                                                    path:
                                                                                        'payment/*',
                                                                                    element: (
                                                                                        <OnboardingPaymentMethodRoute
                                                                                            {...baseProps}
                                                                                        />
                                                                                    ),
                                                                                    children: [
                                                                                        {
                                                                                            path:
                                                                                                'aborted',
                                                                                            element: (
                                                                                                <PaymentAbortedRoute />
                                                                                            ),
                                                                                        },
                                                                                    ],
                                                                                },
                                                                            ],
                                                                        },
                                                                    ],
                                                                },
                                                                {
                                                                    path:
                                                                        'update-payments/payment/:transactionId',
                                                                    element: (
                                                                        <PaymentMethodCheckRoute
                                                                            paymentMethodType={
                                                                                PaymentMethodType.UPDATE
                                                                            }
                                                                            {...baseProps}
                                                                        />
                                                                    ),
                                                                },
                                                                {
                                                                    path:
                                                                        'register-payments/payment/:transactionId',
                                                                    element: (
                                                                        <PaymentMethodCheckRoute
                                                                            paymentMethodType={
                                                                                PaymentMethodType.REGISTER
                                                                            }
                                                                            {...baseProps}
                                                                        />
                                                                    ),
                                                                },
                                                                {
                                                                    path:
                                                                        'onboarding-payments/payment/:transactionId',
                                                                    element: (
                                                                        <PaymentMethodCheckRoute
                                                                            paymentMethodType={
                                                                                PaymentMethodType.ONBOARDING
                                                                            }
                                                                            {...baseProps}
                                                                        />
                                                                    ),
                                                                },
                                                                {
                                                                    path:
                                                                        'update-payments/pairing/:twintPairingToken',
                                                                    element: (
                                                                        <TwintPairingRoute
                                                                            paymentMethodType={
                                                                                PaymentMethodType.UPDATE
                                                                            }
                                                                            {...baseProps}
                                                                        />
                                                                    ),
                                                                },
                                                                {
                                                                    path:
                                                                        'register-payments/pairing/:twintPairingToken',
                                                                    element: (
                                                                        <TwintPairingRoute
                                                                            paymentMethodType={
                                                                                PaymentMethodType.REGISTER
                                                                            }
                                                                            {...baseProps}
                                                                        />
                                                                    ),
                                                                },
                                                                {
                                                                    path:
                                                                        'onboarding-payments/pairing/:twintPairingToken',
                                                                    element: (
                                                                        <TwintPairingRoute
                                                                            paymentMethodType={
                                                                                PaymentMethodType.ONBOARDING
                                                                            }
                                                                            {...baseProps}
                                                                        />
                                                                    ),
                                                                },
                                                                {
                                                                    path:
                                                                        'payment/*',
                                                                    children: [
                                                                        {
                                                                            element: (
                                                                                <PaymentChecker
                                                                                    paymentMethodType={
                                                                                        PaymentMethodType.REGISTER
                                                                                    }
                                                                                    {...baseProps}
                                                                                />
                                                                            ),
                                                                            children: [
                                                                                {
                                                                                    path:
                                                                                        'register/*',
                                                                                    element: (
                                                                                        <AddPaymentMethodRoute
                                                                                            paymentMethodType={
                                                                                                PaymentMethodType.REGISTER
                                                                                            }
                                                                                        />
                                                                                    ),
                                                                                    children: [
                                                                                        {
                                                                                            path:
                                                                                                'initial',
                                                                                            element: (
                                                                                                <PaymentRegisterInitialRoute />
                                                                                            ),
                                                                                        },
                                                                                        {
                                                                                            path:
                                                                                                'aborted',
                                                                                            element: (
                                                                                                <PaymentAbortedRoute />
                                                                                            ),
                                                                                        },
                                                                                    ],
                                                                                },
                                                                            ],
                                                                        },
                                                                        {
                                                                            element: (
                                                                                <PaymentChecker
                                                                                    paymentMethodType={
                                                                                        PaymentMethodType.UPDATE
                                                                                    }
                                                                                    {...baseProps}
                                                                                />
                                                                            ),
                                                                            children: [
                                                                                {
                                                                                    path:
                                                                                        'update/*',
                                                                                    element: (
                                                                                        <AddPaymentMethodRoute
                                                                                            paymentMethodType={
                                                                                                PaymentMethodType.UPDATE
                                                                                            }
                                                                                        />
                                                                                    ),
                                                                                    children: [
                                                                                        {
                                                                                            path:
                                                                                                'initial',
                                                                                            element: (
                                                                                                <PaymentUpdateInitialRoute />
                                                                                            ),
                                                                                        },
                                                                                        {
                                                                                            path:
                                                                                                'aborted',
                                                                                            element: (
                                                                                                <PaymentAbortedRoute />
                                                                                            ),
                                                                                        },
                                                                                    ],
                                                                                },
                                                                            ],
                                                                        },
                                                                    ],
                                                                },
                                                                {
                                                                    element: (
                                                                        <RedirectRequiredChecker
                                                                            {...baseProps}
                                                                        />
                                                                    ),
                                                                    children: [
                                                                        {
                                                                            path:
                                                                                'vehicles/*',
                                                                            element: (
                                                                                <VehiclesRoute
                                                                                    {...baseProps}
                                                                                />
                                                                            ),
                                                                            children: [
                                                                                {
                                                                                    path:
                                                                                        'add',
                                                                                    element: (
                                                                                        <AddVehicleRoute
                                                                                            {...baseProps}
                                                                                        />
                                                                                    ),
                                                                                },
                                                                                {
                                                                                    path:
                                                                                        ':vehicleId',
                                                                                    element: (
                                                                                        <VehiclesDetailRoute
                                                                                            {...baseProps}
                                                                                        />
                                                                                    ),
                                                                                },
                                                                            ],
                                                                        },
                                                                        {
                                                                            path:
                                                                                'products/*',
                                                                            element: (
                                                                                <ProductsRoute
                                                                                    {...baseProps}
                                                                                />
                                                                            ),
                                                                            children: [
                                                                                {
                                                                                    path:
                                                                                        ':contractId',
                                                                                    element: (
                                                                                        <ProductsDetailOutlet
                                                                                            {...baseProps}
                                                                                        />
                                                                                    ),
                                                                                },
                                                                                {
                                                                                    path:
                                                                                        'add',
                                                                                    element: (
                                                                                        <ProductsAddOutlet />
                                                                                    ),
                                                                                    children: [
                                                                                        {
                                                                                            index: true,
                                                                                            element: (
                                                                                                <ProductsSelectionOutlet />
                                                                                            ),
                                                                                        },
                                                                                        {
                                                                                            path:
                                                                                                ':contractTemplateId',
                                                                                            element: (
                                                                                                <ProductsConfigurationOutlet
                                                                                                    {...baseProps}
                                                                                                />
                                                                                            ),
                                                                                        },
                                                                                    ],
                                                                                },
                                                                            ],
                                                                        },
                                                                        {
                                                                            path:
                                                                                'account-statement',
                                                                            element: (
                                                                                <AccountStatementOutlet />
                                                                            ),
                                                                            children: [
                                                                                {
                                                                                    path:
                                                                                        ':bkBookingId',
                                                                                    element: (
                                                                                        <AccountStatementItemDetail />
                                                                                    ),
                                                                                },
                                                                            ],
                                                                        },
                                                                        {
                                                                            path:
                                                                                'settings/*',
                                                                            element: (
                                                                                <SettingsRoute
                                                                                    {...baseProps}
                                                                                />
                                                                            ),
                                                                        },
                                                                        {
                                                                            path:
                                                                                'settings/payment/*',
                                                                            element: (
                                                                                <PaymentSettingsRoute
                                                                                    {...baseProps}
                                                                                />
                                                                            ),
                                                                            children: [
                                                                                {
                                                                                    path:
                                                                                        'success',
                                                                                    element: (
                                                                                        <AddPaymentSuccessRoute />
                                                                                    ),
                                                                                },
                                                                            ],
                                                                        },
                                                                        {
                                                                            path:
                                                                                'settings/user/*',
                                                                            element: (
                                                                                <EditProfileRoute
                                                                                    {...baseProps}
                                                                                />
                                                                            ),
                                                                            children: [
                                                                                {
                                                                                    path:
                                                                                        'email-link-resent',
                                                                                    element: (
                                                                                        <EmailResentConfirmationRoute />
                                                                                    ),
                                                                                },
                                                                                {
                                                                                    path:
                                                                                        'password',
                                                                                    element: (
                                                                                        <EditPasswordRoute
                                                                                            {...baseProps}
                                                                                        />
                                                                                    ),
                                                                                },
                                                                                {
                                                                                    path:
                                                                                        'email',
                                                                                    element: (
                                                                                        <EditEmailRoute
                                                                                            {...baseProps}
                                                                                        />
                                                                                    ),
                                                                                },
                                                                                {
                                                                                    path:
                                                                                        'language',
                                                                                    element: (
                                                                                        <EditLanguageRoute
                                                                                            {...baseProps}
                                                                                        />
                                                                                    ),
                                                                                },
                                                                                {
                                                                                    path:
                                                                                        'name',
                                                                                    element: (
                                                                                        <EditNameRoute
                                                                                            {...baseProps}
                                                                                        />
                                                                                    ),
                                                                                },
                                                                            ],
                                                                        },
                                                                    ],
                                                                },
                                                            ],
                                                        },
                                                    ],
                                                },
                                                {
                                                    path: '/',
                                                    element: (
                                                        <NoMatch
                                                            loggedIn={
                                                                login.loggedIn
                                                            }
                                                        />
                                                    ),
                                                },
                                            ])}
                                        />
                                    </OnboardingProvider>
                                );
                            }}
                        />
                    )}
                />
            )}
        />
    );
}
