import * as React from 'react';
import { Localized } from '../../../../common/hooks/LanguageProvider';
import { FeedbackPopup } from '../../components/FeedbackPopup';
import { useNavigate } from 'react-router-dom';

export const ResetPasswordSuccessRoute: React.FC = () => {
    const navigate = useNavigate();
    return (
        <FeedbackPopup
            open={true}
            color="success"
            onAbort={() => {
                navigate('/login');
            }}
            abortLabel={
                <Localized de="Schliessen" fr="Fermer" it="Chiudi" en="Close" />
            }
            title={
                <Localized
                    de="Bestätigung"
                    fr="Confirmation"
                    it="Conferma"
                    en="Confirmation"
                />
            }
        >
            <Localized
                de="Ihr Passwort wurde erfolgreich geändert."
                fr="Votre mot de passe a été modifié avec succès."
                it="La sua password è stata modificata con successo."
                en="Your password has been successfully changed."
            />
        </FeedbackPopup>
    );
};
