import * as React from 'react';
import { Localized } from '../../../../../common/hooks/LanguageProvider';
import {
    PaymentMethodForm,
    PaymentMethodType,
} from '../../../components/forms/PaymentMethodForm';
import { Outlet } from 'react-router-dom';
import { ParkingaboLayoutWithHeader } from '../../../components/layout/ParkingaboLayoutWithHeader';

export function AddPaymentMethodRoute({
    paymentMethodType,
}: {
    paymentMethodType: PaymentMethodType;
}) {
    return (
        <ParkingaboLayoutWithHeader
            title={
                <Localized
                    de="Zahlungsmittel"
                    fr="Moyen de paiement"
                    it="Mezzo di pagamento"
                    en="Payment mean"
                />
            }
            noGutter
        >
            <Outlet />

            <PaymentMethodForm paymentMethodType={paymentMethodType} />
        </ParkingaboLayoutWithHeader>
    );
}
