import * as React from 'react';
import QRCode from 'qrcode.react';
import { css } from '@emotion/css';
import { PairingTokenDisplay } from './PairingPage';
import { Localized } from './Localized';

export function PairingPageDesktop(p: { token: string }) {
    const midBox = css({
        width: '300px',
        height: '300px',
        marginRight: '55px',
        marginTop: '35px',
        background: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    });
    return (
        <div>
            <div
                className={css({
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                })}
            >
                <div
                    className={css({
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        marginTop: '-35px',
                        marginRight: '-55px',
                    })}
                >
                    <div className={midBox}>
                        <QRCode value={p.token} size={200} />
                    </div>

                    <div className={midBox}>
                        <div className="credential-token text-center text-uppercase">
                            <PairingTokenDisplay token={p.token} />
                        </div>
                    </div>
                </div>
            </div>
            <div className={css({ marginTop: '40px' })}>
                <div
                    className={`description-box ${css({ marginTop: '40px' })}`}
                >
                    <div className="description-item">
                        <div className="description-image">
                            <div className="description-icon icon-app" />
                        </div>
                        <div className="description-text">
                            <Localized
                                de="Öffnen Sie die TWINT App Ihrer Bank auf Ihrem Smartphone."
                                fr="Lancez l’app TWINT de votre banque sur votre smartphone."
                                it="Apra l’App TWINT della sua banca sul suo smartphone."
                                en="Open your bank's TWINT app on your smartphone."
                            />
                        </div>
                    </div>
                    <div className="description-item">
                        <div className="description-image">
                            <div className="description-icon icon-pay" />
                        </div>
                        <div className="description-text">
                            <Localized
                                de="Tippen Sie auf das QR-Code-Symbol."
                                fr="Tapez sur le symbole du code QR."
                                it="Selezioni il simbolo del codice QR."
                                en="Tap on QR code symbol."
                            />
                        </div>
                    </div>
                    <div className="description-item">
                        <div className="description-image">
                            <div className="description-icon icon-cam" />
                        </div>
                        <div className="description-text">
                            <Localized
                                de="Richten Sie Ihre Smartphone-Kamera auf den QR-Code. Oder wählen Sie den Reiter «Code» und geben Sie den Zahlencode ein."
                                fr="Orientez l’appareil photo de votre smartphone vers le code QR. Ou bien sélectionnez l’onglet «Code» et saisissez le code numérique. "
                                en="Point your smartphone camera at the QR code. Or select the ‘Code’ tab and enter the code."
                                it="Rivolga la telecamera del suo smartphone sul codice QR. Oppure scelga la voce «Codice» e inserisca il codice numerico. "
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
