import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';
import { ParkingaboLink } from './ParkinaboLink';
import { rgba } from '../../../lib/Rgba';
import { To } from 'react-router-dom';
import { Theme } from '@mui/material/styles';

export function ParkingaboMenuListItem({
    icons,
    text,
    supText,
    onClick,
    hideChevron,
    to,
}: {
    icons?: React.ReactNode[];
    text?: JSX.Element | React.ReactNode;
    supText?: JSX.Element | string;
    onClick?: (e?: React.MouseEvent) => void;
    hideChevron?: boolean;
    to?: string;
}) {
    return (
        <ParkingaboListItem to={to} onClick={onClick}>
            <Box>
                {supText && (
                    <Typography variant="overline">{supText}</Typography>
                )}
                {text && (
                    <Typography
                        fontWeight="bold"
                        sx={{
                            textTransform: supText ? undefined : 'uppercase',
                        }}
                    >
                        {text}
                    </Typography>
                )}
            </Box>
            <Box
                style={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                {icons}
                {!hideChevron && (to || onClick) && <ParkingaboListChevron />}
            </Box>
        </ParkingaboListItem>
    );
}

export function ParkingaboListChevron({ disabled }: { disabled?: boolean }) {
    return (
        <ChevronRight
            style={{ fontSize: 36 }}
            color={disabled ? 'disabled' : 'primary'}
        />
    );
}

export function ParkingaboListItem({
    to,
    onClick,
    children,
    variableHeight,
    colorVariant = 'white',
}: {
    to?: To;
    onClick?: () => void;
    children: React.ReactNode;
    variableHeight?: boolean;
    colorVariant?: 'white' | 'primary';
}) {
    const clickable = to || onClick;
    const borderStyle = (theme: Theme) =>
        `solid 1px ${theme.palette.grey[300]}`;
    return (
        <ParkingaboLink
            to={to ?? ''}
            sx={theme => ({
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: theme.spacing(3),
                height: variableHeight ? undefined : 80,
                minHeight: 80,
                cursor: clickable ? 'pointer' : 'default',
                backgroundColor:
                    colorVariant === 'primary'
                        ? theme.palette.primary.light
                        : undefined,
                '&:hover': clickable
                    ? {
                          backgroundColor: rgba(
                              theme.palette.primary.main,
                              colorVariant === 'primary' ? 0.25 : 0.05,
                          ),
                      }
                    : undefined,
                '& + a': {
                    borderTop: borderStyle,
                },
                '&:last-child': {
                    borderBottom: borderStyle,
                },
            })}
            underline="none"
            onClick={onClick}
        >
            {children}
        </ParkingaboLink>
    );
}
