export const parkingpayBlue = '#0066a6';
export const parkingpayBlueDark = '#132f69';
export const grey = 'rgba(38, 38, 38, 0.42)';
export const transparentGrey = 'rgba(38, 38, 38, 0.1)';

export const lineGrey = (pp: boolean) =>
    pp ? parkingpayBlue : 'rgb(204, 204, 204)';

export const black = (pp: boolean) =>
    pp ? parkingpayBlueDark : 'rgba(38, 38, 38, 1)';

export const white = 'rgba(255, 255, 255, 1)';
export const blue = 'rgba(0, 122, 255, 1)';
export const transparentBlue = 'rgba(0, 122, 255, 0.1)';
export const green = 'rgb(76, 175, 80)';
export const transparentGreen = 'rgba(76, 175, 80, 0.1)';
export const red = 'rgb(244,67,54)';
export const transparentRed = 'rgba(244,67,54, 0.1)';
export const lightBlue = 'rgb(0,122,255)';
export const highlightBg = 'rgba(5, 70, 150, 0.2)';
export const dynamicContentFg = 'rgba(5, 70, 150, 1)';
export const dynamicContentBg = 'rgba(5, 70, 150, 0.1)';
export const lightGreyBackground = '#F5F5F5';
export const slideUpHeaderGrey = '#D8D8D8';
