import * as React from 'react';
import { Localized } from '../../../../../common/hooks/LanguageProvider';
import { FeedbackPopup } from '../../../components/FeedbackPopup';
import { useNavigate } from 'react-router-dom';

export function PaymentRegisterInitialRoute() {
    const navigate = useNavigate();

    return (
        <FeedbackPopup
            open={true}
            color="warning"
            onAbort={() => {
                navigate('..');
            }}
            abortLabel={
                <Localized
                    de="Weiter"
                    fr="Continuer"
                    it="Avanti"
                    en="Proceed"
                />
            }
            title={
                <Localized
                    de="Zahlungsmittel fehlt"
                    fr="Moyen de paiement manquant"
                    it="Mezzo di pagamento mancante"
                    en="Missing payment mean"
                />
            }
        >
            <Localized
                de="Die Referenz auf das Zahlungsmittel wurde gelöscht. Bitte registrieren Sie ein neues Zahlungsmittel."
                fr="La référence au moyen de paiement a été supprimée. Veuillez enregistrer un nouveau moyen de paiement."
                it="Il riferimento al mezzo di pagamento è stato eliminato. Voglia registrare un nuovo mezzo di pagamento."
                en="The reference to the means of payment was deleted. Please register a new means of payment."
            />
        </FeedbackPopup>
    );
}
