export enum VehicleLicensePlateType {
    CAR = 'CAR',
    MOTORCYCLE = 'MOTORCYCLE',
}

export enum VehicleBadgeType {
    PARKINGPAY_BADGE = 'ParkingpayBadge',
    PARKINGPAY_BADGE_7BYTE = 'ParkingpayBadge7Byte',
    BONCARD = 'Boncard',
    SPITAL_EMMENTAL = 'SpitalEmmental',
    SWISSPASS = 'SwissPass',
    AIRPORT_ZH_SMARTCARD = 'AirportZhSmartCard',
}

export interface ParkingaboVehicle {
    customerTenantCarId: string;
    identificationQrCodeId: string;
    description: string;
    licensePlateNr: string | null;
    type: VehicleLicensePlateType | null;
    country: string | null;
    badgeId: number | null;
    badgeLabelNr: string | null;
    badgeType: VehicleBadgeType | null;
}

export interface ParkingaboVehicleWithLicensePlate extends ParkingaboVehicle {
    licensePlateNr: string;
    type: VehicleLicensePlateType;
    country: string;
}

export function isVehicleWithLicensePlate(
    v: ParkingaboVehicle,
): v is ParkingaboVehicleWithLicensePlate {
    return v.country !== null && v.type !== null && v.licensePlateNr !== null;
}
