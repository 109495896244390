import * as React from 'react';
import { useEffect, useState } from 'react';
import { Outlet, useMatch, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import { AliasRequiredReason } from '../shared/ParkingaboModels';
import {
    OnboardingStepType,
    useOnboarding,
} from '../routes/authorized/onboarding/OnboardingConfig';
import {
    LoadingSpinnerPresets,
    PresetLoadingSpinner,
} from '../../../common/components/material-ui/PresetLoadingSpinner';
import {
    AuthedRouteCompProps,
    useParkingaboAuthedPathGeneration,
} from '../routes/RouteUtils';

export function RedirectRequiredChecker({ user }: AuthedRouteCompProps) {
    const navigate = useNavigate();
    const generateAuthedParkingaboPath = useParkingaboAuthedPathGeneration();
    const { config } = useOnboarding();
    const firstIncompleteOnboardingStep = config.steps.find(
        step => !step.completed,
    );
    const isConfigLoaded = !!config.steps.find(
        step => step.completed !== undefined,
    );
    const { tenantId } = useParams<{ tenantId?: string }>();
    const [routeChecked, setRouteChecked] = useState(false);
    const onboardingSuccess =
        useMatch(generateAuthedParkingaboPath('onboarding/success')) !== null;
    const paymentSuccess =
        useMatch(generateAuthedParkingaboPath('settings/payment/success')) !==
        null;

    useEffect(() => {
        if (tenantId && isConfigLoaded) {
            if (!paymentSuccess && !onboardingSuccess) {
                if (firstIncompleteOnboardingStep) {
                    switch (firstIncompleteOnboardingStep.type) {
                        case OnboardingStepType.USER_DATA:
                            navigate(
                                generateAuthedParkingaboPath(
                                    'onboarding/user-data',
                                ),
                            );
                            break;

                        case OnboardingStepType.VEHICLE:
                            navigate(
                                generateAuthedParkingaboPath(
                                    'onboarding/vehicle',
                                ),
                            );
                            break;

                        case OnboardingStepType.PAYMENT:
                            navigate(
                                generateAuthedParkingaboPath(
                                    'onboarding/payment',
                                ),
                            );
                            break;
                    }
                } else {
                    switch (user.aliasRequiredReason) {
                        case AliasRequiredReason.DELETED:
                            navigate(
                                generateAuthedParkingaboPath(
                                    'payment/register/initial',
                                ),
                            );
                            break;
                        case AliasRequiredReason.INVALID:
                            navigate(
                                generateAuthedParkingaboPath(
                                    'payment/update/initial',
                                ),
                            );
                            break;
                    }
                }
            }
            setRouteChecked(true);
        }
    }, [
        config,
        isConfigLoaded,
        firstIncompleteOnboardingStep,
        tenantId,
        onboardingSuccess,
        paymentSuccess,
    ]);

    if (!routeChecked) {
        return (
            <PresetLoadingSpinner
                preset={LoadingSpinnerPresets.FillAllSpaceAndCenter}
            />
        );
    } else {
        return <Outlet />;
    }
}
