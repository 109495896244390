import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import * as React from 'react';
import Alert from '@mui/material/Alert';

export function ErrorAlert({
    sx,
    children,
}: {
    sx?: SxProps<Theme>;
    children: React.ReactNode;
}) {
    return (
        <Alert variant="filled" severity="error" sx={sx}>
            {children}
        </Alert>
    );
}
