import { Box, Modal, useTheme } from '@mui/material';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { DiscardChangesDialog } from '../DiscardChangesDialog';

interface RoutedModalController {
    close: () => void;
    setShouldConfirmBeforeLeave: (shouldConfirm: boolean) => void;
    safeNavigateTo: (url: string) => void;
    navigateWithinModal: (url: string) => void;
    willConfirmBeforeLeave: boolean;
}
export interface RoutedModalProps {
    open: boolean;
    backUrl: string;
    backNavigationConfirmationMessage: string;
    render: (controller: RoutedModalController) => React.ReactNode;
    style?: React.CSSProperties;
    hideBackdrop?: boolean;
    hideShadows?: boolean;
}

export function RoutedModal({
    open,
    backUrl,
    render,
    backNavigationConfirmationMessage,
    style,
    hideBackdrop,
    hideShadows,
}: RoutedModalProps) {
    const theme = useTheme();
    const navigate = useNavigate();
    const [
        userIsTryingToLeaveModal,
        setUserIsTryingToLeaveModal,
    ] = React.useState(false);
    const [navigationTarget, setNavigationTarget] = React.useState<
        null | string
    >(null);
    const [navigateToUrl, setNavigateToUrl] = React.useState<string | null>(
        null,
    );
    const [
        shouldConfirmBeforeLeave,
        setShouldConfirmBeforeLeave,
    ] = React.useState(false);

    const navigateBack = () => {
        navigate(backUrl);
        setUserIsTryingToLeaveModal(false);
    };
    const handleModalLeave = () => {
        if (shouldConfirmBeforeLeave) {
            setUserIsTryingToLeaveModal(true);
        } else {
            navigateBack();
        }
    };
    const handleStay = () => {
        setUserIsTryingToLeaveModal(false);
    };

    React.useEffect(() => {
        window.onbeforeunload = () => {
            if (shouldConfirmBeforeLeave) {
                return 'This message is ignored by the browsers, a generic message will be shown instead';
            }
        };

        return () => {
            window.onbeforeunload = null;
        };
    });

    React.useEffect(() => {
        if (navigateToUrl) {
            navigate(navigateToUrl, { replace: true });
            setNavigateToUrl(null);
        }
    }, [navigateToUrl]);

    function navigateWithinModal(targetPath: string) {
        if (shouldConfirmBeforeLeave) {
            setNavigationTarget(targetPath);
            setUserIsTryingToLeaveModal(true);
        } else {
            navigate(targetPath);
        }
    }

    function handleLeave() {
        if (navigationTarget) {
            setShouldConfirmBeforeLeave(false);
            setUserIsTryingToLeaveModal(false);
            setNavigateToUrl(navigationTarget);
            setNavigationTarget(null);
        } else {
            navigateBack();
        }
    }

    return (
        <>
            <Modal
                onClose={handleModalLeave}
                open={open}
                hideBackdrop={hideBackdrop}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        minWidth: 320,
                        minHeight: 200,
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '80%',
                        height: '80%',
                        backgroundColor: theme.palette.common.white,
                        borderRadius: 0,
                        boxShadow: hideShadows ? undefined : theme.shadows[1],
                        ':focus-visible': {
                            outline: 'none',
                        },
                        ...style,
                    }}
                >
                    {render({
                        close: handleModalLeave,
                        willConfirmBeforeLeave: shouldConfirmBeforeLeave,
                        setShouldConfirmBeforeLeave,
                        safeNavigateTo: setNavigateToUrl,
                        navigateWithinModal,
                    })}
                    {/*TODO: Enable when react-router ships it*/}
                    {/*<Prompt*/}
                    {/*    message={backNavigationConfirmationMessage}*/}
                    {/*    when={*/}
                    {/*        shouldConfirmBeforeLeave &&*/}
                    {/*        !userIsTryingToLeaveModal*/}
                    {/*    }*/}
                    {/*/>*/}
                </Box>
            </Modal>
            <DiscardChangesDialog
                open={userIsTryingToLeaveModal}
                onLeave={handleLeave}
                onStay={handleStay}
            />
        </>
    );
}
