import { Box, Card, CardActionArea, Typography, Stack } from '@mui/material';
import { ChevronRight, Add } from '@mui/icons-material';
import * as React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { VehicleTypeIcon } from '../../../../../common/components/material-ui/VehicleTypeIcon';
import { Localized } from '../../../../../common/hooks/LanguageProvider';
import { ParkingaboLayoutWithHeader } from '../../../components/layout/ParkingaboLayoutWithHeader';
import { AuthedRouteCompProps } from '../../RouteUtils';
import { VehicleQrCodeDrawer } from '../../../components/VehicleQrCodeDrawer';
import { ParkingaboVehicleWithLicensePlate } from '../../../../../common/models/Vehicle';

export function VehiclesRoute({ vehicles }: AuthedRouteCompProps) {
    return (
        <ParkingaboLayoutWithHeader
            title={
                <Localized
                    de="Fahrzeuge"
                    fr="Véhicules"
                    it="Veicoli"
                    en="Vehicles"
                />
            }
            backTo={'..'}
        >
            <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="stretch"
                spacing={2}
            >
                {vehicles.data.map(vehicle => (
                    <VehicleCard
                        key={vehicle.licensePlateNr}
                        vehicle={vehicle}
                    />
                ))}
                <AddVehicleCard />
            </Stack>

            <Outlet />

            <VehicleQrCodeDrawer vehicles={vehicles.data} />
        </ParkingaboLayoutWithHeader>
    );
}

function VehicleCard({
    vehicle,
}: {
    vehicle: ParkingaboVehicleWithLicensePlate;
}) {
    const navigate = useNavigate();
    return (
        <BaseCard
            onClick={() => {
                navigate(vehicle.customerTenantCarId);
            }}
        >
            <VehicleTypeIcon
                type={vehicle.type}
                sx={theme => ({
                    fontSize: (theme.typography.h3.fontSize as any) * 2,
                })}
            />
            <Box sx={{ flexGrow: 1 }}>
                <Typography fontWeight="bold">{vehicle.description}</Typography>
                <Typography>
                    {vehicle.licensePlateNr || '-'}
                    {vehicle.country && ` (${vehicle.country})`}
                </Typography>
            </Box>
            <ChevronRight />
        </BaseCard>
    );
}

function AddVehicleCard() {
    const navigate = useNavigate();
    return (
        <BaseCard onClick={() => navigate('add')}>
            <Typography
                component="div"
                flexGrow={1}
                textTransform="uppercase"
                fontWeight="bold"
            >
                <Localized
                    de="Fahrzeug hinzufügen"
                    fr="Ajouter un véhicule"
                    it="Aggiungi veicolo"
                    en="Add vehicle"
                />
            </Typography>
            <Add />
        </BaseCard>
    );
}

function BaseCard({
    onClick,
    children,
}: {
    onClick: () => void;
    children: React.ReactNode;
}) {
    return (
        <Card>
            <CardActionArea
                onClick={onClick}
                sx={theme => ({
                    padding: theme.spacing(2),
                    paddingRight: theme.spacing(3),
                    minHeight: theme.spacing(10),
                })}
            >
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                >
                    {children}
                </Stack>
            </CardActionArea>
        </Card>
    );
}
