import * as React from 'react';
import { useEffect, useState } from 'react';
import {
    Box,
    Grid,
    Link,
    Paper,
    Slide,
    Stack,
    SwipeableDrawer,
    Typography,
    useTheme,
} from '@mui/material';
import { ArrowBackIos, Close, QrCode } from '@mui/icons-material';
import { Global } from '@emotion/react';
import QRCodeReact from 'qrcode.react';
import { Localized } from '../../../common/hooks/LanguageProvider';
import { MAX_CONTAINER_WIDTH } from '../shared/ParkingaboModels';
import {
    isVehicleWithLicensePlate,
    ParkingaboVehicle,
    ParkingaboVehicleWithLicensePlate,
} from '../../../common/models/Vehicle';
import { VehicleTypeIcon } from '../../../common/components/material-ui/VehicleTypeIcon';
import { useParkingaboServerFetch } from '../api/ParkingaboApi';
import {
    RequestStatus,
    ServerRequestState,
} from '../../../lib/hooks/ServerStateHooks';
import { DateTime, Duration } from 'luxon';
import {
    LoadingSpinnerPresets,
    PresetLoadingSpinner,
} from '../../../common/components/material-ui/PresetLoadingSpinner';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import { ParkingaboButton } from './layout/ParkingaboButton';
import ErrorIcon from '@mui/icons-material/Error';

const ESTIMATED_FIXED_ELEMENT_HEIGHT = 150;

export function VehicleQrCodeDrawer({
    vehicles,
}: {
    vehicles: ParkingaboVehicle[];
}) {
    const [showQrInformation, setShowQrInformation] = React.useState(false);
    const vehiclesWithLp = React.useMemo(
        () => vehicles.filter(isVehicleWithLicensePlate),
        [vehicles],
    );
    const hasSingleVehicle = vehiclesWithLp.length === 1;
    const [
        selectedVehicle,
        setSelectedVehicle,
    ] = useState<ParkingaboVehicleWithLicensePlate | null>(
        hasSingleVehicle ? vehiclesWithLp[0] : null,
    );

    useEffect(() => {
        if (hasSingleVehicle) {
            setSelectedVehicle(vehiclesWithLp[0]);
        }
    }, [hasSingleVehicle]);

    useEffect(() => {
        if (
            selectedVehicle &&
            !vehiclesWithLp
                .map(vehicle => vehicle.customerTenantCarId)
                .includes(selectedVehicle.customerTenantCarId)
        ) {
            setSelectedVehicle(null);
        }
    }, [vehiclesWithLp, selectedVehicle]);

    if (vehiclesWithLp.length < 1) {
        return null;
    }

    return (
        <CustomizedDrawer
            onClose={() => {
                !hasSingleVehicle && setSelectedVehicle(null);
                setShowQrInformation(false);
            }}
            offsetHeight={ESTIMATED_FIXED_ELEMENT_HEIGHT}
        >
            <BoxSlider
                activeBox={
                    selectedVehicle ? BoxDisplay.SECOND : BoxDisplay.FIRST
                }
                renderBox1={
                    <VehicleSelection
                        vehicles={vehiclesWithLp}
                        onVehicleSelect={vehicle => setSelectedVehicle(vehicle)}
                    />
                }
                renderBox2={
                    <BoxSlider
                        activeBox={
                            showQrInformation
                                ? BoxDisplay.SECOND
                                : BoxDisplay.FIRST
                        }
                        renderBox1={
                            <VehicleQrCode
                                onBack={
                                    hasSingleVehicle
                                        ? undefined
                                        : () => setSelectedVehicle(null)
                                }
                                onInfoClick={() => setShowQrInformation(true)}
                                vehicle={selectedVehicle}
                            />
                        }
                        renderBox2={
                            <QrCodeInformation
                                onBack={() => setShowQrInformation(false)}
                            />
                        }
                    />
                }
            />
        </CustomizedDrawer>
    );
}

function VehicleSelection({
    vehicles,
    onVehicleSelect,
}: {
    vehicles: ParkingaboVehicleWithLicensePlate[];
    onVehicleSelect: (vehicle: ParkingaboVehicleWithLicensePlate) => void;
}) {
    return (
        <Box sx={{ height: '100%', overflow: 'hidden', paddingTop: 2 }}>
            <Stack
                direction="column"
                justifyContent="start"
                alignItems="center"
                spacing={4}
                sx={{
                    paddingTop: 2,
                    paddintBottom: 1,
                    height: '100%',
                    overflowY: 'scroll',
                    scrollbarWidth: 'none' /* Firefox */,
                    msOverflowStyle: 'none' /* Internet Explorer, Edge */,
                    '&::-webkit-scrollbar': {
                        display: 'none',
                    } /* Chrome */,
                }}
            >
                <Typography variant="h3">
                    <Localized
                        de="QR-Code von welchem Fahrzeug?"
                        fr="QR-Code von welchem Fahrzeug?"
                        it="QR-Code von welchem Fahrzeug?"
                        en="QR-Code von welchem Fahrzeug?"
                    />
                </Typography>
                <Box
                    sx={{
                        paddingX: 4,
                        width: '100%',
                    }}
                >
                    <Grid container spacing={2}>
                        {vehicles.map(vehicle => (
                            <Grid item xs={6} key={vehicle.customerTenantCarId}>
                                <Stack
                                    direction="column"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    spacing={0.5}
                                >
                                    <Box sx={{ width: '100%' }}>
                                        <AspectRatioBox ratio={1}>
                                            <Paper
                                                elevation={0}
                                                sx={{
                                                    flexGrow: 1,
                                                    backgroundColor: theme =>
                                                        theme.palette.background
                                                            .default,
                                                    padding: 2,
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    justifyContent:
                                                        'space-between',
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() =>
                                                    onVehicleSelect(vehicle)
                                                }
                                            >
                                                <Typography
                                                    fontWeight="bold"
                                                    component="h4"
                                                >
                                                    {vehicle.description}
                                                </Typography>
                                                <Typography>
                                                    {vehicle.licensePlateNr}
                                                </Typography>
                                                <VehicleTypeIcon
                                                    sx={{
                                                        flexGrow: 1,
                                                        flexShrink: 1,
                                                        fontSize: 86,
                                                    }}
                                                    type={vehicle.type}
                                                />
                                            </Paper>
                                        </AspectRatioBox>
                                    </Box>
                                    <DrawerTypoSmall color="white">
                                        ID: {vehicle.identificationQrCodeId}
                                    </DrawerTypoSmall>
                                </Stack>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Stack>
        </Box>
    );
}

function VehicleQrCode({
    vehicle,
    onBack,
    onInfoClick,
}: {
    vehicle: ParkingaboVehicleWithLicensePlate | null;
    onBack?: () => void;
    onInfoClick: () => void;
}) {
    const paddingX = 4;

    const [qrCodeRequestState, refetchQrCode] = useQrCodeFetch(
        vehicle?.customerTenantCarId ?? null,
    );
    const [renderTrigger, setRenderTrigger] = useState(0);
    const [isOfflineError, setIsOfflineError] = useState(false);

    useEffect(() => {
        if (qrCodeRequestState.status === RequestStatus.SUCCESS) {
            const remainingTimeInMillis = getRemainingTimeInMillis(
                qrCodeRequestState.data.expiresAt,
            );
            // this ensures that the remainingTimeString is updated at least every second
            const timeout = setTimeout(
                () => setRenderTrigger(renderTrigger + 1),
                (remainingTimeInMillis % 1000) + 20,
            );
            return () => clearTimeout(timeout);
        }
    }, [qrCodeRequestState.status, renderTrigger]);

    useEffect(() => {
        setIsOfflineError(
            qrCodeRequestState.status === RequestStatus.ERROR &&
                !window.navigator.onLine,
        );
    }, [qrCodeRequestState.status]);

    const remainingTimeString =
        qrCodeRequestState.status === RequestStatus.SUCCESS &&
        getRemainingTimeString(qrCodeRequestState.data?.expiresAt);

    return (
        <Stack
            direction="column"
            justifyContent="start"
            alignItems="center"
            spacing={2}
            sx={{
                paddingTop: 3,
                height: '100%',
            }}
        >
            <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="center"
                spacing={0}
                sx={{ position: 'relative', width: '100%' }}
            >
                {onBack && (
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            bottom: 0,
                            left: 0,
                            marginLeft: paddingX,
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <LargeBackIcon onClick={onBack} />
                    </Box>
                )}
                <Typography variant="h3">
                    {vehicle?.description ?? '-'}
                </Typography>
                <Typography variant="h3" fontWeight="regular">
                    {vehicle?.licensePlateNr ?? '-'}
                </Typography>
            </Stack>
            <Box
                sx={{
                    paddingX: paddingX,
                    color: 'black',
                    width: '100%',
                }}
            >
                {(qrCodeRequestState.status === RequestStatus.NEVER_EXECUTED ||
                    qrCodeRequestState.status === RequestStatus.PENDING) && (
                    <QrCodeBox>
                        <PresetLoadingSpinner
                            preset={LoadingSpinnerPresets.FillAllSpaceAndCenter}
                        />
                    </QrCodeBox>
                )}
                {qrCodeRequestState.status === RequestStatus.SUCCESS && (
                    <QrCodeBox
                        footer={
                            <>
                                <DrawerTypoSmall color={'black'}>
                                    <Localized
                                        de={`Noch ${remainingTimeString} gültig`}
                                        fr={`Encore ${remainingTimeString} valable`}
                                        it={`Ancora valido ${remainingTimeString}`}
                                        en={`Still ${remainingTimeString} valid`}
                                    />
                                </DrawerTypoSmall>
                                <DrawerTypoSmall color="black">
                                    ID:{' '}
                                    {
                                        qrCodeRequestState.data
                                            .identificationQrCodeId
                                    }
                                </DrawerTypoSmall>
                            </>
                        }
                    >
                        <QRCodeReact
                            value={qrCodeRequestState.data.qrCodeString}
                            level={'M'}
                            renderAs={'svg'}
                            style={{ width: '100%', height: '100%' }}
                        />
                    </QrCodeBox>
                )}
                {qrCodeRequestState.status === RequestStatus.ERROR && (
                    <QrCodeBox>
                        <Stack
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            spacing={0}
                        >
                            <ErrorIcon
                                color="error"
                                fontSize="large"
                                sx={{ marginTop: 2 }}
                            />
                            <Typography color="error" sx={{ marginBottom: 2 }}>
                                {isOfflineError ? (
                                    <Localized
                                        de="Keine Internetverbindung"
                                        fr="Keine Internetverbindung"
                                        it="Keine Internetverbindung"
                                        en="No internet connection"
                                    />
                                ) : (
                                    <Localized
                                        de="Ein Fehler ist passiert"
                                        fr="Ein Fehler ist passiert"
                                        it="Ein Fehler ist passiert"
                                        en="Ein Fehler ist passiert"
                                    />
                                )}
                            </Typography>
                            <ParkingaboButton
                                color="error"
                                variant="contained"
                                onClick={refetchQrCode}
                            >
                                <Localized
                                    de="Nochmals veruschen"
                                    fr="Nochmals veruschen"
                                    it="Nochmals veruschen"
                                    en="Try again"
                                />
                            </ParkingaboButton>
                        </Stack>
                    </QrCodeBox>
                )}
            </Box>
            <Link
                onClick={onInfoClick}
                component={Typography}
                underline={'always'}
                color={'inherit'}
                sx={{ paddingTop: 1, cursor: 'pointer' }}
            >
                <Localized
                    de="Wozu brauche ich einen QR-Code?"
                    fr="Pourquoi ai-je besoin d'un code QR?"
                    it="Perché ho bisogno di un codice QR?"
                    en="Why do I need a QR code?"
                />
            </Link>
        </Stack>
    );
}

function QrCodeBox({
    footer,
    children,
}: {
    footer?: React.ReactNode;
    children: React.ReactNode;
}) {
    return (
        <Paper sx={{ padding: 3, paddingBottom: 0 }} elevation={0}>
            <AspectRatioBox ratio={1}>{children}</AspectRatioBox>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={0}
                sx={{ minHeight: theme => theme.spacing(4.5) }}
            >
                {footer}
            </Stack>
        </Paper>
    );
}

function QrCodeInformation({ onBack }: { onBack: () => void }) {
    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                padding: 5,
            }}
        >
            <Stack
                direction="row"
                justifyContent="start"
                alignItems="center"
                spacing={0}
                sx={{ width: '100%' }}
            >
                <LargeBackIcon onClick={onBack} />
                <Box sx={{ flexGrow: 1 }}>
                    <Typography variant={'h3'}>
                        <Localized
                            de="QR-Code Information"
                            fr="Informations sur le code QR"
                            it="Informazioni sul codice QR"
                            en="QR code information"
                        />
                    </Typography>
                </Box>
                <Box sx={{ flex: 1, justifyItems: 'end' }} />
            </Stack>
            <Typography sx={{ paddingTop: 4 }}>
                <Localized
                    de="Bei entsprechend ausgestatteten Schrankenanlagen ist es möglich, den QR-Code mit dem QR-Code-Leser vorzulegen, um mit einem Parkingabo-Produkt ein- und auszufahren."
                    fr="Dans les systèmes de barrières correctement équipés, le code QR peut être présenté au lecteur approprié pour entrer et sortir avec un produit Parkingabo."
                    it="Nei sistemi a barriera opportunamente equipaggiati, è possibile presentare il codice QR al relativo lettore per entrare e uscire con un prodotto Parkingabo."
                    en="With appropriately equipped barrier systems, it is possible to present the QR code to the QR code reader in order to enter and exit with a Parkingabo product."
                />
            </Typography>
            <Typography sx={{ paddingTop: 1 }}>
                <Localized
                    de="Es ist auch möglich den QR-Code an der Ausfahrt zu benutzen, falls die Nummernschilderkennung nicht richtig funktioniert."
                    fr="Il est également possible d'utiliser le code QR à la sortie au cas où la reconnaissance de la plaque d'immatriculation ne fonctionnerait pas correctement."
                    it="È anche possibile utilizzare il codice QR all'uscita nel caso in cui il riconoscimento della targa non dovesse funzionare correttamente."
                    en="It is also possible to use the QR code at the exit if the licence plate recognition does not work properly."
                />
            </Typography>
        </Box>
    );
}

const enum BoxDisplay {
    FIRST,
    SECOND,
}

function BoxSlider({
    activeBox,
    renderBox1,
    renderBox2,
}: {
    activeBox: BoxDisplay;
    renderBox1: React.ReactNode;
    renderBox2: React.ReactNode;
}): JSX.Element {
    return (
        <Box
            sx={{
                position: 'relative',
                width: '100%',
                height: '100%',
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    bottom: activeBox === BoxDisplay.FIRST ? 0 : undefined,
                    left: 0,
                    right: 0,
                    visibility:
                        activeBox === BoxDisplay.FIRST ? 'visible' : 'hidden',
                }}
            >
                <Slide
                    style={{ height: '100%' }}
                    direction="right"
                    in={activeBox === BoxDisplay.FIRST}
                >
                    <div>{renderBox1}</div>
                </Slide>
            </Box>
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    bottom: activeBox === BoxDisplay.SECOND ? 0 : undefined,
                    left: 0,
                    right: 0,
                    visibility:
                        activeBox === BoxDisplay.SECOND ? 'visible' : 'hidden',
                }}
            >
                <Slide
                    style={{ height: '100%' }}
                    direction="left"
                    in={activeBox === BoxDisplay.SECOND}
                >
                    <div>{renderBox2}</div>
                </Slide>
            </Box>
        </Box>
    );
}

function AspectRatioBox({
    children,
    ratio,
}: {
    children: React.ReactNode;
    ratio: number;
}): JSX.Element {
    return (
        <Box sx={{ position: 'relative' }}>
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    '& > *': { height: '100%', width: '100%' },
                }}
            >
                {children}
            </Box>
            <Box sx={{ paddingBottom: (1 / ratio) * 100 + '%' }} />
        </Box>
    );
}

function calculateDrawerHeight(offsetHeight: number, viewPortWidth: number) {
    const qrHeight = Math.min(MAX_CONTAINER_WIDTH, viewPortWidth);
    return qrHeight + offsetHeight;
}

function useDrawerHeight(offsetHeight: number) {
    const viewPortWidth = window?.visualViewport?.width ?? 0;
    const [drawerHeight, setDrawerHeight] = React.useState(
        calculateDrawerHeight(offsetHeight, viewPortWidth),
    );
    React.useLayoutEffect(() => {
        function updateSize() {
            setDrawerHeight(calculateDrawerHeight(offsetHeight, viewPortWidth));
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return drawerHeight;
}

/**
 * The height of this drawer is fixed on the content width + a fixed offset which can be applied via offsetHeight.
 */
function CustomizedDrawer({
    onClose,
    offsetHeight,
    children,
}: {
    onClose: () => void;
    offsetHeight: number;
    children: React.ReactNode;
}): JSX.Element {
    const theme = useTheme();
    const drawerBackgroundColor = theme.palette.primary.main;
    const drawerDefaultTextColor = theme.palette.background.default;

    const drawerBleeding = 45;
    const iconOffset = 4;
    const marginOffset = 3;

    const drawerHeight = useDrawerHeight(offsetHeight);

    const [open, setOpen] = React.useState(false);
    const Icon = open ? Close : QrCode;
    function toggleDrawer() {
        setOpen(!open);
        if (open) {
            onClose();
        }
    }

    return (
        <>
            <Global
                styles={{
                    '.MuiDrawer-root > .MuiPaper-root': {
                        height: drawerHeight - drawerBleeding,
                        overflow: 'visible',
                    },
                }}
            />
            <SwipeableDrawer
                anchor="bottom"
                open={open}
                onClose={() => {
                    onClose();
                    setOpen(false);
                }}
                onOpen={() => setOpen(true)}
                disableSwipeToOpen={true}
                ModalProps={{
                    keepMounted: true,
                }}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: -drawerBleeding,
                        visibility: 'visible',
                        right: 0,
                        left: 0,
                        bottom: drawerBleeding - iconOffset - marginOffset,
                    }}
                >
                    <Box
                        onClick={toggleDrawer}
                        style={{
                            width: drawerBleeding,
                            height: drawerBleeding - iconOffset,
                            margin: 'auto',
                            marginBottom: -marginOffset,
                            visibility: 'visible',
                            backgroundColor: drawerBackgroundColor,
                            borderTopLeftRadius: theme.shape.borderRadius,
                            borderTopRightRadius: theme.shape.borderRadius,
                            cursor: 'pointer',
                            pointerEvents: 'auto',
                        }}
                    >
                        <Icon
                            style={{
                                objectFit: 'cover',
                                color: drawerDefaultTextColor,
                                width: '100%',
                                height: '100%',
                                padding: '4px 4px 0',
                            }}
                        />
                    </Box>
                    <Box
                        sx={{
                            backgroundColor: drawerBackgroundColor,
                            color: drawerDefaultTextColor,
                            height: '100%',
                        }}
                    >
                        <Box
                            sx={{
                                maxWidth: MAX_CONTAINER_WIDTH,
                                marginX: 'auto',
                                height: '100%',
                            }}
                        >
                            {children}
                        </Box>
                    </Box>
                </Box>
            </SwipeableDrawer>
        </>
    );
}

function DrawerTypoSmall({
    children,
    color,
    sx,
}: {
    children: React.ReactNode;
    color: 'black' | 'white';
    sx?: SxProps<Theme>;
}) {
    return (
        <Typography
            fontSize={12}
            fontWeight="semi-bold"
            color={theme =>
                color === 'black'
                    ? theme.palette.common.black
                    : theme.palette.common.white
            }
            sx={sx}
        >
            {children}
        </Typography>
    );
}

function LargeBackIcon({ onClick }: { onClick: () => void }) {
    return (
        <ArrowBackIos
            onClick={onClick}
            style={{
                objectFit: 'cover',
                height: 36,
                width: 36,
                cursor: 'pointer',
            }}
        />
    );
}

interface QrCodePayload {
    identificationQrCodeId: string;
    expiresAt: DateTime;
    qrCodeString: string;
}

function useQrCodeFetch(
    customerTenantCarId: string | null,
): [ServerRequestState<QrCodePayload>, () => void] {
    const [qrCodeRequestState, refetchQrCode] = useParkingaboServerFetch<
        {
            identificationQrCodeId: string;
            expiresAt: string;
            qrCodeString: string;
        },
        { customerTenantCarId: string },
        null
    >(
        context => ({
            url: `/ui-api/parkingabo/user/self/vehicles/${context.customerTenantCarId}/qr-code`,
        }),
        customerTenantCarId ? { customerTenantCarId } : null,
    );

    /*
     * We deduct 60 seconds from the expiresAt time to ensure that we refresh a bit before the qr code actually expires.
     */
    const parsedData = qrCodeRequestState.data
        ? {
              ...qrCodeRequestState.data,
              expiresAt: DateTime.fromISO(
                  qrCodeRequestState.data.expiresAt,
              ).minus({ second: 60 }),
          }
        : null;

    useEffect(() => {
        if (qrCodeRequestState.status === RequestStatus.SUCCESS && parsedData) {
            const timeoutDuration = getRemainingTimeInMillis(
                parsedData.expiresAt,
            );
            const timeout = setTimeout(refetchQrCode, timeoutDuration);
            return () => clearTimeout(timeout);
        }
    }, [qrCodeRequestState]);

    return [
        {
            ...qrCodeRequestState,
            data: parsedData,
        } as ServerRequestState<QrCodePayload>,
        refetchQrCode,
    ];
}

function getRemainingTimeInMillis(expiresAt: DateTime) {
    return Math.max(0, expiresAt.toMillis() - DateTime.now().toMillis());
}

function getRemainingTimeString(expiresAt?: DateTime): string {
    if (!expiresAt) {
        return '0s';
    }

    const remainingTimeInMillis = getRemainingTimeInMillis(expiresAt);
    const remainingTime = Duration.fromMillis(remainingTimeInMillis).shiftTo(
        'minutes',
        'seconds',
        'milliseconds',
    );

    if (remainingTime.minutes > 0) {
        return remainingTime.minutes + 'm ' + remainingTime.seconds + 's';
    }
    return remainingTime.seconds + 's';
}
