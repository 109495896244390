import * as React from 'react';
import { css } from '@emotion/css';
import { Localized } from './Localized';
import { PairingTokenDisplay } from './PairingPage';

export function PairingPageMobile(p: { token: string }) {
    return (
        <React.Fragment>
            <div className="row text-center">
                <div className="wrap or-wrapper">
                    <h2>
                        <span className="or">
                            <Localized de="ODER" fr="OU" it="OPPURE" en="OR" />
                        </span>
                    </h2>
                </div>
                <div className="alternative-text copy-cache">
                    <Localized
                        de="Geben Sie diesen Code"
                        fr="Entrez ce code"
                        it="Inserire questo codice"
                        en="Enter this code"
                    />
                    <br />
                    <Localized
                        de="in Ihrer TWINT App ein:"
                        fr="dans votre app TWINT:"
                        it="nella vostra app TWINT:"
                        en="in your TWINT app:"
                    />
                </div>
            </div>
            <div className="row white-bg text-center">
                <div className="wrap">
                    <div className="credentials">
                        <div className="credentials-box credential-token text-center text-uppercase float-rt">
                            <PairingTokenDisplay token={p.token} />
                        </div>
                    </div>
                </div>
                <div className="wrap">
                    <div className="copy-cache">
                        <button
                            type="button"
                            id="copy-token"
                            className={
                                'secondary btn ' + css({ marginBottom: '22px' })
                            }
                            onClick={() => copyTokenToClipboard(p.token)}
                        >
                            <Localized
                                de="Code kopieren"
                                fr="Copier le code"
                                it="Copiare il codice"
                                en="Copy code"
                            />
                        </button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

function copyTokenToClipboard(token: string) {
    const tmpTokenContainer = document.createElement('input');
    document.body.appendChild(tmpTokenContainer);
    tmpTokenContainer.value = token;
    tmpTokenContainer.select();
    document.execCommand('copy');
    document.body.removeChild(tmpTokenContainer);
}
