import { Delete, Error } from '@mui/icons-material';
import { Box } from '@mui/material';
import * as React from 'react';
import { Localized } from '../../../../../common/hooks/LanguageProvider';
import {
    RequestStatus,
    useServerSuccessEffect,
} from '../../../../../lib/hooks/ServerStateHooks';
import { useParkingaboServerWrite } from '../../../api/ParkingaboApi';
import { BlockingLoadingModal } from '../../../components/BlockingLoadingModal';
import {
    PaymentMethodForm,
    PaymentMethodType,
} from '../../../components/forms/PaymentMethodForm';
import { ParkingaboLayoutWithHeader } from '../../../components/layout/ParkingaboLayoutWithHeader';
import {
    AuthedRouteCompProps,
    useParkingaboAuthedPathGeneration,
} from '../../RouteUtils';
import { FeedbackPopup } from '../../../components/FeedbackPopup';
import { ParkingaboMenuListItem } from '../../../components/ParkingaboMenuListItem';
import { PaymentIcon } from '../../../components/PaymentIcon';
import { Outlet } from 'react-router-dom';
import {
    CreditCardAliasFound,
    SavedCreditCardAliasResponseTag,
    PaymentCardType,
    paymentMethodTranslations,
} from '../../../../../model/PaymentAlias';

export function PaymentSettingsRoute({
    user,
    refetchUser,
}: AuthedRouteCompProps) {
    const generateAuthedParkingaboPath = useParkingaboAuthedPathGeneration();
    const [confirmDeletionOpen, setConfirmDeletionOpen] = React.useState(false);
    const [deleteState, deletePaymentMethod] = useParkingaboServerWrite<
        never,
        never
    >(() => ({
        url: `/ui-api/parkingabo/user/self/payment/remove-alias`,
    }));

    useServerSuccessEffect(deleteState, refetchUser);

    return (
        <ParkingaboLayoutWithHeader
            noGutter
            backTo={generateAuthedParkingaboPath('settings')}
            title={
                <Localized
                    de="Zahlungsmittel"
                    fr="Moyen de paiement"
                    it="Mezzo di pagamento"
                    en="Payment mean"
                />
            }
        >
            {user.alias.tag !== SavedCreditCardAliasResponseTag.FOUND ? (
                <PaymentMethodForm
                    paymentMethodType={PaymentMethodType.REGISTER}
                />
            ) : (
                <PaymentAliasItems
                    alias={user.alias}
                    onDelete={() => setConfirmDeletionOpen(true)}
                />
            )}

            <Outlet />

            <FeedbackPopup
                open={confirmDeletionOpen}
                onConfirm={() => deletePaymentMethod()}
                onAbort={() => setConfirmDeletionOpen(false)}
                confirmLabel={
                    <Localized
                        de="Löschen"
                        fr="Supprimer"
                        it="Elimina"
                        en="Delete"
                    />
                }
                abortLabel={
                    <Localized
                        de="Zurück"
                        fr="Retourner"
                        it="Indietro"
                        en="Back"
                    />
                }
                title={
                    <Localized
                        de="Referenz löschen"
                        fr="Supprimer référence"
                        it="Elimina riferimento"
                        en="Delete reference"
                    />
                }
            >
                <p>
                    <Localized
                        de="Im Parkingabo-System wird eine sichere und anonyme Referenz auf das Zahlungsmittel gespeichert; alle Details sind nur im Bankrechenzentrum unseres Zahlungsanbieters gespeichert."
                        fr="Seule une référence sécurisée et anonyme au moyen de paiement est stockée dans le système Parkingabo; tous les détails du moyen de paiement ne sont stockées que dans le centre de traitement des données bancaires de notre prestataire de paiement."
                        it="Nel sistema Parkingabo viene memorizzato un riferimento sicuro e anonimo al mezzo di pagamento; tutti i dettagli relativi a quest'ultimo sono memorizzati solo nel centro d'elaborazione dati di tipo bancario del nostro provider di pagamenti."
                        en="Only a secure and anonymous reference to the payment means is stored in the Parkingabo system; all details are only stored in the bank datacenter of our payment provider."
                    />
                </p>
                <p>
                    <Localized
                        de="Möchten Sie diese Referenz trotzdem löschen?"
                        fr="Voulez-vous quand même supprimer cette référence?"
                        it="Vuole eliminare comunque questo riferimento?"
                        en="Would you still like to delete this reference?"
                    />
                </p>
            </FeedbackPopup>
            <BlockingLoadingModal
                open={deleteState.status === RequestStatus.PENDING}
            />
        </ParkingaboLayoutWithHeader>
    );
}

function PaymentMethodIcon({
    paymentMethod,
}: {
    paymentMethod: PaymentCardType;
}) {
    switch (paymentMethod) {
        case PaymentCardType.VIS:
            return <PaymentIcon icon="vis" />;
        case PaymentCardType.AMX:
            return <PaymentIcon icon="amx" />;
        case PaymentCardType.ECA:
            return <PaymentIcon icon="eca" />;
        case PaymentCardType.TWI:
            return <PaymentIcon icon="twi" />;
        case PaymentCardType.PFC:
        case PaymentCardType.PEF:
            return null;
    }
}

function PaymentAliasItems({
    alias,
    onDelete,
}: {
    alias: CreditCardAliasFound;
    onDelete: () => void;
}) {
    const aliasExpiration = alias.creditCardAlias.expiration;
    return (
        <Box>
            <ParkingaboMenuListItem
                supText={<Localized de="Typ" fr="Type" it="Tipo" en="Type" />}
                text={
                    <Localized
                        {...paymentMethodTranslations[
                            alias.creditCardAlias.paymentMethod
                        ]}
                    />
                }
                icons={[
                    <PaymentMethodIcon
                        key={alias.creditCardAlias.paymentMethod}
                        paymentMethod={alias.creditCardAlias.paymentMethod}
                    />,
                ]}
            />
            {hasCardNumber(alias.creditCardAlias.paymentMethod) && (
                <ParkingaboMenuListItem
                    supText={
                        <Localized
                            de="Kartennummer"
                            fr="Numéro de la carte"
                            it="Numero della carta"
                            en="Card number"
                        />
                    }
                    text={alias.creditCardAlias.maskedCreditCardNumber}
                />
            )}
            {aliasExpiration && (
                <ParkingaboMenuListItem
                    supText={
                        <Localized
                            de="Ablaufdatum"
                            fr="Date d'échéance"
                            it="Data di scadenza"
                            en="Expiration date"
                        />
                    }
                    text={
                        <Box
                            component="span"
                            sx={{
                                color: theme =>
                                    aliasExpiration.isExpired
                                        ? theme.palette.error.main
                                        : undefined,
                            }}
                        >
                            {`${aliasExpiration.month} / ${aliasExpiration.year}`}
                        </Box>
                    }
                    icons={
                        aliasExpiration.isExpired
                            ? [<Error key={0} color="error" />]
                            : undefined
                    }
                />
            )}
            <ParkingaboMenuListItem
                text={
                    <Localized
                        de="Löschen"
                        fr="Effacer"
                        it="Elimina"
                        en="Delete"
                    />
                }
                icons={[<Delete key={0} />]}
                hideChevron
                onClick={onDelete}
            />
        </Box>
    );
}

function hasCardNumber(paymentMethod: PaymentCardType): boolean {
    switch (paymentMethod) {
        case PaymentCardType.VIS:
        case PaymentCardType.ECA:
        case PaymentCardType.AMX:
        case PaymentCardType.PFC:
            return true;
        case PaymentCardType.TWI:
        case PaymentCardType.PEF:
            return false;
    }
}
