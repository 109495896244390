import { DateTime } from 'luxon';
import { Formatter } from '../../lib/Date';
import { Language } from '../../lib/Localized';

export const enum SubscriptionIntervalDuration {
    MONTHLY = 'MONTHLY',
}

export function intervalDurationToStartSelectOptions(
    now: DateTime,
    amount: number,
    unit: SubscriptionIntervalDuration,
    language: Language,
): { startDate: DateTime; dateText: string } {
    switch (unit) {
        case SubscriptionIntervalDuration.MONTHLY:
            return {
                startDate: now.startOf('month').plus({ month: amount }),
                dateText: Formatter.monthNameYear(
                    now.startOf('month').plus({ month: amount }),
                    Formatter.getLocaleFromString(language),
                ),
            };
    }
}
