import * as React from 'react';
import { ReactNode, useEffect } from 'react';
import {
    FetchInfo,
    RequestConfig,
    RequestStatus,
    ServerFetchState,
    ServerRequestState,
    ServerWriteState,
    useServerFetch,
    useServerWrite,
    WriteInfo,
} from '../../../lib/hooks/ServerStateHooks';
import { ValidationData } from '../../../common/utils/FormValidationHelpers';

const ApiMiddlewareContext = React.createContext({
    csrfToken: null as string | null,
    setCsrfToken: (val: string | null) => {},
});

export const ParkingaboApiMiddlewereContextProvider: React.FC<{
    children: ReactNode;
}> = ({ children }) => {
    const [csrfToken, setCsrfToken] = React.useState<string | null>(null);

    return (
        <ApiMiddlewareContext.Provider value={{ csrfToken, setCsrfToken }}>
            {children}
        </ApiMiddlewareContext.Provider>
    );
};

export const useUpdateParkingaboCsrfToken = () => {
    const { setCsrfToken } = React.useContext(ApiMiddlewareContext);

    return setCsrfToken;
};

export const useParkingaboCsrfToken = () => {
    const { csrfToken } = React.useContext(ApiMiddlewareContext);

    return csrfToken;
};

function wrapRequestFactory<C>(
    originalFactory: FetchInfo<C>,
    csrfToken: string | null,
): (c: C) => RequestConfig {
    return c => {
        const requestInfo = originalFactory(c);
        const headers = requestInfo.headers ?? {};

        return {
            ...requestInfo,
            headers: {
                'X-CSRF-Token': `${csrfToken}`,
                ...headers, // specific headers should win over generic ones.
            },
        };
    };
}

function useRedirectOnError(
    state: ServerRequestState<any, any>,
    noUnauthRefresh: boolean,
) {
    useEffect(() => {
        if (
            !noUnauthRefresh &&
            state.status === RequestStatus.ERROR &&
            state.httpStatusCode === 401
        ) {
            location.href = '';
        }
    }, [state, noUnauthRefresh]);
}

export function useParkingaboServerFetch<
    Data,
    Context extends { [key: string]: any },
    ErrorData = Data
>(
    requestInfoFactory: FetchInfo<Context>,
    context: Context | null,
    noUnauthRefresh?: boolean,
): ServerFetchState<Data, ErrorData> {
    const ctx = React.useContext(ApiMiddlewareContext);

    const wrappedFactory = wrapRequestFactory(
        requestInfoFactory,
        ctx.csrfToken,
    );
    const [state, refetchSameContext] = useServerFetch<
        Data,
        Context,
        ErrorData
    >(wrappedFactory, context);

    useRedirectOnError(state, noUnauthRefresh ?? false);

    return [state, refetchSameContext];
}

export function useParkingaboServerWrite<
    Payload,
    Data,
    ErrorData = ValidationData
>(
    requestInfoFactory: WriteInfo<Payload>,
    noUnauthRefresh?: boolean,
): ServerWriteState<Data, ErrorData, Payload> {
    const ctx = React.useContext(ApiMiddlewareContext);
    const wrappedFactory = wrapRequestFactory(
        requestInfoFactory,
        ctx.csrfToken,
    );
    const [state, write, reset] = useServerWrite<Payload, Data, ErrorData>(
        wrappedFactory,
    );

    useRedirectOnError(state, noUnauthRefresh ?? false);

    return [state, write, reset];
}
