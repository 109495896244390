import * as superagent from 'superagent';
import { UAParser } from 'ua-parser-js';

const agent = new UAParser().getResult();

const device = {
    ...agent,
    width:
        typeof window !== 'undefined' && typeof window.screen !== 'undefined'
            ? window.screen.width
            : 0,
    height:
        typeof window !== 'undefined' && typeof window.screen !== 'undefined'
            ? window.screen.height
            : 0,
};

export function logAction(
    sessionToken: string,
    name: string,
    payload: any,
): void {
    superagent
        .post('/ui-api/twint/action')
        .set(
            'Authorization',
            sessionToken ? `session_token ${sessionToken}` : '',
        )
        .send({ action: name, payload, appVersion: PCS_VERSION, device })
        .end(() => null);
}
