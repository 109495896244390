import * as React from 'react';
import {
    JsxMessage,
    Language,
    languageFromString,
    Message,
} from '../../lib/Localized';

export function Localized(props: Message | JsxMessage) {
    return <React.Fragment>{props[localizationLanguage()]}</React.Fragment>;
}

export function localizationLanguage(): Language {
    const searchParams = new URLSearchParams(window.location.search);
    const languageInUrl = searchParams.get('lang');

    if (languageInUrl) {
        return languageFromString(languageInUrl);
    }

    return browserLanguageString();
}

function browserLanguageString() {
    const locale =
        (window.navigator as any).userLanguage || window.navigator.language;

    return locale.slice(0, 2);
}
