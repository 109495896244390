import * as React from 'react';
import * as superagent from 'superagent';
import { openApp, Platform } from './PairingPage';
import {
    RequestStatus,
    useServerFetch,
} from '../../lib/hooks/ServerStateHooks';
import { Spinner } from '../../twint-app/src/ui/PendingOrErrorModal';
import { localizationLanguage, Localized } from './Localized';

interface TwintConfig {
    issuerUrlScheme: string;
    displayName: string;
}

export function AppChooser(p: {
    token: string;
    scheme: string;
    setScheme: (scheme: string) => void;
    configsRequest: superagent.Request<any, any>;
}) {
    const [configs] = useServerFetch<
        {
            appSwitchConfigList: TwintConfig[];
        },
        {}
    >(
        {
            request: () => p.configsRequest,
        },
        {},
    );

    if (
        configs.status === RequestStatus.NEVER_EXECUTED ||
        configs.status === RequestStatus.PENDING
    ) {
        return <Spinner visible={true} />;
    }

    if (configs.status === RequestStatus.ERROR) {
        return null;
    }

    const openIosApp = (newScheme: string) => {
        p.setScheme(newScheme);
        openApp(Platform.IOS, p.token, newScheme);
    };

    return (
        <div id="app-chooser-container">
            <div className="app-chooser-title">
                <Localized
                    de="Wählen Sie Ihre TWINT App:"
                    fr="Choisissez votre appli TWINT:"
                    it="Selezioni la sua app TWINT:"
                    en="Select your TWINT App:"
                />
            </div>
            <div className="app-logos-container">
                <div
                    className="bank-logo bank-ubs"
                    onClick={() => openIosApp('twint-issuer2://')}
                />
                <div
                    className="bank-logo bank-raiffeisen"
                    onClick={() => openIosApp('twint-issuer6://')}
                />
                <div
                    className="bank-logo bank-pf"
                    onClick={() => openIosApp('twint-issuer7://')}
                />
                <div
                    className="bank-logo bank-zkb"
                    onClick={() => openIosApp('twint-issuer3://')}
                />
                <div
                    className="bank-logo bank-cs"
                    onClick={() => openIosApp('twint-issuer4://')}
                />
                <div
                    className="bank-logo bank-bcv"
                    onClick={() => openIosApp('twint-issuer5://')}
                />
            </div>

            <div className="row text-center">
                <div className="copy-cache">
                    <select
                        className="app-chooser"
                        id="app-chooser"
                        onChange={e => p.setScheme(e.target.value)}
                        value={p.scheme}
                    >
                        <option value="">
                            {
                                {
                                    de: 'Andere Banken',
                                    fr: 'Autres banques',
                                    it: 'Altre banche',
                                    en: 'Other banks',
                                }[localizationLanguage()]
                            }
                        </option>
                        {configs.data.appSwitchConfigList.map(config => (
                            <option
                                key={config.displayName}
                                value={config.issuerUrlScheme}
                            >
                                {config.displayName}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        </div>
    );
}
