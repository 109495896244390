import { useTheme } from '@mui/material';
import {
    Box,
    CircularProgress,
    CircularProgressProps,
    Theme,
} from '@mui/material';
import * as React from 'react';

interface PresetLoadingSpinnerProps {
    preset: LoadingSpinnerPresets;
    /**
     * The color of the component. It supports those theme colors that make sense for this component.
     */
    color?: 'primary' | 'secondary' | 'inherit';
}

export enum LoadingSpinnerPresets {
    FillAllSpaceAndCenter,
    EmbedInButton,
    TableBody,
    SelectableList,
}

const boxPresets: {
    [key in LoadingSpinnerPresets]: (theme: Theme) => React.CSSProperties;
} = {
    [LoadingSpinnerPresets.FillAllSpaceAndCenter]: t => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
    }),
    [LoadingSpinnerPresets.EmbedInButton]: () => ({}),
    [LoadingSpinnerPresets.TableBody]: theme => ({
        display: 'flex',
        justifyContent: 'center',
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
    }),
    [LoadingSpinnerPresets.SelectableList]: t => ({
        display: 'flex',
        justifyContent: 'center',
        marginTop: 'auto',
        marginBottom: 'auto',
    }),
};

const propsPresets: {
    [key in LoadingSpinnerPresets]: CircularProgressProps;
} = {
    [LoadingSpinnerPresets.FillAllSpaceAndCenter]: {
        size: 50,
    },
    [LoadingSpinnerPresets.EmbedInButton]: {
        size: 20,
    },
    [LoadingSpinnerPresets.TableBody]: {},
    [LoadingSpinnerPresets.SelectableList]: {
        size: 24,
    },
};

export const PresetLoadingSpinner: React.FC<PresetLoadingSpinnerProps> = ({
    preset,
    color,
}) => {
    const theme = useTheme();
    const style = boxPresets[preset];
    const props = propsPresets[preset];

    if (preset === LoadingSpinnerPresets.EmbedInButton) {
        return <CircularProgress {...props} color={color} />;
    }

    return (
        <Box style={style(theme)}>
            <CircularProgress {...props} color={color} />
        </Box>
    );
};
