/*!
 * numeral.js language configuration
 * language : Italian in Switzerland (it-ch)
 */
export const language: NumeralJSLocale = {
    delimiters: {
        thousands: '.',
        decimal: ',',
    },
    abbreviations: {
        thousand: 'mila',
        million: 'mil',
        billion: 'b',
        trillion: 't',
    },
    currency: {
        symbol: 'CHF',
    },
    ordinal: (number: number): string => {
        return 'º';
    },
};
