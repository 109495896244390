import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material';
import * as React from 'react';
import { Localized } from '../../../operator-app/src/common/components/Localized';

interface DiscardChangesDialogProps {
    open: boolean;
    title?: React.ReactNode;
    content?: React.ReactNode;
    stayLabel?: React.ReactNode;
    leaveLabel?: React.ReactNode;
    onLeave: () => void;
    onStay: () => void;
}

export const DiscardChangesDialog: React.FC<DiscardChangesDialogProps> = ({
    open,
    title,
    content,
    stayLabel,
    leaveLabel,
    onLeave,
    onStay,
}) => {
    return (
        <Dialog open={open} maxWidth="xs">
            <DialogTitle>
                {title ?? (
                    <Localized
                        de="Änderungen verwerfen"
                        fr="Rejeter les changements"
                        it="Annulla le modifiche"
                        en="Discard changes"
                    />
                )}
            </DialogTitle>
            <DialogContent>
                {content ?? (
                    <Localized
                        de="Wenn Sie die Seite jetzt verlassen, gehen alle Änderungen verloren."
                        fr="Si vous quittez la page maintenant, tous les changements seront perdus."
                        it="Se esci dalla pagina ora tutte le modifiche andranno perse."
                        en="If you leave the page now all the changes will be lost."
                    />
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onStay}>
                    {stayLabel ?? (
                        <Localized
                            de="Abbrechen"
                            fr="Annuler"
                            it="Annulla"
                            en="Cancel"
                        />
                    )}
                </Button>
                <Button onClick={onLeave}>
                    {leaveLabel ?? (
                        <Localized
                            de="Fortsetzen"
                            fr="Continuer"
                            it="Continua"
                            en="Continue"
                        />
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
