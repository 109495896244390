import * as React from 'react';
import { Stack, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';

export namespace ParkingaboOverlineList {
    export function Body({
        sx,
        children,
    }: {
        sx?: SxProps<Theme>;
        children: React.ReactNode;
    }) {
        return (
            <Stack
                component="ul"
                direction="column"
                justifyContent="flex-start"
                alignItems="stretch"
                spacing={2}
                sx={{ listStyleType: 'none', margin: 0, padding: 0, ...sx }}
            >
                {children}
            </Stack>
        );
    }

    export function Item({
        label,
        children,
    }: {
        label: React.ReactNode;
        children: React.ReactNode;
    }) {
        return (
            <li>
                <Typography variant="overline" component="div">
                    {label}
                </Typography>
                <Typography fontWeight="bold" component="div">
                    {children}
                </Typography>
            </li>
        );
    }
}
