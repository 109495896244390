import * as React from 'react';
import { Maybe } from '../lib/MaybeV2';
import { ReactNode } from 'react';

export type ClickEvent = React.MouseEvent<HTMLElement>;

function execCallback(props: any, propName: string, e: ClickEvent): void {
    if (!props.disabled && props[propName]) {
        props[propName](e);
    }
}

export interface ClickHandler {
    (e?: ClickEvent): void;
}

interface Props extends ClickableDivProps {
    element: string;
    children?: ReactNode;
}

export interface ClickableDivProps {
    disabled?: boolean;
    className?: string;
    onClick?: Maybe<ClickHandler>;
    onClickStart?: Maybe<ClickHandler>;
    onClickEnd?: Maybe<ClickHandler>;
    onMouseDown?: void;
    onTouchStart?: void;
    onTouchEnd?: void;
    onMouseOver?: Maybe<React.MouseEventHandler<HTMLElement>>;
    onMouseOut?: Maybe<React.MouseEventHandler<HTMLElement>>;
    onMouseEnter?: Maybe<React.MouseEventHandler<HTMLElement>>;
    onMouseLeave?: Maybe<React.MouseEventHandler<HTMLElement>>;
    target?: string;
    href?: string;
    style?: any;
}

export class Clickable extends React.Component<Props> {
    static displayName = 'Clickable';

    render() {
        const style: any = Object.assign(
            !this.props.disabled ? { cursor: 'pointer' } : {},
            this.props.style,
        );
        const props: Props = Object.assign({}, this.props);

        // @ts-ignore
        delete props.element;
        delete props.onClickStart;
        delete props.onClickEnd;
        delete props.disabled;

        return React.createElement(
            this.props.element,
            Object.assign({}, props, {
                onClick: (e: ClickEvent): void =>
                    execCallback(this.props, 'onClick', e),
                onMouseUp: (e: ClickEvent): void =>
                    execCallback(this.props, 'onClickEnd', e),
                onMouseDown: (e: ClickEvent): void =>
                    execCallback(this.props, 'onClickStart', e),
                onTouchStart: (e: ClickEvent): void =>
                    execCallback(this.props, 'onClickStart', e),
                onTouchEnd: (e: ClickEvent): void =>
                    execCallback(this.props, 'onClickEnd', e),
                onMouseOver: (e: ClickEvent): void =>
                    execCallback(this.props, 'onMouseOver', e),
                onMouseOut: (e: ClickEvent): void =>
                    execCallback(this.props, 'onMouseOut', e),
                onMouseEnter: (e: ClickEvent): void =>
                    execCallback(this.props, 'onMouseEnter', e),
                onMouseLeave: (e: ClickEvent): void =>
                    execCallback(this.props, 'onMouseLeave', e),
                'data-disabled': this.props.disabled || false,
                tabIndex: -1,
                style: style,
            } as any),
            this.props.children,
        );
    }
}

export const Factory = React.createFactory<Props>(Clickable);
