import * as React from 'react';
import { Stack, Typography } from '@mui/material';

export function StatusBar({
    children,
    rightLabel,
    active,
}: {
    children: React.ReactNode;
    rightLabel?: React.ReactNode;
    active?: boolean;
}) {
    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="stretch"
            spacing={3}
            sx={{
                width: '100%',
                paddingX: 3,
                paddingY: 0.5,
                backgroundColor: theme =>
                    active
                        ? theme.palette.primary.main
                        : theme.palette.grey[600],
                textTransform: 'uppercase',
            }}
        >
            <Typography
                variant="subtitle2"
                color={theme => theme.palette.primary.contrastText}
            >
                {children}
            </Typography>
            {rightLabel && (
                <Typography
                    variant="subtitle2"
                    color={theme => theme.palette.primary.contrastText}
                >
                    {rightLabel}
                </Typography>
            )}
        </Stack>
    );
}
