import * as ReactDOM from 'react-dom';
import * as React from 'react';

export const Modal = (p: { children?: React.ReactNode }) => {
    if (document.getElementById('modal')) {
        return ReactDOM.createPortal(
            p.children,
            document.getElementById('modal') as HTMLElement,
        ) as any;
    } else {
        return null;
    }
};
