import { Box, TextField, Typography, useTheme } from '@mui/material';
import * as React from 'react';
import { generatePath, Outlet } from 'react-router-dom';
import { Localized } from '../../../../common/hooks/LanguageProvider';
import {
    computePasswordStrength,
    PasswordStrengthIndicator,
} from '../../components/PasswordStrengthIndicator';
import { useParkingaboServerWrite } from '../../api/ParkingaboApi';
import {
    useNavigateOnError,
    useNavigateOnSuccess,
    RequestStatus,
} from '../../../../lib/hooks/ServerStateHooks';
import { ParkingaboHeader } from '../../components/layout/ParkingaboHeader';
import { ParkingaboLayout } from '../../components/layout/ParkingaboLayout';
import { ParkingaboButton } from '../../components/layout/ParkingaboButton';
import { useParamOrNull } from '../../../../lib/ReactRouterHelpers';

export function ResetPasswordRoute() {
    const theme = useTheme();
    const token = useParamOrNull('token');
    const [password, setPassword] = React.useState('');
    const [repeatPassword, setRepeatPassword] = React.useState('');
    const [resetState, sendReset] = useParkingaboServerWrite<
        {
            password: string;
            token: string;
        },
        void
    >(
        () => ({
            url: '/ui-api/parkingabo/password-reminder/reset',
        }),
        true,
    );
    useNavigateOnSuccess(
        resetState,
        generatePath('/reset-password/:token/success', { token }),
        true,
    );
    useNavigateOnError(
        resetState,
        generatePath('/reset-password/:token/error', { token }),
    );

    const onPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
    };
    const onNewPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setRepeatPassword(e.target.value);
    };

    const handleSubmit = () => sendReset({ password, token: token ?? '' });
    const passwordStrength = computePasswordStrength(password);
    const canSend = passwordStrength.valid && password === repeatPassword;

    const handleEnterKey = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter' && canSend) {
            handleSubmit();
        }
    };

    return (
        <ParkingaboLayout>
            <Box>
                <ParkingaboHeader
                    linkLabel={
                        <Localized
                            de="Abbrechen"
                            fr="Annuler"
                            it="Annulla"
                            en="Cancel"
                        />
                    }
                    to={'/login'}
                />
                <Typography
                    variant="h1"
                    style={{
                        marginTop: theme.spacing(3),
                    }}
                >
                    <Localized
                        de="Password ersetzen"
                        fr="Remplacer le mot de passe"
                        it="Sostituire la password"
                        en="Replace password"
                    />
                </Typography>
                <Typography
                    variant="h3"
                    style={{
                        marginBottom: theme.spacing(5),
                        marginTop: theme.spacing(2),
                    }}
                >
                    <Localized
                        de="Neues Passwort definieren"
                        fr="Définir le nouveau mot de passe"
                        it="Definire la nuova password"
                        en="Define new password"
                    />
                </Typography>
                <form noValidate onSubmit={handleSubmit}>
                    <TextField
                        label={
                            <Localized
                                de="Passwort"
                                fr="Mot de passe"
                                it="Password"
                                en="Password"
                            />
                        }
                        value={password}
                        type="password"
                        onChange={onPasswordChange}
                        onKeyDown={handleEnterKey}
                        autoComplete="new-password"
                        margin="normal"
                    />
                    <TextField
                        label={
                            <Localized
                                de="Passwort-Bestätigung"
                                fr="Confirmer le mot de passe"
                                it="Conferma password"
                                en="Repeat password"
                            />
                        }
                        value={repeatPassword}
                        type="password"
                        onChange={onNewPasswordChange}
                        onKeyDown={handleEnterKey}
                        margin="normal"
                    />
                    {repeatPassword.length > 0 && password !== repeatPassword && (
                        <Typography color="error">
                            <Localized
                                de="Die zwei Passwörter stimmen nicht überein"
                                fr="Les deux mots de passe ne correspondent pas"
                                it="Le due password non corrispondono"
                                en="The two passwords do not match"
                            />
                        </Typography>
                    )}
                    <PasswordStrengthIndicator
                        passwordStrength={passwordStrength.strength}
                    />
                </form>
            </Box>
            <Box>
                <div style={{ width: '100%' }}>
                    <ParkingaboButton
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        disabled={!canSend}
                        sx={{ width: '50%', float: 'right' }}
                        loading={resetState.status === RequestStatus.PENDING}
                    >
                        <Localized
                            de="Senden"
                            fr="Envoyer"
                            it="Invia"
                            en="Send"
                        />
                    </ParkingaboButton>
                </div>
            </Box>

            <Outlet />
        </ParkingaboLayout>
    );
}
