import * as React from 'react';
import * as Icon from './Icon';
import * as Icons from './Icons';

export class SvgIcon extends React.Component<SvgIconProps> {
    refs: {
        svgNode: Element;
        [key: string]: React.ReactInstance;
    };

    componentDidMount(): void {
        // this needs to be removed and replaced with focusable="false" below once we upgrade to React 15
        // s. https://github.com/facebook/react/issues/6212
        this.refs.svgNode.setAttribute('focusable', 'false');
    }

    render() {
        const icon = this.props.icon;
        const iconSvg = typeof icon === 'string' ? Icons.icons[icon] : icon;
        return (
            <svg
                ref="svgNode"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                width="100%"
                height="100%"
                viewBox={`0 0 ${this.props.width} ${this.props.height}`}
                style={{ display: 'block' }}
            >
                {iconSvg}
            </svg>
        );
    }
}

interface SvgIconProps extends Icon.IconProps {
    width: number;
    height: number;
}
