import * as React from 'react';
import { useParams } from 'react-router';
import { PendingPaymentPoller } from '../../../components/PendingPaymentPoller';
import {
    AuthedRouteCompProps,
    useParkingaboAuthedPathGeneration,
} from '../../RouteUtils';
import { useNavigate } from 'react-router-dom';
import { PaymentMethodType } from '../../../components/forms/PaymentMethodForm';

export function PaymentMethodCheckRoute({
    refetchUser,
    paymentMethodType,
}: { paymentMethodType: PaymentMethodType } & AuthedRouteCompProps) {
    const { transactionId } = useParams<{ transactionId: string }>();
    const searchParams = new URLSearchParams(window.location.search);
    const generateAuthedParkingaboPath = useParkingaboAuthedPathGeneration();
    const status = searchParams.get('status');
    const redirectOnCanceled = ((): string => {
        switch (paymentMethodType) {
            case PaymentMethodType.REGISTER:
                return generateAuthedParkingaboPath('payment/register/aborted');
            case PaymentMethodType.UPDATE:
                return generateAuthedParkingaboPath('payment/update/aborted');
            case PaymentMethodType.ONBOARDING:
                return generateAuthedParkingaboPath(
                    'onboarding/payment/aborted',
                );
        }
    })();
    const navigate = useNavigate();

    if (!transactionId) {
        return null;
    }

    return (
        <PendingPaymentPoller
            pendingPaymentId={transactionId}
            refetchUser={refetchUser}
            cancelling={status === 'cancel'}
            twintPairingAborted={status === 'aborted'}
            onboarding={paymentMethodType === PaymentMethodType.ONBOARDING}
            onPaymentCanceled={() => {
                navigate(redirectOnCanceled);
            }}
            paymentMethodType={paymentMethodType}
        />
    );
}
