import * as React from 'react';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import { TextField, TextFieldProps } from '@mui/material';

type ReactHookFormTextFieldProps<
    FormFields extends FieldValues
> = TextFieldProps & {
    control: Control<FormFields>;
    name: Path<FormFields>;
    autofocus?: boolean;
    readonly?: boolean;
};

export function ReactHookFormTextField<FormFields extends FieldValues>({
    name,
    control,
    readonly,
    onChange: externalChange,
    ...props
}: ReactHookFormTextFieldProps<FormFields>) {
    return (
        <Controller
            name={name}
            control={control}
            render={({
                field: { onChange, value },
                fieldState: { error, isTouched },
            }) => (
                <TextField
                    onChange={(...params) => {
                        onChange(...params);
                        if (externalChange) {
                            externalChange(...params);
                        }
                    }}
                    value={value ?? ''}
                    error={!!error && !isTouched}
                    helperText={error && !isTouched ? error.message : null}
                    disabled={readonly}
                    {...props}
                />
            )}
        />
    );
}
