import * as React from 'react';
import { useState } from 'react';
import { Box, Card, CardActionArea, Stack, Typography } from '@mui/material';
import { Check } from '@mui/icons-material';
import { Theme } from '@mui/material/styles';
import {
    LoadingSpinnerPresets,
    PresetLoadingSpinner,
} from '../../../common/components/material-ui/PresetLoadingSpinner';

export function SelectableList<T extends number | string>({
    selectionItems,
    onChange,
    initialSelection,
    multiSelect,
    preventDeselect,
    loading,
}: {
    selectionItems: SelectableListItem<T>[];
    onChange?: (selectedIds: T[]) => void;
    initialSelection?: T[];
    multiSelect?: boolean;
    preventDeselect?: boolean;
    loading?: boolean;
}) {
    const [selectedIds, setSelectedIds] = useState<T[]>(initialSelection ?? []);

    function updateSelection(newSelection: T[]) {
        setSelectedIds(newSelection);
        onChange && onChange(newSelection);
    }

    function handleSelectionChange(clickedId: T) {
        if (!loading) {
            if (selectedIds.includes(clickedId)) {
                updateSelection(selectedIds.filter(id => id !== clickedId));
            } else {
                if (multiSelect) {
                    updateSelection([...selectedIds, clickedId]);
                } else {
                    updateSelection([clickedId]);
                }
            }
        }
    }

    return (
        <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={2}
            component="ul"
            sx={{ listStyleType: 'none', margin: 0, padding: 0 }}
        >
            {selectionItems.map(item => (
                <SelectionItem
                    key={item.id}
                    item={item}
                    selected={selectedIds.includes(item.id)}
                    loading={selectedIds.includes(item.id) && !!loading}
                    disabled={!!loading}
                    onClick={() => handleSelectionChange(item.id)}
                    preventDeselect={preventDeselect}
                />
            ))}
        </Stack>
    );
}

interface SelectableListItem<T> {
    id: T;
    title?: React.ReactNode;
    content: React.ReactNode;
}

function SelectionItem<T>({
    item,
    selected,
    loading,
    disabled,
    onClick,
    preventDeselect,
}: {
    item: SelectableListItem<T>;
    selected: boolean;
    loading: boolean;
    disabled: boolean;
    onClick: (e: React.SyntheticEvent) => void;
    preventDeselect?: boolean;
}) {
    function cardStyle(theme: Theme) {
        return {
            display: 'flex',
            justifyContent: 'space-between',
            itemsAlign: 'center',
            padding: theme.spacing(2),
            paddingRight: theme.spacing(3),
            minHeight: theme.spacing(9),
            backgroundColor: selected
                ? theme.palette.primary.main
                : theme.palette.primary.light,
            color: selected ? theme.palette.primary.contrastText : undefined,
        };
    }

    if ((selected && preventDeselect) || disabled) {
        return (
            <Card elevation={0} component="li" sx={cardStyle}>
                <CardContent
                    item={item}
                    selected={selected}
                    loading={loading}
                />
            </Card>
        );
    }

    return (
        <Card elevation={0} component="li">
            <CardActionArea sx={cardStyle} onClick={onClick}>
                <CardContent
                    item={item}
                    selected={selected}
                    loading={loading}
                />
            </CardActionArea>
        </Card>
    );
}

function CardContent<T>({
    item,
    selected,
    loading,
}: {
    item: SelectableListItem<T>;
    selected: boolean;
    loading: boolean;
}) {
    return (
        <>
            <Box sx={{ marginY: 'auto', flexGrow: 1 }}>
                {item.title && (
                    <Typography component="div" fontWeight="bold">
                        {item.title}
                    </Typography>
                )}
                {item.content}
            </Box>
            {selected && !loading && (
                <Check sx={{ marginY: 'auto', marginLeft: 1 }} />
            )}
            {loading && (
                <PresetLoadingSpinner
                    color="inherit"
                    preset={LoadingSpinnerPresets.SelectableList}
                />
            )}
        </>
    );
}
