import * as React from 'react';
import { useEffect } from 'react';
import { MaterialThemeProvider } from '../../../common/hooks/MaterialThemeProvider';
import {
    ParkingaboSubdomain,
    parkingaboThemeConfigs,
} from '../config/TenantConfig';
import { useLanguage } from '../../../common/hooks/LanguageProvider';
import { envIsLocalDev } from '../../../lib/Environment';

export function ParkingaboThemeProvider({
    children,
    subdomain,
}: {
    children: React.ReactNode;
    subdomain: ParkingaboSubdomain;
}) {
    const { language } = useLanguage();
    const themeConfig = parkingaboThemeConfigs[subdomain];

    useEffect(() => {
        const addLocalInfo = envIsLocalDev() ? 'LOCAL ' : '';
        document.title = addLocalInfo + themeConfig.displayName;
    }, [themeConfig]);

    return (
        <MaterialThemeProvider theme={themeConfig.theme} language={language}>
            {children}
        </MaterialThemeProvider>
    );
}
