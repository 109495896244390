import * as React from 'react';
import { Theme } from '@mui/material';
import { Localized } from '../../../common/hooks/LanguageProvider';
import { parkingaboDefaultMuiTheme } from '../shared/ParkingaboDefaultMuiTheme';
import { parkingaboCoopMuiTheme } from '../shared/ParkingaboCoopMuiTheme';

interface TenantConfig {
    name: JSX.Element;
}

// TODO: This needs to be handled properly in the backend
export const tenantConfig: { [key: number]: TenantConfig } = {
    3: {
        name: (
            <Localized
                de="Digitalparking"
                fr="Digitalparking"
                en="Digitalparking"
                it="Digitalparking"
            />
        ),
    },
    360: {
        name: <Localized de="UBS" fr="UBS" en="UBS" it="UBS" />,
    },
    361: {
        name: (
            <Localized
                de="UBS Card Center"
                fr="UBS Card Center"
                en="UBS Card Center"
                it="UBS Card Center"
            />
        ),
    },
};

export enum ParkingaboSubdomain {
    Digitalparking = 'app',
    Coop = 'coop',
    CARDCENTER = 'cardcenter',
    UBS = 'ubs',
}

export const parkingaboThemeConfigs: {
    [name in ParkingaboSubdomain]: { displayName: string; theme: Theme };
} = {
    [ParkingaboSubdomain.Digitalparking]: {
        displayName: 'Parkingabo',
        theme: parkingaboDefaultMuiTheme,
    },
    [ParkingaboSubdomain.Coop]: {
        displayName: 'Coop',
        theme: parkingaboCoopMuiTheme,
    },
    [ParkingaboSubdomain.CARDCENTER]: {
        displayName: 'UBS Card Center',
        theme: parkingaboDefaultMuiTheme,
    },
    [ParkingaboSubdomain.UBS]: {
        displayName: 'UBS',
        theme: parkingaboDefaultMuiTheme,
    },
};
