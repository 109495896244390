import {
    Box,
    Card,
    CardActionArea,
    Chip,
    Stack,
    SvgIconTypeMap,
    Typography,
} from '@mui/material';
import * as React from 'react';
import {
    AuthedRouteCompProps,
    useParkingaboAuthedPathGeneration,
} from '../RouteUtils';
import { tenantConfig } from '../../config/TenantConfig';
import { Localized } from '../../../../common/hooks/LanguageProvider';
import {
    AccountBalance,
    ChevronRight,
    DriveEta,
    LocalParking,
    Settings,
} from '@mui/icons-material';
import { ParkingaboLink } from '../../components/ParkinaboLink';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { VehicleQrCodeDrawer } from '../../components/VehicleQrCodeDrawer';
import { ParkingaboButton } from '../../components/layout/ParkingaboButton';
import { ParkingaboProductState } from '../../shared/ParkingaboProductModels';
import { ParkingaboLogo } from '../../components/layout/ParkingaboLogoHeader';
import { Outlet } from 'react-router-dom';

export function HomeRoute({ products, user, vehicles }: AuthedRouteCompProps) {
    const generateAuthedParkingaboPath = useParkingaboAuthedPathGeneration();

    return (
        <Box
            sx={{
                height: '100%',
                position: 'relative',
                backgroundColor: theme => theme.palette.primary.light,
            }}
        >
            <Box
                sx={theme => ({
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText,
                    padding: '29px 32px 29px 26px',
                })}
            >
                <ParkingaboLogo width={237} height={50} />
                <ParkingaboLink to={generateAuthedParkingaboPath('settings')}>
                    <ParkingaboButton
                        sx={{
                            width: 40,
                            height: 40,
                            minWidth: 40,
                            backgroundColor: theme =>
                                theme.palette.primary.contrastText,
                            ['&:hover']: {
                                backgroundColor: theme =>
                                    theme.palette.primary.light,
                            },
                        }}
                    >
                        <Settings />
                    </ParkingaboButton>
                </ParkingaboLink>
            </Box>
            <Box sx={{ padding: theme => theme.spacing(4) }}>
                <Typography
                    variant="h1"
                    color="inherit"
                    sx={theme => ({
                        paddingTop: theme.spacing(1),
                        paddingBottom: theme.spacing(3),
                        textAlign: 'center',
                        marginBottom: theme.spacing(1),
                    })}
                >
                    {tenantConfig[user.tenantId].name}
                </Typography>
                <Stack spacing={2}>
                    <ButtonLink
                        to={'products'}
                        label={
                            <Localized
                                de="Produkte"
                                fr="Produits"
                                it="Prodotti"
                                en="Products"
                            />
                        }
                        Icon={LocalParking}
                        counter={
                            products.filter(
                                product =>
                                    product.state ===
                                    ParkingaboProductState.ACTIVE,
                            ).length
                        }
                    />
                    <ButtonLink
                        to={'account-statement'}
                        label={
                            <Localized
                                de="Kontoauszug"
                                fr="Relevé de compte"
                                it="Estratto conto"
                                en="Account statement"
                            />
                        }
                        Icon={AccountBalance}
                        counter={0}
                    />
                    <ButtonLink
                        to={'vehicles'}
                        label={
                            <Localized
                                de="Fahrzeuge"
                                fr="Véhicules"
                                it="Veicoli"
                                en="Vehicles"
                            />
                        }
                        Icon={DriveEta}
                        counter={vehicles.data.length}
                    />
                </Stack>
                <Outlet />
            </Box>
            <VehicleQrCodeDrawer vehicles={vehicles.data} />
        </Box>
    );
}

function ButtonLink({
    to,
    label,
    Icon,
    counter,
}: {
    to: string;
    label: React.ReactNode;
    Icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
    counter: number;
}) {
    return (
        <ParkingaboLink to={to} underline="none">
            <Card elevation={0}>
                <CardActionArea
                    sx={theme => ({
                        display: 'flex',
                        justifyContent: 'space-between',
                        backgroundColor: theme.palette.background.default,
                        paddingX: theme.spacing(2),
                        paddingY: theme.spacing(3),
                    })}
                >
                    <Box style={{ display: 'flex', alignItems: 'center' }}>
                        <Icon
                            sx={{
                                fontSize: theme =>
                                    2 * (theme.typography.h3.fontSize as any),
                            }}
                        />
                        <Typography
                            variant="h3"
                            component="h2"
                            sx={{
                                marginLeft: theme => theme.spacing(1),
                                textTransform: 'none',
                            }}
                        >
                            {label}
                        </Typography>
                    </Box>
                    <Box style={{ display: 'flex', alignItems: 'center' }}>
                        {counter > 0 && (
                            <Chip
                                label={counter}
                                color="primary"
                                sx={{
                                    marginRight: theme => theme.spacing(1),
                                    minWidth: 24,
                                    height: 24,
                                }}
                            />
                        )}
                        <ChevronRight />
                    </Box>
                </CardActionArea>
            </Card>
        </ParkingaboLink>
    );
}
