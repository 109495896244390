import * as React from 'react';
import { Language } from '../../../../lib/Localized';
import { Localized } from '../../../hooks/LanguageProvider';
import { VehicleLicensePlateType } from '../../../models/Vehicle';
import { ReactHookFormSelectOption } from './ReactHookFormSelect';

export const languageTranslations: { [key in Language]: React.ReactNode } = {
    [Language.DE]: (
        <Localized de="Deutsch" fr="Allemande" it="Tedesco" en="German" />
    ),
    [Language.FR]: (
        <Localized de="Französisch" fr="Français" it="Francese" en="French" />
    ),
    [Language.IT]: (
        <Localized de="Italienisch" fr="Italienne" it="Italiano" en="Italian" />
    ),
    [Language.EN]: (
        <Localized de="Englisch" fr="Anglaise" it="Inglese" en="English" />
    ),
};

export const languageOptions: ReactHookFormSelectOption<Language>[] = [
    {
        display: languageTranslations[Language.DE],
        value: Language.DE,
    },
    {
        display: languageTranslations[Language.FR],
        value: Language.FR,
    },
    {
        display: languageTranslations[Language.IT],
        value: Language.IT,
    },
    {
        display: languageTranslations[Language.EN],
        value: Language.EN,
    },
];

export const licensePlateOptions = (lang: Language) =>
    Object.values(VehicleLicensePlateType).map(type => ({
        display: {
            [VehicleLicensePlateType.CAR]: {
                [Language.DE]: 'Auto',
                [Language.FR]: 'Auto',
                [Language.IT]: 'Auto',
                [Language.EN]: 'Car',
            },
            [VehicleLicensePlateType.MOTORCYCLE]: {
                [Language.DE]: 'Motorrad',
                [Language.FR]: 'Moto',
                [Language.IT]: 'Motocicletta',
                [Language.EN]: 'Motorcycle',
            },
        }[type][lang],
        value: type,
    }));
