import { AuthedRouteCompProps } from '../../RouteUtils';
import { BaseModal } from './EditProfileRoute';
import { UserDataForm } from '../../../components/forms/UserDataForm';
import * as React from 'react';

export function EditNameRoute({ user, refetchUser }: AuthedRouteCompProps) {
    return (
        <BaseModal
            open={true}
            user={user}
            refetchUser={refetchUser}
            render={control => <UserDataForm.NameField control={control} />}
        />
    );
}
