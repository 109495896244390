import { Language } from '../Localized';

export namespace Legal {
    export const tosFiles: any = {
        de: require('./tos_de.pdf'),
        fr: require('./tos_fr.pdf'),
        it: require('./tos_it.pdf'),
        en: require('./tos_en.pdf'),
    };

    export const privacyPolicyFiles: any = {
        de: require('./privacy_de.pdf'),
        fr: require('./privacy_fr.pdf'),
        it: require('./privacy_it.pdf'),
        en: require('./privacy_en.pdf'),
    };

    export const operatorTermOfUseFiles: any = {
        de: require('./term_of_use_de.pdf'),
        fr: require('./term_of_use_fr.pdf'),
        it: require('./term_of_use_it.pdf'),
        en: '',
    };

    const ubsTosFiles: any = {
        de: require('./ubs_tos_de.pdf'),
        fr: require('./ubs_tos_fr.pdf'),
        it: require('./ubs_tos_en.pdf'),
        en: require('./ubs_tos_en.pdf'),
    };

    const ubsPrivacyPolicyFiles: any = {
        de: require('./ubs_privacy_de.pdf'),
        fr: require('./ubs_privacy_fr.pdf'),
        it: require('./ubs_privacy_en.pdf'),
        en: require('./ubs_privacy_en.pdf'),
    };

    export function deriveParkingaboTos() {
        return window.location.host.indexOf('ubs.') > -1
            ? ubsTosFiles
            : tosFiles;
    }

    export function deriveParkingaboPrivacy() {
        return window.location.host.indexOf('ubs.') > -1
            ? ubsPrivacyPolicyFiles
            : privacyPolicyFiles;
    }

    export const coopTosMarkdown: { [key in Language]: string } = {
        de: `
### 1. Präambel

Digitalparking AG (nachfolgend „Digitalparking“ genannt) bietet unter dem Namen „Parkingpay“ Dienstleistungen an, welche registrierten und nicht registrierten Benutzern (natürliche oder juristischen Personen, nachfolgend „Benutzer“ genannt) ermöglicht, die Nutzung von entsprechend ausgerüsteten Parkplätzen abzuwickeln.

Im Namen und mit Ermächtigung der am Parkingpay-System angeschlossenen Betreiber von Parkplätzen (Gemeinden, Städte, Betreiber von Parkhäusern usw., nachfolgend „Betreiber“ genannt) übernimmt Digitalparking die Erhebung und bargeldlose Abrechnung der anfallenden Gebühren gegenüber dem Benutzer.

Die Abrechnung erfolgt über ein Parkingpay-Konto, welches bei der einmaligen Registrierung für den Benutzer angelegt wird. Das Parkingpay-Konto kann vom Benutzer jederzeit online eingesehen werden.

Durch die Nutzung von Parkplätzen mit dem Parkingpay-System autorisiert der Benutzer Digitalparking, die zu den jeweils geltenden Tarifen anfallende Parkgebühr auf seinem Parkingpay-Konto oder mittels e-Payment zu belasten.

Der Benutzer verpflichtet sich, die auf dem Parkingpay-Konto belasteten Gebühren mit der ausgewählten Zahlungsweise zu begleichen. Die Parkingpay-Funktionen können nur genutzt werden, wenn sich der Benutzer nicht in Zahlungsverzug befindet.

### 2. Registrierung und Aufhebung eines Parkingpay-Kontos

Der Benutzer muss sich einmalig über Internet mit Angabe seiner Personalien, der Zahlungsweise sowie mindestens eines Fahrzeugkennzeichens registrieren und dabei diesen Allgemeinen Geschäftsbedingungen (nachfolgend „AGB“ genannt) und der separaten Datenschutzerklärung zustimmen. Die Registrierung ist kostenlos. Ein Anspruch auf Registrierung besteht nicht.

Sowohl der Benutzer als auch Digitalparking kann ein Parkingpay-Konto jederzeit ohne Einhaltung einer Kündigungsfrist aufheben.

Digitalparking ist zudem berechtigt, das Konto jederzeit zu sperren, wenn

* der Benutzer gegen die AGB oder gegen geltendes Recht verstösst,
* eine Lastschrift nicht einlösbar ist,
* der Benutzer in Zahlungsverzug ist,
* der Benutzer falsche Angaben gemacht hat,
* es wird eine missbräuchliche Nutzung vermutet.

Bei Aufhebung des Parkingpay-Kontos wird dem Benutzer ein allfälliges Restguthaben überwiesen bzw. er schuldet den Restsaldo innert 30 Tagen.

### 3. Nutzungsvertrag

Mit der Zustimmung zu den AGB bei der Registrierung kommt zwischen Digitalparking und dem Benutzer ein darauf basierender Vertrag zustande.

Der Benutzer hat weder ein Rücktritts- noch ein Rückgaberecht der bezogenen Leistung.

### 4. Informationspflicht des Benutzers

Der Benutzer verpflichtet sich, Änderungen seiner persönlichen und vertragswesentlichen Daten unverzüglich online in seinem Konto vorzunehmen. Diese Verpflichtung bezieht sich insbesondere auf die Änderung der Rechnungsadresse, der Zahlungsweise, der Kennzeichen seiner Fahrzeuge und der Karten.

Kommt der Benutzer seiner Informationspflicht nicht nach, so ist Digitalparking berechtigt, dem Benutzer die ihr entstehenden Mehraufwendungen in Rechnung zu stellen. Gibt der Benutzer eine Änderung seiner Anschrift nicht bekannt und gehen ihm deshalb an die von ihm zuletzt bekanntgegebene Anschrift gesandte, rechtlich bedeutsame Erklärungen der Digitalparking nicht zu, so gelten die Erklärungen trotzdem als zugegangen.

### 5. Sorgfaltspflicht des Benutzers

Das vom Benutzer festgelegte persönliche Login mit Passwort, welches durch den Benutzer jederzeit abgeändert werden kann, ist streng vertraulich zu behandeln. Das Login mit Passwort ist erforderlich zur Einsicht des persönlichen Parkingpay-Kontos und ist von Zeit zu Zeit zu ändern. Stellt der Benutzer missbräuchliche Belastungen seines Parkingpay-Kontos fest, ist er verpflichtet, das Passwort zum Konto sofort zu ändern sowie die betroffenen Karten in seinem Konto zu löschen oder Digitalparking darüber unverzüglich zu unterrichten, damit sein Parkingpay-Konto gesperrt werden kann. Das gleiche gilt bei Verlust oder Diebstahl einer registrierten Karte.

Bei missbräuchlicher Benutzung durch Dritte entfällt die Haftung des Benutzers für Schäden, die nach dem Eingehen der Mitteilung an Digitalparking belastet werden.

### 6. Gebühren

#### Grundfunktionen

Für die Nutzung der gebührenpflichtigen Parkplätze werden dem Benutzer die Parkgebühren gemäss den Tarifen des Parkingbetreibers belastet. Bei entsprechendem, ausdrücklichem Hinweis in der App, werden bei vereinzelten Betreibern für die Benutzung der Parkingpay Grundfunktionen Zuschläge erhoben.

#### Zusatzfunktionen

Für die Nutzung von den Zusatzfunktionen werden Zuschläge und Kosten gemäss den unter parkingpay.ch publizierten Ansätzen erhoben.

Auf diese Zuschläge wird der Benutzer in der Parkingpay-App vor und nach der Aktivierung der entsprechenden Zusatzfunktion deutlich hingewiesen.

#### Kommunikation

Fallen für die Nutzung der Parkingpay-Funktionen Kommunikationskosten (Internet- und Mobiltelefongebühren) an, sind diese durch den Benutzer zu tragen.

### 7. Zahlungsausstände

Bei nicht fristgerecht bezahlten Ausständen oder bei Rückweisung bzw. Widerrufung einer Belastung (LSV+ oder DebitDirect) wird das entsprechende Parkingpay-Konto umgehend gesperrt, bis die aufgelaufenen Gebühren bezahlt sind.

### 8. Fehlerfall oder inkorrekte Nutzung des Systems

Wenn es dem Benutzer bei der Funktion „Parkieren mit dem Smartphone“ nicht gelingen sollte, einen Parkvorgang zu starten, ist er in jedem Falle dazu verpflichtet, die Parkgebühr auf konventionellem Weg an einer Parkuhr zu bezahlen. Falls keine Parkuhr vorhanden ist, darf der Benutzer nicht parkieren. Der Parkvorgang gilt als gestartet, wenn dies das Parkingpay-System ausdrücklich bestätigt.

Wenn der Benutzer den Parkvorgang beim Start/Stopp-Verfahren beenden will und das Parkingpay-System nicht verfügbar ist, muss sich der Benutzer unverzüglich mit dem Parkingpay-Helpdesk (0848 330 555) in Verbindung setzen, damit der Parkvorgang manuell beendet werden kann.

### 9. Parkbussen

Erhält der Benutzer eine Parkbusse und bestreitet er diese, so hat er sich ausschliesslich an die auf dem entsprechenden Beleg aufgeführte Stelle zu wenden.

Wenn nachweislich ein korrekter Parkvorgang mit dem Parkingpay-System stattgefunden hat, wird Digitalparking dies der entsprechenden Stelle mit Belegen bestätigen. Über eine Aufhebung einer Parkbusse entscheidet in jedem Falle die zuständige Stelle. Digitalparking haftet in keinem Fall für zu bezahlende Parkbussen.

Höchstparkzeiten und Nachzahlungsverbote gelten in jedem Fall auch bei Nutzung des Parkingpay-Systems. Beim Erreichen der geltenden Höchstparkzeit wird der Parkvorgang automatisch beendet.

Nach Ablauf jedes Parkvorgangs muss das Fahrzeug den Parkplatz verlassen; ist dies nicht der Fall und wird ein neuer Parkvorgang gestartet, muss trotz gültigem Parkvorgang mit einer Parkbusse gerechnet werden. Davon ausgenommen sind Parkplätze, bei welchen das Nachzahlen gemäss den geltenden Bestimmungen zulässig ist.

### 10. Haftung

Digitalparking haftet nach den gesetzlichen Bestimmungen für Schäden, sofern diese auf Vorsatz, grobe Fahrlässigkeit oder die schuldhafte Verletzung wesentlicher Vertragspflichten von Digitalparking zurückzuführen sind.

Digitalparking übernimmt keine Verantwortung oder Haftung für die ständige Verfügbarkeit der Parkingpay-Funktionen, insbesondere nicht bezüglich der Verfügbarkeit der Mobilfunknetze und der Funktionsfähigkeit von mobilen Endgeräten.

Bei Nichtverfügbarkeit des Parkingpay-Systems kann Digitalparking nicht für die entstehenden Folgekosten wie Parkbussen, Abschleppgebühren etc. verantwortlich gemacht werden.

Für fehlerhafte oder nicht erfolgte Beendigung von Parkvorgängen übernimmt Digitalparking keine Haftung. Digitalparking kann nicht garantieren, dass Erinnerungsnachrichten (SMS, Push, usw.) eines laufenden Parkvorganges immer zeitgerecht zugestellt werden.

### 11. Änderungen oder Ergänzungen dieser Geschäftsbedingungen

Digitalparking behält sich vor, die AGB jederzeit und ohne Nennung von Gründen zu ändern.

Änderungen oder Ergänzungen werden dem Benutzer schriftlich oder per E-Mail bekanntgegeben.

Neu zugegangene AGB gelten als genehmigt, wenn der Parkingpay-Benutzer nicht innerhalb eines Monats schriftlich seinen Widerspruch erklärt.

Sofern eine Bestimmung dieser AGB unwirksam ist, bleiben die übrigen Bestimmungen davon unberührt. Die unwirksame Bestimmung gilt als durch eine solche ersetzt, die dem Sinn und Zweck der unwirksamen Bestimmung in rechtswirksamer Weise wirtschaftlich am nächsten kommt. Gleiches gilt für eventuelle Regelungslücken.

Mündliche Nebenabreden haben keine Gültigkeit.

### 12. Anwendbares Recht und Gerichtsstand

Das Vertragsverhältnis unterliegt Schweizer Recht. Als Gerichtsstand gilt Schlieren.

`,
        fr: `
### 1. Préambule

Digitalparking SA (ci-après: «Digitalparking») propose sous le nom «Parkingpay» des prestations permettant à une personne physique ou morale (ci-après «l’usager») de s’acquitter de leur taxe de stationnement pour les parkings équipés en conséquence.

Au nom et avec l’habilitation des exploitants de parkings affiliés au système Parkingpay (communes, villes, exploitants de parkings à étages etc., ci-après: «l’exploitant»), Digitalparking se charge de la perception des éventuelles taxes dues par les usagers et de leur règlement dématérialisé à l’exploitant.

Le règlement s'effectue par le biais d’un compte Parkingpay ouvert par Digitalparking au moment de l’enregistrement de l’usager. L’usager peut à tout moment accéder à son compte Parkingpay par Internet.

En utilisant les parkings au moyen du système Parkingpay, l’usager autorise Digitalparking à débiter de son compte Parkingpay le montant du stationnement au tarif en vigueur.

L’usager s’engage à régler les sommes débitées sur le compte Parkingpay par le mode de paiement pour lequel il aura opté. Les fonctions Parkingpay ne peuvent être utilisées que si l’usager n’est pas en retard de paiement.

### 2. Enregistrement et annulation du compte Parkingpay

L’usager doit s'enregistrer une unique fois par Internet en indiquant ses coordonnées, le mode de paiement choisie, au moins le numéro d’immatriculation d’un véhicule et acceptant ces conditions générales de vente (ci-après: «CGV»), ainsi que la déclaration sur la protection des données. L’enregistrement est gratuit, mais il n'est pas un droit.

L’usager aussi bien que Digitalparking peuvent annuler un compte Parkingpay, à tout moment et sans préavis.

Digitalparking est en outre habilité à bloquer le compte en tout temps dès lors que:

* l’usager contrevient aux CGV ou au droit en vigueur
* si un débit direct ne fonctionne pas
* si l’usager est en retard de paiement
* si l'usager a fait de fausses déclarations ou encore
* s’il y a suspicion d’usage frauduleux ou abusif

En cas d'annulation du compte Parkingpay, l’éventuel solde positif est remboursé à l’usager. Si le solde est négatif, l’usager doit s’acquitter du montant sous 30 jours.

### 3. Contrat d'utilisation

L'acceptation des CGV au moment de l'enregistrement lie l'usager à Digitalparking par un contrat basé sur les dites CGV.

L'usager n'a aucun droit de rétractation, ni droit de restitution sur les prestations acquises.

### 4. Devoir d’information de la part de l’usager

L’usager s’engage à procéder par Internet, sur son compte et sans délai, à toute modification concernant sa personne ou les données importantes pour le lien contractuel. Cette obligation vaut tout particulièrement en cas de changement d’adresse de facturation, de mode de paiement, d’immatriculation des véhicules et de cartes.

Si l’usager ne satisfait pas à ce devoir d’information, Digitalparking est habilité à facturer à l’usager les coûts supplémentaires que ce manquement aura généré. Si l’usager ne communique pas son changement d’adresse et que des documents juridiquement importants qui lui auront été envoyés par Digitalparking à la dernière adresse connue ne lui parviennent pas, les documents seront néanmoins considérés comme ayant été reçus.

### 5. Devoir de diligence de l’usager

L’identifiant personnel défini par l'usager et le mot de passe, que l’usager peut modifier à tout moment, doivent être traités de manière strictement confidentielle. L’identifiant personnel et le mot de passe sont indispensables pour accéder au compte Parkingpay personnel et ils doivent être modifiés de temps à autre. Si l’usager constate un usage frauduleux de son compte Parkingpay, il est tenu de modifier immédiatement le mot de passe de son compte et d'effacer sur son compte les cartes ou bien d’en informer sans délai Digitalparking afin que son compte Parkingpay puisse être bloqué. Ceci vaut également en cas de perte ou de vol du téléphone mobile ou d’une carte enregistré.

En cas d’usage frauduleux par des tiers, l’usager est exempté de toute responsabilité quant aux dommages survenant après réception de l’information par Digitalparking.

### 6. Coûts

#### Fonctions de base

Pour l’utilisation des parkings payants, l’usager est débité des taxes de stationnement correspondant aux tarifs de l’exploitant du parking. Sous réserve de le mentionner expressément, chez certains exploitants peuvent s'appliquer des suppléments pour l'utilisation des fonctions Parkingpay de base.

#### Fonctions additionnelles

Des suppléments et des frais sont perçus pour l'utilisation des fonctions additionnelles conformément aux tarifs publiés sur parkingpay.ch.

L'utilisateur est clairement informé de ces suppléments dans l'appli Parkingpay avant et après l'activation de la fonction additionnelle correspondante.

#### Communication

Si l’utilisation des fonctions Parkingpay génère des frais de communication (Internet et téléphone mobile), ceux-ci seront à la charge de l’usager.

### 7. Retards de paiements

En cas de non-règlement dans les délais convenus, de rejet ou révocation d’un prélèvement (LSV+ ou Débit Direct), le compte Parkingpay concerné est immédiatement bloqué jusqu’au paiement intégral des montants dus.

### 8. Erreur ou usage incorrect du système

Si, dans le cadre de la fonction «Payer son stationnement par smartphone», l’usager ne parvient pas à démarrer une procédure de stationnement, il est tenu de s’acquitter de la taxe par les moyens conventionnels (parcomètre). S'il n'y a pas des parcomètres disponibles, l'utilisateur n'a pas le droit de stationner. Le temps de stationnement débute lorsque le système Parkingpay envoie la confirmation expresse.

Si l’usager souhaite terminer une procédure de stationnement (start/stop) et si le système Parkingpay n’est pas accessible, il doit appeler le helpdesk Parkingpay (0848 330 555) pour faire arrêter la procédure manuellement.

### 9. Amende de stationnement

Si l’usager reçoit une amende de stationnement et s’il la conteste, il doit s’adresser exclusivement à l’instance indiquée sur le document.

S’il est avéré que le stationnement s’est déroulé dans les règles, par le biais du système Parkingpay, Digitalparking fournira à l’instance concernée les justificatifs qui le confirment. L’annulation de l’amende demeure, dans tous les cas, l’affaire de l’instance émettrice. Digitalparking n’est en aucun cas responsable du paiement d’amendes de stationnement.

Les durées maximales de stationnement et les interdictions de prolongation s’appliquent dans tous les cas, même dans le cadre de l’utilisation du système Parkingpay. En cas d'atteinte de la durée maximale de stationnement, le système arrête automatiquement la procédure.

Après la fin de chaque procédure de stationnement, le véhicule doit quitter le parking. Si cela ne se produit pas et qu'une nouvelle procédure de stationnement est démarrée, l’usager peut recevoir une amende, même si la procédure de stationnement a été démarrée correctement. Ceci ne s'applique pas aux zones où, conformément à la réglementation en vigueur, il est permis de payer plusieurs fois de suite.

### 10. Responsabilité civile

Aux termes de la loi, Digitalparking est responsable des dommages causés intentionnellement, par négligence grave ou infraction fautive à des devoirs contractuels majeurs de la part de Digitalparking.

La responsabilité de Digitalparking n’est pas engagée quant à la permanence de la disponibilité des fonctions Parkingpay, en particulier concernant la disponibilité des réseaux de téléphonie mobile et le bon fonctionnement des terminaux de téléphonie mobile.

En cas d’inaccessibilité au système Parkingpay, Digitalparking ne saurait être tenu pour responsable des coûts qui en résultent, tels qu’amendes de stationnement, frais d’enlèvement etc.

La responsabilité de Digitalparking n’est pas engagée en cas d’erreur ou d’absence de clôture de la procédure de stationnement. Digitalparking ne peut garantir que les rappels (SMS, push, etc.) pour un stationnement en cours parviennent toujours à temps au destinataire.

### 11. Modifications et ajouts aux présentes conditions générales

Digitalparking se réserve le droit de modifier à tout moment et sans justification les présentes CGV.

Les modifications et ajouts seront notifiés à l’usager par courrier postal ou électronique.

Les nouvelles CGV seront considérées comme acceptées si l’usager ne les rejette pas par écrit sous un mois après réception.

La non-validité d’une des dispositions des présentes CGV n’affecte en rien la validité des autres clauses. La disposition invalidée sera remplacée par une autre valide, qui se rapproche au maximum de la finalité économique de la clause invalidée. Ceci vaut par analogie si les CGV devaient présenter une lacune.

Tout accord oral annexe est nul et non avenu.

### 12. Droit applicable et for

La relation contractuelle est soumise au droit suisse et le for juridique est Schlieren.

`,
        it: `
### 1. Premessa

Digitalparking SA (di seguito «Digitalparking») offre sotto il nome “Parkingpay” dei servizi che permettono a una persona fisica o giuridica (di seguito «utente») di utilizzare i parcheggi adeguatamente attrezzati.

Digitalparking si occupa, in nome e per conto del gestore del parcheggio aderente al sistema Parkingpay (comuni, città, gestori di autosili ecc., di seguito «gestore»), della riscossione senza contanti e del conteggio delle eventuali tasse di parcheggio a carico dell’utente.

Il conteggio avviene tramite un conto Parkingpay che viene creato al momento della registrazione da parte dell’utente. L’utente può verificare in qualsiasi momento tramite Internet lo stato del suo conto Parkingpay.

Utilizzando i parcheggi che aderiscono al sistema Parkingpay, l’utente autorizza Digitalparking ad addebitare la tassa di parcheggio, calcolata secondo le tariffe vigenti, sul proprio conto.

L’utente si impegna a saldare le tasse di parcheggio addebitate sul conto Parkingpay tramite la modalità di pagamento prescelta. L’utente può avvalersi delle funzioni Parkingpay solo se non ci sono pagamenti arretrati.

### 2. Apertura e chiusura di un conto Parkingpay

L’utente deve registrarsi tramite Internet indicando i propri dati personali, la modalità di pagamento prescelta, almeno il numero di targa di un veicolo e accettando queste Condizioni generali (di seguito «CG») e l’informativa sulla protezione dei dati. La registrazione è gratuita. Non sussiste alcun diritto alla registrazione.

Il conto Parkingpay può essere chiuso in qualsiasi momento, sia dall’utente che da Digitalparking, senza alcun termine di preavviso.

Digitalparking può bloccare in qualsiasi momento il conto se:

* l’utente contravviene alle disposizioni delle CG o viola le leggi vigenti;
* l’addebito diretto non ha potuto essere effettuato;
* l’utente è in ritardo con i pagamenti;
* l’utente ha fornito dati personali errati;
* si sospetta un utilizzo improprio.

In caso di chiusura del conto Parkingpay, l’utente riceverà tramite bonifico l’eventuale credito residuo oppure sarà invitato a saldare il debito residuo entro 30 giorni.

### 3. Contratto di utilizzo

Con l’accettazione delle CG al momento della registrazione, l’utente stipula un contratto con Digitalparking basato sulle suddette condizioni.

Per le prestazioni percepite non è possibile avvalersi del diritto di recesso o di restituzione.

### 4. Obbligo di informazione dell’utente

L’utente si impegna ad aggiornare tempestivamente il proprio conto via Internet in caso di eventuali variazioni dei dati personali o di altre informazioni fondamentali nel quadro del contratto. Tale obbligo è riferito in modo particolare a modifiche dell’indirizzo di fatturazione, della modalità di pagamento prescelta, delle targhe dei propri veicoli o le relative carte.

In caso di mancato adempimento di tali obblighi informativi, Digitalparking è autorizzata ad addebitare all’utente le eventuali spese supplementari che ha dovuto sostenere. Se per esempio un utente non comunica una modifica d’indirizzo e pertanto non riceve da Digitalparking importanti comunicazioni legalmente vincolanti, in quanto inviate all’ultimo indirizzo noto, per Digitalparking tali comunicazioni sono considerate come regolarmente recapitate.

### 5. Obbligo di diligenza dell’utente

Il nome utente e la password definiti dall’utente (modificabili in qualsiasi momento) dovranno essere trattati da quest’ultimo con la massima riservatezza; rappresentano la chiave di accesso al conto Parkingpay ed è raccomandabile modificarli periodicamente. Se l’utente constata degli addebiti irregolari sul proprio conto Parkingpay, è tenuto a modificare immediatamente la password e a cancellare le carte ad esso associati oppure a informare tempestivamente Digitalparking per autorizzare il blocco del proprio conto. Occorre procedere analogamente in caso di smarrimento o furto del cellulare o di una carta registrata.

In caso di utilizzo improprio da parte di terzi, decade la responsabilità dell’utente per qualsiasi danno che si sia verificato successivamente al ricevimento della comunicazione da parte di Digitalparking.

### 6. Costi

#### Funzioni di base

Per la sosta nei parcheggi a pagamento, all’utente vengono addebitate le tasse di parcheggio in base alle tariffe stabilite dal gestore. Laddove espressamente indicato nell’app, presso alcuni gestori viene richiesto il pagamento di un supplemento per l’utilizzo delle funzioni Parkingpay base.

#### Funzioni aggiuntive

Per l'uso delle funzioni aggiuntive vengono applicati supplementi e costi secondo le tariffe pubblicate su parkingpay.ch.

L'utente è chiaramente informato nell'app Parkingpay di questi supplementi prima e dopo l'attivazione della funzione aggiuntiva corrispondente.

#### Comunicazione

Se l’utilizzo delle funzioni Parkingpay comporta dei costi per le comunicazioni (via Internet o cellulare), questi sono a carico dell’utente.

### 7. Pagamenti arretrati

In caso di mancato pagamento dell’importo dovuto nei termini prescritti o di contestazione di un addebito diretto (LSV+ oppure DebitDirect) il conto Parkingpay viene bloccato immediatamente fino all’avvenuto pagamento degli importi dovuti.

### 8. Errore o utilizzo non corretto del sistema

Se utilizzando la funzione «Parcheggiare con lo smartphone» l’utente non dovesse riuscire ad avviare una procedura di parcheggio, è comunque tenuto a pagare la tassa di parcheggio in modo convenzionale, ossia tramite il parchimetro. Nel caso non ci fossero parchimetri a disposizione, l’utente non ha diritto a parcheggiare. La procedura di parcheggio si considera iniziata solo se si riceve la conferma ufficiale da parte del sistema Parkingpay.

Se l’utente intende terminare una procedura di parcheggio (start/stop) ma il sistema Parkingpay non è disponibile, deve mettersi in contatto immediatamente con l’helpdesk Parkingpay (0848 330 555) per richiedere la chiusura manuale della procedura di parcheggio.

### 9. Contravvenzioni di parcheggio

Se l’utente riceve una contravvenzione di parcheggio, per contestarla deve rivolgersi esclusivamente all’ufficio riportato sulla contravvenzione stessa.

Se la procedura di parcheggio con il sistema Parkingpay è avvenuta correttamente, Digitalparking ne darà conferma all’ufficio corrispondente inviando le ricevute necessarie. Riguardo all’annullamento di una contravvenzione di parcheggio decide esclusivamente l’ufficio competente e Digitalparking non è in nessun caso responsabile per eventuali contravvenzioni che devono essere pagate.

I limiti di durata del parcheggio e i divieti di prolungamento del parcheggio vengono applicati anche in caso di adesione al sistema Parkingpay. Quando viene raggiunto il tempo massimo di sosta, la procedura di parcheggio viene terminata automaticamente.

Dopo la fine di ogni procedura di parcheggio, il veicolo deve lasciare il parcheggio; se questo non avviene e una nuova procedura di parcheggio viene avviata, si può andare in contro a una contravvenzione, nonostante la procedura di parcheggio valida. Questo non si applica ai parcheggi in cui, in conformità con le normative applicabili, è consentito pagare più volte di seguito.

### 10. Responsabilità

Conformemente alle disposizioni di legge, Digitalparking risponde dei danni imputabili a dolo, colpa grave o violazione intenzionale di obblighi contrattuali fondamentali da parte di Digitalparking.

Digitalparking non si assume nessuna responsabilità generale o civile per la costante disponibilità delle funzioni Parkingpay, con particolare riferimento alla disponibilità delle reti di telefonia mobile e al funzionamento dei terminali mobili.

In caso di mancata disponibilità del sistema Parkingpay, Digitalparking non può essere chiamata in causa come responsabile dei costi conseguenti a carico dell’utente, quali multe nel parcheggio, spese di traino ecc.

Digitalparking non si assume alcuna responsabilità per procedure di parcheggio non correttamente terminate o non eseguite. Digitalparking non può garantire che i promemoria (SMS, push, ecc.), relativi a una procedura di parcheggio in corso, vengano recapitati sempre in tempo utile.

### 11. Variazioni o aggiunte alle presenti condizioni

Digitalparking si riserva il diritto di modificare in qualsiasi momento le presenti CG senza necessità di motivare le ragioni. Le eventuali variazioni o aggiunte verranno comunicate all’utente per iscritto o via e-mail.

Le nuove CG vengono considerate accettate se entro un mese l’utente non si oppone ad esse mediante comunicazione scritta.

L’inefficacia di una qualsiasi delle disposizioni contenute nelle presenti CG non comporta l’inefficacia delle altre disposizioni. La disposizione inefficace si considera sostituita da un’altra che più si avvicina al senso e all’obiettivo della disposizione originale da un punto di vista sia giuridico che commerciale. Si procede analogamente in caso di eventuali lacune normative.

Eventuali accordi accessori verbali sono privi di validità giuridica.

### 12. Diritto applicabile e foro competente

Il rapporto contrattuale soggiace al diritto svizzero. Foro competente è Schlieren.

`,
        en: `
### 1. Introduction

Digitalparking AG (hereinafter referred to as "Digitalparking") offers services under the name "Parkingpay" which enable registered and non-registered users (natural or legal persons, hereinafter referred to as "users") to use appropriately equipped parking spaces.

Digitalparking is authorised by operators of car parks connected to the Parkingpay system (municipalities, cities, operators of multi-storey car parks, etc., hereinafter referred to as "operators"), to collect and bill users on their behalf for the fees incurred without the use of cash.

Billing takes place via a Parkingpay account, which is created on completion of a one-time registration process by the user. Users can view online their Parkingpay account at any time.

By using parking spaces with the Parkingpay system, users authorise Digitalparking to charge the parking fee incurred at the applicable rates to their Parkingpay account or via e-payment.

Users undertake himself to pay the fees charged to the Parkingpay account by the selected method of payment. The Parkingpay functions can only be used if users are not in default of payment.

### 2. Registration and cancellation of a Parkingpay account

Users must register once via the Internet, stating their personal details, chosen method of payment and at least one vehicle. They must also agree to these General Terms and Conditions (hereinafter referred to as "GTC") and the separate data privacy statement. Registration is free of charge. There is no right to registration.

Both the user and Digitalparking may cancel a Parkingpay account at any time without notice. Digitalparking is also entitled to block the account at any time, if

* the user violates the GTC or applicable law,
* a direct debit cannot be made,
* the user is in default of payment,
* the user has provided false information, or
* improper use is suspected.

If the Parkingpay account is cancelled, any remaining credit balance will be transferred to the user. Any remaining debit balance is payable by the user within 30 days.

### 3. Contract of use

By agreeing to the GTC during registration, users enter into a contract with Digitalparking on this basis.

Users have neither a right of withdrawal nor a right to return the purchased service.

### 4. Users’ obligation to provide information

Users undertake to make changes to their personal and contractual data in their online account immediately. This obligation refers in particular to any change of billing address, payment method, vehicles license plates and cards.

If a user does not comply with their obligation to provide information, Digitalparking is entitled to charge that user for the additional expenses incurred. If a users fails to disclose a change of address and therefore does not receive legally relevant declarations sent by Digitalparking to that user’s last known address, the declarations shall nevertheless be deemed to have been received.

### 5. Users’ duty of care

The personal login and password specified by users, which can be changed by users at any time, must be kept strictly confidential. The login and password are required by users to view their personal Parkingpay account and must be changed from time to time. If users discovers unauthorised/improper debits to their Parkingpay account, they are obliged to change the password to the account immediately and to delete the cards concerned in this account or to inform Digitalparking immediately so that their Parkingpay account can be blocked. The same applies in case of the loss or theft of a registered card.

In the event of misuse by third parties, users will not be liable for any damages incurred following receipt of the notification by Digitalparking.

### 6. Fees

#### Base functions

For the use of the paid parking spaces, the user is charged the parking fees according to the parking operator's tariffs. If expressly indicated in the app, a surcharge for Parkingpay base functions is charged at certain operators.

#### Additional functions

For the use of the additional functions, surcharges and costs are levied according to the rates published on parkingpay.ch.

The user will be clearly informed of these surcharges in the Parkingpay app before and after activation of the corresponding additional function.

#### Communication

Any communication costs (Internet and mobile phone fees) incurred in the use of the Parkingpay functions must be borne by the user.

### 7. Outstanding payments

If outstanding amounts are not paid on time or if a debit is rejected or revoked (LSV+ or DebitDirect), the corresponding Parkingpay account will be blocked immediately until the accrued fees have been paid.

### 8. Failure or incorrect use of the system

If users are unable to start a parking procedure when using the "Park with smartphone" function, they are obliged to pay the parking fee at a parking meter in the conventional way. If there is no parking meter, users are not allowed to park. The parking procedure is considered to have started if the Parkingpay system expressly confirms this.

If users want to stop the parking procedure during the start/stop mode and the Parkingpay system is not available, users must contact the Parkingpay Helpdesk (0848 330 555) immediately so that the parking procedure can be stopped manually.

### 9. Parking fines

If users receive a parking fine which they wish to contest, they must contact the office listed on the relevant receipt.

If it can be proven that a correct parking procedure with the Parkingpay system took place, Digitalparking will confirm this to the appropriate authority, providing documentary evidence. The competent authority is solely responsible for deciding on the cancellation of any parking fine. Digitalparking is in no case liable for parking fines.

Maximum parking times and payment restrictions apply in each case, even when using the Parkingpay system. When the maximum parking time is reached, the parking procedure is automatically terminated.

At the end of each parking procedure, the vehicle must leave the parking; if this is not the case and a new parking procedure is started, a parking fine must be expected despite the valid parking procedure. This does not apply to parking spaces where additional payment is permitted in accordance with the applicable regulations.

### 10. Liability

Digitalparking is liable by law for damage that is demonstrably caused by intent, gross negligence or the culpable violation of materail contract obligations on the part of Digitalparking.

Digitalparking assumes no responsibility or liability for the constant availability of the Parkingpay functions, in particular with regard to the availability of mobile radio networks and the operability of mobile terminals.

If the Parkingpay system is not available, Digitalparking cannot be held responsible for the resulting costs, such as parking fines, towing charges, etc.

Digitalparking accepts no liability for faulty or failure to complete parking procedures. Digitalparking cannot guarantee that reminder messages (SMS, push, etc.) for an ongoing parking procedure will always be delivered on time.

### 11. Amendments or additions to these terms and conditions

Digitalparking reserves the right to change the GTC at any time and without stating reasons.

Amendments or additions will be notified to users in writing or by e-mail.

Newly received GTC are deemed approved if Parkingpay users do not declare their objection in writing within one month.

If a provision of these GTC is ineffective, the remaining provisions remain unaffected. The ineffective provision shall be deemed replaced by one that comes closest to the meaning and purpose of the ineffective provision in a legally effective manner. The same applies to any loopholes.

Side agreements are not valid.

### 12. Applicable law and place of jurisdiction

The contractual relationship is subject to Swiss law. The place of jurisdiction is Schlieren.
`,
    };

    export const privacyPolicyMarkdown: { [key in Language]: string } = {
        de: `
# DATENSCHUTZERKLÄRUNG

## CLOUD-PLATFORM
## Version 10.2023

### 1. Allgemeines

Datenschutz und Datensicherheit haben für uns eine sehr hohe Priorität. Wir haben deshalb umfangreiche technische und organisatorische Massnahmen im Rahmen der datenschutzrechtlichen Vorschriften getroffen, die den Schutz ihrer personenbezogenen Daten sicherstellen. Wir stellen Ihnen Angebote über verschiedene Zugangswege (z.B. Website und Smartphone Applikation) bereit (zusammen die „Plattform“ genannt).

In dieser Datenschutzerklärung informieren wir Sie über die Verwendung Ihrer persönlichen Daten sowie über die Verwendung von Cookies oder ähnliche Technologien auf unserer Plattform.

Die Erhebung und Verarbeitung Ihrer personenbezogenen Daten geschieht unter Beachtung der geltenden datenschutzrechtlichen Vorschriften, insbesondere des Schweizer Datenschutzgesetzes (DSG) und der Europäischen Datenschutzgrundverordnung (DSGVO), insoweit diese im konkreten Fall auf die Datenverarbeitung Anwendung findet.

### 2. Verantwortlicher Anbieter

Verantwortlicher Anbieter der Plattform und der im Zusammenhang mit Ihrer Plattformnutzung erhobenen und verarbeiteten personenbezogenen Daten ist die Digitalparking AG, Rütistrasse 13, CH-8952 Schlieren.

### 3. Erhebung, Verarbeitung und Nutzung Ihrer personenbezogenen Daten

Der Umfang und die Art der Erhebung, Verarbeitung und Nutzung Ihrer personenbezogenen Daten unterscheidet sich danach, ob Sie unsere Plattform nur besuchen und informatorisch nutzen oder auch über unsere Plattform mit uns korrespondieren, bzw. diese aktiv als Benutzer der Parkingpay Web- oder Smartphone-Applikation (nachfolgend „Parkingpay“), der Parkingabo Web- oder Smartphone-Applikation (nachfolgend „Parkingabo“), der TWINT-App (nachfolgend „TWINT“) oder eines Smart-Device verwenden.

#### a) Lediglich informatorische Nutzung

Für die nur informatorische Nutzung unserer Plattform ist es grundsätzlich nicht erforderlich, dass Sie personenbezogene Daten angeben. Vielmehr erheben und verwenden wir in diesem Fall nur diejenigen Ihrer Daten, die uns Ihr Internetbrowser automatisch übermittelt (insbesondere Ihre IP-Adresse). Diese Informationen werden – in nicht anonymisierter Form – lediglich für die Dauer Ihres Plattform-Besuchs gespeichert und eine Auswertung erfolgt im Nachhinein in anonymisierter Form lediglich zu statistischen Zwecken. Es werden daher keine personenbezogenen Daten über Sie erhoben.

#### b) Aktive Nutzung der Plattform / Parkuhren mit Kennzeicheneingaben

Bei der Nutzung einer solchen Parkuhr müssen Sie zwingend Ihr Kennzeichen eingeben.

Der Betreiber der von Ihnen genutzten Parkplätze erhält Zugriff auf ihre Transaktionen; letztere enthalten die folgenden Daten:

* Kennzeichen
* Parkuhrnummer
* Parkzeit und Parkgebühr

#### c) Aktive Nutzung der Plattform / Parkingpay

Sofern Sie die Plattform aktiv nutzen und sich als Benutzer registrieren, werden wir die von Ihnen im Rahmen der Registrierung zwingend anzugebenden Daten speichern und verarbeiten, um Ihnen die Buchung und Bezahlung von kostenpflichtigen Parkplätzen über Parkingpay zu ermöglichen.

Es handelt sich hierbei um folgende Daten:

* E-Mail Adresse
* Name und Vorname
* Firmenname (nur Firmenkunden)
* Adresse
* Kennzeichen

Wir erheben, verarbeiten und nutzen die von Ihnen zur Verfügung gestellten personenbezogenen Daten soweit es für die Begründung, Durchführung oder Beendigung eines solchen rechtsgeschäftlichen oder rechtsgeschäftsähnlichen Vertrages mit Ihnen oder zwischen Ihnen und dem Betreiber der von Ihnen genutzten Parkplätze erforderlich ist. Wir dürfen Ihre personenbezogenen Daten zum Zwecke der Begründung, Durchführung und Abwicklung des Vertragsverhältnisses mit uns und zwischen Ihnen und dem Betreiber der von Ihnen genutzten Parkplätze erheben, verarbeiten und nutzen.

Der Betreiber der von Ihnen genutzten Parkplätze erhält Zugriff auf ihre Transaktionen; letztere enthalten die folgenden Daten:

* Kennzeichen, Badgenummer, QR-Code-Referenz oder Ticketnummer
* Kontoinhaber (Vorname Nachname oder Firmenname)
* Zone (evt. Parkuhr und Parkplatznummer) oder Parking
* Parkzeit und Parkgebühr

Die Betreiber haben keine Einsicht in oder Zugriff auf Ihr Benutzerkonto; sie dürfen lediglich Inhaber, Zahlungsweise und Saldo (nur bei Vorauszahlung) in einem dedizierten Betreiberportal abfragen.
Ausserdem speichern wir als Teil Ihres Benutzerkontos bei jeder Aktion folgende Daten:

* Datum / Zeit
* Ereignistyp
* Erhalten bzw. übermittelte Daten

Die Daten werden nur bei Support-Fällen verwendet und werden nicht systematisch ausgewertet. Die Betreiber haben keine Einsicht in diesen Daten. Diese Daten werden 3 Monate aufbewahrt und dann automatisch gelöscht.

#### d) Aktive Nutzung der Plattform / TWINT

Sofern Sie die Plattform via die TWINT-App aktiv nutzen, werden wir die von Ihnen im Rahmen der ersten Nutzung angebenden Daten in einem anonymen Benutzerkonto speichern und verarbeiten, um Ihnen die Buchung und Bezahlung von kostenpflichtigen Parkplätzen über Parkingpay zu ermöglichen.

Es handelt sich hierbei um folgende Daten:

* Kennzeichen
* Referenz zum TWINT-Konto (pseudonymisierte IDs)

Wir erheben, verarbeiten und nutzen die von Ihnen zur Verfügung gestellten personenbezogenen Daten soweit es für die Begründung, Durchführung oder Beendigung eines solchen rechtsgeschäftlichen oder rechtsgeschäftsähnlichen Vertrages mit Ihnen oder zwischen Ihnen und dem Betreiber der von Ihnen genutzten Parkplätze erforderlich ist. Wir dürfen Ihre personenbezogenen Daten zum Zwecke der Begründung, Durchführung und Abwicklung des Vertragsverhältnisses mit uns und zwischen Ihnen und dem Betreiber der von Ihnen genutzten Parkplätze erheben, verarbeiten und nutzen.

Der Betreiber der von Ihnen genutzten Parkplätze erhält Zugriff auf ihre Transaktionen; letztere enthalten die folgenden Daten:

* Kennzeichen, QR-Code-Referenz oder Ticketnummer
* Zone (evt. Parkuhr und Parkplatznummer) oder Parking
* Parkzeit und Parkgebühr

Die Betreiber haben keine Einsicht in oder Zugriff auf Ihr Benutzerkonto.
Ausserdem speichern wir als Teil Ihres Benutzerkontos bei jeder Aktion auf unsere Platform folgende Daten:

* Datum / Zeit
* Ereignistyp
* Erhalten bzw. übermittelte Daten

Die Daten werden nur bei Support-Fällen verwendet und werden nicht systematisch ausgewertet. Die Betreiber haben keine Einsicht in diesen Daten. Diese Daten werden 3 Monate aufbewahrt und dann automatisch gelöscht.

#### e) Aktive Nutzung der Plattform / Parkingabo

Sofern Sie die Plattform aktiv nutzen und sich als Benutzer registrieren, werden wir die von Ihnen im Rahmen der Registrierung zwingend anzugebenden Daten speichern und verarbeiten, um Ihnen die Buchung und ggf. Bezahlung von den Parkplätzen über Parkingabo zu ermöglichen.

Es handelt sich hierbei um folgende Daten:

* E-Mail Adresse
* Name und Vorname
* Firmenname (nur Firmenkunden)
* Adresse
* Fahrzeuge (Kennzeichen, Badge und QR-Code)
* Referenz zum Zahlungsmittel (pseudonymisierte IDs)
* betreiberspezifische Referenzen

Wir erheben, verarbeiten und nutzen die von Ihnen zur Verfügung gestellten personenbezogenen Daten soweit es für die Begründung, Durchführung oder Beendigung eines solchen rechtsgeschäftlichen oder rechtsgeschäftsähnlichen Vertrages mit Ihnen oder zwischen Ihnen und dem Betreiber der von Ihnen genutzten Parkplätze erforderlich ist. Wir dürfen Ihre personenbezogenen Daten zum Zwecke der Begründung, Durchführung und Abwicklung des Vertragsverhältnisses mit uns und zwischen Ihnen und dem Betreiber der von Ihnen genutzten Parkplätze erheben, verarbeiten und nutzen.

Der Betreiber der von Ihnen genutzten Parkplätze erhält Zugriff auf ihre Transaktionen; letztere enthalten die folgenden Daten:

* Kennzeichen, Badgenummer oder QR-Code-Referenz
* Kontoinhaber (Vorname Nachname oder Firmenname)
* Zone oder Parking
* Parkzeit und Parkgebühr

Der Betreiber hat volle Einsicht auf Ihr Benutzerkonto und darf die Daten anpassen, mit Ausnahme der Zugangsdaten und Zahlungsmittel; beim Zahlungsmittel kann er lediglich sehen, ob es vorhanden und gültig ist, aber nicht die Details einsehen oder ändern.

Alle Daten im Benutzerkonto, mit der einzigen Ausnahme von Zugangsdaten, sind betriebsspezifisch und daher nur für den jeweiligen Betreiber zugänglich.
Ausserdem speichern wir als Teil Ihres Benutzerkontos bei jeder Aktion folgende Daten:

* Datum / Zeit
* Ereignistyp
* erhaltene bzw. übermittelte Daten

Die Daten werden nur bei Support-Fällen verwendet und werden nicht systematisch ausgewertet. Die Betreiber haben keine Einsicht in diesen Daten. Diese Daten werden 3 Monate aufbewahrt und dann automatisch gelöscht.

#### f) Aktive Nutzung der Plattform / Online-Shop ohne Registrierung

Sofern Sie die Plattform via den Online-Shop aktiv nutzen, werden wir die von Ihnen im Rahmen der Nutzung angegebenen Daten in einem anonymen Konto speichern und verarbeiten, um Ihnen die Buchung und Bezahlung von kostenpflichtigen Parkplätzen über Parkingpay zu ermöglichen.

Es handelt sich hierbei um folgende Daten:

* Referenz zum Gerät (pseudonymisierte IDs)
* Referenz zum Zahlungsmittel (pseudonymisierte IDs)
* E-Mail-Adresse (optional)

Nach der erfolgreichen Ausführung der Zahlung wird das Zahlungsmittel sofort gelöscht.
Bei nicht ausgeführten Zahlungen wird das Zahlungsmittel spätestens nach 7 Tagen gelöscht.

Wir erheben, verarbeiten und nutzen die von Ihnen zur Verfügung gestellten personenbezogenen Daten soweit es für die Begründung, Durchführung oder Beendigung eines solchen rechtsgeschäftlichen oder rechtsgeschäftsähnlichen Vertrages mit Ihnen oder zwischen Ihnen und dem Betreiber der von Ihnen genutzten Parkplätze erforderlich ist. Wir dürfen Ihre personenbezogenen Daten zum Zwecke der Begründung, Durchführung und Abwicklung des Vertragsverhältnisses mit uns und zwischen Ihnen und dem Betreiber der von Ihnen genutzten Parkplätze erheben, verarbeiten und nutzen.

Der Betreiber der von Ihnen benutzten Parkplätze erhält Zugriff auf Ihre Transaktionen; letztere enthalten die folgenden Daten:

* Ticketnummer, Kennzeichen oder QR-Code-Referenz
* Zone (evt. Parkuhr und Parkplatznummer) oder Parking
* Parkzeit und Parkgebühr

Der Betreiber hat keine Einsicht in oder Zugriff auf das anonyme Konto.

Ausserdem speichern wir als Teil des anonymen Kontos bei jeder Aktion folgende Daten:

* Datum / Zeit
* Ereignistyp
* erhaltene bzw. übermittelte Daten

Die Daten werden nur bei Support-Fällen verwendet und werden nicht systematisch ausgewertet. Die Betreiber haben keine Einsicht in diesen Daten. Diese Daten werden 3 Monate aufbewahrt und dann automatisch gelöscht.

#### Unpersönliche Zugriffsdaten der Plattform

Wir sammeln Informationen über Sie, wenn Sie die Plattform nutzen. Wir erfassen automatisch Informationen über Ihr Nutzungsverhalten und Ihre Interaktion mit uns und registrieren Daten zu Ihrem Computer oder Mobilgerät. Wir erheben, speichern und nutzen Daten über jeden Zugriff auf unsere Plattform (sogenannte Serverlogfiles). Zu den Zugriffsdaten gehören:

* Name und URL der abgerufenen Datei
* Datum und Uhrzeit des Abrufs
* übertragene Datenmenge
* Meldung über erfolgreichen Abruf (HTTP response code)
* Browsertyp und Browserversion
* Betriebssystem
* Referer URL (d.h. die zuvor besuchte Seite)
* Websites, die vom System des Nutzers über unsere Website aufgerufen werden
* Internet-Service-Provider des Nutzers
* IP-Adresse und der anfragende Provider

Wir nutzen diese Protokolldaten ohne Zuordnung zu Ihrer Person oder sonstiger Profilerstellung für statistische Auswertungen zum Zweck des Betriebs, der Sicherheit und der Optimierung unserer Website, aber auch zur anonymen Erfassung der Anzahl der Besucher auf unserer Website (traffic) sowie zum Umfang und zur Art der Nutzung unserer Website und Dienste. Aufgrund dieser Informationen können wir den Datenverkehr analysieren, Fehler suchen und beheben und unsere Dienste verbessern. Im Anwendungsbereich der DSGVO liegt hierin auch unser berechtigtes Interesse gemäss Art 6 Abs. 1 S. 1 f) DSGVO.

Wir behalten uns vor, die Protokolldaten nachträglich zu überprüfen, wenn aufgrund konkreter Anhaltspunkte der berechtigte Verdacht einer rechtswidrigen Nutzung besteht. Nach einem Monat löschen wir die Logfiles, wenn diese für Sicherheitszwecke nicht mehr erforderlich ist und wenn wir keinen konkreten Verdacht einer Straftat im Zusammenhang mit der Nutzung unserer Website haben.

### 4. Ihre Rechte

Nach den anwendbaren Gesetzen haben Sie verschiedene Rechte bezüglich Ihrer personenbezogenen Daten. Möchten Sie diese Rechte geltend machen, so richten Sie Ihre Anfrage bitte per E-Mail oder per Post unter eindeutiger Identifizierung Ihrer Person an die in Ziffer 8 genannte Adresse.

Nachfolgend finden Sie eine Übersicht über Ihre Rechte.

### 4.1. Recht auf Bestätigung und Auskunft

Sie haben das Recht auf eine übersichtliche Auskunft über die Verarbeitung Ihrer personenbezogenen Daten. Entsprechend haben Sie jederzeit das Recht, von uns eine Bestätigung darüber zu erhalten, ob Sie betreffende personenbezogene Daten verarbeitet werden. Ist dies der Fall, so haben Sie das Recht, von uns eine unentgeltliche Auskunft über die zu Ihnen gespeicherten personenbezogenen Daten nebst einer Kopie dieser Daten zu verlangen. Des Weiteren besteht ein Recht auf folgende Informationen:

* die Verarbeitungszwecke;
* die Kategorien personenbezogener Daten, die verarbeitet werden;
* die Empfänger oder Kategorien von Empfängern, gegenüber denen die personenbezogenen Daten offengelegt worden sind oder noch offengelegt werden, insbesondere bei Empfängern in Drittländern oder bei internationalen Organisationen;
* falls möglich, die geplante Dauer, für die die personenbezogenen Daten gespeichert werden, oder, falls dies nicht möglich ist, die Kriterien für die Festlegung dieser Dauer;
* das Bestehen eines Rechts auf Berichtigung oder Löschung der Sie betreffenden personenbezogenen Daten oder auf Einschränkung der Verarbeitung durch den Verantwortlichen oder eines Widerspruchsrechts gegen diese Verarbeitung;
* das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde;
* wenn die personenbezogenen Daten nicht bei Ihnen erhoben werden, alle verfügbaren Informationen über die Herkunft der Daten;
* das Bestehen einer automatisierten Entscheidungsfindung einschliesslich Profiling gemäss Art. 22 Abs. 1 und 4 DSGVO und – zumindest in diesen Fällen – aussagekräftige Informationen über die involvierte Logik sowie die Tragweite und die angestrebten Auswirkungen einer derartigen Verarbeitung für Sie.
* Werden personenbezogene Daten an ein Drittland oder an eine internationale Organisation übermittelt, so haben Sie das Recht, über die geeigneten Garantien gemäss Art. 46 DSGVO im Zusammenhang mit der Übermittlung unterrichtet zu werden.

### 4.2. Recht auf Berichtigung

Sie haben das Recht, von uns die Berichtigung und ggf. auch Vervollständigung Sie betreffender personenbezogener Daten zu verlangen. Entsprechend haben Sie das Recht, von uns unverzüglich die Berichtigung Sie betreffender unrichtiger personenbezogener Daten zu verlangen. Unter Berücksichtigung der Zwecke der Verarbeitung haben Sie das Recht, die Vervollständigung unvollständiger personenbezogener Daten – auch mittels einer ergänzenden Erklärung – zu verlangen.

Sie können die in Ihrem Benutzerkonto gespeicherten personenbezogenen Daten zudem jederzeit direkt bearbeiten.

### 4.3. Recht auf Löschung ("Recht auf Vergessenwerden")

In einer Reihe von Fällen sind wir verpflichtet, Sie betreffende personenbezogene Daten zu löschen. Entsprechend haben Sie insbesondere im Anwendungsbereich gemäss Art. 17 Abs. 1 DSGVO das Recht, von uns zu verlangen, dass Sie betreffende personenbezogene Daten unverzüglich gelöscht werden, und wir sind verpflichtet, personenbezogene Daten unverzüglich zu löschen, sofern einer der folgenden Gründe zutrifft:

* Die personenbezogenen Daten sind für die Zwecke, für die sie erhoben oder auf sonstige Weise verarbeitet wurden, nicht mehr notwendig.
* Sie widerrufen Ihre Einwilligung, auf die sich die Verarbeitung stützte, und es fehlt an einer anderweitigen Rechtsgrundlage für die Verarbeitung.
* Sie legen gemäss Art. 21 Abs. 1 DSGVO Widerspruch gegen die Verarbeitung ein und es liegen keine vorrangigen berechtigten Gründe für die Verarbeitung vor, oder Sie legen gemäss Art. 21 Abs. 2 DSGVO Widerspruch gegen die Verarbeitung ein.
* Die personenbezogenen Daten wurden unrechtmässig verarbeitet.
* Die Löschung der personenbezogenen Daten ist zur Erfüllung einer rechtlichen Verpflichtung der wir unterliegen.
* Die personenbezogenen Daten wurden in Bezug auf angebotene Dienste der Informationsgesellschaft gemäss Art. 8 Abs. 1 DSGVO erhoben.

Sie können die in Ihrem Benutzerkonto gespeicherten personenbezogenen Daten sowie das Konto als Ganzes jederzeit löschen.

### 4.4. Recht auf Einschränkung der Verarbeitung

In einer Reihe von Fällen sind Sie berechtigt, von uns eine Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Entsprechend haben Sie das Recht, von uns die Einschränkung der Verarbeitung zu verlangen, wenn eine der folgenden Voraussetzungen gegeben ist:

* die Richtigkeit der personenbezogenen Daten wird von Ihnen bestritten, und zwar für eine Dauer, die es uns ermöglicht, die Richtigkeit der personenbezogenen Daten zu überprüfen,
* die Verarbeitung unrechtmässig ist und Sie die Löschung der personenbezogenen Daten ablehnten und stattdessen die Einschränkung der Nutzung der personenbezogenen Daten verlangt haben;
* wir die personenbezogenen Daten für die Zwecke der Verarbeitung nicht länger benötigen, Sie die Daten jedoch zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen, oder
* Sie Widerspruch gegen die Verarbeitung gemäss Art. 21 Abs. 1 DSGVO eingelegt haben, solange noch nicht feststeht, ob die berechtigten Gründe unseres Unternehmens gegenüber den Ihren überwiegen.

### 4.5. Recht auf Datenübertragbarkeit

Jede von der Bearbeitung von Personendaten betroffene Person hat das Recht, die sie betreffenden Personendaten in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten. Darüber hinaus hat die betroffene Person das Recht, zu erwirken, dass die Personendaten direkt von einem für die Bearbeitung Verantwortlichen an einen anderen für die Bearbeitung Verantwortlichen übermittelt werden, sofern dies technisch machbar ist und sofern dadurch nicht die Rechte und Freiheiten anderer Personen beeinträchtigt werden.

Um das Recht auf Datenübertragbarkeit geltend zu machen, können Sie Ihre Anfrage per E-Mail oder per Post, unter eindeutiger Identifizierung Ihrer Person, an die in Ziffer 11 genannte Adresse schicken.

### 4.6. Widerspruchsrecht

Jede von der Bearbeitung von Personendaten betroffene Person hat das Recht, aus Gründen, die sich aus ihrer besonderen Situation ergeben, jederzeit gegen die Bearbeitung sie betreffender Personendaten Widerspruch einzulegen.

Digitalparking wird die Personendaten im Falle des Widerspruchs nicht mehr bearbeiten, es sei denn, wir können zwingende schutzwürdige Gründe für die Bearbeitung nachweisen, die die Interessen, Rechte und Freiheiten der betroffenen Person überwiegen, oder die Bearbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.

Um von Ihrem Widerspruchsrecht Gebrauch zu machen, können Sie Ihre Anfrage per E-Mail oder per Post, unter eindeutiger Identifizierung Ihrer Person, an die in Ziffer 11 genannte Adresse schicken.

### 4.7. Recht auf Widerruf einer datenschutzrechtlichen Einwilligung

Sie haben das Recht, eine von Ihnen erteilte Einwilligung zur Verarbeitung personenbezogener Daten jederzeit zu widerrufen.

###  4.8. Recht auf Beschwerde bei einer Aufsichtsbehörde

Sie haben das Recht auf Beschwerde bei einer Aufsichtsbehörde, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten rechtswidrig ist. In der Schweiz können Sie sich an den Eidgenössischen Datenschutz- und Öffentlichkeitsbeauftragten wenden (www.edoeb.admin.ch) und ausserhalb der Schweiz an die für Ihre Sie aufgrund Ihres Wohnsitzes oder Aufenthaltsortes zuständige Aufsichtsbehörde.

### 5. Externe Dienstleister / Datentransfer ins Ausland

Grundsätzlich verwenden wir Ihre personenbezogenen Daten nur innerhalb unseres Unternehmens. Wenn und soweit wir Dritte im Rahmen der Erfüllung von Verträgen einschalten (etwa Logistik-Dienstleister), erhalten diese personenbezogene Daten nur in dem Umfang, in welchem die Übermittlung für die entsprechende Leistung erforderlich ist.

Für den Fall, dass wir bestimmte Teile der Datenverarbeitung auslagern („Auftragsverarbeitung“), verpflichten wir Auftragsverarbeiter vertraglich dazu, personenbezogene Daten nur im Einklang mit den Anforderungen der Datenschutzgesetze zu verwenden und den Schutz der Rechte der betroffenen Person zu gewährleisten. Eine Datenübertragung an Stellen oder Personen ausserhalb der Schweiz bzw. EU ausserhalb der in dieser Erklärung genannten Fälle findet nicht statt.

### 5.1. Hosting

Die von uns in Anspruch genommenen Hosting-Leistungen dienen der Zurverfügungstellung der folgenden Leistungen: Infrastruktur- und Plattformdienstleistungen, Rechenkapazität, Speicherplatz und Datenbankdienste, Sicherheitsleistungen sowie technische Wartungsleistungen, die wir zum Zweck des Betriebs der Website einsetzen.

Hierbei verarbeiten wir, bzw. unser Hostinganbieter Bestandsdaten, Kontaktdaten, Inhaltsdaten, Vertragsdaten, Nutzungsdaten, Meta- und Kommunikationsdaten von Kunden, Interessenten und Besuchern dieser Website auf Grundlage unserer berechtigten Interessen an einer effizienten und sicheren Zurverfügungstellung unserer Website (im Anwendungsbereich der DSGVO massgebliche Bestimmungen sind die Art. 6 Abs. 1 S. 1 f) DSGVO i.V.m. Art. 28 DSGVO).

### 5.2. Payment – Dienstleister

Zur Realisierung der Bezahlvorgänge setzen wir den Payment-Dienstleister Datatrans AG und TWINT AG ein. Wir haben mit Datatrans AG einen Auftragsverarbeitervertrag nach Art. 9 DSG und Art. 28 DSGVO.

### 6. Cookies und Local Storage

Ein Cookie ist eine kleine Textdatei, die verschiedene Informationen beinhaltet und auf Ihrem Endgerät durch dem Web-Browser abgelegt wird.

Den Local Storage (HTML5 Local Storage) ist eine kleine Datenbank, die verschiedene Informationen beinhaltet und auf Ihrem Endgerät im Web-Browser integriert ist.

#### a) Lediglich informatorische Nutzung

Wir verwenden weder die Cookies noch den Local Storage.

#### b) Aktive Nutzung der Plattform / Parkingpay

Wir verwenden die Cookies und die Local Storage wenn Sie sich in unsere Webapplikationen einloggen.

Die Cookies werden benutzt, um die verschiedenen Anfragen Ihres Browsers der gemeinsamen authentifizierten Sitzung zuzuordnen und somit Ihr Konto vor unbefugtem Zugriff zu schützen. Diese Cookies werden auf Ihrer Festplatte gespeichert und löschen sich nach der vorgegebenen Zeit automatisch. Ihre Lebensdauer beträgt entweder 1 Tag („Login speichern“ inaktiv) oder 90 Tage („Login speichern“ aktiv).

In den Cookies werden etwa folgende Daten und Informationen gespeichert:

* Session-Identifikation (pseudonymisierte IDs)

Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies vorab informiert werden und im Einzelfall entscheiden können, ob Sie die Annahme von Cookies für bestimmte Fälle oder generell ausschliessen, oder dass Cookies komplett verhindert werden. Dadurch kann die Funktionalität der Webapplikation eingeschränkt werden.

Der Local Storage wird benutzt um die Benutzerfreundlichkeit der Applikation zu erhöhen. Die Informationen im Local Storage werden nie automatisch gelöscht.

Im Local Storage werden folgende Daten und Informationen gespeichert:

* Sprachcode
* Letzte ausgewähltes Optionen (pseudonymisierte IDs)
* Letzte ausgewählte PLZ

Sie können Ihren Browser so einstellen, dass es Local Storage Informationen komplett verhindert werden. Dadurch kann die Funktionalität der Webapplikation eingeschränkt werden.

Unser berechtigtes Interesse an der Nutzung der Cookies und des Local Storage gemäss Art 6 Abs. 1 S. 1 f) DSGVO liegt darin, unsere Webapplikationen nutzerfreundlicher, effektiver und sicherer zu machen.

### 7. Speicherdauer

Sofern nicht spezifisch angegeben speichern wir personenbezogene Daten nur so lange, wie dies zur Erfüllung der verfolgten Zwecke notwendig ist.

In einigen Fällen sieht der Gesetzgeber die Aufbewahrung von personenbezogenen Daten vor, etwa im Steuer- oder Handelsrecht. In diesen Fällen werden die Daten von uns lediglich für diese gesetzlichen Zwecke weiter gespeichert, aber nicht anderweitig verarbeitet und nach Ablauf der gesetzlichen Aufbewahrungsfrist gelöscht.

### 8. Datensicherheit

Wir sind um die Sicherheit Ihrer Daten im Rahmen der geltenden Datenschutzgesetze und technischen Möglichkeiten maximal bemüht.

Ihre persönlichen Daten werden bei uns verschlüsselt mit dem Codierungssystem SSL (Secure Socket Layer) übertragen. Dies gilt für Ihre Stammdaten, Ihre Transaktionen und auch für das Kundenlogin. Wir weisen jedoch darauf hin, dass die Datenübertragung im Internet Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.

Zur Sicherung Ihrer Daten unterhalten wir technische und organisatorische Sicherungsmassnahmen entsprechend Art. 32 DSGVO, die wir immer wieder dem Stand der Technik anpassen.

Wir gewährleisten ausserdem nicht, dass unser Angebot zu bestimmten Zeiten zur Verfügung steht; Störungen, Unterbrechungen oder Ausfälle können nicht ausgeschlossen werden. Die von uns verwendeten Server werden regelmässig sorgfältig gesichert.

### 9. Urheberrecht

Das Urheberrecht und alle anderen Rechte an den Inhalten, Bildern, Fotos oder sonstigen Dateien auf der Plattform gehören ausschliesslich dem Betreiber dieser Plattform oder den namentlich genannten Rechteinhabern. Für die Vervielfältigung sämtlicher Dateien muss vorab die schriftliche Zustimmung der Urheberrechtsinhaber eingeholt werden.

Wer ohne Zustimmung der jeweiligen Urheberrechtsinhaber eine Urheberrechtsverletzung begeht, kann sich strafbar machen und unter Umständen Schadenersatzansprüche geltend machen.

### 10. Haftungsausschluss

Alle Angaben auf unserer Plattform wurden sorgfältig geprüft. Wir sind bemüht, dafür Sorge zu tragen, dass die von uns bereitgestellten Informationen aktuell, richtig und vollständig sind. Dennoch ist das Auftreten von Fehlern nicht völlig auszuschliessen, so dass wir für die Vollständigkeit, Richtigkeit und Aktualität der Informationen keine Gewähr übernehmen können. Haftungsansprüche, die sich auf Schäden materieller oder ideeller Art beziehen, welche durch die Nutzung oder Nichtnutzung der dargebotenen Informationen bzw. durch die Nutzung fehlerhafter und unvollständiger Informationen verursacht wurden, sind grundsätzlich ausgeschlossen.

Der Herausgeber kann Texte nach eigenem Ermessen und ohne vorherige Ankündigung ändern oder löschen und ist nicht dazu verpflichtet, die Inhalte dieser Plattform zu aktualisieren. Die Nutzung dieser Plattform bzw. der Zugang zu ihr erfolgt auf eigenes Risiko des Besuchers. Der Herausgeber, seine Kunden oder Partner sind nicht verantwortlich für Schäden, wie z.B. direkte, indirekte, zufällige oder Folgeschäden, die angeblich durch den Besuch dieser Plattform verursacht wurden und übernehmen folglich keine Haftung für solche Schäden.

Der Herausgeber übernimmt auch keine Verantwortung oder Haftung für den Inhalt und die Verfügbarkeit von Websites Dritter, die über externe Links von dieser Plattform aus erreicht werden können. Für den Inhalt der verlinkten Seiten sind ausschliesslich deren Betreiber verantwortlich. Der Herausgeber distanziert sich daher ausdrücklich von allen fremden Inhalten, die möglicherweise straf- oder haftungsrechtlich relevant sind oder gegen die guten Sitten verstossen.

### 11. Ansprechpartner

Bei Fragen zur Erhebung, Verarbeitung oder Nutzung Ihrer personenbezogenen Daten, bei Auskünften, Berichtigung, Sperrung oder Löschung von personenbezogenen Daten sowie Widerruf von Einwilligungen oder Widerspruch der Bearbeitung wenden Sie sich an:

Postadresse: Digitalparking AG, Rütistrasse 13, 8952 Schlieren

E-Mail Adresse: <privacy@digitalparking.ch>

### 12. Änderungen

Die Digitalparking AG kann diese Datenschutzerklärung jederzeit ohne vorherige Ankündigung ändern. Es gilt die jeweils aktuelle, auf unserer Plattform veröffentlichte Fassung. Soweit die Datenschutzerklärung Teil einer Vereinbarung mit Ihnen ist, werden wir Sie im Falle einer Aktualisierung per E-Mail oder auf andere geeignete Weise über die Änderung informieren.

`,
        fr: `
# DÉCLARATION SUR LA PROTECTION DES DONNÉES

## PLATEFORME CLOUD
## Version 10.2023

### 1. Généralités

La protection des données et leur sécurité sont pour nous une priorité. C’est pourquoi nous avons pris, dans le cadre des prescriptions légales sur la protection des données, de vastes mesures techniques et organisationnelles qui garantissent la protection de vos données à caractère personnel. Nous vous soumettons des offres par différents canaux (p. ex. site internet et application smartphone, ci-après collectivement: la «plateforme»).

Dans la présente déclaration sur la protection des données, nous vous informons sur l’utilisation de vos données personnelles ainsi que sur les cookies et les technologies similaires qui sont utilisés sur notre plateforme.

La collecte et le traitement de vos données à caractère personnel ont lieu dans le respect des prescriptions légales en matière de protection des données, en particulier de la loi suisse sur la protection des données (LPD) et du Règlement général européen sur la protection des données (RGPD), dans la mesure où ils s’appliquent respectivement aux différents cas concrets.

### 2. Fournisseur responsable

Le fournisseur responsable de la plateforme et des données à caractère personnel collectées et traitées dans le cadre de votre utilisation de la plateforme est la société Digitalparking SA, Rütistrasse 13, CH-8952 Schlieren.

### 3. Collecte, traitement et utilisation de vos données à caractère personnel

Les données à caractère personnel vous concernant que nous collectons, traitons et utilisons diffèrent dans leur quantité et leur genre selon que vous ne faites que visiter notre plateforme et l’utiliser à titre informatif ou que vous correspondez avec nous via notre plateforme, c'est-à-dire l’utilisez de manière active, en tant qu’utilisateur de l’application web ou smartphone Parkingpay (ci-après: «Parkingpay»), de l’application web ou smartphone Parkingabo (ci-après: «Parkingabo»), de l’appli TWINT (ci-après: „TWINT“) ou d’un appareil connecté.

#### a) Utilisation à titre purement informatif

Pour une utilisation de notre plateforme à titre purement informatif, il n’est pas nécessaire que vous fournissiez des données à caractère personnel. Dans ce cas, plus exactement, nous collectons uniquement celles de vos données que votre navigateur Internet nous transmet automatiquement (en particulier votre adresse IP). Ces informations sont enregistrées – sous forme non anonymisée – uniquement pendant la durée de votre visite sur la plateforme, et elles ne sont exploitées ensuite sous forme anonymisée qu’à des fins de statistiques. Aucune donnée à caractère personnel n’est donc collectée à votre sujet.

#### b) Utilisation active de la plateforme: parcomètres avec saisie de la plaque

Lorsque vous utilisez un tel parcomètre, vous devez obligatoirement saisir votre numéro d’immatriculation.

L'exploitant du parking que vous utilisez a accès à ses transactions; ces dernières contiennent les données suivantes:

* Plaque d’immatriculation
* Numéro du parcomètre
* Temps de stationnement et taxe de stationnement

#### c) Utilisation active de la plateforme: Parkingpay

Si vous utilisez la plateforme de manière active et vous enregistrez en tant qu’utilisateur, nous sauvegardons les données que vous devez fournir dans le cadre de l’enregistrement et les traiterons pour vous permettre d’utiliser et de payer des places de parc via Parkingpay.

Il s’agit des données suivantes:

* Adresse e-mail
* Nom et prénom
* Nom de l’entreprise (seulement si le client est une entreprise)
* Adresse
* Plaque d’immatriculation

Nous collectons, traitons et utilisons les données à caractère personnel que vous avez mises à notre disposition dans la mesure nécessaire pour justifier, exécuter ou résilier un contrat passé avec vous ou entre vous et l’exploitant des places de parc que vous utilisez. Nous avons le droit de collecter, de traiter et d’utiliser vos données à caractère personnel pour justifier, exécuter et organiser la relation contractuelle avec nous et entre vous et l’exploitant des places de parc que vous utilisez.

L'exploitant du parking que vous utilisez a accès à ses transactions; ces dernières contiennent les données suivantes:

* Plaque d’immatriculation, numéro de badge, référence du code QR ou numéro de ticket
* Titulaire du compte (prénom et nom, ou nom de l’entreprise)
* Zone (év. parcomètre et numéro de place) ou parking
* Temps de stationnement et taxe de stationnement

Les exploitants n’ont pas accès à votre compte d’utilisateur et ne peuvent pas le consulter; ils peuvent seulement appeler les données suivantes: titulaire, mode de paiement et solde (seulement en cas de paiement d’avance), sur un portail d’exploitant dédié.

En outre, lors de chaque action, nous enregistrons les données suivantes qui font partie de votre compte utilisateur:

* Date / heure
* Type d’événement
* Données reçues ou transmises

Ces données ne sont utilisées que dans des cas de support et ne sont pas systématiquement évaluées. Les exploitants n’ont pas accès à ces données. Elles sont conservées pendant 3 mois, puis sont automatiquement effacées.

#### d) Utilisation active de la plateforme: TWINT

Si vous utilisez la plateforme de manière active via l’appli TWINT, nous sauvegardons, dans un compte utilisateur anonyme, les données que vous devez fournir dans le cadre de la première utilisation et les traiterons pour vous permettre d’utiliser et de payer des places de stationnement via Parkingpay.

Il s’agit des données suivantes:

* Plaque d’immatriculation
* Référence au compte TWINT (ID pseudonymisées)

Nous collectons, traitons et utilisons les données à caractère personnel que vous avez mises à notre disposition dans la mesure nécessaire pour justifier, exécuter ou résilier un contrat passé avec vous ou entre vous et l’exploitant des places de parc que vous utilisez. Nous avons le droit de collecter, de traiter et d’utiliser vos données à caractère personnel pour justifier, exécuter et organiser la relation contractuelle avec nous et entre vous et l’exploitant des places de parc que vous utilisez.

L'exploitant du parking que vous utilisez a accès à ses transactions; ces dernières contiennent les données suivantes:

* Plaque d’immatriculation, référence du code QR ou numéro de ticket
* Zone (év. parcomètre et numéro de place) ou parking
* Temps de stationnement et taxe de stationnement

Les exploitants n’ont pas accès à votre compte d’utilisateur et ne peuvent pas le consulter.

En outre, lors de chaque action, nous enregistrons les données suivantes qui font partie de votre compte utilisateur:

* Date / heure
* Type d’événement
* Données reçues ou transmises

Ces données ne sont utilisées que dans des cas de support et ne sont pas systématiquement évaluées. Les exploitants n’ont pas accès à ces données. Elles sont conservées pendant 3 mois, puis sont automatiquement effacées.

#### e) Utilisation active de la plateforme: Parkingabo

Si vous utilisez la plateforme de manière active et vous enregistrez en tant qu’utilisateur, nous sauvegardons les données que vous devez fournir dans le cadre de l’enregistrement et les traiterons pour vous permettre d’utiliser et evt. payer des places de parc via Parkingabo.

Il s’agit des données suivantes:

* adresse e-mail
* nom et prénom
* nom de l’entreprise (seulement si le client est une entreprise)
* adresse
* véhicules (plaque d’immatriculation, badge et code QR)
* référence au moyen de paiement (ID pseudonymisées)
* références spécifiques à l'exploitant

Nous collectons, traitons et utilisons les données à caractère personnel que vous avez mises à notre disposition dans la mesure nécessaire pour justifier, exécuter ou résilier un contrat passé avec vous ou entre vous et l’exploitant des places de parc que vous utilisez. Nous avons le droit de collecter, de traiter et d’utiliser vos données à caractère personnel pour justifier, exécuter et organiser la relation contractuelle avec nous et entre vous et l’exploitant des places de parc que vous utilisez.

L'exploitant du parking que vous utilisez a accès à ses transactions; ces dernières contiennent les données suivantes:

* plaque d’immatriculation, numéro de badge ou référence du code QR
* titulaire du compte (prénom et nom, ou nom de l’entreprise)
* zone ou parking
* temps de stationnement et taxe de stationnement

L’exploitation a un droit de regard total sur votre compte d'utilisateur et peut adapter les données, à l'exception des données d'accès et du moyen de paiement; pour le moyen de paiement, il peut seulement voir s'il existe et s'il est valable, mais il ne peut pas voir ou modifier les détails.

Toutes les données du compte d'utilisateur, à la seule exception des données d'accès, sont spécifiques à l’exploitation et ne sont donc accessibles qu'à l'exploitant concerné.

En outre, lors de chaque action, nous enregistrons les données suivantes qui font partie de votre compte utilisateur:

* date / heure
* type d’événement
* données reçues ou transmises

Ces données ne sont utilisées que dans des cas de support et ne sont pas systématiquement évaluées. Les exploitants n’ont pas accès à ces données. Elles sont conservées pendant 3 mois, puis sont automatiquement effacées.

#### f) Utilisation active de la plateforme: Shop en ligne sans inscription

Si vous utilisez la plateforme de manière active en tant qu’utilisateur du shop en ligne, nous sauvegardons dans un compte anonyme les données que vous devez fournir dans le cadre de l’utilisation et les traiterons pour vous permettre d’utiliser et payer des places de parc via Parkingpay.

Il s’agit des données suivantes:

* référence de l'appareil (ID pseudonymisées)
* référence au moyen de paiement (ID pseudonymisées)
* adresse e-mail (facultatif)

Après l'exécution réussie du paiement, le moyen de paiement est immédiatement supprimé.
Si le paiement n'est pas effectué, le moyen de paiement est supprimé au plus tard après 7 jours.

Nous collectons, traitons et utilisons les données à caractère personnel que vous avez mises à notre disposition dans la mesure nécessaire pour justifier, exécuter ou résilier un contrat passé avec vous ou entre vous et l’exploitant des places de parc que vous utilisez. Nous avons le droit de collecter, de traiter et d’utiliser vos données à caractère personnel pour justifier, exécuter et organiser la relation contractuelle avec nous et entre vous et l’exploitant des places de parc que vous utilisez.

L'exploitant du parking que vous utilisez a accès à ses transactions; ces dernières contiennent les données suivantes:

* numéro de ticket, plaque d’immatriculation ou référence du code QR
* zone ou parking
* temps de stationnement et taxe de stationnement

L’exploitant n’a pas accès au compte anonyme et ne peut pas le consulter.

En outre, lors de chaque action, nous enregistrons les données suivantes qui font partie du compte anonyme:

* date / heure
* type d’événement
* données reçues ou transmises

Ces données ne sont utilisées que dans des cas de support et ne sont pas systématiquement évaluées. Les exploitants n’ont pas accès à ces données. Elles sont conservées pendant 3 mois, puis sont automatiquement effacées.

#### Données d’accès à la plateforme qui n’ont pas de caractère personnel

Lorsque vous utilisez la plateforme, nous collectons des informations à votre sujet. Nous saisissons automatiquement des informations sur votre comportement d’utilisation et votre interaction avec nous, et nous enregistrons des données relatives à votre ordinateur ou appareil portable. Nous collectons, enregistrons et utilisons des données sur chaque accès à notre plateforme (ce qu’on appelle des fichiers journaux de serveur). Ces données d’accès comprennent:

* Nom et URL du fichier appelé
* Date et heure de l’appel
* Volume des données transmises
* Signal d'aboutissement de l’appel (HTTP response code)
* Type et version du navigateur
* Système d’exploitation
* URL du referrer (c’est-à-dire de la page précédemment consultée)
* Sites Internet qui sont appelés par le système de l’utilisateur, via notre site Internet
* Fournisseur d’accès internet de l’utilisateur
* Adresse IP et le fournisseur d’accès requérant

Nous utilisons ces données de protocole sans les rattacher à votre personne ni établir d’autre profil, dans un but statistique lié à l’exploitation, à la sécurité et à l’optimisation de notre site internet, mais aussi pour la saisie anonyme du nombre des visiteurs de notre site (trafic) ainsi que concernant la quantité et le genre d’utilisations qui sont faites de notre site Internet et de nos services. Sur la base de ces informations, nous pouvons analyser le trafic des données, rechercher et réparer des erreurs et améliorer nos services. À cet égard, nos intérêts légitimes prévus à l’art. 6, par. 1, point 1 f) RGPD sont également compris dans le champ d’application du RGPD.

Nous nous réservons le droit de vérifier après coup les données de protocole si des éléments concrets éveillent le soupçon d’une utilisation contraire à la loi. Au bout d’un mois, nous effaçons les fichiers journaux lorsqu’ils ne nous sont plus nécessaires à des fins de sécurité et lorsqu’il n’y a pas de soupçon concret d’une infraction pénale liée à l’utilisation de notre site Internet.

### 4. Vos droits

Selon les lois applicables, vous disposez de différents droits concernant vos données à caractère personnel. Si vous souhaitez exercer ces droits, veuillez adresser votre demande à l’adresse figurant au chiffre 8, par e-mail ou par la poste, en vous identifiant sans équivoque.

Vous trouvez ci-dessous un récapitulatif de vos droits.

### 4.1. Droit à la confirmation et à la communication

Vous avez le droit d’être clairement renseigné sur le traitement de vos données à caractère personnel. En conséquence, si des données à caractère personnel vous concernant sont traitées, vous avez à tout moment le droit d’en obtenir de notre part la confirmation. Si c’est le cas, vous avez le droit d’exiger de notre part une communication gratuite concernant les données à caractère personnel enregistrées à votre sujet, ainsi qu’une copie de ces données. Il existe également un droit aux informations suivantes:

* la finalité du traitement;
* les catégories de données à caractère personnel qui sont traitées;
* les destinataires ou catégories de destinataires à qui les données à caractère personnel ont été ou seront communiquées, notamment quand il y a des destinataires dans des pays tiers ou des organisations internationales;
* si possible, la durée prévue pendant laquelle les données à caractère personnel seront enregistrées, ou, si ce n'est pas possible, les critères selon lesquels cette durée est fixée;
* l’existence d’un droit à la rectification ou à l’effacement des données à caractère personnel ou à ce que le traitement par le responsable soit limité; ou l’existence d’un droit de s’opposer à ce traitement;
* l’existence d’un droit de réclamation auprès d’une autorité de contrôle;
* si les données à caractère personnel n’ont pas été collectées auprès de vous, toutes les informations disponibles sur la provenance des données;
* l’existence d’un processus de décision automatisé, y compris profilage, au sens de l’art. 22, par. 1 et 4 RGPD et – du moins dans ces cas – des informations compréhensibles sur la logique impliquée ainsi que sur la portée et les effets escomptés pour vous d’un tel traitement.
* Lorsque des données à caractère personnel sont transférées vers un pays tiers ou une organisation internationale, vous avez le droit d’être informé des garanties appropriées qui doivent accompagner ce transfert, au sens de l’art. 46 RGPD.

### 4.2. Droit de rectification

Vous avez le droit d'exiger que nous rectifiions et, le cas échéant, complétions des données à caractère personnel qui vous concernent. Dans ce sens, vous avez le droit d’obtenir de nous, dans les meilleurs délais, la rectification de données à caractère personnel inexactes vous concernant. En tenant compte des finalités du traitement, vous avez le droit d’exiger que des données à caractère personnel incomplètes soient complétées, y compris en fournissant une déclaration complémentaire.

Vous pouvez en outre à tout moment modifier directement les données à caractère personnel qui sont enregistrées sur votre compte utilisateur.

### 4.3. Droit à l’effacement («droit à l’oubli»)

Dans une série de cas, nous avons l’obligation d’effacer des données à caractère personnel vous concernant. Ainsi, vous avez le droit d’obtenir de notre part, plus particulièrement dans le champ d’application de l’art. 17, par. 1 RGPD, dans les meilleurs délais, l’effacement de données à caractère personnel vous concernant, et nous avons l’obligation d’effacer dans les meilleurs délais des données à caractère personnel lorsque l’un des motifs suivants s’applique:

* Les données à caractère personnel ne sont plus nécessaires au regard des finalités pour lesquelles elles ont été collectées ou traitées d'une autre manière.
* Vous retirez le consentement sur lequel est fondé le traitement, et il n'existe pas d'autre fondement juridique au traitement.
* Vous vous opposez au traitement en vertu de l’art. 21, par. 1 RGPD et il n'existe pas de motif légitime impérieux pour le traitement, ou vous vous opposez au traitement en vertu de l’art. 21, par. 2 RGPD.
* Les données à caractère personnel ont fait l'objet d'un traitement illicite.
* Les données à caractère personnel doivent être effacées pour respecter une obligation légale à laquelle nous sommes soumis.
* Les données à caractère personnel ont été collectées dans le cadre de l'offre de services de la société de l'information visée à l’art. 8, par. 1 RGPD.
Vous pouvez à tout moment effacer les données à caractère personnel enregistrées sur votre compte utilisateur ou effacer entièrement ce compte lui-même.

### 4.4. Droit à la limitation du traitement

Dans une série de cas, vous avez le droit d’obtenir de notre part une limitation du traitement de vos données à caractère personnel. Ainsi, vous avez le droit d'obtenir que nous limitions le traitement lorsqu'un des éléments suivants s'applique:

* Vous contestez l'exactitude des données à caractère personnel, pendant une durée qui nous permet de vérifier l'exactitude des données à caractère personnel.
* Le traitement est illicite et vous vous êtes opposé à l’effacement des données à caractère personnel et avez exigé à la place la limitation de leur utilisation.
* Nous n'avons plus besoin des données à caractère personnel aux fins du traitement mais celles-ci vous sont encore nécessaires pour la constatation, l'exercice ou la défense de vos droits.
* Vous êtes opposé au traitement en vertu de l’art. 21, par. 1 RGPD, pendant la vérification portant sur le point de savoir si les motifs légitimes poursuivis par notre entreprise prévalent sur les vôtres.

### 4.5. Droit à la portabilité des données

Toute personne concernée par le traitement de données personnelles a le droit d’obtenir les données personnelles la concernant dans un format structuré, couramment utilisé et lisible par machine. En outre, la personne concernée a le droit d’obtenir que les données personnelles soient transmises directement d’un responsable du traitement à un autre, dans la mesure où cela est techniquement possible et à condition que cela ne porte pas atteinte aux droits et libertés d’autrui.

Pour exercer votre droit à la portabilité des données, vous pouvez envoyer votre demande par e-mail ou par courrier, en vous identifiant clairement, à l’adresse indiquée au point 11.

### 4.6. Droit d’opposition

Toute personne concernée par le traitement de données personnelles a le droit de s’opposer à tout moment au traitement des données personnelles la concernant pour des raisons tenant à sa situation particulière.

En cas d’opposition, Digitalparking ne traitera plus les données personnelles, à moins que nous puissions démontrer qu’il existe des motifs légitimes et impérieux pour le traitement qui prévalent sur les intérêts, les droits et les libertés de la personne concernée, ou pour la constatation, l’exercice ou la défense de droits en justice.

Pour exercer votre droit d’opposition, vous pouvez envoyer votre demande par e-mail ou par courrier, en vous identifiant clairement, à l’adresse indiquée au point 11.

### 4.7. Droit de retirer son consentement en matière de protection des données

Vous avez le droit de retirer à tout moment un consentement donné pour le traitement de données à caractère personnel.

### 4.8. Droit de réclamation auprès d’une autorité de contrôle

Vous avez le droit d’introduire une réclamation auprès d’une autorité de contrôle si vous estimez que le traitement des données à caractère personnel vous concernant est contraire à la loi. En Suisse, vous pouvez vous adresser au préposé fédéral à la protection des données et à la transparence (www.edoeb.admin.ch), et hors de Suisse, à l’autorité de contrôle compétente pour vous en raison de votre domicile ou de votre lieu de résidence.

### 5. Prestataires externes / transfert de données à l’étranger

En principe, nous n’utilisons vos données à caractère personnel qu’au sein de notre entreprise. Si nous faisons intervenir des tiers (par exemple pour des services de logistique) dans le cadre de l’exécution de contrats, ces personnes peuvent recevoir des données à caractère personnel, mais uniquement dans la mesure où cette transmission est nécessaire à la prestation correspondante.

Si nous déléguons certaines parties du traitement de données («sous-traitance»), nous soumettons nos sous-traitants, par contrat, à la double obligation d’utiliser des données à caractère personnel uniquement conformément aux exigences des lois sur la protection des données et de garantir la protection des droits de la personne concernée. Aucun transfert de données à des organismes ou à des personnes hors de Suisse ou hors de l’UE n’a lieu dans d’autres cas que ceux évoqués dans la présente déclaration.

### 5.1. Hébergement

Les prestations d’hébergement auxquelles nous recourons servent à mettre à disposition les prestations suivantes: services d’infrastructure et de plateforme, capacité de calcul, emplacements mémoire et services de banque de données, prestations de sécurité ainsi que prestations techniques de maintenance que nous mettons en œuvre pour le bon fonctionnement du site Internet.

Nous ou notre hébergeur traitons alors des données de base (données d’inventaire), des données de contact, de contenu, de contrats, d’utilisation, des métadonnées et données de communication de clients, de personnes intéressées et de visiteurs de ce site Internet, sur la base de notre intérêt légitime à mettre à disposition notre site Internet de manière efficace et sûre (les dispositions déterminantes dans le champ d’application du RGPD sont les art. 6, par. 1, point 1 f) RGPD en combinaison avec l’art. 28 RGPD).

### 5.2. Prestataire pour les paiements

Pour la réalisation des procédures de paiement, nous recourons au prestataire Datatrans SA et TWINT SA. Nous avons avec Datatrans SA un contrat de sous-traitance au sens de l’art. 9 LPD et de l’art. 28 RGPD.

### 6. Cookies et local storage

Un cookie est un petit fichier texte qui contient différentes informations et est enregistré par le navigateur web sur votre appareil terminal.

Local storage (HTML5 Local Storage) est une petite base de données qui contient différentes informations et est intégrée dans le navigateur web sur votre appareil terminal.

#### a) Utilisation à titre purement informatif

Nous n’utilisons ni les cookies, ni le local storage.

#### b) Utilisation active de la plateforme: Parkingpay

Nous utilisons les cookies et le local storage quand vous ouvrez une session dans nos applications web.

Les cookies servent à assigner à la session commune authentifiée les différentes requêtes de votre navigateur, et donc à protéger votre compte contre un accès non autorisé. Ces cookies sont enregistrés sur votre disque dur et s’effacent automatiquement après le temps prescrit. Leur durée de vie est soit d’une journée («Rester connecté» désactivé), soit de 90 jours («Rester connecté» activé).

Les données et informations suivantes, par exemple, sont enregistrées dans les cookies:

* Identification de session (ID pseudonymisées)

Vous pouvez configurer votre navigateur pour être informé à l’avance quand des cookies sont mis en place et décider dans chaque cas d’exclure l'acceptation de cookies dans certains cas ou de manière générale, voire de les bloquer complètement. Cela peut restreindre les fonctionnalités de l’application web.

Le local storage est utilisé pour améliorer la convivialité de l’application. Les informations du local storage ne sont jamais effacées automatiquement.

Les données et informations suivantes sont enregistrées dans le local storage:

* Code de langue
* Dernières options sélectionnées (ID pseudonymisées)
* Dernier NPA sélectionné

Vous pouvez configurer votre navigateur pour qu’il bloque entièrement les informations local storage. Cela peut restreindre les fonctionnalités de l’application web.

Au sens de l’art. 6, par. 1, point 1 f) RGPD, l’intérêt légitime que présente pour nous l’utilisation des cookies et du local storage est de nous permettre de rendre nos applications web plus conviviales, plus efficaces et plus sûres.

### 7. Durée de l’enregistrement

Sauf indication contraire spécifique, nous n’enregistrons les données à caractère personnel qu’aussi longtemps que c’est nécessaire à la réalisation des buts poursuivis.

Dans certains cas, par exemple en droit fiscal ou commercial, le législateur prévoit que des données à caractère personnel doivent être conservées. Dans ces cas, nous continuons à mémoriser les données, mais uniquement dans ces buts légaux; nous ne les traitons pas autrement et elles sont effacées à l’expiration du délai légal de conservation.

### 8. Sécurité des données

Dans les limites des possibilités techniques et de la législation en vigueur sur la protection des données, nous faisons le maximum pour que vos données soient en sécurité.

Chez nous, vos données personnelles sont transmises sous forme chiffrée, au moyen du système de codage SSL (Secure Socket Layer). C’est le cas pour vos données de base, vos transactions et aussi pour le login de client. Nous vous signalons toutefois que la transmission de données sur Internet peut présenter des failles de sécurité. Une protection totalement infaillible des données contre l’accès de tiers n'est pas possible.

Pour sécuriser vos données, nous maintenons des mesures techniques et organisationnelles correspondant à l’art. 32 RGPD et nous les adaptons régulièrement à l’état de la technique.

Pour le surplus, nous ne garantissons pas que notre offre soit disponible à certains moments; des perturbations, des interruptions ou des pannes ne peuvent pas être exclues. Les serveurs que nous utilisons sont régulièrement sécurisés avec soin.

### 9. Droit d’auteur

Le droit d’auteur et tous les autres droits relatifs aux contenus, illustrations, photos ou autres fichiers placés sur la plateforme appartiennent exclusivement à l’exploitant de cette plateforme ou aux détenteurs des droits nommément cités. La reproduction de tous les fichiers nécessite l’accord écrit préalable des détenteurs des droits d’auteur.

Toute personne qui enfreint le droit d’auteur sans l’accord du détenteur des droits d’auteur peut être passible de sanctions. Le cas échéant, le détenteur des droits d’auteur peut faire valoir des prétentions en dommages-intérêts.

### 10. Clause de non-responsabilité

Toutes les informations figurant sur notre plateforme ont été vérifiées avec soin. Nous nous efforçons de veiller à ce que les informations que nous fournissons soient actuelles, exactes et complètes. Toutefois, la survenue d’erreurs ne peut être totalement exclue, de sorte que nous déclinons toute responsabilité quant à l’exhaustivité, l’exactitude et l’actualité des informations. Les prétentions en responsabilité se rapportant à des dommages matériels ou immatériels causés par l’utilisation ou la non-utilisation des informations présentées ou par l’utilisation d’informations erronées ou incomplètes sont en principe exclues.

L’éditeur peut modifier ou supprimer des textes à sa seule discrétion et sans préavis et n’est pas tenu de mettre à jour le contenu de la présente plateforme. L’utilisation de cette plateforme ou l’accès à celle-ci se fait aux risques et périls du visiteur. L’éditeur et ses clients ou partenaires ne sont pas responsables des dommages, tels que les dommages directs, indirects, fortuits ou consécutifs, qui auraient été causés par la visite de cette plateforme et déclinent par conséquent toute responsabilité pour de tels dommages.

L’éditeur décline également toute responsabilité quant au contenu et à la disponibilité des sites Internet de tiers auxquels il est possible d’accéder par des liens externes à partir de la présente plateforme. Les exploitants des sites référencés assument seuls la responsabilité de leur contenu. L’éditeur se distancie ainsi expressément de tout contenu tiers susceptible d’entraîner des poursuites pénales, de mettre en jeu la responsabilité civile ou d’être contraire aux mœurs.

### 11. Contact

Pour des questions sur la collecte, le traitement ou l’utilisation de vos données à caractère personnel, pour des renseignements, une rectification, un blocage ou l’effacement de données à caractère personnel ainsi que pour une opposition au traitement des données, veuillez-vous adresser à:

Adresse postale: Digitalparking AG, Rütistrasse 13, 8952 Schlieren

Adresse e-mail: <privacy@digitalparking.ch>

### 12. Modifications

Digitalparking SA peut modifier la présente déclaration de protection des données à tout moment et sans préavis. La version actuelle publiée sur notre plateforme fait foi. Dans la mesure où la déclaration de protection des données fait partie intégrante d’un accord passé avec vous, nous vous informerons par e-mail ou d’une autre manière appropriée en cas de mise à jour.

`,
        it: `
# INFORMATIVA SULLA PROTEZIONE DEI DATI

## PIATTAFORMA CLOUD
## Versione 10.2023

### 1. Aspetti generali

La protezione e la sicurezza dei dati hanno per noi assoluta priorità. Per questo motivo abbiamo intrapreso misure tecniche e organizzative complete, nell’ambito delle disposizioni in materia di protezione dei dati, al fine di garantire la protezione dei vostri dati personali. Noi predisponiamo per voi le offerte attraverso diversi canali (p.es. sito web e applicazione smartphone), denominati in generale “piattaforma”.

In questa informativa sulla protezione dei dati vi informiamo sull’utilizzo dei vostri dati personali e sull’utilizzo dei cookie e delle tecnologie simili nella nostra piattaforma.

La raccolta e il trattamento dei vostri dati personali avviene nel rispetto delle disposizioni vigenti in materia di protezione dei dati, soprattutto della Legge sulla protezione dei dati svizzera (LPD) e del Regolamento generale sulla protezione dei dati (GDPR), qualora questo trovi applicazione nel caso concreto del trattamento dei dati.

### 2. Fornitore responsabile

Il fornitore responsabile della piattaforma e dei dati personali raccolti e trattati nell’ambito del vostro utilizzo della piattaforma è Digitalparking SA, Rütistrasse 13, CH-8952 Schlieren.

### 3. Raccolta, trattamento e utilizzo dei vostri dati personali

L’entità e il tipo di raccolta, trattamento e utilizzo dei vostri dati personali varia a seconda che voi vi limitiate a visitare la nostra piattaforma e a utilizzarla per scopi informativi o se attraverso la piattaforma vi mettete in contatto con noi, ovvero se la usate attivamente come utente dell’applicazione web o smartphone Parkingpay (di seguito denominata «Parkingpay»), dell’applicazione web o smartphone Parkingabo (di seguito denominata «Parkingabo»), l’app TWINT (di seguito denominata „TWINT“) o di un dispositivo smart.

#### a) Utilizzo a scopo unicamente informativo

Per l’utilizzo della nostra piattaforma a scopo unicamente informativo, in linea generale non è necessario che voi comunichiate i vostri dati personali. In questo caso noi raccogliamo e utilizziamo solo i dati sul vostro conto che ci vengono trasmessi automaticamente dal vostro browser Internet (soprattutto il vostro indirizzo IP). Queste informazioni vengono salvate – in forma non anonimizzata – solo per la durata della vostra visita alla piattaforma, mentre successivamente viene effettuata una valutazione in forma anonimizzata a scopi unicamente statistici. Pertanto non vengono raccolti dati personali sul vostro conto.

#### b) Utilizzo attivo della piattaforma: parchimetri con inserimento del numero di targa

Quando utilizzate un parchimetro di questo tipo, voi dovete necessariamente inserire il numero di targa.

Il gestore dei parcheggi da voi utilizzati ha accesso alle proprie transazioni; queste ultime contengono i seguenti dat:

* numero di targa
* numero del parchimetro
* tempo e tassa di parcheggio

#### c) Utilizzo attivo della piattaforma: Parkingpay

Se utilizzate la piattaforma attivamente e vi registrate come utente, noi salveremo e tratteremo i dati da voi inseriti nell’ambito della registrazione, al fine di consentirvi di utilizzare e pagare i parcheggi a pagamento attraverso Parkingpay.

Si tratta in questo caso dei seguenti dati:

* indirizzo e-mail
* nome e cognome
* nome dell’azienda (solo clienti aziendali)
* indirizzo
* numero di targa

Noi registriamo, trattiamo e utilizziamo i dati personali da voi messi a disposizione, nella misura in cui questi sono necessari per l’impostazione, l’esecuzione o la cessazione di un contratto di natura commerciale o simile con voi o tra voi e il gestore dei parcheggi da voi utilizzati. Noi siamo autorizzati a raccogliere, trattare e utilizzare i dati personali sul vostro conto allo scopo di impostare, eseguire ed espletare il rapporto contrattuale con noi e tra voi e il gestore dei parcheggi da voi utilizzati.

Il gestore dei parcheggi da voi utilizzati ha accesso alle proprie transazioni; queste ultime contengono i seguenti dat:

* numero di targa, badge, referenza codice QR o numero di ticket
* titolare del conto (nome, cognome o nome dell’azienda)
* zona (ev. parchimetro o numero del posto) o parcheggio
* tempo e tassa di parcheggio

Il gestore non può prendere visione né ha accesso al vostro conto utente; può effettuare unicamente delle ricerche su titolare, modalità di pagamento e saldo (solo in caso di pagamento anticipato) in un portale gestori dedicato.

Inoltre, durante ogni azione all’interno del vostro conto utente, noi salviamo i seguenti dati:

* data/ora
* tipo di evento
* dati ricevuti o trasmessi

I dati vengono utilizzati solo nei casi di supporto e non vengono valutati sistematicamente. I gestori non possono prendere visione di tali dati. Questi dati vengono conservati per 3 mesi e quindi cancellati automaticamente.

#### d) Utilizzo attivo della piattaforma: TWINT

Se utilizzate la piattaforma attivamente tramite l’app TWINT, noi salveremo e tratteremo i dati da voi inseriti nell’ambito del primo utilizzo, al fine di consentirvi di utilizzare e pagare i parcheggi a pagamento attraverso Parkingpay.

Si tratta in questo caso dei seguenti dati:

* numero di targa
* Referenza al conto TWINT (ID pseudonimizzato)

Noi registriamo, trattiamo e utilizziamo i dati personali da voi messi a disposizione, nella misura in cui questi sono necessari per l’impostazione, l’esecuzione o la cessazione di un contratto di natura commerciale o simile con voi o tra voi e il gestore dei parcheggi da voi utilizzati. Noi siamo autorizzati a raccogliere, trattare e utilizzare i dati personali sul vostro conto allo scopo di impostare, eseguire ed espletare il rapporto contrattuale con noi e tra voi e il gestore dei parcheggi da voi utilizzati.

Il gestore dei parcheggi da voi utilizzati ha accesso alle proprie transazioni; queste ultime contengono i seguenti dat:

* numero di targa, referenza codice QR o numero di ticket
* zona (ev. parchimetro o numero del posto) o parcheggio
* tempo e tassa di parcheggio

Il gestore non può prendere visione né ha accesso al vostro conto utente

Inoltre, durante ogni azione all’interno del vostro conto utente, noi salviamo i seguenti dati:

* data/ora
* tipo di evento
* dati ricevuti o trasmessi

I dati vengono utilizzati solo nei casi di supporto e non vengono valutati sistematicamente. I gestori non possono prendere visione di tali dati. Questi dati vengono conservati per 3 mesi e quindi cancellati automaticamente.

#### e) Utilizzo attivo della piattaforma: Parkingabo

Se utilizzate la piattaforma attivamente e vi registrate come utente, noi salveremo e tratteremo i dati da voi inseriti nell’ambito della registrazione, al fine di consentirvi di utilizzare ed ev. pagare i parcheggi attraverso Parkingabo.

Si tratta in questo caso dei seguenti dati:

* indirizzo e-mail
* nome e cognome
* nome dell’azienda (solo clienti aziendali)
* indirizzo
* veicolo (numero di targa, badge e codice QR)
* referenza al mezzo di pagamento (ID pseudonimizzato)
* referenze specifiche al gestore

Noi registriamo, trattiamo e utilizziamo i dati personali da voi messi a disposizione, nella misura in cui questi sono necessari per l’impostazione, l’esecuzione o la cessazione di un contratto di natura commerciale o simile con voi o tra voi e il gestore dei parcheggi da voi utilizzati. Noi siamo autorizzati a raccogliere, trattare e utilizzare i dati personali sul vostro conto allo scopo di impostare, eseguire ed espletare il rapporto contrattuale con noi e tra voi e il gestore dei parcheggi da voi utilizzati.

Il gestore dei parcheggi da voi utilizzati ha accesso alle proprie transazioni; queste ultime contengono i seguenti dati:

* numero di targa, badge o referenza del codice QR
* titolare del conto (nome, cognome o nome dell’azienda)
* zona o parcheggio
* tempo e tassa di parcheggio

Il gestore ha pieno accesso al vostro conto utente e può modificarne i dati, ad eccezione dei dati di accesso e del mezzo di pagamento; nel caso del mezzo di pagamento, il gestore può solo vedere se esiste ed è valido, ma non può visualizzare o modificare i dettagli.

Tutti i dati del conto utente, con la sola eccezione dei dati di accesso, sono specifici al gestore e quindi accessibili solo al rispettivo gestore.

Inoltre, durante ogni azione all’interno del vostro conto utente, noi salviamo i seguenti dati:

* data/ora
* tipo di evento
* dati ricevuti o trasmessi

I dati vengono utilizzati solo nei casi di supporto e non vengono valutati sistematicamente. I gestori non possono prendere visione di tali dati. Questi dati vengono conservati per 3 mesi e quindi cancellati automaticamente.

#### f) Utilizzo attivo della piattaforma: shop online senza registrazione

Se utilizzate la piattaforma attivamente tramite lo shop online, noi salveremo e tratteremo in un conto anonimo i dati da voi inseriti nell’ambito dell’utilizzo, al fine di consentirvi di utilizzare e pagare i parcheggi a pagamento.

Si tratta in questo caso dei seguenti dati:

* referenza all’apparecchio (ID pseudonimizzato)
* referenza al mezzo di pagamento (ID pseudonimizzato)
* indirizzo e-mail (facoltativo)

Dopo l'esecuzione con successo del pagamento, il mezzo di pagamento viene cancellato immediatamente.
In caso di pagamenti non eseguiti, il mezzo di pagamento viene cancellato al più tardi dopo 7 giorni.

Noi registriamo, trattiamo e utilizziamo i dati personali da voi messi a disposizione, nella misura in cui questi sono necessari per l’impostazione, l’esecuzione o la cessazione di un contratto di natura commerciale o simile con voi o tra voi e il gestore dei parcheggi da voi utilizzati. Noi siamo autorizzati a raccogliere, trattare e utilizzare i dati personali sul vostro conto allo scopo di impostare, eseguire ed espletare il rapporto contrattuale con noi e tra voi e il gestore dei parcheggi da voi utilizzati.

Il gestore dei parcheggi da voi utilizzati ha accesso alle proprie transazioni; queste ultime contengono i seguenti dati:

* numero di ticket, numero di targa o referenza codice QR
* zona (ev. parchimetro o numero del posto) o parcheggio
* tempo e tassa di parcheggio

Il gestore non può prendere visione né ha accesso al conto anonimo.

Inoltre, durante ogni azione all’interno del conto anonimo, noi salviamo i seguenti dati:

* data/ora
* tipo di evento
* dati ricevuti o trasmessi

I dati vengono utilizzati solo nei casi di supporto e non vengono valutati sistematicamente. I gestori non possono prendere visione di tali dati. Questi dati vengono conservati per 3 mesi e quindi cancellati automaticamente.

#### Dati di accesso alla piattaforma che non hanno carattere personale

Noi raccogliamo informazioni su di voi ogni qualvolta utilizzate la piattaforma. Registriamo automaticamente informazioni sul vostro comportamento di utilizzo e sulla vostra interazione con noi e registriamo i dati relativi al vostro computer o dispositivo mobile. Noi registriamo, salviamo e utilizziamo dati su ogni accesso alla nostra piattaforma (cosiddetti file log del server). Fra i dati di accesso vi sono:

* nome e URL del file richiamato
* data e ora della richiesta
* quantità di dati trasferita
* notifica sulla richiesta con esito positivo (HTTP response code)
* tipo e versione del browser
* sistema operativo
* referer URL (ovvero la pagina precedentemente visitata)
* siti web che vengono richiamati dal sistema dell’utente attraverso il nostro sito web
* Internet Service Provider dell’utente
* indirizzo IP e provider richiedente

Noi utilizziamo questi dati di protocollo - senza associarli alla vostra persona né per creare profili di qualsiasi tipo - per valutazioni statistiche finalizzate alla gestione, alla sicurezza e all’ottimizzazione del nostro sito web, ma anche per registrare in forma anonima il numero dei visitatori del nostro sito web (traffic) e per valutare l’entità e il tipo di utilizzo del nostro sito web e dei nostri servizi. In base a queste informazioni noi possiamo analizzare il traffico dati, cercare e rimuovere gli errori e migliorare i nostri servizi. Nell’ambito di applicazione della GDPR sussiste anche il nostro interesse legittimo come da art. 6 par. 1 f. 1 f) GDPR.

Ci riserviamo il diritto di controllare a posteriori i dati di protocollo, quando a causa di circostanze concrete sussista il legittimo sospetto di un utilizzo non conforme alle regole. Dopo un mese cancelliamo i file log, qualora questi non siano più necessari per scopi di sicurezza o se non vi sono sospetti concreti di un reato relativamente all’utilizzo del nostro sito web.

#### 4. I vostri diritti

Secondo le leggi vigenti, voi avete diversi diritti in materia di dati personali. Se desiderate rivendicare questi diritti, inviate la vostra richiesta via e-mail o per posta, identificando in modo univoco la vostra persona, all’indirizzo riportato al punto 8.

Di seguito trovate una panoramica dei vostri diritti.

### 4.1. Diritto alla conferma e all’informazione

Avete il diritto di ottenere informazioni chiare sul trattamento dei vostri dati personali. A questo proposito voi avete il diritto, in ogni momento, di ricevere da noi una conferma se presso di noi vengono trattati dati personali su di voi. In caso positivo, voi avete il diritto di ottenere da noi informazioni a titolo gratuito sui dati personali salvati, oltre che una copia di questi dati. Sussiste altresì il diritto alle seguenti informazioni:

* finalità del trattamento;
* categorie di dati personali trattati;
* destinatari o categorie di destinatari a cui i dati personali sono stati o saranno comunicati, in particolare se destinatari di paesi terzi o organizzazioni internazionali;
* ove possibile, il periodo di conservazione dei dati personali previsto oppure, se non è possibile, i criteri utilizzati per determinare tale periodo;
* esistenza del diritto di chiedere al titolare del trattamento la rettifica o la cancellazione dei dati personali o la limitazione del trattamento dei dati personali che vi riguardano o di opporsi al loro trattamento;
* diritto di proporre reclamo a un'autorità di vigilanza;
* qualora i dati non siano raccolti presso di voi, tutte le informazioni disponibili sulla loro origine;
* esistenza di un processo decisionale automatizzato, compresa la profilazione di cui all'art. 22, par. 1 e 4 GDPR e, almeno in tali casi, informazioni significative sulla logica utilizzata, nonché l'importanza e le conseguenze previste di tale trattamento per voi;
* qualora i dati personali siano trasferiti a un paese terzo o a un'organizzazione internazionale, voi avete il diritto di essere informati dell'esistenza di garanzie adeguate ai sensi dell'art. 46 GDPR relative al trasferimento.

### 4.2. Diritto di rettifica

Avete il diritto di richiederci la rettifica ed eventualmente anche il completamento dei dati personali che vi riguardano. Di conseguenza, avete il diritto di ottenere da noi la rettifica immediata dei dati personali inesatti che vi riguardano. Tenuto conto delle finalità del trattamento, avete il diritto di ottenere l'integrazione dei dati personali incompleti, anche fornendo una dichiarazione integrativa.

Potete inoltre elaborare i dati personali salvati nel vostro conto utenti in qualsiasi momento.

### 4.3. Diritto alla cancellazione («diritto all'oblio»)

In una serie di casi siamo tenuti a cancellare i dati personali che vi riguardano. In tali circostanze voi avete in particolare nell’ambito di applicazione di cui all’art. 17 par. 1 GDPR il diritto di chiedere la cancellazione immediata dei dati personali che vi riguardano e noi siamo tenuti a cancellarli immediatamente in presenza di uno dei seguenti motivi:

* i dati personali non sono più necessari rispetto alle finalità per le quali sono stati raccolti o altrimenti trattati;
* voi revocate l’autorizzazione su cui si basava il trattamento e non vi sono altre basi giuridiche che consentano il trattamento,
* ai sensi dell’ art. 21 par. 1 GDPR voi vi opponete al trattamento e non vi sono altri motivi legittimi prevalenti che lo consentano, oppure ai sensi dell’art. 21 par. 2 GDPR voi vi opponete al trattamento,
* i dati personali sono stati trattati illecitamente,
* la cancellazione dei dati personali serve a ottemperare a un obbligo di legge cui siamo soggetti,
* i dati personali sono stati raccolti relativamente all'offerta di servizi della società dell'informazione di cui all'art. 8, par. 1 GDPR.
Potete cancellare in qualsiasi momento i dati personali salvati nel vostro conto utente nonché l’intero conto.

### 4.4. Diritto di limitazione del trattamento

In una serie di casi avete il diritto di richiederci una limitazione del trattamento dei vostri dati personali. Nella fattispecie avete il diritto di ottenere da noi la limitazione del trattamento, quando ricorre una delle seguenti situazioni:

* voi contestate l'esattezza dei dati personali, per un periodo tale che permetta a noi di verificare l'esattezza di tali dati personali;
* il trattamento è illecito e voi vi siete opposti alla cancellazione dei dati personali e avete chiesto invece che ne fosse limitato l'utilizzo;
* benché noi non abbiamo più bisogno dei dati personali ai fini del trattamento, questi sono necessari a voi per la rivendicazione, l'esercizio o la difesa di pretese giuridiche oppure
* voi vi siete opposti al trattamento ai sensi dell’art. 21 par. 1 GDPR, sebbene non sia ancora chiaro se i motivi legittimi della nostra azienda prevalgano sui vostri.

### 4.5. Diritto alla portabilità dei dati

L’interessato dal trattamento dei dati personali ha il diritto di ricevere in un formato strutturato, di uso comune e leggibile da dispositivo automatico, i dati personali che lo riguardano. Inoltre, ha il diritto di ottenere la trasmissione diretta dei dati personali da un titolare del trattamento all’altro, qualora ciò sia tecnicamente fattibile e non leda i diritti e le libertà altrui.

Per esercitare il diritto alla portabilità dei dati potete inviare la vostra richiesta via e-mail o per posta all’indirizzo indicato al punto 11, identificandovi in modo inequivocabile.

### 4.6. Diritto di opposizione

L’interessato dal trattamento dei dati personali ha il diritto di opporsi in qualsiasi momento, per motivi connessi alla sua situazione particolare, al trattamento dei dati personali che lo riguardano.

In caso di opposizione, Digitalparking si asterrà dal trattare ulteriormente i dati personali salvo poter dimostrare l’esistenza di motivi legittimi cogenti per procedere al trattamento che prevalgono sugli interessi, sui diritti e sulle libertà dell’interessato oppure per l’accertamento, l’esercizio o la difesa di un diritto in sede giudiziaria.

Per esercitare il vostro diritto di opposizione, potete inviare la vostra richiesta per e-mail o per posta all’indirizzo indicato al punto 11, identificandovi in modo inequivocabile.

### 4.7. Diritto alla revoca di un consenso in materia di protezione dei dati

Voi avete il diritto di revocare in qualsiasi momento un consenso fornito ai fini del trattamento dei vostri dati personali.

### 4.8. Diritto di proporre reclamo a un’autorità di vigilanza

Voi avete il diritto di proporre reclamo a un’autorità di vigilanza, se ritenete che il trattamento dei dati personali che vi riguardano sia illecito. In Svizzera potete rivolgervi all’Incaricato federale della protezione dei dati e della trasparenza (www.edoeb.admin.ch), mentre al di fuori della Svizzera all’autorità di vigilanza di competenza in base al vostro domicilio o luogo di dimora.

### 5. Fornitori di servizi esterni / Trasmissione di dati all’estero

In linea generale utilizziamo i vostri dati personali solo all’interno della nostra azienda. Se dovessimo attivare terzi relativamente all’esecuzione dei contratti (per esempio fornitori di servizi logistici), questi ricevono i dati personali solo nella misura in cui la trasmissione è necessaria per lo svolgimento delle relative prestazioni.

Nel caso in cui esternalizziamo determinate parti di trattamento dati («Elaborazione dell’ordine di trattamento»), noi vincoliamo contrattualmente i responsabili del trattamento a utilizzare i dati personali solo in linea con i requisiti delle leggi sulla protezione dei dati e a garantire la protezione dei diritti dell’interessato. La trasmissione dati a uffici o persone esternamente alla Svizzera risp. all’UE e al di fuori dei casi riportati nella presente informativa non ha luogo.

### 5.1. Hosting

I servizi di hosting di cui ci serviamo servono per erogare i seguenti servizi: servizi relativi all’infrastruttura e alla piattaforma, capacità di calcolo, spazio di memoria e servizi di banche dati, servizi di sicurezza e di manutenzione tecnica che utilizziamo al fine di gestire la piattaforma.

Noi, ovvero il fornitore dei servizi di hosting, trattiamo nello specifico dati di base, di contatto, di contenuto, dati contrattuali, dati di utilizzo, metadati e dati di comunicazione di clienti, interessati e visitatori del presente sito web in base ai nostri legittimi interessi di un approntamento efficiente e sicuro della nostra piattaforma (nell’ambito di applicazione delle disposizioni del GDPR sono rilevanti le disposizioni di cui all’art. 6 par. 1 f. 1 f) GDPR unitamente all’art. 28 GDPR).

### 5.2. Pagamento – Fornitore di servizi

Per realizzare le procedure di pagamento ci avvaliamo dei fornitori di servizi di pagamento Datatrans SA e TWINT SA. Con Datatrans SA abbiamo un contratto di responsabilità del trattamento di cui all’art. 28 GDPR.

### 6. Cookie e local storage

Un cookie è un piccolo file di testo contenente diverse informazioni che viene salvato sul vostro terminale dal web browser.

Il local storage (HTML5 Local Storage) è una piccola banca dati contenente diverse informazioni, che è integrata nel web browser del vostro terminale.

#### a) Utilizzo a scopo unicamente informativo

Noi non utilizziamo né i cookie né il local storage.

#### b) Utilizzo attivo della piattaforma: Parkingpay

Noi utilizziamo i cookie e il local storage se voi effettuate il login nelle nostre applicazioni web.

I cookie vengono utilizzati per assegnare le diverse richieste del vostro browser alla sessione autenticata comune e quindi per proteggere il vostro conto dall’accesso non autorizzato. Questi cookie vengono salvati sul vostri disco fisso e si cancellano automaticamente dopo un periodo definito. La loro durata di vita è di 1 giorno («Rimanere connessi» inattivo) o 90 giorni («Rimanere connessi» attivo).

Nei cookie vengono salvati i seguenti dati e le seguenti informazioni:

* identificazione della sessione (ID pseudonimizzati)

Potete impostare il vostro browser in modo che siate informati in anticipo sul salvataggio dei cookie e possiate decidere nei singoli casi se rifiutare i cookie in determinati casi o in generale o se bloccare completamente il salvataggio dei cookie. In questo caso la funzionalità dell’applicazione web può essere limitata.

Il local storage viene utilizzato per aumentare la facilità di utilizzo dell’applicazione. Le informazioni nel local storage non vengono mai cancellate automaticamente.

Nel local storage vengono salvati i seguenti dati e le seguenti informazioni:

* lingua
* ultime opzioni selezionate (ID pseudonimizzati)
* ultimi NPA utilizzati

Potete impostare il browser in modo che le informazioni del local storage vengano completamente bloccate. In questo caso la funzionalità dell’applicazione web può essere limitata.

Il nostro interesse legittimo all’utilizzo dei cookie e del local storage ai sensi dell'art. 6 par. 1 f. 1 f) GDPR consiste nel rendere più fruibili, efficaci e sicure le nostre applicazioni web.

### 7. Durata di conservazione dei dati

Qualora non sia specificato diversamente, noi conserviamo i dati personali solo per il periodo necessario per il soddisfacimento degli scopi perseguiti.

In alcuni casi il legislatore impone la conservazione dei dati personali, per esempio nel diritto fiscale o commerciale. In questi casi i dati continuano a essere da noi conservati unicamente per questi scopi giuridici, ma non vengono utilizzati in modi diversi e dopo la scadenza dei termini di conservazione i dati vengono cancellati.

### 8. Sicurezza dei dati

Ci adoperiamo al meglio per la sicurezza dei vostri dati nell'ambito delle leggi vigenti sulla protezione dei dati e delle possibilità tecniche.

I vostri dati vengono da noi trasmessi cifrati attraverso il sistema di codifica SSL (Secure Socket Layer). Questo vale per i vostri dati anagrafici, le vostre transazioni e anche per il login del cliente. Vi ricordiamo che la trasmissione dei dati in Internet può presentare delle lacune nella sicurezza. Non è possibile garantire una protezione dei dati perfetta dall’accesso da parte di terzi.

Per garantire la sicurezza dei vostri dati noi intraprendiamo misure di sicurezza tecniche e organizzative ai sensi dell’art. 32 GDPR, che adeguiamo sempre in funzione dello stato della tecnica.

Non assicuriamo inoltre che la nostra offerta sia disponibile in determinati periodi; guasti, interruzioni o malfunzionamenti non possono essere esclusi. I server da noi utilizzati sono messi regolarmente e accuratamente in sicurezza.

### 9. Diritto d'autore

Il diritto d’autore e tutti gli altri diritti relativi a contenuti, immagini, foto e altri file su questa piattaforma sono di esclusiva proprietà del gestore di questa piattaforma o dei titolari di diritti specificamente nominati. Per la riproduzione di tutti i file è necessario ottenere prima l’approvazione scritta dei titolari dei diritti d’autore.

Chiunque commetta una violazione del diritto d’autore senza l’approvazione dei rispettivi titolari di tale diritto può essere perseguibile penalmente ed eventualmente confrontato con una richiesta di risarcimento dei danni.

### 10. Esclusione di responsabilità

Tutto quanto riportato sulla nostra piattaforma è stato sottoposto ad accurata verifica e, da parte nostra, ci impegniamo a garantire che le informazioni da noi fornite siano aggiornate, corrette e complete. Tuttavia, non è possibile escludere del tutto la presenza di errori. Pertanto non possiamo garantire per la completezza, la correttezza e l’attualità delle informazioni. In linea di principio è esclusa qualsiasi rivendicazione di responsabilità per danni materiali o immateriali causati dall’utilizzo o dal mancato utilizzo delle informazioni offerte o dall’utilizzo di informazioni errate e incomplete.

L’editore si riserva il diritto di modificare o cancellare testi a sua esclusiva discrezione e senza preavviso e non è tenuto ad aggiornare i contenuti di questa piattaforma. L’utilizzo di questa piattaforma e l’accesso a essa avvengono a rischio e pericolo dell’utente. L’editore, i suoi clienti o partner non sono responsabili per eventuali danni, quali ad esempio danni diretti, indiretti, accidentali o consequenziali, presumibilmente causati dalla visita alla presente piattaforma e pertanto declinano qualsiasi responsabilità in tal senso.

L’editore declina inoltre qualsiasi responsabilità per i contenuti e per la disponibilità di siti web di terzi accessibili da questa piattaforma tramite link esterni. Del contenuto delle pagine alle quali fanno riferimento i link rispondono esclusivamente i rispettivi gestori. L’editore si dissocia quindi esplicitamente da tutti i contenuti di terzi che siano rilevanti in materia di responsabilità civile e penale o contrari alla pubblica decenza.

### 11. Interlocutore

In caso di domande sulla raccolta, il trattamento o l’utilizzo dei vostri dati personali e in caso di informazioni, rettifica, blocco o cancellazione dei dati personali o di revoca di consenso od opposizione al trattamento, rivolgetevi a:

Indirizzo postale: Digitalparking SA, Rütistrasse 13, 8952 Schlieren

Indirizzo e-mail: <privacy@digitalparking.ch>

### 12. Modifiche

Digitalparking SA può modificare la presente informativa sulla protezione dei dati in qualsiasi momento e senza preavviso. La versione valida è quella attualmente pubblicata sulla nostra piattaforma. Nel caso in cui l’informativa sulla protezione dei dati sia parte di un accordo con voi, vi informeremo su eventuali aggiornamenti o modifiche via e-mail o con altri mezzi adeguati.

`,
        en: `
# DATA PRIVACY STATEMENT

## CLOUD PLATFORM
## Version 10.2023

### 1. General

As we attach great importance to data privacy and data protection, we have implemented wide- ranging technical and organisational measures in line with the provisions on data privacy in order to protect your personal data. We make offers available to you via a number of different access channels (e.g. website and smartphone application) (hereinafter referred together as the "platform").

With this data privacy statement, we inform you about the processing of your personal data and the use of cookies or similar technologies on our platform.

Your personal data are collected and processed in compliance with the applicable data protection provisions, in particular Swiss Data Protection Act (DSG) and the EU's General Data Protection Regulation (GDPR) where this applies to data processing in a specific case.

### 2. Responsible provider

Digitalparking AG, Rütistrasse 13, CH-8952 Schlieren is the responsible provider of the platform and the personal data collected and processed in connection with your use of the platform.

### 3. Collection, processing and use of your personal data

The scope and type of collection, processing and use of your personal data differ according to whether you just visit our platform to gather information or whether you also correspond with us via our platform and actively use the platform as a user of the Parkingpay web or app (hereinafter referred to as "Parkingpay"), the Parkingabo web or app (hereinafter referred to as "Parkingabo"), the TWINT app (hereinafter referred to as "TWINT") or a smart device.

#### a) Used for information purposes only

It is generally not necessary to provide any personal data if you just use our platform to gather information. In this case, we only collect and process the data that are automatically sent to us by your Internet browser (in particular your IP address). This information is stored in non- anonymised form for the duration of your visit to our platform, and are subsequently analysed in anonymised form for statistical purposes only. No personal data about you are collected.

#### b) Active use of the platform / parking meters with license plate

When using such a parking meter, you always have to enter your licence plate number.

The operator of the parking spaces you use will have access to their transactions; the latter will contain the following data:

* Licence plate number
* Parking meter number
* Parking time and fee

#### c) Active use of the platform / Parkingpay

If you use the platform actively and register as a user, the data you are required to provide upon registration are stored and processed in order to allow you to use Parkingpay to book and pay for parking places.

The following data are collected:

* E-mail address
* Last and first name
* Company name (only for corporate customers)
* Address
* Licence plate number

We collect, process and use the personal data provided by you to the extent required for establishing, performing or terminating such a legal or similar contract with you or between you and the operator of the parking places used by you. We may collect, process and use your personal data for the purpose of establishing, performing and processing the contractual relationship with us and between you and the operator of the parking places used by you.

The operator of the parking spaces you use will have access to their transactions; the latter will contain the following data:

* Licence plate number, badge number, QR code reference or ticket number
* Account owner (first and last name / company name)
* Zone (parking meter and parking lot number, if applicable) or parking
* Parking time and fee

Operators cannot view or access your user account; they can only view the owner, method of payment and balance (only for prepaid) on a dedicated operator portal.

As part of your user account we also store the following data for each individual action:

* Date / time
* Type of event
* Data received or transmitted

The data are only used if support is needed and are not systematically analysed. Operators do not have access to these data. These data are stored for three months before they are automatically deleted.

#### d) Active use of the platform / TWINT

If you use the platform actively via TWINT app, the data you are required to provide upon the first use are stored in an anonymous user account and processed in order to allow you to use Parkingpay to book and pay for parking places.

The following data are collected:

* License plate
* Reference to TWINT account (pseudonymized ID)

We collect, process and use the personal data provided by you to the extent required for establishing, performing or terminating such a legal or similar contract with you or between you and the operator of the parking places used by you. We may collect, process and use your personal data for the purpose of establishing, performing and processing the contractual relationship with us and between you and the operator of the parking places used by you.

The operator of the parking spaces you use will have access to their transactions; the latter will contain the following data:

* License plate, QR code reference or ticket number
* Zone (parking meter and parking lot number, if applicable) or parking
* Parking time and fee

Operators cannot view or access your user account.

As part of your user account we also store the following data for each individual action:

* Date / time
* Type of event
* Data received or transmitted

The data are only used if support is needed and are not systematically analysed. Operators do not have access to these data. These data are stored for three months before they are automatically deleted.

#### e) Active use of the platform / Parkingabo

If you use the platform actively and register as a user, the data you are required to provide upon registration are stored and processed in order to allow you to use Parkingabo to book and pay for parking places.

The following data are collected:

* E-mail address
* Last and first name
* Company name (only for corporate customers)
* Address
* Vehicles (licence plate, badge and QR code)
* Reference to the mean of payment (pseudonymised ID)
* Operator specific references

We collect, process and use the personal data provided by you to the extent required for establishing, performing or terminating such a legal or similar contract with you or between you and the operator of the parking places used by you. We may collect, process and use your personal data for the purpose of establishing, performing and processing the contractual relationship with us and between you and the operator of the parking places used by you.

The operator of the parking spaces you use will have access to their transactions; the latter will contain the following data:

* Licence plate number, badge number, QR code reference
* Account owner (first and last name / company name)
* Zone or parking
* Parking time and fee

The operator has full access to your user account and may adjust the data, with the exception of the access data and means of payment; in the case of the means of payment, he can only see whether it exists and is valid, but cannot view or change the details.
All data in the user account, with the sole exception of access data, is operator-specific and therefore only accessible to the corresponding operator.

As part of your user account we also store the following data for each individual action:

* Date / time
* Type of event
* Data received or transmitted

The data are only used if support is needed and are not systematically analysed. Operators do not have access to these data. These data are stored for three months before they are automatically deleted.

#### f) Active use of the platform / Online shop without registration

If you use the platform actively via the online shop, the data you are required to provide upon the use are stored in an anonymous user account and processed in order to allow you to use Parkingpay to book and pay for parking places.

The following data are collected:

* Reference to the device (pseudonymised IDs)
* Reference to the means of payment (pseudonymised IDs)
* E-mail address (optional)

After successful execution of the payment, the means of payment is deleted immediately.
In the case of non-executed payments, the means of payment is deleted after 7 days at the latest.

We collect, process and use the personal data provided by you to the extent required for establishing, performing or terminating such a legal or similar contract with you or between you and the operator of the parking places used by you. We may collect, process and use your personal data for the purpose of establishing, performing and processing the contractual relationship with us and between you and the operator of the parking places used by you.

The operator of the parking spaces you use will have access to their transactions; the latter will contain the following data:

* Ticket number, license plate or QR code reference
* Zone (parking meter and parking lot number, if applicable) or parking
* Parking time and fee

Operators cannot view or access the anonymous account.

As part of your user account we also store the following data for each individual action:

* Date / time
* Type of event
* Data received or transmitted

The data are only used if support is needed and are not systematically analysed. Operators do not have access to these data. These data are stored for three months before they are automatically deleted.

#### Impersonal data relating to platform access

We collect information about you when you use the platform. We automatically capture information on your user behaviour and your interaction with us and register data pertaining to your computer or mobile device. We collect, store and use data every time you access our platform (in server log files). These access data include:

* Name and URL of the requested file
* Date and time of access
* Volume of data transmitted
* Confirmation that access was successful (HTTP response code)
* Browser type and version
* Operating system
* Referrer's URL (i.e. the previous site visited by you)
* Websites that are called up by the user's system via our website
* User's Internet service provider
* IP address and requesting provider

We use these data without allocating them to your person or creating any profiles for preparing statistical analyses concerning the operation, security and optimisation of our website as well as to anonymously capture information about the number of visitors to our website (traffic) and the scope and type of use of our website and services. We can use this information to analyse the data traffic, search for and rectify errors, and improve our services. Where the GDPR applies, this also establishes our legitimate interest pursuant to point 1 (f) of Art. 6 para. 1 GDPR.

We reserve the right to subsequently check the protocol data if there is specific evidence to legitimately suspect the unlawful use of the website. We delete the log files after one month if they are no longer needed for security purposes and there is no specific suspicion of a criminal offence related to the use of our website.

### 4. Your rights

You have various rights pertaining to your personal data under the applicable laws. If you wish to assert these rights, please address your enquiry by e-mail or post to the address provided in section 8, making sure that you clearly identify yourself.

The following is an overview of your rights.

### 4.1. Right to confirmation and information

You have the right to receive well-structured information regarding the processing of your personal data. This means that you have the right at any time to request confirmation from us on whether any of your personal data are being processed. If this is the case, you have the right to request information from us free of charge regarding your stored personal data as well as a copy of these data. You also have a right to the following information:

* Purposes of processing
* Categories of personal data that are processed;
* recipients or categories of recipients to whom the personal data were or will be disclosed, in particular regarding recipients in foreign countries or with international organisations;
* if possible, the planned duration of storage of the personal data or, if this is impossible, the criteria for determining this duration;
* the existence of a right to rectification or erasure of personal data concerning you or to restriction of processing by the Controller or a right to object to such processing;
* the existence of a right of appeal to a supervisory authority;
* if the personal data are not collected from you, all available information concerning the origin of the data;
* the existence of automated decision-making, including profiling in accordance with Art. 22 paras. 1 and 4 GDPR and - at least in these cases - meaningful information on the logic involved and the scope and intended effects of such processing for you;
* if personal data are transmitted to a foreign country or an international organisation, you have the right to be instructed about the appropriate safeguards to protect the transmission pursuant to Art. 46 GDPR.

### 4.2. Right to rectification

You have the right to require us to rectify and possibly complete your personal data. This means that you have the right to require us to immediately rectify any incorrect personal data about you. Taking into account the purposes of processing, you have the right to have incomplete personal data completed, including by means of providing a supplementary statement.

You can also directly process your personal data stored in your user account.

### 4.3. Right to erasure ("right to be forgotten")

In many cases we are obliged to erase your personal data. You therefore have the right, in particular where Art. 17 para. 1 GDPR applies, to require us to erase your personal data without undue delay and we will be obliged to erase your personal data without undue delay where one of the following grounds applies:

* the personal data are no longer necessary in relation to the purposes for which they were collected or otherwise processed;
* you withdraw your consent on which the processing is based and there are no other legal grounds for the processing;
* you object to the processing pursuant to Art. 21 para. 1 GDPR and there are no overriding legitimate grounds for the processing, or you object to the processing pursuant to Art. 21 para. 2 GDPR;
* the personal data have been unlawfully processed;
* the personal data have to be erased for compliance with a legal obligation to which we are subject;
* the personal data have been collected in relation to the offer of information society services referred to in Art. 8 para. 1 GDPR.

You can erase the personal data stored in your user account as well as the account itself at any time.

### 4.4. Right to restriction of processing

In many cases you are entitled to ask us to restrict the processing of your personal data. You therefore have the right to require us to restrict the processing where one of the following applies:

* the accuracy of the personal data is contested by you, for a period enabling us to verify the accuracy of the personal data;
* the processing is unlawful and you opposed the erasure of the personal data and requested the restriction of their use instead;
* we no longer need the personal data for the purposes of the processing, but they are required by you for the establishment, exercise or defence of legal claims;
* you objected to processing pursuant to Art. 21 para. 1 GDPR pending verification whether our legitimate grounds override yours.

### 4.5. Right to data portability

Every data subject affected by the processing of personal data has the right to receive their personal data in a structured, commonly used and machine-readable format. In addition, the data subject has the right to have the personal data transmitted directly from one controller to another controller, insofar as this is technically feasible and insofar as the rights and freedoms of others are not adversely affected by this.

In order to assert your right to data portability, you can send your request by e-mail or post, clearly identifying yourself, to the address indicated in Section 11.

### 4.6. Right to object

Every data subject affected by the processing of personal data has the right to object to the processing of their personal data at any time on grounds relating to their particular situation.

In the event of such an objection, Digitalparking shall no longer process the personal data, unless we can demonstrate compelling legitimate grounds for the processing which override the interests, rights and freedoms of the data subject, or for the establishment, exercise or defence of legal claims.

To exercise your right to object, you can send your request by e-mail or post, clearly identifying yourself, to the address indicated in Section 11.

### 4.7. Right to withdraw the declaration of consent for data processing

Right to withdraw the declaration of consent for data processing

###  4.8. Right to lodge a complaint with a supervisory authority

You have the right to lodge a complaint with a supervisory authority if you believe that the processing of your personal data is unlawful. In Switzerland, complaints can be lodged with the Federal Data Protection and Information Commissioner (www.edoeb.admin.ch), and outside of Switzerland with the supervisory authority at your habitual residence or place of domicile.

### 5. External service providers / data transfers abroad

We generally only use your personal data within our company. When and to the extent that we involve third parties in the performance of contracts (e.g. providers of logistics services), these third parties are only sent the personal data they need to perform the respective service.

Where we outsource specific components of data processing (to a processor), we contractually oblige the processor to only use personal data in compliance with the provisions of data protection legislation and to warrant the protection of the rights of the affected parties. Except for the cases mentioned in this statement, data are not transmitted to organisations or persons outside Switzerland or the EU.

### 5.1. Hosting

We use hosting services in order to provide the following services: infrastructure and platform services, computing capacity, storage capacity and database services, security services and technical maintenance services that we use for the operation of the website.

We and our hosting provider process portfolio data, contact data, content data, contractual data, usage data, meta data and communication data of customers, interested parties and visitors to this website based on our legitimate interest in providing an efficient and secure website (the applicable provisions in the area of application of the GDPR are point 1 (f) of Art. 6 para. 1 GDPR in conjunction with Art. 28 GDPR).

### 5.2. Providers of payment services

We use Datatrans Ltd., a provider of payment services to execute the payment transactions. We have concluded a processor contract pursuant to Art. 9 FADP and Art. 28 GDPR with Datatrans Ltd.

### 6. Cookies and Local Storage

A cookie is a small text file containing all sorts of information which is filed on your computer by the web browser.

Local storage (HTML5 Local Storage) is a small database containing all sorts of information which is integrated in the web browser on your computer.

#### a) Used for information purposes only

We use neither cookies nor local storage.

#### b) Active use of platform / Parkingpay

We use cookies and local storage when you log on to our web applications.

Cookies are used to link the different requests by your browser to the shared authenticated visit in order to protect your account against unauthorised access. These cookies are stored on your hard disk and are automatically deleted again after the specified period. Their lifetime is either one day («Stay signed in» is inactive) or 90 days («Stay signed in» is active).

The following data and information are stored in the cookies:

* Session identification (pseudonymised IDs)

You can change the settings on your browser to notify you when a cookie should be set, which will allow you to approve or reject cookies on an ad hoc basis or to generally block all cookies. This can restrict the functionality of the web application.

Local storage is used to make the application more user-friendly. The information kept in local storage is never deleted automatically.

The following data and information are saved in local storage:

* Language code
* Last selected options (pseudonymised IDs)
* Last selected postcode

You can change your browser settings to completely prevent the local storage of information. This can restrict the functionality of the web application.

Our legitimate interest in the use of cookies and local storage pursuant to point (f) of Art. 6 para. 1 GDPR lies in our ambition to make our web applications more user-friendly, more effective and more secure.

### 7. Storage period

Unless specifically indicated otherwise, we store personal data only for as long as required to meet the purposes pursued by us.

In some cases the legislator requires us to store personal data, e.g. under tax or commercial law. In these cases we store the data for longer to meet these legal requirements without any further processing, and we delete the data after expiry of the statutory safekeeping period.

### 8. Data security

We do our utmost to ensure the security of your data in compliance with the applicable data protection laws and the technical possibilities.

We transmit your personal data in encrypted form using the SSL (Secure Socket Layer) system. This applies to your master data, your transactions and also to customer log ins. Please note, however, that the transmission of data via the Internet can never be completely secure. It is impossible to completely protect data from access by third parties.

We implement and constantly update technical and organisational security measures pursuant to Art. 32 GDPR to secure your data.

We also cannot guarantee that our services will be available at certain times, as malfunctions, interruptions or breakdowns cannot be excluded. The servers used by us are regularly and carefully secured.

### 9. Copyright

The copyright and all other rights to the content, images, photos or other files on the platform belong exclusively to the operator of this platform or the named rights holders. The written consent of the copyright holder must be obtained before reproducing all the files.

Anyone who commits a copyright infringement without the respective copyright holder's consent may be liable to prosecution and may be subject to damages claims.

### 10. Disclaimer

All information on our platform has been carefully checked. We make every effort to ensure that the information we provide is up-to-date, accurate and complete. Nevertheless, errors cannot be completely ruled out, and therefore we hereby disclaim liability for whether the information is complete, correct and up-to-date. As a matter of principle, we hereby disclaim all liability for any pecuniary or non-pecuniary damage caused by using or failing to use the information provided or by using incorrect or incomplete information.

The publisher may change or delete texts at its own discretion and without prior notice and is under no obligation to update the contents of this platform. The use of or access to this platform is at the visitor’s own risk. The publisher, its customers and partners are not responsible for any damages (e.g. direct, indirect, incidental or consequential damages) allegedly caused by visiting this platform and therefore bear no liability for such damages.

The publisher also bears no responsibility or liability for the content or availability of third-party websites that may be accessed via external links from this platform. Responsibility for the contents of linked sites lies solely with the operators of those sites. The publisher therefore expressly dissociates itself from any third-party content which might have criminal-law or liability-law implications or which violates common decency.

### 11. Contact person

If you have any questions on the collection, processing or use of your personal data, information, amendments, blocking or erasure of personal data and the withdrawal of consent for processing or the lodging of a complaint, please contact:

Postal address: Digitalparking AG, Rütistrasse 13, 8952 Schlieren

E-mail address: privacy@digitalparking.ch

### 12. Changes

Digitalparking AG may amend this Data Privacy Statement at any time without prior notice. The current version as published on our platform shall apply. If this Data Privacy Statement is part of an agreement with you, in the event of an update we will notify you of the changes via e-mail or other suitable method.
`,
    };

    export const twintTosMarkdown: { [key in Language]: string } = {
        de: `
# ALLGEMEINE GESCHÄFTSBEDINGUNGEN

## Für Benutzer via TWINT
## Version 07.2022

### 1. Präambel

Digitalparking AG (nachfolgend „Digitalparking“ genannt) bietet unter dem Namen „Parkingpay“ Dienstleistungen an, welche TWINT-Benutzern (natürliche oder juristischen Personen, nachfolgend „Benutzer“ genannt) ermöglicht, die Nutzung von entsprechend ausgerüsteten Parkplätzen abzuwickeln.

Im Namen und mit Ermächtigung der am Parkingpay-System angeschlossenen Betreiber von Parkplätzen (Gemeinden, Städte, Betreiber von Parkhäusern usw., nachfolgend „Betreiber“ genannt) übernimmt Digitalparking die Erhebung und bargeldlose Abrechnung der anfallenden Gebühren gegenüber dem Benutzer.

Die Abrechnung der Parkinggebühr für die Nutzung von Parkplätzen mit dem Parkingpay-System zu den jeweils geltenden Tarifen erfolgt über das TWINT-Konto des Benutzers.

Die Parkingpay-Funktionen können nur im Rahmen der verfügbaren Limite vom TWINT-Konto genutzt werden.

### 2. Aktivierung

Der Benutzer muss einmalig diesen Allgemeinen Geschäftsbedingungen (nachfolgend „AGB“ genannt) und der separaten Datenschutzerklärung zustimmen. Die Aktivierung ist kostenlos. Ein Anspruch auf Aktivierung besteht nicht.

Sowohl der Benutzer als auch Digitalparking können die Parkingpay-Funktionen jederzeit ohne Einhaltung einer Kündigungsfrist deaktivieren.

Digitalparking ist zudem berechtigt, die Funktionen jederzeit zu sperren, wenn

* der Benutzer gegen die AGB oder gegen geltendes Recht verstösst,
* der Benutzer falsche Angaben gemacht hat oder
* eine missbräuchliche Nutzung vermutet wird.

### 3. Nutzungsvertrag

Mit der Zustimmung zu den AGB bei der Aktivierung kommt zwischen Digitalparking und dem Benutzer ein darauf basierender Vertrag zustande. Der Benutzer hat weder ein Rücktritts- noch ein Rückgaberecht der bezogenen Leistung.

### 4. Informationspflicht des Benutzers

Der Benutzer verpflichtet sich, Änderungen seiner vertragswesentlichen Daten unverzüglich selber vorzunehmen.

Kommt der Benutzer seiner Informationspflicht nicht nach, so ist Digitalparking berechtigt, dem Benutzer die ihr entstehenden Mehraufwendungen in Rechnung zu stellen.

### 5. Sorgfaltspflicht des Benutzers

Stellt der Benutzer missbräuchliche Parkgebühren-Belastungen über sein TWINT-Konto fest, ist er verpflichtet, Digitalparking oder den TWINT-Issuer darüber unverzüglich zu unterrichten, damit seine Parkingpay-Funktionen gesperrt werden können. Das gleiche gilt bei Verlust oder Diebstahl des Mobiltelefons.

Bei missbräuchlicher Benutzung durch Dritte entfällt die Haftung des Benutzers für Schäden, die nach dem Eingehen der Mitteilung an Digitalparking belastet werden.

### 6. Gebühren

#### Parkgebühren

Für die Nutzung der gebührenpflichtigen Parkplätze werden dem Benutzer die Gebühren gemäss den Tarifen des entsprechenden Parkingbetreibers belastet. Bei entsprechendem ausdrücklichem Hinweis, können für die Benutzung einzelner Parkingpay-Funktionen Zuschläge erhoben werden.

#### Kommunikationsgebühren

Fallen für die Nutzung der Parkingpay-Funktionen Kommunikationskosten (Internet- und Mobiltelefongebühren) an, sind diese durch den Benutzer zu tragen.

### 7. Fehlerfall oder inkorrekte Nutzung des Systems

Wenn es dem Benutzer nicht gelingen sollte, über Parkingpay zu bezahlen, ist er in jedem Falle dazu verpflichtet, die Parkgebühr auf konventionellem Weg zu bezahlen. Falls es keine solche Möglichkeit gibt, darf der Benutzer nicht parkieren. Der Parkvorgang bei Parkplätzen ohne Zugangskontrolle gilt als gestartet, wenn die dazugehörige Zahlung ausdrücklich vom TWINT bestätigt wurde.

### 8. Parkbussen

Erhält der Benutzer eine Parkbusse und bestreitet er diese, so hat er sich ausschliesslich an die auf dem entsprechenden Bussenbeleg aufgeführte Stelle zu wenden.

Wenn nachweislich ein korrekter Parkvorgang mit dem Parkingpay-System stattgefunden hat, findet der Benutzer den entsprechenden Beleg als URL in der Transaktionshistorie der TWINT-App. Über eine Aufhebung einer Parkbusse entscheidet in jedem Falle die zuständige Stelle. Digitalparking haftet in keinem Fall für zu bezahlende Parkbussen.

Höchstparkzeiten und Nachzahlungsverbote gelten in jedem Fall auch bei Nutzung des Parkingpay-Systems. Beim Erreichen der geltenden Höchstparkzeit wird der Parkvorgang automatisch beendet.

Nach dem Ablauf jedes Parkvorgangs muss das Fahrzeug wieder in den Verkehr eingefügt werden. Ist dies nicht der Fall und es wird ein neuer Parkvorgang gestartet, muss trotz laufendem Parkvorgang mit einer Parkbusse gerechnet werden. Ein blosses Verschieben des Fahrzeuges auf einen anderen, in der Nähe liegenden Parkplatz ist unzulässig. Davon ausgenommen sind Parkplätze, bei welchen das Nachzahlen gemäss den geltenden Bestimmungen zulässig ist.

### 9. Haftung

Digitalparking haftet nach den gesetzlichen Bestimmungen für Schäden, sofern diese auf Vorsatz, grobe Fahrlässigkeit oder die schuldhafte Verletzung wesentlicher Vertragspflichten von Digitalparking zurückzuführen sind.

Digitalparking übernimmt keine Verantwortung oder Haftung für die ständige Verfügbarkeit der Parkingpay-Funktionen, insbesondere nicht bezüglich der Verfügbarkeit der Mobilfunknetze und der Funktionsfähigkeit von mobilen Endgeräten.

Bei Nichtverfügbarkeit des Parkingpay-Systems kann Digitalparking nicht für die entstehenden Folgekosten wie Parkbussen, Abschleppgebühren etc. verantwortlich gemacht werden.

Für fehlerhafte oder nicht erfolgte Beendigung von Parkvorgängen übernimmt Digitalparking keine Haftung.

### 10. Änderungen oder Ergänzungen dieser Geschäftsbedingungen

Digitalparking behält sich vor, diese AGB jederzeit und ohne Nennung von Gründen zu ändern.

Änderungen oder Ergänzungen werden dem Benutzer bei der nächsten Nutzung der Parkingpay-Funktionen eingeblendet.

Sofern eine Bestimmung dieser AGB unwirksam ist, bleiben die übrigen Bestimmungen davon unberührt. Die unwirksame Bestimmung gilt als durch eine solche ersetzt, die dem Sinn und Zweck der unwirksamen Bestimmung in rechtswirksamer Weise wirtschaftlich am nächsten kommt. Gleiches gilt für eventuelle Regelungslücken.

Mündliche Nebenabreden haben keine Gültigkeit.

### 11. Anwendbares Recht und Gerichtsstand

Das Vertragsverhältnis unterliegt Schweizer Recht. Als Gerichtsstand gilt Schlieren.
`,
        fr: `
# CONDITIONS GÉNÉRALES DE VENTE

## Pour les usagers via TWINT
## Version 07.2022

### 1. Préambule

Digitalparking SA (ci-après: «Digitalparking») propose sous le nom «Parkingpay» des prestations permettant à un utilisateur TWINT (personne physique ou morale, ci-après «l’usager») de s’acquitter de sa taxe de stationnement pour les parkings équipés en conséquence.

Au nom et avec l’habilitation des exploitants de parkings affiliés au système Parkingpay (communes, villes, exploitants de parkings à étages etc., ci-après: «l’exploitant»), Digitalparking se charge de la perception des éventuelles taxes dues par les usagers et de leur règlement dématérialisé à l’exploitant.

Les frais de stationnement pour l'utilisation des espaces de stationnement avec le système Parkingpay aux tarifs applicables sont imputés au compte TWINT de l'utilisateur.

Les fonctions Parkingpay ne peuvent être utilisées que dans les limites du compte TWINT.

### 2. Activation

L’usager est tenu d’accepter ces conditions générales (ci-après: «CGV»), ainsi que la déclaration sur la protection des données. L’activation est gratuite, mais ne constitue pas un droit en soi.

L’usager aussi bien que Digitalparking peuvent désactiver les fonctions Parkingpay, à tout moment et sans préavis.

Digitalparking est en outre habilité à bloquer les fonctions en tout temps dès lors que

* l’usager contrevient aux CGV ou au droit en vigueur,
* si l'usager a fait de fausses déclarations ou encore,
* s’il y a suspicion d’usage frauduleux ou abusif.

### 3. Contrat d'utilisation

L'acceptation des CGV au moment de l’activation lie l'usager à Digitalparking par un contrat basé sur lesdites CGV.

L'usager n'a aucun droit de rétractation ni droit de restitution sur les prestations acquises.

### 4. Devoir d’information de la part de l’usager

L’usager s’engage à corriger, sur son compte et sans délai, toute modification eventuelle des données contractuelles.

Si l’usager ne satisfait pas à ce devoir d’information, Digitalparking est habilité à facturer à l’usager les coûts supplémentaires que ce manquement aura générés.

### 5. Devoir de diligence de l’usager

Si l’usager constate des charges abusives sur son compte TWINT concernant les taxes de stationnemment, il est tenu d’informer sans délai Digitalparking ou l’émetteur TWINT afin que son compte Parkingpay puisse être bloqué. Ceci vaut également en cas de perte ou de vol du téléphone mobile.

En cas d’usage frauduleux par des tiers, l’usager est dégagé de toute responsabilité quant aux dommages survenant après réception de l’information par Digitalparking/émetteur TWINT.

### 6. Coûts

Taxes de stationnement

Pour l’utilisation des parkings payants, l’usager est débité des taxes correspondantes aux tarifs de l’exploitant du parking. À condition que ceux-ci soient mentionnés expressément, des suppléments peuvent être appliqués pour l'utilisation de certaines fonctions Parkingpay.

Frais de communication

Les éventuels frais de communication générés par l’utilisation de Parkingpay (Internet et téléphone mobile) seront à la charge de l’usager.

### 7. Erreur ou usage incorrect du système

Si l’usager ne parvient pas à payer via Parkingpay, il est tenu de s’acquitter de la taxe par les moyens conventionnels. Si une telle possibilité n'existe pas, l'utilisateur n'a pas le droit de stationner. La procédure de stationnement pour les parkings sans contrôle d'accès est considérée comme démarrée si le paiement correspondant a été expressément confirmé par TWINT.

### 8. Amende de stationnement

Pour toute contestation d’amende de stationnement, l’usager doit s’adresser exclusivement à l’instance indiquée sur l’amende.

S’il est avéré que le stationnement s’est déroulé dans les règles, par le biais du système Parkingpay, l’usager trouve le justificatif correspondant dans l'historique des transactions de l’appli TWINT. L’annulation de l’amende demeure, dans tous les cas, l’affaire de l’instance émettrice. Digitalparking n’est en aucun cas responsable du paiement d’amendes de stationnement.

Les durées maximales de stationnement et les interdictions de prolongation s’appliquent dans tous les cas, même dans le cadre de l’utilisation du système Parkingpay. En cas d'atteinte de la durée maximale de stationnement, le système arrête automatiquement la procédure.

Après la fin de chaque procédure de stationnement, le véhicule doit être remis en circulation. Si cela ne se produit pas et qu'une nouvelle procédure de stationnement est démarrée, l’usager peut recevoir une amende, même si la procédure de stationnement a été démarrée correctement. Il n'est pas permis de simplement déplacer le véhicule vers une autre place de stationnement adjacente. Ceci ne s'applique pas aux zones où, conformément à la réglementation en vigueur, il est permis de payer plusieurs fois de suite.

### 9. Responsabilité civile

Aux termes de la loi, Digitalparking est responsable des dommages causés intentionnellement, par négligence grave ou infraction fautive à des devoirs contractuels majeurs de la part de Digitalparking.

La responsabilité de Digitalparking n’est pas engagée quant à la permanence de la disponibilité des fonctions Parkingpay, en particulier concernant la disponibilité des réseaux de téléphonie mobile et le bon fonctionnement des terminaux de téléphonie mobile.

En cas d’inaccessibilité au système Parkingpay, Digitalparking ne saurait être tenu pour responsable des coûts qui en résultent, tels qu’amendes de stationnement, frais d’enlèvement etc.

La responsabilité de Digitalparking n’est pas engagée en cas d’erreur ou d’absence de clôture de la procédure de stationnement.

### 10. Modifications et ajouts aux présentes conditions générales

Digitalparking se réserve le droit de modifier à tout moment et sans justification les présentes CGV.

Les modifications ou ajouts seront affichés à l'utilisateur la prochaine fois que les fonctions Parkingpay seront utilisées.

Les nouvelles CGV sont considérées comme acceptées si l'utilisateur les confirme et continue avec l’utilisation des fonctions Parkingpay.

Si une disposition des présentes CGV devait être invalide, les autres dispositions n'en seraient aucunement affectées. La disposition invalide serait alors remplacée par une disposition qui se rapprocherait le plus possible, sur le plan économique et légal, du sens et de l'objectif de la disposition invalide. Il en va de même pour les éventuelles lacunes réglementaires.

Tout accord oral annexe est nul et non-avenu.

### 11. Droit applicable et for

La relation contractuelle est soumise au droit suisse et le for juridique est Schlieren.

`,
        it: `
# CONDIZIONI GENERALI

## Per gli utenti via TWINT
## Versione 07.2022

### 1. Premessa

Digitalparking SA (di seguito «Digitalparking») offre sotto il nome “Parkingpay” dei servizi che permettono agli utenti TWINT (persona fisica o giuridica, di seguito «utente») di utilizzare i parcheggi adeguatamente attrezzati.

Digitalparking si occupa, in nome e per conto del gestore del parcheggio aderente al sistema Parkingpay (comuni, città, gestori di autosili ecc., di seguito «gestore»), della riscossione senza contanti e del conteggio delle eventuali tasse di parcheggio a carico dell’utente.

Le tasse per l’utilizzo dei parcheggi con il sistema Parkingpay vengono addebitate, secondo le tariffe in vigore, sul conto TWINT dell'utente.

Le funzioni Parkingpay possono essere utilizzate solo entro i limiti del conto TWINT.

### 2. Attivazione

L’utente deve accettare queste Condizioni generali (di seguito «CG»), come pure l’informativa sulla protezione dei dati. L’attivazione è gratuita. Non sussiste alcun diritto all’attivazione.

Le funzioni Parkingpay possono essere disattivate in qualsiasi momento, sia dall’utente che da Digitalparking, senza alcun termine di preavviso.

Digitalparking può bloccare in qualsiasi momento le funzioni se:

* l’utente contravviene alle disposizioni delle CG o viola le leggi vigenti;
* l’utente ha fornito dati personali errati;
* si sospetta un utilizzo improprio.

### 3. Contratto di utilizzo

Con l’accettazione delle CG al momento dell’attivazione, l’utente stipula un contratto con Digitalparking basato sulle suddette condizioni.

Per le prestazioni percepite non è possibile avvalersi del diritto di recesso o di restituzione.

### 4. Obbligo di informazione dell’utente

L’utente si impegna ad aggiornare tempestivamente le informazioni fondamentali nel quadro del contratto.

In caso di mancato adempimento di tali obblighi informativi, Digitalparking è autorizzata ad addebitare all’utente le eventuali spese supplementari che ha dovuto sostenere.

### 5. Obbligo di diligenza dell’utente

Se l’utente constata degli addebiti irregolari sul proprio conto TWINT, riguardanti le tasse di parcheggio, è tenuto a informare tempestivamente Digitalparking oppure l’emittente TWINT per fare in modo che le funzioni Parkingpay vengano bloccate. Occorre procedere analogamente in caso di smarrimento o furto del cellulare.

In caso di utilizzo improprio da parte di terzi, decade la responsabilità dell’utente per qualsiasi danno che si sia verificato successivamente al ricevimento della comunicazione da parte di Digitalparking o dell’emittente TWINT.

### 6. Tasse e costi

#### Tasse di parcheggio

Per la sosta nei parcheggi a pagamento, all’utente vengono addebitate le tasse di parcheggio in base alle tariffe stabilite dal gestore. Laddove espressamente indicato, può essere richiesto il pagamento di un supplemento per l’utilizzo di alcune funzioni Parkingpay.

#### Costi per le comunicazioni

Se l’utilizzo delle funzioni Parkingpay comporta dei costi per le comunicazioni (via Internet o cellulare), questi sono a carico dell’utente.

### 7. Errore o utilizzo non corretto del sistema

Se l’utente non dovesse riuscire a pagare tramite Parkingpay, è comunque tenuto a pagare la tassa di parcheggio in modo convenzionale. Nel caso non ci fosse tale possibilità, l’utente non ha diritto a parcheggiare. La procedura di parcheggio nei parcheggi senza controllo degli accessi si considera avviata solo se il pagamento corrispondente è stato espressamente confermato da TWINT.

### 8. Contravvenzioni di parcheggio

Se l’utente riceve una contravvenzione di parcheggio, per contestarla deve rivolgersi esclusivamente all’ufficio riportato sulla contravvenzione stessa.

Se la procedura di parcheggio con il sistema Parkingpay è avvenuta correttamente, l'utente trova il documento corrispondente nella cronologia delle transazioni dell'app TWINT. Riguardo all’annullamento di una contravvenzione di parcheggio decide esclusivamente l’ufficio competente e Digitalparking non è in nessun caso responsabile per eventuali contravvenzioni che devono essere pagate.

I limiti di durata del parcheggio e i divieti di prolungamento del parcheggio vengono applicati anche in caso di utilizzo del sistema Parkingpay. Quando viene raggiunto il tempo massimo di parcheggio, la procedura di parcheggio viene terminata automaticamente.

Dopo la fine di ogni procedura di parcheggio, il veicolo dev’essere rimesso in circolazione; se questo non avviene e una nuova procedura di parcheggio viene avviata, si può andare in contro a una contravvenzione, nonostante la procedura di parcheggio valida. Non è consentito spostare semplicemente il veicolo in un altro parcheggio adiacente. Questo non si applica ai parcheggi in cui, in conformità con le normative applicabili, è consentito pagare più volte di seguito.

### 9. Responsabilità

Conformemente alle disposizioni di legge, Digitalparking risponde dei danni imputabili a dolo, colpa grave o violazione intenzionale di obblighi contrattuali fondamentali da parte di Digitalparking.

Digitalparking non si assume nessuna responsabilità generale o civile per la costante disponibilità delle funzioni Parkingpay, con particolare riferimento alla disponibilità delle reti di telefonia mobile e al funzionamento dei terminali mobili.

In caso di mancata disponibilità del sistema Parkingpay, Digitalparking non può essere chiamata in causa come responsabile dei costi conseguenti a carico dell’utente, quali multe nel parcheggio, spese di traino ecc.

Digitalparking non si assume alcuna responsabilità per procedure di parcheggio non correttamente terminate o non eseguite.

### 10. Variazioni o aggiunte alle presenti condizioni

Digitalparking si riserva il diritto di modificare in qualsiasi momento le presenti CG senza necessità di motivare le ragioni. Le eventuali variazioni o aggiunte verranno comunicate all’utente al successivo utilizzo delle funzioni Parkingpay.

Le nuove CG vengono considerate accettate se l’utente le conferma e prosegue con l’utilizzo delle funzioni Parkingpay.

L’inefficacia di una qualsiasi delle disposizioni contenute nelle presenti CG non comporta l’inefficacia delle altre disposizioni. La disposizione inefficace si considera sostituita da un’altra che più si avvicina al senso e all’obiettivo della disposizione originale da un punto di vista sia giuridico che commerciale. Si procede analogamente in caso di eventuali lacune normative.

Eventuali accordi accessori verbali sono privi di validità giuridica.

### 11. Diritto applicabile e foro competente

Il rapporto contrattuale soggiace al diritto svizzero. Foro competente è Schlieren.
`,
        en: `
# ALLGEMEINE GESCHÄFTSBEDINGUNGEN

## Für Benutzer via TWINT
## Version 07.2022

### 1. Präambel

Digitalparking AG (nachfolgend „Digitalparking“ genannt) bietet unter dem Namen „Parkingpay“ Dienstleistungen an, welche TWINT-Benutzern (natürliche oder juristischen Personen, nachfolgend „Benutzer“ genannt) ermöglicht, die Nutzung von entsprechend ausgerüsteten Parkplätzen abzuwickeln.

Im Namen und mit Ermächtigung der am Parkingpay-System angeschlossenen Betreiber von Parkplätzen (Gemeinden, Städte, Betreiber von Parkhäusern usw., nachfolgend „Betreiber“ genannt) übernimmt Digitalparking die Erhebung und bargeldlose Abrechnung der anfallenden Gebühren gegenüber dem Benutzer.

Die Abrechnung der Parkinggebühr für die Nutzung von Parkplätzen mit dem Parkingpay-System zu den jeweils geltenden Tarifen erfolgt über das TWINT-Konto des Benutzers.

Die Parkingpay-Funktionen können nur im Rahmen der verfügbaren Limite vom TWINT-Konto genutzt werden.

### 2. Aktivierung

Der Benutzer muss einmalig diesen Allgemeinen Geschäftsbedingungen (nachfolgend „AGB“ genannt) und der separaten Datenschutzerklärung zustimmen. Die Aktivierung ist kostenlos. Ein Anspruch auf Aktivierung besteht nicht.

Sowohl der Benutzer als auch Digitalparking können die Parkingpay-Funktionen jederzeit ohne Einhaltung einer Kündigungsfrist deaktivieren.

Digitalparking ist zudem berechtigt, die Funktionen jederzeit zu sperren, wenn

* der Benutzer gegen die AGB oder gegen geltendes Recht verstösst,
* der Benutzer falsche Angaben gemacht hat oder
* eine missbräuchliche Nutzung vermutet wird.

### 3. Nutzungsvertrag

Mit der Zustimmung zu den AGB bei der Aktivierung kommt zwischen Digitalparking und dem Benutzer ein darauf basierender Vertrag zustande. Der Benutzer hat weder ein Rücktritts- noch ein Rückgaberecht der bezogenen Leistung.

### 4. Informationspflicht des Benutzers

Der Benutzer verpflichtet sich, Änderungen seiner vertragswesentlichen Daten unverzüglich selber vorzunehmen.

Kommt der Benutzer seiner Informationspflicht nicht nach, so ist Digitalparking berechtigt, dem Benutzer die ihr entstehenden Mehraufwendungen in Rechnung zu stellen.

### 5. Sorgfaltspflicht des Benutzers

Stellt der Benutzer missbräuchliche Parkgebühren-Belastungen über sein TWINT-Konto fest, ist er verpflichtet, Digitalparking oder den TWINT-Issuer darüber unverzüglich zu unterrichten, damit seine Parkingpay-Funktionen gesperrt werden können. Das gleiche gilt bei Verlust oder Diebstahl des Mobiltelefons.

Bei missbräuchlicher Benutzung durch Dritte entfällt die Haftung des Benutzers für Schäden, die nach dem Eingehen der Mitteilung an Digitalparking belastet werden.

### 6. Gebühren

#### Parkgebühren

Für die Nutzung der gebührenpflichtigen Parkplätze werden dem Benutzer die Gebühren gemäss den Tarifen des entsprechenden Parkingbetreibers belastet. Bei entsprechendem ausdrücklichem Hinweis, können für die Benutzung einzelner Parkingpay-Funktionen Zuschläge erhoben werden.

#### Kommunikationsgebühren

Fallen für die Nutzung der Parkingpay-Funktionen Kommunikationskosten (Internet- und Mobiltelefongebühren) an, sind diese durch den Benutzer zu tragen.

### 7. Fehlerfall oder inkorrekte Nutzung des Systems

Wenn es dem Benutzer nicht gelingen sollte, über Parkingpay zu bezahlen, ist er in jedem Falle dazu verpflichtet, die Parkgebühr auf konventionellem Weg zu bezahlen. Falls es keine solche Möglichkeit gibt, darf der Benutzer nicht parkieren. Der Parkvorgang bei Parkplätzen ohne Zugangskontrolle gilt als gestartet, wenn die dazugehörige Zahlung ausdrücklich vom TWINT bestätigt wurde.

### 8. Parkbussen

Erhält der Benutzer eine Parkbusse und bestreitet er diese, so hat er sich ausschliesslich an die auf dem entsprechenden Bussenbeleg aufgeführte Stelle zu wenden.

Wenn nachweislich ein korrekter Parkvorgang mit dem Parkingpay-System stattgefunden hat, findet der Benutzer den entsprechenden Beleg als URL in der Transaktionshistorie der TWINT-App. Über eine Aufhebung einer Parkbusse entscheidet in jedem Falle die zuständige Stelle. Digitalparking haftet in keinem Fall für zu bezahlende Parkbussen.

Höchstparkzeiten und Nachzahlungsverbote gelten in jedem Fall auch bei Nutzung des Parkingpay-Systems. Beim Erreichen der geltenden Höchstparkzeit wird der Parkvorgang automatisch beendet.

Nach dem Ablauf jedes Parkvorgangs muss das Fahrzeug wieder in den Verkehr eingefügt werden. Ist dies nicht der Fall und es wird ein neuer Parkvorgang gestartet, muss trotz laufendem Parkvorgang mit einer Parkbusse gerechnet werden. Ein blosses Verschieben des Fahrzeuges auf einen anderen, in der Nähe liegenden Parkplatz ist unzulässig. Davon ausgenommen sind Parkplätze, bei welchen das Nachzahlen gemäss den geltenden Bestimmungen zulässig ist.

### 9. Haftung

Digitalparking haftet nach den gesetzlichen Bestimmungen für Schäden, sofern diese auf Vorsatz, grobe Fahrlässigkeit oder die schuldhafte Verletzung wesentlicher Vertragspflichten von Digitalparking zurückzuführen sind.

Digitalparking übernimmt keine Verantwortung oder Haftung für die ständige Verfügbarkeit der Parkingpay-Funktionen, insbesondere nicht bezüglich der Verfügbarkeit der Mobilfunknetze und der Funktionsfähigkeit von mobilen Endgeräten.

Bei Nichtverfügbarkeit des Parkingpay-Systems kann Digitalparking nicht für die entstehenden Folgekosten wie Parkbussen, Abschleppgebühren etc. verantwortlich gemacht werden.

Für fehlerhafte oder nicht erfolgte Beendigung von Parkvorgängen übernimmt Digitalparking keine Haftung.

### 10. Änderungen oder Ergänzungen dieser Geschäftsbedingungen

Digitalparking behält sich vor, diese AGB jederzeit und ohne Nennung von Gründen zu ändern.

Änderungen oder Ergänzungen werden dem Benutzer bei der nächsten Nutzung der Parkingpay-Funktionen eingeblendet.

Sofern eine Bestimmung dieser AGB unwirksam ist, bleiben die übrigen Bestimmungen davon unberührt. Die unwirksame Bestimmung gilt als durch eine solche ersetzt, die dem Sinn und Zweck der unwirksamen Bestimmung in rechtswirksamer Weise wirtschaftlich am nächsten kommt. Gleiches gilt für eventuelle Regelungslücken.

Mündliche Nebenabreden haben keine Gültigkeit.

### 11. Anwendbares Recht und Gerichtsstand

Das Vertragsverhältnis unterliegt Schweizer Recht. Als Gerichtsstand gilt Schlieren.
`,
    };
}
