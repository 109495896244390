import { createTheme } from '@mui/material';

const primary = '#ff8c00';
export const parkingaboCoopMuiTheme = createTheme({
    typography: {
        fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
        fontWeightMedium: 600,
        fontWeightBold: 800,
        h1: {
            // color: '#ff8c00',
            fontWeight: 600,
            fontSize: 34,
        },
        h2: {
            fontSize: 22,
            // color: '#ff8c00',
            fontWeight: 800,
        },
        h3: {
            fontSize: 18,
            fontWeight: 800,
            // color: '#ff8c00'
        },
    },
    // @ts-ignore
    palette: {
        background: {
            default: '#fff',
        },
        primary: {
            main: primary,
            contrastText: '#fff',
        },
        warning: {
            main: 'rgb(255, 152, 0)',
            contrastText: '#fff',
        },
    },
    shape: {
        borderRadius: 10,
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    fontSize: '0.875rem',
                    lineHeight: 1.43,
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: ({ ownerState }) => ({
                    ...(ownerState.color !== 'inherit' && {
                        color: 'black',
                        '&:hover': {
                            color: primary,
                        },
                        fontWeight: 700,
                    }),
                }),
            },
            defaultProps: {
                underline: 'hover',
            },
        },
        MuiDialog: {
            styleOverrides: {
                paperFullWidth: {
                    borderRadius: 8,
                },
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    padding: '10px 16px',
                    fontSize: 16,
                },
            },
        },
        MuiAlert: {
            styleOverrides: {
                root: ({ ownerState: { variant, color } }) => ({
                    ...(variant === 'standard' &&
                        color === 'info' && {
                            color: 'black',
                            backgroundColor: '#f5f3f0',
                            borderTop: '2px solid #ff8c00',

                            '& .MuiAlert-icon': {
                                display: 'none',
                            },
                        }),
                }),
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    '&$focused': {
                        color: 'black',
                    },
                },
            },
        },
        MuiInput: {
            styleOverrides: {
                root: {
                    '&$focused': {
                        '&::after': {
                            borderColor: 'black',
                        },
                    },
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    borderRadius: 4,
                    fontWeight: 700,
                    minWidth: 32,
                },
                label: {
                    paddingLeft: 0,
                    paddingRight: 0,
                },
            },
        },
        MuiTextField: {
            defaultProps: {
                fullWidth: true,
                variant: 'standard',
            },
        },
        MuiSelect: {
            defaultProps: {
                variant: 'standard',
            },
        },
    },
});
