import * as React from 'react';
import * as superagent from 'superagent';
import { RequestStatus } from '../../lib/hooks/ServerStateHooks';
import { generateState } from '../../lib/Flux';
import { TwintParkingpayPendingState } from './TwintPairingParkingpay';

export enum PairingState {
    UNKNOWN = 'UNKNOWN',
    CREATED = 'CREATED',
    CONFIRMED_BY_USER = 'CONFIRMED_BY_USER',
    SUCCESS = 'SUCCESS',
    ABORTED = 'ABORTED',
}

export namespace PendingTwintPairing {
    export enum Mode {
        PAYMENT = 'PAYMENT',
        UOF = 'UOF',
    }

    export interface State {
        paymentAttemptId: number | null;
        pairingToken: string | null;
        clientState: ClientState;
        mode: Mode;
    }

    export const { get, set, reset } = generateState<State>(
        'twint-pairing-token',
        {
            pairingToken: null,
            paymentAttemptId: null,
            clientState: null,
            mode: Mode.PAYMENT,
        },
    );
}

export type TwintPaymentStateUnknown = {
    mode: PendingTwintPairing.Mode.PAYMENT;
    state: PairingState.UNKNOWN;
    requestState: RequestStatus;
};
export type TwintPaymentStateKnown = {
    mode: PendingTwintPairing.Mode.PAYMENT;
    state: PairingState;
    requestState: RequestStatus;
    amount: string;
};
type TwintPaymentState = TwintPaymentStateUnknown | TwintPaymentStateKnown;
export interface TwintUofStateUnknown {
    mode: PendingTwintPairing.Mode.UOF;
    state: PairingState.UNKNOWN;
    requestState: RequestStatus;
}
export interface TwintUofStateKnown {
    mode: PendingTwintPairing.Mode.UOF;
    state: PairingState;
    requestState: RequestStatus;
}

type TwintUofState = TwintUofStateUnknown | TwintUofStateKnown;
export type TwintPendingState = TwintPaymentState | TwintUofState;
export type ClientState = null | 'SUCCESS' | 'NO_TWINT' | 'CANCELED' | 'ERROR';

export enum UoFState {
    CREATED = 'CREATED',
    REQUESTED = 'REQUESTED',
    PAIRED = 'PAIRED',
    ABORTED = 'ABORTED',
    SUCCESS = 'SUCCESS',
}

export function transactionFromUoFState(state: UoFState) {
    switch (state) {
        case UoFState.ABORTED:
            return PairingState.ABORTED;
        case UoFState.SUCCESS:
            return PairingState.SUCCESS;
        case UoFState.PAIRED:
            return PairingState.CONFIRMED_BY_USER;
        case UoFState.REQUESTED:
        case UoFState.CREATED:
            return PairingState.CREATED;
    }
}

export interface TwintUoFStateResponse {
    state: UoFState;
}

export type TwintPairingState = (
    mode: PendingTwintPairing.Mode,
    requestCounter: number,
    token: string | null,
    paymentAttemptId: number | null,
) => TwintPendingState | TwintParkingpayPendingState;

export function abortTwintTransaction(
    abortRequest: superagent.Request<any, any>,
    onDone?: () => void,
) {
    const onEnd = () => {
        if (onDone) {
            onDone();
        }
    };
    return abortRequest.end(onEnd);
}

export function useTwintState<T extends TwintPendingState>(
    token: string | null,
    paymentAttemptId: number | null,
    mode: PendingTwintPairing.Mode,
    clientState: ClientState = null,
    fluxLogger: (action: string, payload: any) => any,
    stateState: TwintPairingState,
    abortRequest: superagent.Request<any, any>,
): T {
    const [requestCounter, setRequestCounter] = React.useState(0);
    const [lastState, setLastState] = React.useState<
        TwintPendingState | TwintParkingpayPendingState
    >(
        mode === PendingTwintPairing.Mode.PAYMENT
            ? {
                  mode: PendingTwintPairing.Mode.PAYMENT,
                  state: PairingState.UNKNOWN,
                  requestState: RequestStatus.NEVER_EXECUTED,
              }
            : {
                  mode: PendingTwintPairing.Mode.UOF,
                  state: PairingState.UNKNOWN,
                  requestState: RequestStatus.NEVER_EXECUTED,
              },
    );
    const pairingState = stateState(
        mode,
        requestCounter,
        token,
        paymentAttemptId,
    );

    React.useEffect(() => {
        if (
            pairingState.state === PairingState.SUCCESS ||
            pairingState.state === PairingState.ABORTED
        ) {
            return;
        }
        const interval = setInterval(() => {
            if (pairingState.requestState !== RequestStatus.PENDING) {
                setRequestCounter(requestCounter => requestCounter + 1);
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [pairingState]);

    React.useEffect(() => {
        if (clientState === 'ERROR') {
            fluxLogger('twint-abort-client-error', pairingState);
            abortTwintTransaction(abortRequest);
        }
    }, [clientState]);

    React.useEffect(() => {
        if (
            pairingState.state !== PairingState.UNKNOWN &&
            pairingState.state !== lastState.state
        ) {
            setLastState(pairingState);
        }
    }, [pairingState.state]);

    return <T>lastState;
}
