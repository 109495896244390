import * as React from 'react';
import { Popper, Typography } from '@mui/material';
import { useLanguage } from '../../../common/hooks/LanguageProvider';
import { Language } from '../../../lib/Localized';
import { Theme } from '@mui/material/styles';

export function LanguageSelector() {
    const { language, setLanguage } = useLanguage();
    const [popoverOpen, setPopoverOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    function handleLanguageSelection(lng: Language) {
        setLanguage(lng);
        setPopoverOpen(false);
    }

    function handleToggle(evt: React.MouseEvent<HTMLElement>) {
        setAnchorEl(evt.currentTarget);
        setPopoverOpen(o => !o);
    }

    function generateStyle(theme: Theme) {
        return {
            border: 'solid 1px',
            borderBottom: 'solid 0px',
            borderColor: theme.palette.primary.contrastText,
            borderRadius: theme.shape.borderRadius,
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            paddingX: theme.spacing(1),
            paddingY: theme.spacing(0.5),
            textAlign: 'center',
            textTransform: 'uppercase',
            cursor: 'pointer',
            width: 36,
        };
    }

    return (
        <>
            <Typography
                component="button"
                onClick={handleToggle}
                variant="body2"
                sx={theme => ({
                    ...generateStyle(theme),
                    borderBottom: 'solid 1px',
                })}
            >
                {language}
            </Typography>
            <Popper anchorEl={anchorEl} open={popoverOpen} placement="top-end">
                {Object.entries(Language)
                    .filter(([_, value]) => value !== language)
                    .map(([key, value]) => (
                        <Typography
                            component="div"
                            variant="body2"
                            key={value}
                            onClick={() => handleLanguageSelection(value)}
                            sx={generateStyle}
                        >
                            {key}
                        </Typography>
                    ))}
            </Popper>
        </>
    );
}
